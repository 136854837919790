import React from 'react';
import { MasterPageHeader } from '../../../components/master-page-header/master-page-header';
import { Form, Button, Alert } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { MasterContent } from '../../../master-page/components/master-content/master-content';
import { InjectedFormProps, reduxForm, getFormValues } from 'redux-form';
import { FormContainer } from '../../../components/form-container/form-container';
import { getInProgress, getProgress } from '../../../../logic/store/process-tracker/process-tracker.selectors';
import { translate, translationKeys } from '../../../../logic/translations/translations.service';
import { ImportUsersForm, IMPORT_USERS_FORM, validateImportUsersForm } from './import-users.form';
import { userActionCreators } from '../../../../logic/store/user/user.action';
import { ImportUsersFormFields } from './import-users.fields';
import {
  getAuthenticatedUserCompanyId,
  getAuthenticatedUserRole,
} from '../../../../logic/store/authentication/authentication.selectors';
import { store } from '../../../../app/app.store';
import { Role } from '../../../../api-models/api-models';
import { ImportUsersFormFieldName } from './import-users.field-name';
import { getProgressSpinner } from '../../../components/progress-spinner/progress-spinner';
import { ImportUsersResultPanel } from './result-panel/import-users-result-panel';
import { getImportUsersResult } from '../../../../logic/store/user/user.selector';
import { ResourceLinks } from '../../../components/resources/resource-link';
import { Resource } from '../../../components/resources/resource.const';
import './import-users.styles.scss';

interface ImportUsersPageProps extends InjectedFormProps<ImportUsersForm> {}

const ImportUsers: React.FC<ImportUsersPageProps> = ({ error }) => {
  const dispatch = useDispatch();
  const inProgress = useSelector(getInProgress(userActionCreators.importUsers.name));
  const progress = useSelector(getProgress(userActionCreators.importUsers.name));
  const disabledFields = [ImportUsersFormFieldName.company_id];
  const formValues: ImportUsersForm = useSelector(getFormValues(IMPORT_USERS_FORM)) || {};

  const handleSubmitAction = () => dispatch(userActionCreators.importUsers(IMPORT_USERS_FORM, formValues));

  const progressSpinner = getProgressSpinner(inProgress, progress);
  const importUsersResult = useSelector(getImportUsersResult);

  return (
    <>
      <MasterPageHeader
        showBack
        title={translate(translationKeys.pages.importUsers.title)}
        content={
          <>
            <div className={'text-centered'}>{translate(translationKeys.pages.importUsers.description)}</div>
            <div className={'text-centered'}>
              <ResourceLinks resources={[Resource.importUsersTemplateCSV]} />
            </div>
          </>
        }
      />
      <MasterContent>
        <FormContainer className={'import-form-container'} inProgress={inProgress} progressSpinner={progressSpinner}>
          <ImportUsersFormFields inProgress={inProgress} disabledFields={disabledFields} />

          <Form.Item>
            <Button
              type={'primary'}
              className={'form-button'}
              onClick={handleSubmitAction}
              disabled={inProgress}
              loading={inProgress}
            >
              {translate(translationKeys.buttons.importUsers)}
            </Button>
          </Form.Item>
        </FormContainer>

        <ImportUsersResultPanel
          key={1}
          type={'success'}
          title={translate(translationKeys.pages.importUsers.usersWasSuccessfullyImported, {
            count: importUsersResult?.OK.total,
          })}
          show={!!importUsersResult?.OK.total}
        />
        <ImportUsersResultPanel
          key={2}
          type={'info'}
          title={translate(translationKeys.pages.importUsers.usersWasSkipped, {
            count: importUsersResult?.SKIPPED.total,
          })}
          show={!!importUsersResult?.SKIPPED.total}
          results={importUsersResult?.SKIPPED}
        />
        <ImportUsersResultPanel
          key={3}
          type={'error'}
          title={translate(translationKeys.pages.importUsers.usersHasValidationErrors, {
            count: importUsersResult?.ERROR.total,
          })}
          show={!!importUsersResult?.ERROR.total}
          results={importUsersResult?.ERROR}
        />
        {error && (
          <Alert message={translate(translationKeys.errors.error)} description={error} type={'error'} showIcon />
        )}
      </MasterContent>
    </>
  );
};

export const importUsersPageCreator = () => {
  const role = getAuthenticatedUserRole(store.getState());
  const company_id = role !== Role.SuperAdmin ? getAuthenticatedUserCompanyId(store.getState()) : undefined;

  return reduxForm({
    form: IMPORT_USERS_FORM,
    initialValues: {
      company_id,
    },
    validate: validateImportUsersForm,
  })(ImportUsers);
};
