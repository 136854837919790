import React from 'react';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import { SUPPORT_CONTACT_FORM } from './support.contact-form.form';
import { SupportContactFormFieldName } from './support.contact-form.field-name';
import { translate, translationKeys } from '../../logic/translations/translations.service';
import { FormTextInput } from '../components/form-inputs/form-text-input/form-text-input';
import { trimValue } from '../../logic/formaters/formaters';
import { Form, Alert, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { userActionCreators } from '../../logic/store/user/user.action';
import { compose } from 'redux';
import { getInProgress } from '../../logic/store/process-tracker/process-tracker.selectors';
import { mapUserDataToContactSupportForm } from '../users/pages/user-form-template/user-form.form';
import { FormContainer } from '../components/form-container/form-container';

interface SupportContactFormProps extends InjectedFormProps {}

const SupportContact: React.FC<SupportContactFormProps> = ({ error }) => {
  const dispatch = useDispatch();

  const handleSubmitAction = () => {
    dispatch(userActionCreators.submitSupportForm(SUPPORT_CONTACT_FORM, mapUserDataToContactSupportForm));
  };

  const inProgress = useSelector(getInProgress(userActionCreators.submitSupportForm.name));

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 14, offset: 1 },
    },
  };

  const formButtonLayout = {
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 14, offset: 5 },
    },
  };

  const commonAttributes = (disabled: boolean) => ({
    ...formItemLayout,
    disabled,
    onPressEnter: handleSubmitAction,
  });

  const translateFieldLabel = (fieldName: SupportContactFormFieldName) =>
    translate(translationKeys.forms.fields.supportContactForm[fieldName]);

  const getTextField = (
    fieldName: SupportContactFormFieldName,
    disabled?: boolean,
    required?: boolean,
    rows?: number
  ) => (
    <Field
      name={fieldName}
      component={FormTextInput}
      label={translateFieldLabel(fieldName)}
      required={required}
      onBlurFormater={trimValue}
      rows={rows}
      {...commonAttributes(disabled || inProgress)}
    />
  );

  return (
    <FormContainer className="support-form">
      {getTextField(SupportContactFormFieldName.first_name, true)}
      {getTextField(SupportContactFormFieldName.last_name, true)}
      {getTextField(SupportContactFormFieldName.company, false, false)}
      {getTextField(SupportContactFormFieldName.email, true)}
      {getTextField(SupportContactFormFieldName.phone, false, true)}
      {getTextField(SupportContactFormFieldName.message, false, true, 4)}

      {error && (
        <Form.Item {...formButtonLayout}>
          <Alert message={translate(translationKeys.errors.error)} description={error} type={'error'} showIcon />
        </Form.Item>
      )}

      <Form.Item {...formButtonLayout}>
        <Button
          type={'primary'}
          className={'form-button'}
          onClick={handleSubmitAction}
          disabled={inProgress}
          loading={inProgress}
        >
          {translate(translationKeys.buttons.submit)}
        </Button>
      </Form.Item>
    </FormContainer>
  );
};

export const SupportContactForm = compose(
  reduxForm({
    form: SUPPORT_CONTACT_FORM,
  })
)(SupportContact);
