import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Checkbox, Col, Layout, Modal, Row, Space } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

import { VideoSection } from '../video-section/video-section';
import { Resource } from '../resources/resource.const';
import { translate, translationKeys } from '../../../logic/translations/translations.service';
import { navigationActionCreators } from '../../../logic/store/navigation/navigation.actions';
import { AUTHENTICATED_ROUTES } from '../../../logic/routes/routes.const';
import { getAuthenticatedUser } from '../../../logic/store/authentication/authentication.selectors';
import { userActionCreators } from '../../../logic/store/user/user.action';
import { WELCOME_SESSION_STORAGE_TAG, getWelcomePopupVisibility } from './greetings-modal.helper';

import './greetings-modal.styles.scss';

const GreetingsModal = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isCheckboxSelected, setIsCheckboxSelected] = useState(false);

  const dispatch = useDispatch();
  const authenticatedUser = useSelector(getAuthenticatedUser);

  const layout = {
    md: 24,
    lg: 12,
  };

  const hideModal = () => {
    setIsVisible(false);
    sessionStorage.setItem(WELCOME_SESSION_STORAGE_TAG, '1');

    if (isCheckboxSelected) {
      dispatch(userActionCreators.setWelcomePopupDone(authenticatedUser?.id));
    }
  };

  useEffect(() => {
    setIsVisible(getWelcomePopupVisibility(authenticatedUser));
  }, [authenticatedUser]);

  const onOnboardingClick = () => {
    window.open('https://calendly.com/teamalert-support-on-boarding/30-on-boarding-session-clone', '_blank');
  };

  const onHelpClick = () => dispatch(navigationActionCreators.navigateTo(AUTHENTICATED_ROUTES.Support));

  const onCheckboxChange = (e: CheckboxChangeEvent) => {
    setIsCheckboxSelected(e.target.checked);
  };

  return (
    <Modal
      open={isVisible}
      onOk={hideModal}
      onCancel={hideModal}
      width={750}
      bodyStyle={{ padding: 10 }}
      footer={null}
      centered
      destroyOnClose
    >
      <Space direction="vertical" size="middle">
        <GreetingsTitle>{translate(translationKeys.pages.dashboard.welcomePopup.title)}</GreetingsTitle>
        <GreetingsVideoSection>
          <VideoSection res={Resource.newUserVideo} posterRes={Resource.newUserCover} controls={false} />
        </GreetingsVideoSection>
        <GreetingsText>{translate(translationKeys.pages.dashboard.welcomePopup.text)}</GreetingsText>
        <GreetingsRow>
          <GreetingsCol {...layout}>
            <GreetingsButton onClick={onOnboardingClick} type="primary" size="large">
              {translate(translationKeys.pages.dashboard.welcomePopup.buttons.onboarding)}
            </GreetingsButton>
          </GreetingsCol>
          <GreetingsCol {...layout}>
            <GreetingsButton onClick={onHelpClick} type="primary" size="large">
              {translate(translationKeys.pages.dashboard.welcomePopup.buttons.help)}
            </GreetingsButton>
          </GreetingsCol>
        </GreetingsRow>
        <Layout style={{ display: 'flex', alignItems: 'center' }}>
          <Checkbox onChange={onCheckboxChange}>
            {translate(translationKeys.pages.dashboard.welcomePopup.checkbox)}
          </Checkbox>
        </Layout>
      </Space>
    </Modal>
  );
};

const GreetingsTitle = styled.div`
  font-size: 24px;
  color: #005197;
  text-align: center;
  font-weight: bold;
`;

const GreetingsText = styled.div`
  font-size: 14px;
  font-weight: 500;
  text-align: center;

  @media screen and (min-width: 820px) {
    margin-left: 70px;
    margin-right: 70px;
    line-height: 1.35;
  }
`;

const GreetingsButton = styled(Button)`
  @media screen and (min-width: 820px) {
    width: 250px;
  }
`;

const GreetingsRow = styled(Row)``;

const GreetingsCol = styled(Col)`
  justify-content: center;
  text-align: center;
  margin-top: 10px;

  @media screen and (min-width: 820px) {
    margin-top: 0px;
  }
`;

const GreetingsVideoSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default GreetingsModal;
