export const colors = {
  white: '#fff',
  black: '#000',
  alto: '#d9d9d9',
  blackPearl: '#041c2b',
  paleSky: '#68767f;',
  curiousBlue: '#3298d4',
  dodgerBlue: '#427CFF',
  silverChalice: '#adadad',
  mineShaft: '#313131',
  sushi: '#73b246',
  sun: '#faad14',
  sunsetOrange: '#ff4d4f',
};

export const featureColor = {
  primary: colors.dodgerBlue,
  secondary: colors.sushi,
  border: colors.alto,
};
