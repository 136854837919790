import {
  AccountStatus,
  ImportUserResponseRowError,
  ImportUserFileStructureError,
  UserBulkOperationResponse,
  User,
} from '../../../api-models/api-models';
import { isString, isEmpty, isArray, isObject, uniq } from 'lodash';
import { AxiosError } from 'axios';
import { NetworkCode } from '../../../models/network-code';
import { errorFieldAndValuesToString } from '../../server-error-parser/server-error-parser';
import { reduxFormAsyncErrorsHandler } from '../../error-handlers/redux-form-error-handler';

export const shouldActivateAccount = (accountStatus: AccountStatus | undefined) =>
  accountStatus === AccountStatus.NotActive;

export const EMAIL_EXISTS_ERROR_MESSAGE = 'user with this email already exists.';

export const isEmaiExistsError = (error: ImportUserResponseRowError | undefined) => {
  const email = error?.email || [];
  const emailError = (isString(email) ? [email] : email).join();

  return emailError.indexOf(EMAIL_EXISTS_ERROR_MESSAGE) >= 0;
};

export function* importUsersFileStructureErrorHandler(error: AxiosError<string[]>, reduxFormName: string) {
  const statusCode = error.response && error.response.status;

  if (statusCode === NetworkCode.UnprocessableEntity) {
    const data = error.response?.data as ImportUserFileStructureError[] | ImportUserFileStructureError | undefined;

    const reduxFormErrors = {
      _error: isArray(data)
        ? data?.map(row => errorFieldAndValuesToString(row.error)).join(', ')
        : isObject(data)
        ? errorFieldAndValuesToString(data.error)
        : '',
    };

    if (reduxFormErrors && !isEmpty(reduxFormErrors)) {
      yield reduxFormAsyncErrorsHandler(reduxFormName, reduxFormErrors);
    }

    return true;
  }

  return false;
}

export const getUserBulkOperationSuccessfullCount = (response: UserBulkOperationResponse | undefined) =>
  response?.results?.filter(result => !result.error).length || 0;

export const getUserBulkOperationFailedCount = (response: UserBulkOperationResponse | undefined) =>
  response?.results?.filter(result => !!result.error).length || 0;

export const getFirstSuccessfullUserName = (
  response: UserBulkOperationResponse | undefined,
  users: (User | undefined)[] | undefined
) => {
  const index = response?.results?.findIndex(result => !result.error);
  const user = users && index !== undefined && index >= 0 ? users[index] : undefined;
  const userName = user ? `${user.first_name || ''} ${user.last_name || ''}` : '';
  return userName;
};

export const getFirstFailedUserName = (
  response: UserBulkOperationResponse | undefined,
  users: (User | undefined)[] | undefined
) => {
  const index = response?.results?.findIndex(result => !!result.error);
  const user = users && index !== undefined && index >= 0 ? users[index] : undefined;
  const userName = user ? `${user.first_name || ''} ${user.last_name || ''}` : '';
  return userName;
};

export const getUniqueErrorMessages = (response: UserBulkOperationResponse | undefined) => {
  const errorMessages = response?.results
    ?.filter(result => !!result.error)
    .map(result => result.error)
    .filter(error => !!error);

  const result = uniq(errorMessages);
  return result.join(', ');
};
