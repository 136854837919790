import { DEFAULT_PHONE_COUNTRY_CODE } from '../../../authentication/models/country-code/country-code';
import { EmergencyMode, Location } from '../../../../api-models/api-models';
import { FormErrors } from 'redux-form';
import { clearEmptyFieldsAndEmptyObjects } from '../../../../logic/validators/utils';
import { isValidPhoneNumber } from '../../../../logic/phone/phone.validator';
import { mapPhoneToCountryCodeAndNumber, parsePhoneNumberForPayload } from '../../../../logic/phone/phone.mapper';
import { isGeolocationValid } from '../../../../logic/geolocation/geolocation.validator';
import { LocationForm } from '../../../../common/location/location.type';
import { devFieldsLocation } from '../../../../logic/location/location.helper';
import { isRequiredValidator } from '../../../../logic/validators/validators';

export type LocationFormMeta = {
  [K in keyof Location]: {
    visited?: boolean;
    touched?: boolean;
    active?: boolean;
  };
};

export interface GetSingleLocationResponse {
  address: string;
  city: string;
  company_id: number;
  country: string;
  e911_phone: string;
  id: number;
  is_geolocation_enabled: boolean;
  latitude: string;
  longitude: string;
  name: string;
  range_size: number;
  state: string;
  zip_code: string;
}

const flattenGeoposition = (geolocation: LocationForm['geolocation']) => {
  if (!geolocation) {
    return {
      latitude: undefined,
      longitude: undefined,
      range_size: undefined,
    };
  }

  return {
    latitude: geolocation.lat.toString(),
    longitude: geolocation.lng.toString(),
    range_size: geolocation.radius,
  };
};

export const locationE911FormInitialValues: LocationForm = {
  ...devFieldsLocation,
  is_e911_enabled: true,
  first_emergency_contact_phone_country_code: DEFAULT_PHONE_COUNTRY_CODE,
  second_emergency_contact_phone_country_code: DEFAULT_PHONE_COUNTRY_CODE,
  third_emergency_contact_phone_country_code: DEFAULT_PHONE_COUNTRY_CODE,
  emergency_mode: EmergencyMode.E911,
  monitoring_company_phone_number_country_code: DEFAULT_PHONE_COUNTRY_CODE,
};

export const createGeolocation = ({ latitude, longitude, range_size }: GetSingleLocationResponse) => {
  if (!parseFloat(longitude) || !parseFloat(latitude) || !range_size) return undefined;

  return {
    lng: parseFloat(longitude),
    lat: parseFloat(latitude),
    radius: range_size,
  };
};

export const createLocationFormData = (data: GetSingleLocationResponse): LocationForm => {
  const geolocation = createGeolocation(data);
  const { latitude, range_size, longitude, ...rest } = data;

  return {
    ...rest,
    geolocation,
  };
};

const validateFieldForEmergencyMode = (emergency_mode?: EmergencyMode, value?: string) => {
  return emergency_mode === EmergencyMode.E911 ? isRequiredValidator(value) : undefined;
};

const clearValueIfMonitoring = (emergency_mode?: EmergencyMode, value?: string) => {
  // Fix for normal location (not e911)
  if (!emergency_mode) {
    return value;
  }

  return emergency_mode === EmergencyMode.E911 ? value : undefined;
};

export const validateLocationForm = (formValues: LocationForm): FormErrors<LocationForm, string> => {
  const {
    first_emergency_contact_phone,
    first_emergency_contact_phone_country_code,

    second_emergency_contact_phone,
    second_emergency_contact_phone_country_code,

    third_emergency_contact_phone,
    third_emergency_contact_phone_country_code,

    geolocation,
    is_geolocation_enabled,

    emergency_mode,
    monitoring_company_phone_number,
    monitoring_company_phone_number_country_code,

    address,
    city,
    zip_code,
    state,
    country,
  } = formValues;

  return clearEmptyFieldsAndEmptyObjects({
    first_emergency_contact_phone: !!first_emergency_contact_phone
      ? isValidPhoneNumber(first_emergency_contact_phone, first_emergency_contact_phone_country_code)
      : undefined,
    second_emergency_contact_phone: !!second_emergency_contact_phone
      ? isValidPhoneNumber(second_emergency_contact_phone, second_emergency_contact_phone_country_code)
      : undefined,
    third_emergency_contact_phone: !!third_emergency_contact_phone
      ? isValidPhoneNumber(third_emergency_contact_phone, third_emergency_contact_phone_country_code)
      : undefined,
    monitoring_company_phone_number:
      !!monitoring_company_phone_number && emergency_mode === EmergencyMode.Monitoring
        ? isValidPhoneNumber(monitoring_company_phone_number, monitoring_company_phone_number_country_code)
        : undefined,

    address: validateFieldForEmergencyMode(emergency_mode, address),
    city: validateFieldForEmergencyMode(emergency_mode, city),
    zip_code: validateFieldForEmergencyMode(emergency_mode, zip_code),
    state: validateFieldForEmergencyMode(emergency_mode, state),
    country: validateFieldForEmergencyMode(emergency_mode, country),

    geolocation: is_geolocation_enabled ? isGeolocationValid(geolocation) : undefined,
  });
};

export const locationToFormValuesMapper = (location: Location): LocationForm => {
  const first_emergency_contact_phone_split = mapPhoneToCountryCodeAndNumber(location.first_emergency_contact_phone);
  const second_emergency_contact_phone_split = mapPhoneToCountryCodeAndNumber(location.second_emergency_contact_phone);
  const third_emergency_contact_phone_split = mapPhoneToCountryCodeAndNumber(location.third_emergency_contact_phone);
  const monitoring_company_phone_number_split = mapPhoneToCountryCodeAndNumber(
    location.monitoring_company_phone_number
  );

  return {
    ...location,
    first_emergency_contact_phone_country_code: first_emergency_contact_phone_split?.code || DEFAULT_PHONE_COUNTRY_CODE,
    first_emergency_contact_phone: first_emergency_contact_phone_split?.number || undefined,
    second_emergency_contact_phone_country_code:
      second_emergency_contact_phone_split?.code || DEFAULT_PHONE_COUNTRY_CODE,
    second_emergency_contact_phone: second_emergency_contact_phone_split?.number || undefined,
    third_emergency_contact_phone_country_code: third_emergency_contact_phone_split?.code || DEFAULT_PHONE_COUNTRY_CODE,
    third_emergency_contact_phone: third_emergency_contact_phone_split?.number || undefined,
    monitoring_company_phone_number: monitoring_company_phone_number_split?.number,
    monitoring_company_phone_number_country_code: monitoring_company_phone_number_split?.code,
  };
};

export const formValuesToLocationMapper = (formValues: LocationForm): Location => {
  const {
    first_emergency_contact_phone,
    first_emergency_contact_phone_country_code,

    second_emergency_contact_phone,
    second_emergency_contact_phone_country_code,

    third_emergency_contact_phone,
    third_emergency_contact_phone_country_code,

    geolocation,

    monitoring_company_phone_number,
    monitoring_company_phone_number_country_code,

    emergency_mode,

    address,
    city,
    zip_code,
    state,
    country,
  } = formValues;

  return {
    ...formValues,
    first_emergency_contact_phone: parsePhoneNumberForPayload(
      first_emergency_contact_phone_country_code,
      first_emergency_contact_phone
    ),
    second_emergency_contact_phone: parsePhoneNumberForPayload(
      second_emergency_contact_phone_country_code,
      second_emergency_contact_phone
    ),
    third_emergency_contact_phone: parsePhoneNumberForPayload(
      third_emergency_contact_phone_country_code,
      third_emergency_contact_phone
    ),
    monitoring_company_phone_number: parsePhoneNumberForPayload(
      monitoring_company_phone_number_country_code,
      monitoring_company_phone_number
    ),

    // For Monitoring emergency mode we don't want to send address info
    address: clearValueIfMonitoring(emergency_mode, address),
    city: clearValueIfMonitoring(emergency_mode, city),
    zip_code: clearValueIfMonitoring(emergency_mode, zip_code),
    state: clearValueIfMonitoring(emergency_mode, state),
    country: clearValueIfMonitoring(emergency_mode, country),

    ...flattenGeoposition(geolocation),
  };
};
