import { axiosAuthenticatedClient } from '../../axios/axios-authenticated-client';
import {
  CompanyForm,
  formValuesToCompanyMapper,
} from '../../../pages/company/pages/company-form-template/company-form.form';
import { env } from '../../env/env';

export const companyApi = {
  fetchSingle: (id: any) => axiosAuthenticatedClient().get(`${env.API_VERSION}/company/${id}`),
  addSingle: (formValues: CompanyForm) =>
    axiosAuthenticatedClient().post(`${env.API_VERSION}/company/`, formValuesToCompanyMapper(formValues)),
  updateSingle: (formValues: CompanyForm) =>
    axiosAuthenticatedClient().put(`${env.API_VERSION}/company/${formValues.id}/`, formValuesToCompanyMapper(formValues)),
  deleteSinble: (id: number) => axiosAuthenticatedClient().delete(`${env.API_VERSION}/company/${id}`),
  setupCompany: (id: number) => axiosAuthenticatedClient().post(`${env.API_VERSION}/company/${id}/initial_setup/`),
};
