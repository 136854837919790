import { call, put, select, takeEvery } from 'redux-saga/effects';
import {
  authenticationActionCreators,
  AuthenticationActions,
  AuthenticationActionTypes,
} from './authentication.actions';
import { SagaManager } from '../../saga-manager/saga-manager';
import { navigationActionCreators } from '../navigation/navigation.actions';
import { logActionCreators } from '../log/log.actions';
import { authenticationApi } from './authentication.api';
import { AUTHENTICATION_ROUTES, defaultRoute, LANDING_ROUTES } from '../../routes/routes.const';
import { LOGIN_FROM, LoginForm, validateLoginForm } from '../../../pages/authentication/pages/login/login.form';
import { CognitoAuthenticatedUser, CognitoChallengeName, CognitoChallengeUser } from './authentication.models';
import {
  COMPLETE_NEW_PASSWORD_FORM_NAME,
  validateCompleteNewPasswordForm,
} from '../../../pages/authentication/pages/complete-new-password/complete-new-password.form';
import {
  FORGOT_PASSWORD_FORM_NAME,
  validateForgotPasswordForm,
} from '../../../pages/authentication/pages/forgot-password/forgot-password.form';
import {
  RESET_PASSWORD_FORM_NAME,
  validateResetPasswordForm,
} from '../../../pages/authentication/pages/reset-password/reset-password.form';
import { SIGNUP_FORM, validateSignupForm } from '../../../pages/authentication/pages/signup/signup.form';
import { parseNumber } from '../../utils/number';
import { userActionCreators } from '../user/user.action';
import { companyActionCreators } from '../company/company.action';
import { groupActionCreators } from '../group/group.action';
import { tableActionCreators } from '../tables/tables.action';
import { alertActionCreators } from '../alert/alert.action';
import { companyApi } from '../company/company.api';
import { ApiResponse } from '../../axios/axios-api-response';
import { listsActionCreators } from '../lists/lists.action';
import { Company, User } from '../../../api-models/api-models';
import { cognitoErrorHandler } from './cognito.error-handlers';
import { ReturnActionOfType } from '../../redux/action-creator';
import { getAuthenticatedUserCompanyId, getAuthenticatedUserId } from './authentication.selectors';
import { activateUserSagaManaged } from '../user/user.saga';
import { shouldActivateAccount } from '../user/user.logic';
import { locationE911ActionCreators } from '../location/location-e911/location-e911.action';
import { initialize } from 'redux-form';
import { queryStringStringify } from '../../query-string/query-string';
import { AxiosResponse } from 'axios';
import { autocompleteActionCreators } from '../autocomplete/autocomplete.action';
import { locationActionCreators } from '../location/location/location.action';

export function* authenticationSagaWatch() {
  yield takeEvery(AuthenticationActionTypes.INITIALIZE_LOGIN_FORM, initializeLoginFormSaga);
  yield takeEvery(AuthenticationActionTypes.LOGIN, loginSagaManaged);
  yield takeEvery(AuthenticationActionTypes.COMPLETE_NEW_PASSWORD, completeNewPasswordSagaManaged);
  yield takeEvery(AuthenticationActionTypes.FORGOT_PASSWORD, forgotPasswordSagaManaged);
  yield takeEvery(AuthenticationActionTypes.RESET_PASSWORD, resetPasswordSagaManaged);
  yield takeEvery(AuthenticationActionTypes.SUBMIT_COMPANY, submitCompanySagaManaged);
  yield takeEvery(AuthenticationActionTypes.LOGOUT, logoutSagaManaged);
  yield takeEvery(AuthenticationActionTypes.REFRESH_USER_COMPANY, refreshUserCompanySagaManaged);
  yield takeEvery(AuthenticationActionTypes.REFRESH_AUTHENTICATED_USER, refreshAuthenticatedUserSagaManaged);
  yield takeEvery(AuthenticationActionTypes.SIGN_UP, signUpSagaManaged);
  yield takeEvery(AuthenticationActionTypes.SET_ENTERPRISE_USER, setEnterpriseUserSagaManaged);
}

function* initializeLoginFormSaga(
  action: ReturnActionOfType<AuthenticationActions, AuthenticationActionTypes.INITIALIZE_LOGIN_FORM>
) {
  const formValues: LoginForm = {
    email: action.payload.formValues.email,
    password: action.payload.formValues.code,
  };

  yield put(initialize(action.payload.formName, formValues));
}

function* resetPasswordSagaManaged(
  action: ReturnActionOfType<AuthenticationActions, AuthenticationActionTypes.RESET_PASSWORD>
) {
  yield new SagaManager()
    .addTracking(authenticationActionCreators.resetPassword.name)
    .addReduxFormValidation(RESET_PASSWORD_FORM_NAME, action.payload.formValues, validateResetPasswordForm)
    .execute(resetPasswordSaga, action);
}

function* resetPasswordSaga(
  action: ReturnActionOfType<AuthenticationActions, AuthenticationActionTypes.RESET_PASSWORD>
) {
  yield call(authenticationApi.forgotPasswordSubmit, action.payload.formValues);
  yield put(navigationActionCreators.navigateTo(AUTHENTICATION_ROUTES.ChangePasswordSuccess));
}

function* forgotPasswordSagaManaged(
  action: ReturnActionOfType<AuthenticationActions, AuthenticationActionTypes.FORGOT_PASSWORD>
) {
  yield new SagaManager()
    .addTracking(authenticationActionCreators.forgotPassword.name)
    .addReduxFormValidation(FORGOT_PASSWORD_FORM_NAME, action.payload.formValues, validateForgotPasswordForm)
    .execute(forgotPasswordSaga, action);
}

function* forgotPasswordSaga(
  action: ReturnActionOfType<AuthenticationActions, AuthenticationActionTypes.FORGOT_PASSWORD>
) {
  yield call(authenticationApi.forgotPassword, action.payload.formValues);
  yield put(navigationActionCreators.navigateTo(AUTHENTICATION_ROUTES.ResetPassword));
}

function* completeNewPasswordSagaManaged(
  action: ReturnActionOfType<AuthenticationActions, AuthenticationActionTypes.COMPLETE_NEW_PASSWORD>
) {
  yield new SagaManager()
    .addTracking(authenticationActionCreators.completeNewPassword.name)
    .addReduxFormValidation(COMPLETE_NEW_PASSWORD_FORM_NAME, action.payload.formValues, validateCompleteNewPasswordForm)
    .execute(completeNewPasswordSaga, action);
}

function* completeNewPasswordSaga(
  action: ReturnActionOfType<AuthenticationActions, AuthenticationActionTypes.COMPLETE_NEW_PASSWORD>
) {
  if (authenticationApi.isCompletePasswordSessionExpired()) {
    yield put(navigationActionCreators.navigateTo(AUTHENTICATION_ROUTES.SessionExpired));
    return;
  }

  yield call(authenticationApi.completeNewPassword, action.payload.formValues);
  authenticationApi.completePasswordCognitoUser = undefined;

  yield put(navigationActionCreators.navigateTo(AUTHENTICATION_ROUTES.ChangePasswordSuccess));
}

function* submitCompanySagaManaged(
  action: ReturnActionOfType<AuthenticationActions, AuthenticationActionTypes.SUBMIT_COMPANY>
) {
  yield new SagaManager()
    .addTracking(authenticationActionCreators.submitCompany.name)
    .execute(submitCompanySaga, action);
}

function* submitCompanySaga(
  action: ReturnActionOfType<AuthenticationActions, AuthenticationActionTypes.SUBMIT_COMPANY>
) {
  yield call(authenticationApi.submitCompanyAuthorization, action.payload.providerName);
}

function* loginSagaManaged(action: ReturnActionOfType<AuthenticationActions, AuthenticationActionTypes.LOGIN>) {
  yield new SagaManager()
    .addTracking(authenticationActionCreators.login.name)
    .addReduxFormValidation(LOGIN_FROM, action.payload.formValues, validateLoginForm)
    .addCustomErrorHandler(cognitoErrorHandler)
    .execute(loginSaga, action);
}

const getUserId = (cognitoUser?: CognitoAuthenticatedUser) =>
  parseNumber(cognitoUser?.attributes && cognitoUser.attributes['custom:crs_id']);

const getUserIdToken = (cognitoUser: CognitoAuthenticatedUser | undefined): string | undefined =>
  cognitoUser?.signInUserSession?.idToken?.jwtToken;

function* refreshAuthenticatedUserSagaManaged() {
  const userId: number | undefined = yield select(getAuthenticatedUserId);
  yield new SagaManager()
    .addTracking(authenticationActionCreators.refreshAuthenticatedUser.name)
    .execute(refreshAuthenticatedUser, userId);
}

export function* refreshAuthenticatedUser(userId: number | undefined) {
  const userResponse: AxiosResponse<User> = yield call(authenticationApi.fetchUser, userId);
  const user: User = userResponse.data;
  yield put(authenticationActionCreators.setAuthenticatedUser(user));
  return user;
}

function* setEnterpriseUserSagaManaged(
  action: ReturnActionOfType<AuthenticationActions, AuthenticationActionTypes.SET_ENTERPRISE_USER>
) {
  yield new SagaManager()
    .addTracking(authenticationActionCreators.setEnterpriseUser.name)
    .execute(setEnterpriseUserSaga, action);
}

function* setEnterpriseUserSaga(
  action: ReturnActionOfType<AuthenticationActions, AuthenticationActionTypes.SET_ENTERPRISE_USER>
) {
  const cognitoUser = action.payload.cognitoUser;

  const userId = getUserId(cognitoUser);
  const idToken = getUserIdToken(cognitoUser);

  yield put(authenticationActionCreators.setUserId(userId));
  yield put(authenticationActionCreators.setIdToken(idToken, cognitoUser));

  const user: User = yield refreshAuthenticatedUser(userId);

  if (shouldActivateAccount(user.status)) {
    yield activateUserSagaManaged(user);
  }

  yield refreshUserCompanySaga();

  yield put(
    navigationActionCreators.navigateTo(
      user.permission_level ? defaultRoute[user.permission_level] : AUTHENTICATION_ROUTES.NoAuthorization
    )
  );
}

function* refreshUserCompanySagaManaged() {
  yield new SagaManager()
    .addTracking(authenticationActionCreators.refreshUserCompany.name)
    .execute(refreshUserCompanySaga);
}

export function* refreshUserCompanySaga() {
  const companyId: User['company_id'] = yield select(getAuthenticatedUserCompanyId);
  if (companyId) {
    const companyResponse: ApiResponse<Company> = yield call(companyApi.fetchSingle, companyId);
    const company = companyResponse.data;
    yield put(authenticationActionCreators.setUserCompany(company));
  }
}

function* loginSaga(action: ReturnActionOfType<AuthenticationActions, AuthenticationActionTypes.LOGIN>) {
  yield logoutSaga();

  const { email, password } = action.payload.formValues;
  const cognitoUser: CognitoAuthenticatedUser = yield call(authenticationApi.login, email, password);

  if ((cognitoUser as CognitoChallengeUser).challengeName === CognitoChallengeName.NEW_PASSWORD_REQUIRED) {
    authenticationApi.completePasswordCognitoUser = cognitoUser as CognitoChallengeUser;
    yield put(navigationActionCreators.navigateTo(AUTHENTICATION_ROUTES.CompleteNewPassword));
    return;
  }

  const userId = getUserId(cognitoUser);
  const idToken = getUserIdToken(cognitoUser);

  yield put(authenticationActionCreators.setUserId(userId));
  yield put(authenticationActionCreators.setIdToken(idToken, cognitoUser));

  const user: User = yield refreshAuthenticatedUser(userId);

  if (shouldActivateAccount(user.status)) {
    yield activateUserSagaManaged(user);
  }

  yield refreshUserCompanySaga();

  yield put(
    navigationActionCreators.navigateTo(
      user.permission_level ? defaultRoute[user.permission_level] : AUTHENTICATION_ROUTES.NoAuthorization
    )
  );
}

function* logoutSagaManaged() {
  yield new SagaManager().addTracking(authenticationActionCreators.logout.name).execute(logoutAndNavigateToLandingSaga);
}

function* logoutAndNavigateToLandingSaga() {
  yield logoutSaga();
  yield put(navigationActionCreators.navigateTo(LANDING_ROUTES.Landing));
}

function* logoutSaga() {
  yield put(authenticationActionCreators.reset());
  yield put(userActionCreators.reset());
  yield put(companyActionCreators.reset());
  yield put(groupActionCreators.reset());
  yield put(locationE911ActionCreators.reset());
  yield put(locationActionCreators.reset());
  yield put(tableActionCreators.reset());
  yield put(listsActionCreators.reset());
  yield put(alertActionCreators.reset());
  yield put(logActionCreators.reset());
  yield put(autocompleteActionCreators.reset());
  yield call(authenticationApi.logout);
}

function* signUpSagaManaged(action: ReturnActionOfType<AuthenticationActions, AuthenticationActionTypes.SIGN_UP>) {
  yield new SagaManager()
    .addTracking(authenticationActionCreators.signUp.name)
    .addReduxFormValidation(SIGNUP_FORM, action.payload.formValues, validateSignupForm)
    .execute(signUpSaga, action);
}

function* signUpSaga(action: ReturnActionOfType<AuthenticationActions, AuthenticationActionTypes.SIGN_UP>) {
  const { email, name, country_code, phone_number } = action.payload.formValues;
  yield call(authenticationApi.signUp, email, name, phone_number, country_code);
  yield put(
    navigationActionCreators.navigateTo(AUTHENTICATION_ROUTES.SignupSuccess, `?${queryStringStringify({ email })}`)
  );
}
