import { wrapComponentsWithProps } from '../../../../logic/hoc/wrap-components-with-props.hoc';
import { AUTHENTICATED_ROUTES } from '../../../../logic/routes/routes.const';
import { TableName } from '../../../../logic/store/tables/tables.action';
import { translate, translationKeys } from '../../../../logic/translations/translations.service';
import { SsoCompanyAdminsFormFieldName } from '../sso-company-admins-form-template/sso-company-admins-form-field-name';
import {
  SsoCompanyAdminsPageOwnProps,
  SsoCompanyAdminsTemplate,
} from '../sso-company-admins-template/sso-company-admins.template.page.';

export const SsoCompanyAdmins = wrapComponentsWithProps<SsoCompanyAdminsPageOwnProps>({
  tableName: TableName.ssoCompanyAdmins,
  buttons: [
    {
      text: translate(translationKeys.buttons.addSsoCompanyAdmin),
      route: AUTHENTICATED_ROUTES.SsoCompanyAdminsAdd,
    },
  ],
  columnsFields: [SsoCompanyAdminsFormFieldName.company_name, SsoCompanyAdminsFormFieldName.email],
})(SsoCompanyAdminsTemplate);
