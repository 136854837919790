import { FormErrors } from 'redux-form';
import { SsoGroupMapping } from '../../../../api-models/api-models';
import { clearEmptyFieldsAndEmptyObjects } from '../../../../logic/validators/utils';
import { isRequiredValidator } from '../../../../logic/validators/validators';

export interface SsoGroupMappingForm extends SsoGroupMapping {}

export const SSO_GROUP_MAPPING_FORM = 'ssoGroupMapping';

export const validateSsoGroupMappingForm = (
  formValues: SsoGroupMappingForm
): FormErrors<SsoGroupMappingForm, string> => {
  const { sso_group_name, corresponding_permission_level } = formValues;

  const validation = clearEmptyFieldsAndEmptyObjects({
    sso_group_name: isRequiredValidator(sso_group_name),
    corresponding_permission_level: isRequiredValidator(corresponding_permission_level),
  });

  return validation;
};

export const ssoGroupMappingToFormValuesMapper = (ssoGroupMapping: SsoGroupMapping): SsoGroupMappingForm => {
  return {
    ...ssoGroupMapping,
  };
};

export const formValuesToSsoGroupMappingMapper = (formValues: SsoGroupMappingForm): SsoGroupMapping => {
  return {
    ...formValues,
  };
};
