import { useEffect } from 'react';
import { Form, Button } from 'antd';
import { authenticationActionCreators } from '../../../../logic/store/authentication/authentication.actions';
import { useDispatch, useSelector } from 'react-redux';
import { translate, translationKeys } from '../../../../logic/translations/translations.service';
import { reduxForm, Field, getFormValues } from 'redux-form';
import { FormTextInput } from '../../../components/form-inputs/form-text-input/form-text-input';
import { FormContainer, formButtonLayout, formItemLayout } from '../../../components/form-container/form-container';
import { getInProgress } from '../../../../logic/store/process-tracker/process-tracker.selectors';
import { LoginForm, LOGIN_FROM, validateLoginForm } from './login.form';
import { AuthenticationMasterPage } from '../authentication-master/authentication-master.page';
import { navigationActionCreators } from '../../../../logic/store/navigation/navigation.actions';
import { AUTHENTICATION_ROUTES, defaultRoute } from '../../../../logic/routes/routes.const';
import { isDevelopment, env } from '../../../../logic/env/env';
import { Logo } from '../../../components/logo/logo';
import Title from 'antd/lib/typography/Title';
import {
  getIsAuthenticated,
  getAuthenticatedUserRole,
} from '../../../../logic/store/authentication/authentication.selectors';
import { getCodeFromUrl, getEmailFromUrl } from '../../../../logic/query-string/get-query-string-code';
import { trimValue } from '../../../../logic/formaters/formaters';
import parse from 'html-react-parser';

export const LOGIN_BUTTON_TEST_ID = 'LOGIN_BUTTON_TEST_ID';

declare global {
  interface Window {
    dataLayer: any[];
  }
}

const Login = () => {
  const dispatch = useDispatch();
  const formValues: LoginForm = useSelector(getFormValues(LOGIN_FROM)) || {};
  const inProgress = useSelector(getInProgress(authenticationActionCreators.login.name));
  const isAuthenticated = useSelector(getIsAuthenticated);
  const role = useSelector(getAuthenticatedUserRole);
  const { code } = getCodeFromUrl(window.location.href);
  const { email } = getEmailFromUrl(window.location.href);

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'loginPage_view',
    });
  }, []);

  useEffect(() => {
    dispatch(authenticationActionCreators.initializeLoginForm(LOGIN_FROM, { code, email }));
  }, [dispatch, code, email]);

  useEffect(() => {
    if (isAuthenticated && role) {
      dispatch(navigationActionCreators.navigateTo(defaultRoute[role]));
    }
  }, [dispatch, isAuthenticated, role]);

  const handleLogin = () => dispatch(authenticationActionCreators.login(formValues));

  const handleForgotPassword = () =>
    dispatch(navigationActionCreators.navigateTo(AUTHENTICATION_ROUTES.ForgotPassword));

  const handleEvent = (event: string) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event,
      page: 'Login',
      action: event,
    });
  };

  return (
    <AuthenticationMasterPage>
      <FormContainer>
        <Form.Item {...formButtonLayout}>
          <Logo />
          <Title className={'text-centered'} level={4}>
            {translate(translationKeys.buttons.login)}
          </Title>
        </Form.Item>
        <Field
          name={'email'}
          component={FormTextInput}
          label={translate(translationKeys.forms.fields.user.email)}
          required
          disabled={inProgress}
          onPressEnter={handleLogin}
          autoComplete="off"
          onBlurFormater={trimValue}
          {...formItemLayout}
        />
        <Field
          name={'password'}
          component={FormTextInput}
          label={translate(translationKeys.forms.fields.password)}
          isPassword
          required
          disabled={inProgress}
          onPressEnter={handleLogin}
          autoComplete="off"
          onBlurFormater={trimValue}
          {...formItemLayout}
        />
        <Form.Item {...formButtonLayout}>
          <Button
            type={'primary'}
            className={'form-button'}
            onClick={() => {
              handleLogin();
              handleEvent('loginButton');
            }}
            disabled={inProgress}
            loading={inProgress}
          >
            {translate(translationKeys.buttons.login)}
          </Button>
        </Form.Item>
        <Form.Item {...formButtonLayout}>
          <Button
            data-testid={LOGIN_BUTTON_TEST_ID}
            type="link"
            className={'form-button'}
            onClick={() => {
              handleForgotPassword();
              handleEvent('forgotPassword');
            }}
            disabled={inProgress}
          >
            {translate(translationKeys.buttons.forgotPassword)}
          </Button>
          <Button
            type="link"
            href={'https://teamalert.com/contact/'}
            className={'form-button'}
            onClick={() => {
              handleEvent('contactUs');
            }}
            disabled={inProgress}
          >
            {translate(translationKeys.buttons.contactUs)}
          </Button>
        </Form.Item>
        <Form.Item {...formButtonLayout}>
          <p className={'text-left'}>{parse(translate(translationKeys.messages.noticeAboutBindingAgreements))}</p>
        </Form.Item>
      </FormContainer>
    </AuthenticationMasterPage>
  );
};

const initialValues = isDevelopment()
  ? {
      email: env.REACT_APP_DEV_EMAIL,
      password: env.REACT_APP_DEV_PASSWORD,
    }
  : {};

export const LoginPage = reduxForm({
  form: LOGIN_FROM,
  validate: validateLoginForm,
  initialValues,
})(Login as any);
