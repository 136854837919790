import { Button, PageHeader, Typography } from 'antd';
import styled from 'styled-components';
import { featureColor } from '../../../styles/colors';
import { APPLICATION_PADDING } from '../../../styles/margins';
const { Text } = Typography;

export const MasterPagePageHeader = styled(PageHeader)`
  padding: ${APPLICATION_PADDING};
`;

export const HeaderActionBar = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  flex-wrap: wrap-reverse;
`;

export const HeaderSideContent = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
`;

export const TableHeaderButton = styled(Button)`
  margin-right: 8px;
  margin-bottom: 8px;
`;

export const BulkOperationButton = styled(Button).attrs({ type: 'link' })`
  padding-right: 0px;
  margin-bottom: 8px;
`;

export const BulkOperationDivider = styled.div`
  margin: 0 20px;
  border-left: 1px solid ${featureColor.border};
  height: 32px;
  margin-bottom: 8px;
`;

export const SelectedRowsText = styled(Text)`
  padding-left: 10px;
  margin-bottom: 8px;
`;
