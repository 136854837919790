import { useCallback, useEffect, useMemo } from 'react';
import { SsoCompanyGroupMappingFormFieldName } from './sso-company-group-mapping-form-field-name';
import { formItemLayout } from '../../../components/form-container/form-container';
import { translate, translationKeys } from '../../../../logic/translations/translations.service';
import { Field } from 'redux-form';
import { FormTextInput } from '../../../components/form-inputs/form-text-input/form-text-input';
import { trimValue } from '../../../../logic/formaters/formaters';
import { FormSelectInput } from '../../../components/form-inputs/form-select-input/form-select-input';
import { useDispatch, useSelector } from 'react-redux';
import { ListName, getFetchListTrackName, listsActionCreators } from '../../../../logic/store/lists/lists.action';
import { getListData } from '../../../../logic/store/lists/lists.selectors';
import { getDefaultSelectOptions } from '../../../components/form-inputs/select-option/select-option';
import { getInProgress } from '../../../../logic/store/process-tracker/process-tracker.selectors';

interface SsoCompanyGroupMappingFormProps {
  onPressEnter?: any;
  inProgress?: boolean;
  fieldsToDisplay: SsoCompanyGroupMappingFormFieldName[];
  disabledFields: SsoCompanyGroupMappingFormFieldName[];
  formName: string;
}

export const SsoCompanyGroupMappingFormFields: React.FC<SsoCompanyGroupMappingFormProps> = ({
  onPressEnter,
  disabledFields,
  inProgress,
  fieldsToDisplay,
}) => {
  const dispatch = useDispatch();

  const isDisabled = useCallback(
    (fieldName: SsoCompanyGroupMappingFormFieldName) => {
      return disabledFields.indexOf(fieldName) >= 0 || inProgress;
    },
    [disabledFields, inProgress]
  );

  const commonAttributes = useCallback(
    (fieldName: SsoCompanyGroupMappingFormFieldName) => ({
      ...formItemLayout,
      disabled: isDisabled(fieldName),
      onPressEnter,
    }),
    [isDisabled, onPressEnter]
  );

  const translateFieldLabel = useCallback(
    (fieldName: SsoCompanyGroupMappingFormFieldName) =>
      translate(translationKeys.forms.fields.ssoCompanyGroupMapping[fieldName]),
    []
  );

  useEffect(() => {
    dispatch(listsActionCreators.fetchList(ListName.group));
  }, [dispatch]);

  const groups = useSelector(getListData(ListName.group)) || [];
  const groupsOptions = getDefaultSelectOptions(groups);
  const fetchGroupsInProgress = useSelector(getInProgress(getFetchListTrackName(ListName.group)));

  const getTextField = useCallback(
    (fieldName: SsoCompanyGroupMappingFormFieldName, required?: boolean, rows?: number, maxLength?: number) => (
      <Field
        name={fieldName}
        component={FormTextInput}
        label={translateFieldLabel(fieldName)}
        required={required}
        maxLength={maxLength}
        onBlurFormater={trimValue}
        rows={rows}
        {...commonAttributes(fieldName)}
      />
    ),
    [commonAttributes, translateFieldLabel]
  );

  const fields = useMemo(
    () => ({
      [SsoCompanyGroupMappingFormFieldName.sso_group_name]: getTextField(
        SsoCompanyGroupMappingFormFieldName.sso_group_name
      ),
      [SsoCompanyGroupMappingFormFieldName.company_group]: (
        <Field
          name={SsoCompanyGroupMappingFormFieldName.company_group}
          component={FormSelectInput}
          label={translateFieldLabel(SsoCompanyGroupMappingFormFieldName.company_group)}
          toolTipMessage={translate(translationKeys.forms.fields.ssoCompanyGroupMapping.company_group_tooltip)}
          options={groupsOptions}
          showSearch
          filterOption={true}
          optionFilterProp={'label'}
          allowClear
          loading={fetchGroupsInProgress}
          {...commonAttributes(SsoCompanyGroupMappingFormFieldName.company)}
        />
      ),
      [SsoCompanyGroupMappingFormFieldName.company]: null,
      [SsoCompanyGroupMappingFormFieldName.company_name]: null,
      [SsoCompanyGroupMappingFormFieldName.company_group_name]: null,
    }),
    [commonAttributes, fetchGroupsInProgress, getTextField, groupsOptions, translateFieldLabel]
  );

  return (
    <>
      {fieldsToDisplay.map((fieldName) => (
        <div key={fieldName}>{fields[fieldName]}</div>
      ))}
    </>
  );
};
