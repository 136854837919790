import { isDevelopment } from '../../../../logic/env/env';
import { GroupFormFieldName } from './group-form.field-name';
import { DEFAULT_PHONE_COUNTRY_CODE } from '../../../authentication/models/country-code/country-code';
import { Group } from '../../../../api-models/api-models';
import { mapPhoneToCountryCodeAndNumber, parsePhoneNumberForPayload } from '../../../../logic/phone/phone.mapper';
import { FormErrors } from 'redux-form';
import { clearEmptyFieldsAndEmptyObjects } from '../../../../logic/validators/utils';
import { isValidPhoneNumber } from '../../../../logic/phone/phone.validator';

export const GROUP_FORM = 'group';

export interface GroupForm extends Group {
  phone_country_code?: string;
}

const devFields: Partial<GroupForm> = isDevelopment()
  ? {
      name: 'Group',
    }
  : {};

export const groupFormInitialValues: GroupForm = {
  ...devFields,

  phone_country_code: GroupFormFieldName.phone_country_code,
};

export const validateGroupForm = (formValues: GroupForm): FormErrors<GroupForm, string> => {
  const { phone, phone_country_code } = formValues;

  return clearEmptyFieldsAndEmptyObjects({
    phone: !!phone ? isValidPhoneNumber(phone, phone_country_code) : undefined,
  });
};

export const groupToFormValuesMapper = (group: Group): GroupForm => {
  const phone_split = mapPhoneToCountryCodeAndNumber(group.phone);

  return {
    ...group,
    phone_country_code: phone_split?.code || DEFAULT_PHONE_COUNTRY_CODE,
    phone: phone_split?.number || undefined,
  };
};

export const formValuesToGroupMapper = (formValues: GroupForm): Undefineable<Group> => {
  const { phone, phone_country_code } = formValues;

  return {
    ...formValues,
    phone: parsePhoneNumberForPayload(phone_country_code, phone),
  };
};
