export enum AlertTypeFormFieldName {
  id = 'id',
  name = 'name',
  cancellation_timeout = 'cancellation_timeout',
  is_active = 'is_active',
  sender_group = 'sender_group',
  recipient_groups = 'recipient_groups',
  recipient_groups_ids = 'recipient_groups_ids',
  sender_group_id = 'sender_group_id',
  can_be_lowered_by_raising_user = 'can_be_lowered_by_raising_user',
  calls_e911 = 'calls_e911',
  button_settings = 'button_settings',
  files = 'files',
  order = 'order',
  change_order = 'change_order',
  language = 'language',
  instructions = 'instructions',
  voice_message = 'voice_message',
  e911_behavior = 'e911_behavior',
  locations_ids = 'locations_ids',
  locations_names = 'locations_names',
}

export enum AlertTypeButtonFieldName {
  id = 'id',
  text_color = 'text_color',
  background_color = 'background_color',
  icon_id = 'icon_id',
  icon = 'icon',
}

export enum E911Behavior {
  ASK_WITH_TIMEOUT = 1,
  ASK_AND_WAIT = 2,
  RAISE_IMMEDIATELY = 3,
}
