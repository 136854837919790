import { reduxForm } from 'redux-form';
import { compose } from 'redux';
import { translate, translationKeys } from '../../../logic/translations/translations.service';
import { groupActionCreators } from '../../../logic/store/group/group.action';
import { GROUP_FORM, validateGroupForm } from './group-form-template/group-form.form';
import { GroupFormPage, GroupFormPageOwnProps } from './group-form-template/group-form-template.page';
import { wrapComponentsWithProps } from '../../../logic/hoc/wrap-components-with-props.hoc';

export const GroupEditPage = compose(
  wrapComponentsWithProps<GroupFormPageOwnProps>({
    submitButtonText: translate(translationKeys.buttons.updateGroup),
    submitFormAction: groupActionCreators.updateSingle,
    formName: GROUP_FORM,
  }),
  reduxForm({
    form: GROUP_FORM,
    validate: validateGroupForm,
  })
)(GroupFormPage) as React.FC;
