import React, { useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getColumnCreator } from '../../../components/table/table.column';
import { translationKeys, translate } from '../../../../logic/translations/translations.service';
import { MasterContent } from '../../../master-page/components/master-content/master-content';
import { getInProgress } from '../../../../logic/store/process-tracker/process-tracker.selectors';
import queryString from 'query-string';
import { RouteComponentProps } from 'react-router-dom';
import { MasterPageTableHeader } from '../../../components/master-page-table-header/master-page-table-header';
import { alertActionCreators } from '../../../../logic/store/alert/alert.action';
import { parseId } from '../../../../logic/utils/parse-id';
import { getAlertType } from '../../../../logic/store/alert/alert.selectors';
import { AlertDocumentFieldName, AlertDocumentTypeName } from './alert-documents.field-name';
import { Table, Button } from 'antd';
import { AlertFile } from '../../../../api-models/api-models';
import { useConfirmActionModal } from '../../../components/confirm-modal/confirm-modal';
import { ResourceLink } from '../../../components/resources/resource-link';
import { ResourceType } from '../../../components/resources/resource.const';
import { formatFileSize, getFileName } from '../../../../logic/utils/file';
import { formatDateTimeForDisplay } from '../../../../logic/date-time/date-time.format';
import { ColumnProps } from 'antd/lib/table';
import { parseNumber } from '../../../../logic/utils/number';
import { useFormModal } from '../../../components/form-modal/form-modal';
import { ALERT_LINK_FORM, AlertLinkForm } from './alert-link-form/alert-link-form.form';
import { AlertLinkFormFields } from './alert-link-form/alert-link-form.fields';
import { ALERT_FILE_FORM, AlertFileForm } from './alert-file-form/alert-file-form.form';
import { AlertFileFormFields } from './alert-file-form/alert-file-form.fields';
import { TableHeaderButton } from '../../../components/master-page-table-header/master-page-table-header.components';
import { DateTime } from 'luxon';

interface AlertDocumentsPageProps extends RouteComponentProps {}

export const AlertDocumentsPage: React.FC<AlertDocumentsPageProps> = ({ location }) => {
  const dispatch = useDispatch();
  const fetchSingleInProgress = useSelector(getInProgress(alertActionCreators.fetchSingle.name));
  const deleteDocumentInProgress = useSelector(getInProgress(alertActionCreators.deleteDocument.name));
  const inProgress = fetchSingleInProgress || deleteDocumentInProgress;
  const alertType = useSelector(getAlertType);
  const { alert_id } = queryString.parse(location.search);
  const alert_id_parsed = parseId(alert_id) || -1;
  const alertName = (!fetchSingleInProgress && alertType?.name) || '...';
  const documents = alertType?.files;

  useEffect(() => {
    dispatch(alertActionCreators.fetchSingle(alert_id_parsed));
  }, [dispatch, alert_id_parsed]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getAlertDocumentColumn = useCallback(
    getColumnCreator<AlertDocumentFieldName, AlertFile>(translationKeys.forms.fields.alertDocument),
    []
  );

  const handleDelete = useCallback(
    (alertFile: AlertFile | undefined) => {
      if (alertFile && alertFile.id) {
        dispatch(alertActionCreators.deleteDocument(alert_id_parsed, alertFile.id));
      }
    },
    [dispatch, alert_id_parsed]
  );

  const deleteModal = useConfirmActionModal(
    translate(translationKeys.modalDialog.deleteAlertDocument),
    translate(translationKeys.modalDialog.areYouSureYouWantToRemoveThisAlertDocument),
    handleDelete,
    alertActionCreators.deleteDocument.name
  );

  const handleUploadFile = useCallback(
    (formValues: AlertFileForm) => dispatch(alertActionCreators.uploadFile(alert_id_parsed, formValues)),
    [dispatch, alert_id_parsed]
  );

  const handleUploadLink = useCallback(
    (formValues: AlertLinkForm) => dispatch(alertActionCreators.uploadLink(alert_id_parsed, formValues)),
    [dispatch, alert_id_parsed]
  );

  const addFileModal = useFormModal({
    title: translate(translationKeys.buttons.addFile),
    formName: ALERT_FILE_FORM,
    submit: handleUploadFile,
    processName: alertActionCreators.uploadFile.name,
    getContent: () => <AlertFileFormFields />,
    showContentInProgress: true,
  });

  const addLinkModal = useFormModal({
    title: translate(translationKeys.buttons.addLink),
    formName: ALERT_LINK_FORM,
    submit: handleUploadLink,
    processName: alertActionCreators.uploadLink.name,
    getContent: () => <AlertLinkFormFields />,
  });

  const actionsColumn: ColumnProps<AlertFile> = useMemo(
    () => ({
      title: translate(translationKeys.forms.fields.actions),
      dataIndex: 'actions',
      key: 'actions',
      render: (value, row) => (
        <span>
          <Button type={'link'} onClick={deleteModal.tryToPerformAction(row)}>
            {translate(translationKeys.table.action.delete)}
          </Button>
        </span>
      ),
    }),
    [deleteModal]
  );

  const getDocumentType = useCallback(
    (document: AlertFile | undefined) =>
      document?.file
        ? translate(translationKeys.forms.fields.alertDocumentType[AlertDocumentTypeName.file])
        : document?.url
        ? translate(translationKeys.forms.fields.alertDocumentType[AlertDocumentTypeName.url])
        : '',
    []
  );

  const columns = useMemo(
    () => [
      actionsColumn,
      getAlertDocumentColumn(
        AlertDocumentFieldName.description,
        (a, b) => (a.description || '').localeCompare(b.description || ''),
        (value, row) => row.description
      ),
      getAlertDocumentColumn(
        AlertDocumentFieldName.fileNameOrUrl,
        (a, b) => (a.file || a.url || '').localeCompare(b.file || b.url || ''),
        (value, row) => {
          if (row.file)
            return (
              <ResourceLink
                content={{ url: row.file || '', resourceType: ResourceType.OpenInNewTab }}
                text={getFileName(row.file)}
                textClassName={'link-ellipsis'}
              />
            );
          if (row.url)
            return (
              <ResourceLink
                content={{ url: row.url || '', resourceType: ResourceType.OpenInNewTab }}
                text={row.url}
                textClassName={'link-ellipsis'}
              />
            );
          return null;
        }
      ),
      getAlertDocumentColumn(
        AlertDocumentFieldName.type,
        (a, b) => getDocumentType(a).localeCompare(getDocumentType(b)),
        (value, row) => getDocumentType(row)
      ),
      getAlertDocumentColumn(
        AlertDocumentFieldName.size,
        (a, b) => (parseNumber(a.size_bytes) || 0) - (parseNumber(b.size_bytes) || 0),
        (value, row) => formatFileSize(row.size_bytes)
      ),
      getAlertDocumentColumn(
        AlertDocumentFieldName.created_at,
        (a, b) => DateTime.fromISO(a.created_at || '').diff(DateTime.fromISO(b.created_at || '')).seconds,
        (value, row) => formatDateTimeForDisplay(row.created_at)
      ),
    ],
    [actionsColumn, getAlertDocumentColumn, getDocumentType]
  );

  const handleAddFile = useCallback(() => addFileModal.show(), [addFileModal]);

  const handleAddLink = useCallback(() => addLinkModal.show(), [addLinkModal]);

  const tableButtons = useMemo(
    () => [
      <TableHeaderButton type={'primary'} key={1} onClick={handleAddFile}>
        {translate(translationKeys.buttons.addFile)}
      </TableHeaderButton>,
      <TableHeaderButton key={2} onClick={handleAddLink}>
        {translate(translationKeys.buttons.addLink)}
      </TableHeaderButton>,
    ],
    [handleAddFile, handleAddLink]
  );

  return (
    <>
      <MasterPageTableHeader leftSection={tableButtons} showBack title={alertName} />
      <MasterContent>
        <Table
          className={'table'}
          columns={columns}
          rowKey={(record) => `${record.id}`}
          dataSource={documents}
          loading={inProgress}
        />
      </MasterContent>
      {deleteModal.render()}
      {addFileModal.render()}
      {addLinkModal.render()}
    </>
  );
};
