import { axiosAuthenticatedClient } from '../../axios/axios-authenticated-client';
import { LocationTagForm, mapFormToLocationTag } from '../../../pages/users/pages/user-form-template/user-form.form';
import { GetLocationTagParameters } from './location-tag.models';
import { env } from '../../env/env';

export const locationTagApi = {
  getSingleForEdit: ({ user_id }: GetLocationTagParameters) => axiosAuthenticatedClient().get(`${env.API_VERSION}/location-tag`, {params: {user_id}}),
  addSingle: (userId: string, locationTagForm: LocationTagForm) => axiosAuthenticatedClient().post(`${env.API_VERSION}/location-tag/`, mapFormToLocationTag(userId, locationTagForm)),
  updateSingle: (userId: string, locationTagForm: LocationTagForm) => axiosAuthenticatedClient().put(
    `${env.API_VERSION}/location-tag/${locationTagForm?.id}/`,  mapFormToLocationTag(userId, locationTagForm)
  ),
  deleteSingle: (locationTagId: string) => axiosAuthenticatedClient().delete(`${env.API_VERSION}/location-tag/${locationTagId}/`),
};
