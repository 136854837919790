import { AlertLinkFieldName } from './alert-link-form.field-name';
import { FormErrors } from 'redux-form';
import { clearEmptyFieldsAndEmptyObjects } from '../../../../../logic/validators/utils';
import { isRequiredValidator } from '../../../../../logic/validators/validators';

export const ALERT_LINK_FORM = 'alertLink';

export interface AlertLinkForm {
  [AlertLinkFieldName.url]?: string;
  [AlertLinkFieldName.description]?: string;
}

export const validateAlertLinkForm = (formValues: AlertLinkForm): FormErrors<AlertLinkForm, string> => {
  const { url, description } = formValues;

  return clearEmptyFieldsAndEmptyObjects({
    url: isRequiredValidator(url),
    description: isRequiredValidator(description),
  });
};
