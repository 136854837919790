import { ImportUsersFormFieldName } from './import-users.field-name';
import { FormErrors } from 'redux-form';
import { isRequiredValidator, isFileSizeLessOrEqual } from '../../../../logic/validators/validators';
import { clearEmptyFieldsAndEmptyObjects } from '../../../../logic/validators/utils';
import { UploadFile } from 'antd/lib/upload/interface';
import { FILE_SIZE_POWER_BASE } from '../../../../logic/utils/file';

export const IMPORT_USERS_FORM = 'importUsers';

export interface ImportUsersForm {
  [ImportUsersFormFieldName.file]?: UploadFile;
  [ImportUsersFormFieldName.company_id]?: number;
}

export type ImportUsersFormErrors = FormErrors<ImportUsersForm, string>;

const IMPORT_FILE_MAX_SIZE_IN_BYTES = 1 * FILE_SIZE_POWER_BASE * FILE_SIZE_POWER_BASE;

export const validateImportUsersForm = (formValues: ImportUsersForm) => {
  const { file, company_id } = formValues;

  const errors: ImportUsersFormErrors = {
    file:
      isRequiredValidator(file) || isFileSizeLessOrEqual(file?.originFileObj as File, IMPORT_FILE_MAX_SIZE_IN_BYTES),
    company_id: isRequiredValidator(company_id),
  };

  return clearEmptyFieldsAndEmptyObjects(errors);
};
