import { isNumber, isObject, isString } from 'lodash';
import { DateTime } from 'luxon';

export const FORMAT_DATE_TIME = 'D ttt';
export const FORMAT_DATE = 'D';
export const MOMENT_DATE_FORMAT = 'L';

export type DateFormater = (value: Date | string | undefined) => string;
export type DateFormaterCreator = (displayFormat?: string) => DateFormater;

export const formatDateTime: DateFormaterCreator =
  (displayFormat = FORMAT_DATE_TIME) =>
  (value) => {
    if (!value) return '';
    if (!isString(value) || isObject() || isNumber(value)) return '';
    if (!DateTime.fromISO(value).isValid) return '';

    return DateTime.fromISO(value).toFormat(displayFormat);
  };

export const formatDateTimeForDisplay: DateFormater = formatDateTime();

export const formatDateForDisplay: DateFormater = formatDateTime(FORMAT_DATE);

export const formatDateTimeForPayload: DateFormater = (value) => {
  if (!value) {
    return '';
  }

  return DateTime.fromJSDate(new Date(value)).toISO({ includeOffset: true }) || '';
};
