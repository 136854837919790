import { translationKeys } from '../../../../logic/translations/translations.service';
import { getSortableFilterableColumnCreator, renderCheck, renderPhone } from '../../../components/table/table.column';
import { Company } from '../../../../api-models/api-models';
import { CompanyFormFieldName } from '../company-form-template/company-form-field-name';
import { formatDateForDisplay } from '../../../../logic/date-time/date-time.format';

const getCompanyColumn = getSortableFilterableColumnCreator<CompanyFormFieldName, Company>(
  translationKeys.forms.fields.company
);

const companyColumns = {
  [CompanyFormFieldName.companyInfoHeader]: getCompanyColumn(CompanyFormFieldName.companyInfoHeader),
  [CompanyFormFieldName.address]: getCompanyColumn(CompanyFormFieldName.address),
  [CompanyFormFieldName.admins]: getCompanyColumn(CompanyFormFieldName.admins),
  [CompanyFormFieldName.alerts]: getCompanyColumn(CompanyFormFieldName.alerts),
  [CompanyFormFieldName.city]: getCompanyColumn(CompanyFormFieldName.city),
  [CompanyFormFieldName.groups]: getCompanyColumn(CompanyFormFieldName.groups),
  [CompanyFormFieldName.id]: getCompanyColumn(CompanyFormFieldName.id),
  [CompanyFormFieldName.isActive]: getCompanyColumn(CompanyFormFieldName.isActive, true, renderCheck),
  [CompanyFormFieldName.trialHeader]: getCompanyColumn(CompanyFormFieldName.trialHeader),
  [CompanyFormFieldName.isTrial]: getCompanyColumn(CompanyFormFieldName.isTrial, true, renderCheck),
  [CompanyFormFieldName.created_at]: getCompanyColumn(CompanyFormFieldName.created_at, true, (_, row) =>
    formatDateForDisplay(row.created_at)
  ),
  [CompanyFormFieldName.trial_to_date]: getCompanyColumn(CompanyFormFieldName.trial_to_date, true, (_, row) =>
    formatDateForDisplay(row.trial_to_date)
  ),
  [CompanyFormFieldName.trial_alerts_limit]: getCompanyColumn(CompanyFormFieldName.trial_alerts_limit, true),
  [CompanyFormFieldName.name]: getCompanyColumn(CompanyFormFieldName.name),
  [CompanyFormFieldName.phone_country_code]: getCompanyColumn(CompanyFormFieldName.phone_country_code),
  [CompanyFormFieldName.phone]: getCompanyColumn(CompanyFormFieldName.phone, false, (_, row) => renderPhone(row.phone)),
  [CompanyFormFieldName.state]: getCompanyColumn(CompanyFormFieldName.state),
  [CompanyFormFieldName.tokens]: getCompanyColumn(CompanyFormFieldName.tokens),
  [CompanyFormFieldName.users_no]: getCompanyColumn(CompanyFormFieldName.users_no),
  [CompanyFormFieldName.max_users_no]: getCompanyColumn(CompanyFormFieldName.max_users_no),
  [CompanyFormFieldName.users]: getCompanyColumn(CompanyFormFieldName.users),
  [CompanyFormFieldName.zip_code]: getCompanyColumn(CompanyFormFieldName.zip_code),
  [CompanyFormFieldName.country]: getCompanyColumn(CompanyFormFieldName.country),
  [CompanyFormFieldName.industry]: getCompanyColumn(CompanyFormFieldName.industry),
  [CompanyFormFieldName.footer_note]: getCompanyColumn(CompanyFormFieldName.footer_note),
  [CompanyFormFieldName.e911Header]: getCompanyColumn(CompanyFormFieldName.e911Header),
  [CompanyFormFieldName.e911HeaderDescription]: getCompanyColumn(CompanyFormFieldName.e911HeaderDescription),
  [CompanyFormFieldName.is_e911_enabled]: getCompanyColumn(CompanyFormFieldName.is_e911_enabled, true, renderCheck),
  [CompanyFormFieldName.is_e911_test_enabled]: getCompanyColumn(
    CompanyFormFieldName.is_e911_test_enabled,
    true,
    renderCheck
  ),
  [CompanyFormFieldName.ssoHeader]: getCompanyColumn(CompanyFormFieldName.ssoHeader),
  [CompanyFormFieldName.is_sso_enabled]: getCompanyColumn(CompanyFormFieldName.is_sso_enabled, true, renderCheck),
  [CompanyFormFieldName.is_permission_mapping_enabled]: getCompanyColumn(
    CompanyFormFieldName.is_permission_mapping_enabled,
    true,
    renderCheck
  ),
  [CompanyFormFieldName.is_company_group_mapping_enabled]: getCompanyColumn(
    CompanyFormFieldName.is_company_group_mapping_enabled,
    true,
    renderCheck
  ),
  [CompanyFormFieldName.appsHeader]: getCompanyColumn(CompanyFormFieldName.appsHeader),
  [CompanyFormFieldName.is_updates_on]: getCompanyColumn(
    CompanyFormFieldName.is_company_group_mapping_enabled,
    true,
    renderCheck
  ),
  [CompanyFormFieldName.multisite_header]: getCompanyColumn(CompanyFormFieldName.multisite_header),
  [CompanyFormFieldName.is_multisite_enabled]: getCompanyColumn(
    CompanyFormFieldName.is_multisite_enabled,
    true,
    renderCheck
  ),
  [CompanyFormFieldName.locations_no]: getCompanyColumn(CompanyFormFieldName.locations_no),
  [CompanyFormFieldName.max_locations_no]: getCompanyColumn(CompanyFormFieldName.max_locations_no),
};

export const getCompanyColumns = (fieldNames: CompanyFormFieldName[]) =>
  fieldNames.map((fieldName) => companyColumns[fieldName]);
