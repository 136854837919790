import { AuthenticationActionTypes, AuthenticationActions } from './authentication.actions';
import { User, Company } from '../../../api-models/api-models';
import { omit } from 'lodash';
import { DateTime } from 'luxon';

export interface AuthenticationState {
  user?: User;
  userId?: number | undefined;
  idToken?: string;
  userCompany?: Company;
  authenticatedAt?: string;
  expirationAt?: string;
}

const initialState: AuthenticationState = {
  user: undefined,
  userId: undefined,
  idToken: undefined,
  userCompany: undefined,
};

export const authenticationReducer = (state = initialState, action: AuthenticationActions): AuthenticationState => {
  switch (action.type) {
    case AuthenticationActionTypes.RESET:
      return initialState;
    case AuthenticationActionTypes.SET_AUTHENTICATED_USER:
      return { ...state, user: omit(action.payload.user, 'groups') };
    case AuthenticationActionTypes.SET_USER_ID:
      return { ...state, userId: action.payload.userId };
    case AuthenticationActionTypes.SET_ID_TOKEN:
      return {
        ...state,
        idToken: action.payload.idToken,
        authenticatedAt:
          DateTime.fromSeconds(action.payload.cognitoUser?.signInUserSession?.idToken?.payload?.auth_time || 0).toISO({
            includeOffset: true,
          }) || undefined,
        expirationAt:
          DateTime.fromSeconds(action.payload.cognitoUser?.signInUserSession?.idToken?.payload?.exp || 0).toISO({
            includeOffset: true,
          }) || undefined,
      };
    case AuthenticationActionTypes.SET_USER_COMPANY:
      return { ...state, userCompany: omit(action.payload.userCompany, 'groups', 'users', 'admins') };
    default:
      return state;
  }
};
