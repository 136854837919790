import React from 'react';
import { AuthenticationMasterPage } from '../authentication-master/authentication-master.page';
import { translationKeys, translate } from '../../../../logic/translations/translations.service';
import { Button, Form } from 'antd';
import { useDispatch } from 'react-redux';
import { navigationActionCreators } from '../../../../logic/store/navigation/navigation.actions';
import { AUTHENTICATION_ROUTES } from '../../../../logic/routes/routes.const';
import { formButtonLayout, FormContainer } from '../../../components/form-container/form-container';
import { Logo } from '../../../components/logo/logo';

export const ChangePasswordSuccessPage: React.FC = () => {
  const dispatch = useDispatch();
  const handleNavigateToLogin = () => dispatch(navigationActionCreators.navigateTo(AUTHENTICATION_ROUTES.Login));

  return (
    <AuthenticationMasterPage>
      <FormContainer>
        <Form.Item {...formButtonLayout}>
          <>
            <Logo />
            {translate(translationKeys.messages.youCreatedNewPassword)}
          </>
        </Form.Item>
        <Form.Item {...formButtonLayout}>
          <Button type={'primary'} onClick={handleNavigateToLogin}>
            {translate(translationKeys.buttons.goBackToLogin)}
          </Button>
        </Form.Item>
      </FormContainer>
    </AuthenticationMasterPage>
  );
};
