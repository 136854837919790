import React, { useRef } from 'react';
import { Resource, resourceContents } from '../resources/resource.const';
import { getResourceUrl } from '../resources/resource-link.logic';
import { env } from '../../../logic/env/env';
import styled from 'styled-components';
import Title from 'antd/lib/typography/Title';
import { translate, translationKeys } from '../../../logic/translations/translations.service';

interface VideoSectionProps extends React.HTMLAttributes<HTMLVideoElement> {
  res: Resource;
  posterRes?: Resource;
  showTitle?: boolean;
  controls?: boolean;
}

export const VideoSection: React.FC<VideoSectionProps> = ({
  className,
  res,
  showTitle,
  posterRes,
  controls = true,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  const onVideoClick = () => {
    if (!posterRes && controls) {
      return;
    }

    if (videoRef.current?.paused) {
      videoRef.current?.play();
    } else {
      videoRef.current?.pause();
    }
  };

  return (
    <>
      {showTitle && (
        <div id={resourceContents[res].anchor}>
          <Title className={'text-centered'} level={4}>
            {translate(translationKeys.resources[res])}
          </Title>
        </div>
      )}
      <Video
        ref={videoRef}
        className={className}
        poster={
          posterRes
            ? getResourceUrl(env.REACT_APP_DOWNLOADS_FOLDER_URL, resourceContents[posterRes].url || '')
            : undefined
        }
        controls={controls}
        onClick={onVideoClick}
      >
        <source
          src={getResourceUrl(env.REACT_APP_DOWNLOADS_FOLDER_URL, resourceContents[res].url || '')}
          type={resourceContents[res].linkType}
        />
      </Video>
    </>
  );
};

const Video = styled.video<{ height?: string | number }>`
  width: 100%;

  @media screen and (min-width: 820px) {
    height: 350px;
  }
`;
