import { reduxForm } from 'redux-form';
import { compose } from 'redux';
import { translate, translationKeys } from '../../../logic/translations/translations.service';
import { wrapComponentsWithProps } from '../../../logic/hoc/wrap-components-with-props.hoc';
import { locationE911ActionCreators } from '../../../logic/store/location/location-e911/location-e911.action';
import { validateLocationForm } from './location-e911/location-e911-form';
import { LocationE911FormPage } from './location-e911/location-e911-form.page';
import { LocationFormPageOwnProps } from '../../../common/location/location.type';
import { LOCATION_FORM } from '../../../common/location/location.form';

export const LocationE911ViewPage = compose(
  wrapComponentsWithProps<LocationFormPageOwnProps>({
    submitButtonText: translate(translationKeys.buttons.updateLocation911),
    submitFormAction: locationE911ActionCreators.updateSingle,
    formName: LOCATION_FORM,
    disabledFields: [],
    isViewOnly: true,
  }),
  reduxForm({
    form: LOCATION_FORM,
    validate: validateLocationForm,
    initialValues: {},
  })
)(LocationE911FormPage) as React.FC;
