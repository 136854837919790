import { SelectOption } from '../../../components/form-inputs/select-option/select-option';
import { Country } from '../../../../api-models/api-models';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import React from 'react';
import './country-code.styles.scss';
import { getCountryCallingCode } from '../../../../logic/phone/phone.validator';

export type CountryCodeSelectOption = SelectOption<
  string | null | undefined,
  JSX.Element | null | undefined,
  Country | null | undefined
>;

export const getCountryCodeOptions = (countries: Country[] | undefined = []): CountryCodeSelectOption[] =>
  countries.filter(c => c.iso_code_alpha_2 !== 'AQ').map(c => getCountryCodeSelectOption(c));

const getCountryCodeSelectOption = (c: Country): CountryCodeSelectOption => {
  const callingCode = `+${getCountryCallingCode(c.iso_code_alpha_2)}`;

  return {
    label: (
      <>
        {getFlag(c)}
        <span className={'calling-code-select-option-code'}>{callingCode}</span>
      </>
    ),
    value: c.iso_code_alpha_2,
    tag: c,
  };
};

const getFlag = (c: Country) => getUnicodeFlagIcon(c.iso_code_alpha_2);
