import { FormErrors } from 'redux-form';
import { clearEmptyFields } from '../../../../logic/validators/utils';
import { isRequiredValidator, isEmailValididator } from '../../../../logic/validators/validators';

export const FORGOT_PASSWORD_FORM_NAME = 'forgotPassword';

export interface ForgotPasswordForm {
  email?: string;
}

export type ForgotPasswordFormValidationErrors = FormErrors<ForgotPasswordForm>;

export const validateForgotPasswordForm = (formValues: ForgotPasswordForm) => {
  const { email } = formValues;

  const errors: FormErrors<ForgotPasswordForm, any> = {
    email: isRequiredValidator(email) || isEmailValididator(email),
  };

  return clearEmptyFields(errors);
};
