import { SelectOption } from '../../../components/form-inputs/select-option/select-option';
import { map } from 'lodash';
import { translationKeys, translate } from '../../../../logic/translations/translations.service';
import { getEnumNumbersOlny } from '../../../../logic/utils/enum';
import { Role } from '../../../../api-models/api-models';

export type RoleSelectOption = SelectOption<Role>;

export const getAllRoleSelectOptions = (): RoleSelectOption[] =>
  map(getEnumNumbersOlny(Role), value => getRoleSelectOption(value));

export const getRoleSelectOptions = (roles: Role[]): RoleSelectOption[] =>
  map(roles, value => getRoleSelectOption(value));

export const getRoleSelectOption = (value: Role): RoleSelectOption => ({
  label: translate(translationKeys.role[value]),
  value,
});
