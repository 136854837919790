import axios, { AxiosInstance, AxiosRequestConfig, AxiosError, AxiosResponse } from 'axios';
import { getIdToken } from '../store/authentication/authentication.selectors';
import { store } from '../../app/app.store';

export const createAxios = (
  config?: AxiosRequestConfig | undefined,
  interceptor?: (error: AxiosError) => Promise<AxiosResponse>
): AxiosInstance => {
  const base = axios.create(config);

  if (interceptor) {
    base.interceptors.response.use(undefined, interceptor);
  }

  return base;
};

export const getCommonHeaders = () => ({
  'content-type': 'application/json',
});

export const getAuthenticationHeaders = (): { [key: string]: string } => {
  const idToken = getIdToken(store.getState());
  return idToken ? { Authorization: `Bearer ${idToken}` } : {};
};

export const getFileHeaders = () => ({
  'content-type': 'multipart/form-data',
});
