import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { APPLICATION_PADDING } from '../../../../styles/margins';

export const AuthenticationMasterPage: React.FC<PropsWithChildren> = ({ children }) => (
  <AuthenticationMasterPageContainer>
    <>{children}</>
  </AuthenticationMasterPageContainer>
);

const AuthenticationMasterPageContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: ${APPLICATION_PADDING}px;
  margin-right: ${APPLICATION_PADDING}px;
  text-align: center;
`;
