import { wrapComponentsWithProps } from '../../../../logic/hoc/wrap-components-with-props.hoc';
import { AUTHENTICATED_ROUTES } from '../../../../logic/routes/routes.const';
import { TableName } from '../../../../logic/store/tables/tables.action';
import { translate, translationKeys } from '../../../../logic/translations/translations.service';
import { CompaniesTemplatePage, CompaniesTemplatePageOwnProps } from '../companies-template/companies-template.page';
import { CompanyFormFieldName } from '../company-form-template/company-form-field-name';

export const TrialCompaniesPage = wrapComponentsWithProps<CompaniesTemplatePageOwnProps>({
  tableName: TableName.trialCompany,
  prefilters: { is_trial: true },
  buttons: [
    {
      text: translate(translationKeys.buttons.addTrialCompany),
      route: AUTHENTICATED_ROUTES.TrialCompaniesAdd,
    },
  ],
  editCompanyRoute: AUTHENTICATED_ROUTES.TrialCompaniesEdit,
  columnsFields: [
    CompanyFormFieldName.name,
    CompanyFormFieldName.address,
    CompanyFormFieldName.city,
    CompanyFormFieldName.state,
    CompanyFormFieldName.zip_code,
    CompanyFormFieldName.phone,
    CompanyFormFieldName.max_users_no,
    CompanyFormFieldName.users_no,
    CompanyFormFieldName.alerts,
    CompanyFormFieldName.isActive,
    CompanyFormFieldName.created_at,
    CompanyFormFieldName.trial_to_date,
    CompanyFormFieldName.trial_alerts_limit,
    CompanyFormFieldName.is_e911_enabled,
    CompanyFormFieldName.is_e911_test_enabled,
  ],
})(CompaniesTemplatePage);
