import { FormErrors } from 'redux-form';
import { clearEmptyFields } from '../../../../../../logic/validators/utils';
import { isRequiredValidator } from '../../../../../../logic/validators/validators';
import { isCognitoPasswordValidator } from '../../../../../../logic/validators/passwordValidator';

export const SET_NEW_PASSWORD_FORM_NAME = 'setNewPassword';

export interface SetNewPasswordForm {
  newPassword?: string;
}

export type SetNewPasswordValidationErrors = FormErrors<SetNewPasswordForm>;

export const validateSetNewPasswordForm = (formValues: SetNewPasswordForm) => {
  const { newPassword } = formValues;

  const errors: FormErrors<SetNewPasswordForm, any> = {
    newPassword: isRequiredValidator(newPassword) || isCognitoPasswordValidator(newPassword),
  };

  return clearEmptyFields(errors);
};
