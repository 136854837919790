import { PartialObject, reduce, isObject, isEmpty } from 'lodash';

export const clearEmptyFields = <T extends object>(obj: T): PartialObject<T> => {
  return reduce(
    obj,
    (result: T, value: any, field: string) => {
      if (value !== null && value !== undefined && value !== '') {
        // @ts-ignore
        result[field] = value;
      }

      return result;
    },
    {} as T
  );
};

export const clearEmptyFieldsAndEmptyObjects = <T extends object>(obj: T): PartialObject<T> => {
  return reduce(
    obj,
    (result: T, value: any, field: string) => {
      if (value !== null && value !== undefined && value !== '' && (!isObject(value) || !isEmpty(value))) {
        // @ts-ignore
        result[field] = value;
      }

      return result;
    },
    {} as T
  );
};
