import { SsoCompanyGroupMapping } from '../../../api-models/api-models';
import { SsoCompanyGroupMappingForm } from '../../../pages/sso-company-group-mapping/pages/sso-company-group-mapping-form-template/sso-company-group-mapping-form.form';
import { ActionsUnion, createAction } from '../../redux/action-creator';

export enum SsoCompanyGroupMappingActionTypes {
  RESET = 'sso-company-group-mapping/RESET',
  CREATE_SINGLE = 'sso-company-group-mapping/CREATE_SINGLE',
  GET_SINGLE_FOR_EDIT = 'sso-company-group-mapping/GET_SINGLE_FOR_EDIT',
  UPDATE_SINGLE = 'sso-company-group-mapping/UPDATE_SINGLE',
  DELETE_SINGLE = 'sso-company-group-mapping/DELETE_SINGLE',
}

export const ssoCompanyGroupMappingActionCreators = {
  reset: () => createAction(SsoCompanyGroupMappingActionTypes.RESET),
  createSingle: (formValues: SsoCompanyGroupMappingForm) =>
    createAction(SsoCompanyGroupMappingActionTypes.CREATE_SINGLE, { formValues }),
  updateSingle: (formValues: SsoCompanyGroupMappingForm) =>
    createAction(SsoCompanyGroupMappingActionTypes.UPDATE_SINGLE, { formValues }),
  getSingleForEdit: (id: any, formName: string, mapper: (data: SsoCompanyGroupMapping) => SsoCompanyGroupMappingForm) =>
    createAction(SsoCompanyGroupMappingActionTypes.GET_SINGLE_FOR_EDIT, { id, formName, mapper }),
  deleteSingle: (id: number) => createAction(SsoCompanyGroupMappingActionTypes.DELETE_SINGLE, { id }),
};

export type SsoCompanyGroupMappingActions = ActionsUnion<typeof ssoCompanyGroupMappingActionCreators>;
