import { AxiosError } from 'axios';
import { NetworkCode } from '../../../models/network-code';

export const appVersionNotFound = (error: AxiosError, tableName: string) => {
  const statusCode = error.response && error.response.status;

  if (statusCode === NetworkCode.NotFound) {
    console.log('can not fetch app version');
    return true;
  }
  return false;
};
