import React from 'react';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import { AlertCustomResponsesFieldName } from './alert-custom-responses.field-name';
import { translate, translationKeys } from '../../../../../logic/translations/translations.service';
import { FormTextInput } from '../../../../components/form-inputs/form-text-input/form-text-input';
import { trimValue } from '../../../../../logic/formaters/formaters';
import { ALERT_CUSTOM_RESPONSES_FORM, validateAlertCustomResponsesForm } from './alert-custom-responses.form';
import { useSelector } from 'react-redux';
import { getInProgress } from '../../../../../logic/store/process-tracker/process-tracker.selectors';
import { alertActionCreators } from '../../../../../logic/store/alert/alert.action';
import { Alert } from 'antd';

interface AlertFileFormProps extends InjectedFormProps {}

const AlertCustomResponsesForm: React.FC<AlertFileFormProps> = ({ error }) => {
  const inProgress = useSelector(getInProgress(alertActionCreators.uploadFile.name));
  const commonAttributes = {
    disabled: inProgress,
  };

  const translateFieldLabel = (fieldName: AlertCustomResponsesFieldName) =>
    translate(translationKeys.forms.fields.alertCustomResponses[fieldName]);

  return (
    <>
      <Field
        name={AlertCustomResponsesFieldName.label_text}
        component={FormTextInput}
        placeholder={translateFieldLabel(AlertCustomResponsesFieldName.label_text)}
        onBlurFormater={trimValue}
        {...commonAttributes}
      />
      <Field
        name={AlertCustomResponsesFieldName.text}
        component={FormTextInput}
        placeholder={translateFieldLabel(AlertCustomResponsesFieldName.text)}
        onBlurFormater={trimValue}
        rows={5}
        {...commonAttributes}
      />

      {error && <Alert message={translate(translationKeys.errors.error)} description={error} type={'error'} showIcon />}
    </>
  );
};

export const AlertCustomResponsesFormFields = reduxForm({
  form: ALERT_CUSTOM_RESPONSES_FORM,
  validate: validateAlertCustomResponsesForm,
})(AlertCustomResponsesForm);
