import React, { ReactNode } from 'react';
import './page-description.styles.scss';
import Title from 'antd/lib/typography/Title';
import btButtonBanner from '../../../assets/banners/bt_button.jpg'; 

interface PageDescriptionProps {
  title?: string;
  content?: ReactNode | string;
  showAd?: boolean;
}

export const PageDescription: React.FC<PageDescriptionProps> = ({ title, content, showAd = false }) => {
  return (
    <div className={'page-description__container'}>
      <div className={'page-description__content-wrapper'}>
        {title && (
          <Title level={4}>
            {title}
          </Title>
        )}
        {content && <div>{content}</div>}
      </div>
      {showAd && (
        <div className={'page-description__banner-wrapper'}>
          <a href={'https://teamalert.com/pricing/#add-ons'} target={'__blank'}>
            <img className={'page-description__banner-img'} src={btButtonBanner} alt={''} />
          </a>
        </div>
      )}
    </div>
  );
};
