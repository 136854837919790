import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { channelActionCreators } from '../../../../logic/store/channel/channel.action';
import { getSortableFilterableColumnCreator } from '../../../components/table/table.column';
import { ChannelFormFieldName } from './channel-form.field-name';
import { translationKeys, translate } from '../../../../logic/translations/translations.service';
import { MasterContent } from '../../../master-page/components/master-content/master-content';
import { Table, SortableFilterableColumn } from '../../../components/table/table';
import { getActiveChannels, getNotActiveChannels } from '../../models/channel';
import { Switch, Dropdown, Button } from 'antd';
import { getInProgress } from '../../../../logic/store/process-tracker/process-tracker.selectors';
import queryString from 'query-string';
import { RouteComponentProps } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { getTableSelectedRowKeys, getTableData } from '../../../../logic/store/tables/tables.selectors';
import './channels.styles.scss';
import { UserAlertChannelSettings } from '../../../../api-models/api-models';
import { TableName } from '../../../../logic/store/tables/tables.action';
import { MasterPageTableHeader } from '../../../components/master-page-table-header/master-page-table-header';
import { alertActionCreators } from '../../../../logic/store/alert/alert.action';
import { parseId } from '../../../../logic/utils/parse-id';
import { getAlertType } from '../../../../logic/store/alert/alert.selectors';
import { PageDescription } from '../../../components/page-description/page-description';
import { ItemType } from 'antd/lib/menu/hooks/useItems';

declare global {
  interface Window {
    dataLayer: any[];
  }
}

interface ChannelsPageProps extends RouteComponentProps {}

export const ChannelsPage: React.FC<ChannelsPageProps> = ({ location }) => {
  const dispatch = useDispatch();
  const updateSingleInProgress = useSelector(getInProgress(channelActionCreators.updateSingle.name));
  const updateManyInProgress = useSelector(getInProgress(channelActionCreators.updateMany.name));
  const fetchSingleInProgress = useSelector(getInProgress(alertActionCreators.fetchSingle.name));
  const inProgress = updateSingleInProgress || updateManyInProgress || fetchSingleInProgress;
  const alertType = useSelector(getAlertType);
  const { alert_id } = queryString.parse(location.search);
  const alert_id_parsed = parseId(alert_id) || -1;
  const alertName = (!fetchSingleInProgress && alertType?.name) || '';

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'setupChannelsTab_view',
    });
  }, []);

  useEffect(() => {
    dispatch(alertActionCreators.fetchSingle(alert_id_parsed));
  }, [dispatch, alert_id_parsed]);

  const getChannelColumn = getSortableFilterableColumnCreator<ChannelFormFieldName, UserAlertChannelSettings>(
    translationKeys.forms.fields.channel
  );

  const renderSwitch = (field: ChannelFormFieldName) => (value: boolean, channel: UserAlertChannelSettings) =>
    (
      <Switch
        checked={value}
        size={'small'}
        onChange={(value) => {
          dispatch(
            channelActionCreators.updateSingle({
              ...channel,
              [field]: value,
            })
          );
        }}
      />
    );

  const tableSelectedRowKeys = useSelector(getTableSelectedRowKeys(TableName.channel)) || [];
  const anyRowSelected = !isEmpty(tableSelectedRowKeys);
  const allChannels = useSelector(getTableData(TableName.channel));

  const menu = (fieldName: ChannelFormFieldName) => {
    const toSwitchOn = getNotActiveChannels(allChannels, tableSelectedRowKeys, fieldName).length;
    const toSwitchOff = getActiveChannels(allChannels, tableSelectedRowKeys, fieldName).length;

    return [
      ...[
        !!toSwitchOn && {
          label: translate(translationKeys.buttons.switchOn, { toSwitchOn }),
          key: 1,
          onClick: handleSwitchSelectedOnClick(fieldName, true),
        },
      ],
      ...[
        !!toSwitchOff && {
          label: translate(translationKeys.buttons.switchOff, { toSwitchOff }),
          key: 2,
          onClick: handleSwitchSelectedOnClick(fieldName, false),
        },
      ],
    ] as Array<ItemType>;
  };

  const handleSwitchSelectedOnClick = (fieldName: ChannelFormFieldName, newValue: boolean) => () => {
    const channels = !!newValue
      ? getNotActiveChannels(allChannels, tableSelectedRowKeys, fieldName)
      : getActiveChannels(allChannels, tableSelectedRowKeys, fieldName);

    dispatch(channelActionCreators.updateMany(channels.map((channel) => ({ ...channel, [fieldName]: newValue }))));
  };

  const getChannelSwitchColumn =
    (fieldName: ChannelFormFieldName): SortableFilterableColumn<UserAlertChannelSettings> =>
    (_, sorter) => ({
      title: () => (
        <div className={'channel-action-header'}>
          <div className={'channel-action-title'}>{translate(translationKeys.forms.fields.channel[fieldName])}</div>
          {anyRowSelected && (
            <Dropdown className={'channel-action-button'} menu={{ items: menu(fieldName) }}>
              <Button size={'small'}>{'...'}</Button>
            </Dropdown>
          )}
        </div>
      ),
      width: 132,
      dataIndex: fieldName,
      key: fieldName,
      sorter: !anyRowSelected,
      sortOrder: sorter?.field === fieldName ? sorter?.order : undefined,
      render: renderSwitch(fieldName),
    });

  const getColumns = [
    getChannelColumn(ChannelFormFieldName.user_first_name, false),
    getChannelColumn(ChannelFormFieldName.user_last_name, false),
    getChannelColumn(ChannelFormFieldName.user_department, false),
    getChannelSwitchColumn(ChannelFormFieldName.is_email_enabled),
    getChannelSwitchColumn(ChannelFormFieldName.is_call_enabled),
    getChannelSwitchColumn(ChannelFormFieldName.is_sms_enabled),
    getChannelSwitchColumn(ChannelFormFieldName.is_desktop_enabled),
  ];

  return (
    <>
      <MasterPageTableHeader
        showBack
        content={
          <PageDescription
            title={alertName}
            content={
              <ul>
                <li>{translate(translationKeys.pages.alertChannels.bullet1)}</li>
                <li>{translate(translationKeys.pages.alertChannels.bullet2)}</li>
                <li>{translate(translationKeys.pages.alertChannels.bullet3)}</li>
              </ul>
            }
          />
        }
      />
      <MasterContent>
        <Table
          tableName={TableName.channel}
          getColumns={getColumns}
          rowSelectable
          prefilters={{ alert_type_id: alert_id_parsed }}
          loading={inProgress}
        />
      </MasterContent>
    </>
  );
};
