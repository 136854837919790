import { put } from 'redux-saga/effects';
import { touch, stopSubmit } from 'redux-form';
import { flatten } from '../utils/flatten';

export function* reduxFormErrorHandler(formName: string, errors: object) {
  yield showReduxFormErrors(formName, Object.keys(flatten(errors)));
}

function* showReduxFormErrors(formName: string, fields: string[]) {
  yield put(touch(formName, ...fields));
}

export function* reduxFormAsyncSubmitErrorHandler(formName: string, message: string) {
  yield put(stopSubmit(formName, { _error: `validator.${message}` }));
}

export function* reduxFormAsyncErrorsHandler(formName: string, errors: object) {
  yield put(stopSubmit(formName, errors));
  yield showReduxFormErrors(formName, Object.keys(flatten(errors)));
}
