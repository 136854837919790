import React, { PropsWithChildren, useEffect } from 'react';
import { AppSpin } from '../../components/app-spin/app-spin';
import { useSelector, useDispatch } from 'react-redux';
import { getInProgress } from '../store/process-tracker/process-tracker.selectors';
import { initializationActionCreators } from '../store/initialization/initialization.actions';

export const CacheCleanProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useDispatch();

  const isLoading = useSelector(getInProgress(initializationActionCreators.refreshBrowserCacheIfNeeded.name));

  useEffect(() => {
    dispatch(initializationActionCreators.refreshBrowserCacheIfNeeded());
  }, [dispatch]);

  return isLoading ? <AppSpin /> : <>{children}</>;
};
