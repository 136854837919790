import React, { ChangeEventHandler, FocusEventHandler, SyntheticEvent } from 'react';
import { WrappedFieldProps } from 'redux-form';
import { FormItemProps } from 'antd/lib/form';
import { Form, Input } from 'antd';
import { LabelWithToolTip } from '../label-with-tooltip/label-with-tooltip';
import { InputProps } from 'antd/lib/input';
import { parseEmptyValueAs } from '../../../../logic/formaters/formaters';
import { isNil } from 'lodash';
import RichTextEditor from '../rich-text-editor-input/rich-text-editor-input';
const { TextArea } = Input;

interface OwnProps extends InputProps {
  isPassword?: boolean;
  toolTipMessage?: string;
  rows?: number;
  onBlurFormater?: <T>(value: T) => T;
  richText: boolean;
}

type FormTextInputProps = WrappedFieldProps & FormItemProps & OwnProps;

export const FormTextInput = ({
  label,
  required,
  disabled,
  validateStatus,
  labelCol,
  wrapperCol,
  isPassword = false,
  toolTipMessage,
  onBlurFormater,
  meta,
  input,
  rows,
  richText = false,
  ...rest
}: FormTextInputProps) => {
  const errorMessage = !!meta && meta.error;
  const showError = !!meta && meta.touched && !!meta.error;
  const TextInputComponent = isPassword ? Input.Password : Input;
  const labelComponent = toolTipMessage ? <LabelWithToolTip label={label} toolTipMessage={toolTipMessage} /> : label;
  const isTextArea = !!rows && rows > 1;
  const { onBlur, onChange, ...restInput } = input;

  const isPasted = (e: SyntheticEvent<Element, InputEvent>): boolean =>
    !isNil(e.nativeEvent.inputType) && e.nativeEvent.inputType === 'insertFromPaste';

  const handleBlur: FocusEventHandler<any> = e => {
    const newValue = e.target.value;
    const formatedValue = onBlurFormater ? onBlurFormater(newValue) : newValue;
    onBlur(parseEmptyValueAs(null)(formatedValue));
  };

  const handleChange: ChangeEventHandler<any> = (e) => {
    const newValue = parseEmptyValueAs(null)(e.target.value);
    onChange(isPasted(e as SyntheticEvent<Element, InputEvent>)
      ? onBlurFormater ? onBlurFormater(newValue) : newValue
      : newValue);
  };

  return (
    <Form.Item
      label={labelComponent}
      required={required}
      validateStatus={showError ? 'error' : undefined}
      help={showError ? errorMessage : undefined}
      labelCol={labelCol}
      wrapperCol={wrapperCol}
    >
      {richText ? (
        <RichTextEditor input={input} meta={meta} />
      ) : isTextArea ? (
        <TextArea
          onBlur={handleBlur}
          onChange={handleChange}
          {...restInput}
          disabled={disabled}
          rows={rows}
          autoComplete={'chrome-off'}
        />
      ) : (
        <TextInputComponent
          onBlur={handleBlur}
          onChange={handleChange}
          {...restInput}
          {...rest}
          disabled={disabled}
          autoComplete={'chrome-off'}
        />
      )}

    </Form.Item>
  );
};
