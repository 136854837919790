import Amplify from 'aws-amplify';
import { env } from '../env/env';

export const initializeCognito = () => {
  Amplify.configure({
    Auth: {
      userPoolId: env.REACT_APP_COGNITO_USER_POOL_ID,
      userPoolWebClientId: env.REACT_APP_COGNITO_POOL_WEB_CLIENT_ID,
    },
    oauth: {
      domain: env.REACT_APP_SSO_DOMAIN_URL,
      scope: ['email', 'openid', 'aws.cognito.signin.user.admin', 'profile'],
      redirectSignIn: env.REACT_APP_SSO_REDIRECT_SIGN_IN_URL,
      redirectSignOut: env.REACT_APP_SSO_REDIRECT_SIGN_OUT_URL,
      responseType: 'token',
      clientId: env.REACT_APP_COGNITO_POOL_WEB_CLIENT_ID,
    },
  });
};
