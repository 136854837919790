import { createAction, ActionsUnion } from '../../redux/action-creator';
import { GroupForm } from '../../../pages/group/pages/group-form-template/group-form.form';
import { Group } from '../../../api-models/api-models';

export enum GroupActionTypes {
  RESET = 'group/RESET',
  CREATE_SINGLE = 'group/CREATE_SINGLE',
  UPDATE_SINGLE = 'group/UPDATE_SINGLE',
  GET_SINGLE_FOR_EDIT = 'group/GET_SINGLE_FOR_EDIT',
  DELETE_SINGLE = 'group/DELETE_SINGLE',
}

export const groupActionCreators = {
  reset: () => createAction(GroupActionTypes.RESET),
  createSingle: (formValues: GroupForm) => createAction(GroupActionTypes.CREATE_SINGLE, { formValues }),
  updateSingle: (formValues: GroupForm) => createAction(GroupActionTypes.UPDATE_SINGLE, { formValues }),
  getSingleForEdit: (id: any, formName: string, mapper: (data: Group) => GroupForm) =>
    createAction(GroupActionTypes.GET_SINGLE_FOR_EDIT, { id, formName, mapper }),
  deleteSingle: (id: number) => createAction(GroupActionTypes.DELETE_SINGLE, { id }),
};

export type GroupActions = ActionsUnion<typeof groupActionCreators>;
