import { LocationTag } from '../../api-models/api-models';
import { LocationTagForm } from '../../pages/users/pages/user-form-template/user-form.form';

export const mapLocationTagToForm = (locationTagList: LocationTag[] = []): LocationTagForm[] =>
  locationTagList.map(item => {
    const {location_id, tag, id} = item;

    return {
      location_id,
      tag_name: tag,
      id
    }
  });
