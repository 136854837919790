import { Form } from 'antd';
import { InputProps } from 'antd/lib/input';
import * as React from 'react';
import { touch, WrappedFieldProps } from 'redux-form';
import { DatePicker } from 'antd';
import { FormItemProps } from 'antd/lib/form';
import moment from 'moment';
import { formatDateTimeForPayload, MOMENT_DATE_FORMAT } from '../../../../logic/date-time/date-time.format';
import { DatePickerProps } from 'antd/lib/date-picker';
import styled from 'styled-components';

interface OwnProps extends InputProps {}

type FormDateInputProps = WrappedFieldProps & FormItemProps & DatePickerProps & OwnProps;

export const FormDateInput = ({
  label,
  required,
  validateStatus,
  labelCol,
  wrapperCol,
  meta,
  input,
  min,
  max,
  ...rest
}: FormDateInputProps) => {
  const errorMessage = !!meta && meta.error;
  const showError = !!meta && meta.touched && !!meta.error;
  const { value, onBlur, onChange, ...restInput } = input;

  const handleChange: DatePickerProps['onChange'] = (e) => {
    const newValue = e ? formatDateTimeForPayload(e as any) : '';
    onChange(newValue);
  };

  const handleBlur: DatePickerProps['onBlur'] = (e) => {
    meta.dispatch(touch(meta.form, input.name));
  };

  const dateValue = value ? moment(value) : undefined;

  return (
    <Form.Item
      label={label}
      required={required}
      validateStatus={showError ? 'error' : undefined}
      help={showError ? errorMessage : undefined}
      labelCol={labelCol}
      wrapperCol={wrapperCol}
    >
      <DatePickerStyled
        value={dateValue}
        onChange={handleChange}
        format={MOMENT_DATE_FORMAT}
        onBlur={handleBlur}
        autoComplete={'chrome-off'}
        disabledDate={(d) => !d || (!!min && d.isBefore(moment(min))) || (!!max && d.isAfter(moment(max)))}
        {...restInput}
        {...rest}
      />
    </Form.Item>
  );
};

const DatePickerStyled = styled(DatePicker)`
  width: 100%;
`;
