import { Button } from 'antd';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFormValues } from 'redux-form';
import { companyActionCreators } from '../../../../logic/store/company/company.action';
import { translate, translationKeys } from '../../../../logic/translations/translations.service';
import { useFormModal } from '../../../components/form-modal/form-modal';
import { SetupUserDetailsFormFields } from '../setup-user-details-form/setup-user-details.form-fields';
import { SetupUserDetailsForm, SETUP_USER_DETAILS_FORM } from '../setup-user-details-form/setup-user-details.form';
import { getAuthenticatedUser } from '../../../../logic/store/authentication/authentication.selectors';
import { getShouldUpdateUserDetails } from './setup-company-button.logic';

export const SetupCompanyButton: React.FC = () => {
  const dispatch = useDispatch();
  const formValues: SetupUserDetailsForm = useSelector(getFormValues(SETUP_USER_DETAILS_FORM));
  const authenticatedUser = useSelector(getAuthenticatedUser);
  const shouldUpdateUserDetails = getShouldUpdateUserDetails(authenticatedUser) || true;

  const handleSetupCompanyWithUserDetails = useCallback(() => {
    dispatch(companyActionCreators.setupCompanyWithUserDetails(formValues));
  }, [dispatch, formValues]);

  const setupUserModal = useFormModal({
    title: translate(translationKeys.pages.dashboard.setUpAutomatically.setUpForm.title),
    formName: SETUP_USER_DETAILS_FORM,
    submit: handleSetupCompanyWithUserDetails,
    processName: companyActionCreators.setupCompanyWithUserDetails.name,
    getContent: () => (
      <>
        {translate(translationKeys.pages.dashboard.setUpAutomatically.content.setUpButton.description)}
        <br />
        <br />
        <SetupUserDetailsFormFields onPressEnter={handleSetupCompanyWithUserDetails} formValues={formValues} />
      </>
    ),
    showContentInProgress: true,
    okText: translate(translationKeys.pages.dashboard.setUpAutomatically.content.setUpButton.title),
  });

  const handleSetUpCompany = useCallback(() => {
    if (shouldUpdateUserDetails) {
      setupUserModal.show();
    } else {
      dispatch(companyActionCreators.setupCompany());
    }
  }, [dispatch, setupUserModal, shouldUpdateUserDetails]);

  return (
    <>
      <Button type="primary" onClick={handleSetUpCompany}>
        {translate(translationKeys.pages.dashboard.setUpAutomatically.content.setUpButton.title)}
      </Button>
      {setupUserModal.render()}
    </>
  );
};
