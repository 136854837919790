import { translationKeys } from '../../../../logic/translations/translations.service';
import { getSortableFilterableColumnCreator } from '../../../components/table/table.column';
import { SsoCompanyAdmin } from '../../../../api-models/api-models';
import { SsoCompanyAdminsFormFieldName } from '../sso-company-admins-form-template/sso-company-admins-form-field-name';

const getCompanyAdminSsoColumn = getSortableFilterableColumnCreator<SsoCompanyAdminsFormFieldName, SsoCompanyAdmin>(
  translationKeys.forms.fields.ssoCompanyAdmins
);

const ssoCompanyAdminsColumns = {
  [SsoCompanyAdminsFormFieldName.company]: getCompanyAdminSsoColumn(SsoCompanyAdminsFormFieldName.company),
  [SsoCompanyAdminsFormFieldName.company_name]: getCompanyAdminSsoColumn(SsoCompanyAdminsFormFieldName.company_name),
  [SsoCompanyAdminsFormFieldName.email]: getCompanyAdminSsoColumn(SsoCompanyAdminsFormFieldName.email),
};

export const getCompanyAdminsSsoColumns = (fieldNames: SsoCompanyAdminsFormFieldName[]) =>
  fieldNames.map((fieldName) => ssoCompanyAdminsColumns[fieldName]);
