import { reduxForm } from 'redux-form';
import { compose } from 'redux';
import { translate, translationKeys } from '../../../logic/translations/translations.service';
import { groupActionCreators } from '../../../logic/store/group/group.action';
import { GROUP_FORM, groupFormInitialValues, validateGroupForm } from './group-form-template/group-form.form';
import { GroupFormPage, GroupFormPageOwnProps } from './group-form-template/group-form-template.page';
import { getAuthenticatedUserCompanyId } from '../../../logic/store/authentication/authentication.selectors';
import { store } from '../../../app/app.store';
import { DEFAULT_PHONE_COUNTRY_CODE } from '../../authentication/models/country-code/country-code';
import { wrapComponentsWithProps } from '../../../logic/hoc/wrap-components-with-props.hoc';

export const GroupAddPageCreator = () =>
  compose(
    wrapComponentsWithProps<GroupFormPageOwnProps>({
      submitButtonText: translate(translationKeys.buttons.addGroup),
      submitFormAction: groupActionCreators.createSingle,
      formName: GROUP_FORM,
    }),
    reduxForm({
      form: GROUP_FORM,
      initialValues: {
        ...groupFormInitialValues,
        phone_country_code: DEFAULT_PHONE_COUNTRY_CODE,
        company_id: getAuthenticatedUserCompanyId(store.getState()),
      },
      validate: validateGroupForm,
    })
  )(GroupFormPage) as React.FC;
