import { CompanySsoDomain } from '../../../../api-models/api-models';
import { FormErrors } from 'redux-form';
import { clearEmptyFieldsAndEmptyObjects } from '../../../../logic/validators/utils';
import { isRequiredValidator } from '../../../../logic/validators/validators';

export const COMPANY_DOMAINS_FORM = 'companyDomains';

export interface CompanyDomainsForm extends CompanySsoDomain {}

const devFields: Partial<CompanyDomainsForm> = {};

export const companyDomainsFormInitialValues: CompanyDomainsForm = {
  ...devFields,
};

export const validateCompanyDomainsForm = (formValues: CompanyDomainsForm): FormErrors<CompanyDomainsForm, string> => {
  const { company, domain } = formValues;

  const validation = clearEmptyFieldsAndEmptyObjects({
    company: isRequiredValidator(company),
    domain: isRequiredValidator(domain),
  });

  return validation;
};

export const domainsToFormValuesMapper = (ssoDomain: CompanySsoDomain): CompanyDomainsForm => {
  return {
    ...ssoDomain,
  };
};

export const formValuesToDomainsMapper = (formValues: CompanyDomainsForm): Undefineable<CompanySsoDomain> => {
  return {
    ...formValues,
  };
};
