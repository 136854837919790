import { FormErrors } from 'redux-form';
import { clearEmptyFields } from '../../../../logic/validators/utils';
import { isRequiredValidator, isEmailValididator } from '../../../../logic/validators/validators';

export const LOGIN_FROM = 'login';

export interface LoginForm {
  email?: string;
  password?: string;
}

export type LoginFormValidationErrors = FormErrors<LoginForm>;

export const validateLoginForm = (formValues: LoginForm) => {
  const { email, password } = formValues;

  const errors: FormErrors<LoginForm, any> = {
    email: isRequiredValidator(email) || isEmailValididator(email),
    password: isRequiredValidator(password),
  };

  return clearEmptyFields(errors);
};
