import React, { useEffect, useCallback } from 'react';
import { Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { ColumnProps } from 'antd/lib/table';
import { alertActionCreators } from '../../../logic/store/alert/alert.action';
import { navigationActionCreators } from '../../../logic/store/navigation/navigation.actions';
import { AUTHENTICATED_ROUTES } from '../../../logic/routes/routes.const';
import { translate, translationKeys } from '../../../logic/translations/translations.service';
import { MasterContent } from '../../master-page/components/master-content/master-content';
import {
  getSortableFilterableColumnCreator,
  renderCheck,
  renderColorCircle,
} from '../../components/table/table.column';
import { useConfirmActionModal } from '../../components/confirm-modal/confirm-modal';
import { Table } from '../../components/table/table';
import { getInProgress } from '../../../logic/store/process-tracker/process-tracker.selectors';
import { isProduction } from '../../../logic/env/env';
import { AlertType } from '../../../api-models/api-models';
import { useSearchInput } from '../../components/search-input/search-input';
import { TableName } from '../../../logic/store/tables/tables.action';
import { MasterPageTableHeader } from '../../components/master-page-table-header/master-page-table-header';
import { ChangeOrderActionsCell } from '../../components/table/change-order-actions-cell/change-order-actions-cell';
import { getTableUnfilteredTotalData } from '../../../logic/store/tables/tables.selectors';
import { mapGroupsToNames, mapLocationToNames } from '../../../logic/store/group/group.logic';
import { TableHeaderButton } from '../../components/master-page-table-header/master-page-table-header.components';
import parse from 'html-react-parser';
import { useGroupFilter } from '../../components/groups-filter/groups-filter';
import {
  AlertTypeButtonFieldName,
  AlertTypeFormFieldName,
} from './alert-type-form-template/alert-type.form.field-name';
import { PageDescription } from '../../components/page-description/page-description';
import { ResourceLink } from '../../components/resources/resource-link';
import { Resource, resourceContents } from '../../components/resources/resource.const';

declare global {
  interface Window {
    dataLayer: any[];
  }
}

export const AlertTypesPage: React.FC = () => {
  const searchInput = useSearchInput();
  const groupFilter = useGroupFilter();
  const dispatch = useDispatch();
  const lowerInProgress = useSelector(getInProgress(alertActionCreators.lower.name));
  const riseInProgress = useSelector(getInProgress(alertActionCreators.rise.name));
  const changeOrderInProgress = useSelector(getInProgress(alertActionCreators.changeOrder.name));
  const inProgress = lowerInProgress || riseInProgress || changeOrderInProgress;
  const total = useSelector(getTableUnfilteredTotalData(TableName.alert));

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'setupAlertsTab_view',
    });
  }, []);

  const handleAddSingle = () => dispatch(navigationActionCreators.navigateTo(AUTHENTICATED_ROUTES.AlertAdd));

  const handleDuplicateSingle = (row: AlertType) => () =>
    dispatch(navigationActionCreators.navigateTo(AUTHENTICATED_ROUTES.AlertAdd, `?id=${row.id}`));

  const handleEditSingle = (row: AlertType) => () =>
    dispatch(navigationActionCreators.navigateTo(AUTHENTICATED_ROUTES.AlertEdit, `?id=${row.id}`));

  const handleManageChannels = (row: AlertType) => () =>
    dispatch(navigationActionCreators.navigateTo(AUTHENTICATED_ROUTES.AlertManageChannels, `?alert_id=${row.id}`));

  const handleManageDocuments = (row: AlertType) => () =>
    dispatch(navigationActionCreators.navigateTo(AUTHENTICATED_ROUTES.AlertManageDocuments, `?alert_id=${row.id}`));

  const handleQuickResponses = (row: AlertType) => () =>
    dispatch(navigationActionCreators.navigateTo(AUTHENTICATED_ROUTES.AlertQuickResponses, `?alert_id=${row.id}`));

  const handleRiseAlert = (row: AlertType) => () => row.id && dispatch(alertActionCreators.rise(row.id));

  const handleDelete = (alert: AlertType | undefined) => {
    if (alert && alert.id) {
      dispatch(alertActionCreators.deleteSingle(alert.id));
    }
  };

  const deleteModal = useConfirmActionModal(
    translate(translationKeys.modalDialog.deleteAlert),
    translate(translationKeys.modalDialog.areYouSureYouWantToRemoveThisAlert),
    handleDelete,
    alertActionCreators.deleteSingle.name
  );

  const actionsColumn = (): ColumnProps<AlertType> => ({
    title: translate(translationKeys.forms.fields.actions),
    dataIndex: 'actions',
    key: 'actions',
    render: (value, row) => (
      <span>
        <Button type={'link'} onClick={handleEditSingle(row)}>
          {translate(translationKeys.table.action.edit)}
        </Button>
        <Button type={'link'} onClick={deleteModal.tryToPerformAction(row)}>
          {translate(translationKeys.table.action.delete)}
        </Button>
        <Button type={'link'} onClick={handleManageChannels(row)}>
          {translate(translationKeys.table.action.manageChannels)}
        </Button>
        <Button type={'link'} onClick={handleManageDocuments(row)}>
          {translate(translationKeys.table.action.manageDocuments)}
        </Button>
        <Button type={'link'} onClick={handleQuickResponses(row)}>
          {translate(translationKeys.table.action.quickResponses)}
        </Button>
        <Button type={'link'} onClick={handleDuplicateSingle(row)}>
          {translate(translationKeys.table.action.duplicate)}
        </Button>
        {!isProduction() && (
          <Button type={'link'} onClick={handleRiseAlert(row)}>
            {translate(translationKeys.table.action.raise)}
          </Button>
        )}
      </span>
    ),
  });

  const getAlertTypeColumn = getSortableFilterableColumnCreator<AlertTypeFormFieldName, AlertType>(
    translationKeys.forms.fields.alertType
  );

  const getAlertTypeButtonColumn = getSortableFilterableColumnCreator<AlertTypeButtonFieldName, AlertType>(
    translationKeys.forms.fields.alertTypeButon
  );

  const handleOrderChange = useCallback(
    (alertId: number | undefined) => (order: number | undefined) =>
      dispatch(alertActionCreators.changeOrder(alertId, order)),
    [dispatch]
  );

  const getColumns = [
    actionsColumn,
    getAlertTypeButtonColumn(
      AlertTypeButtonFieldName.background_color,
      false,
      (value, row) => renderColorCircle(row.button_settings?.background_color),
      ''
    ),
    getAlertTypeColumn(AlertTypeFormFieldName.name),
    getAlertTypeColumn(AlertTypeFormFieldName.sender_group, false, (value, row) => row.sender_group_name),
    getAlertTypeColumn(AlertTypeFormFieldName.locations_names, false, (value, row) =>
      mapLocationToNames(row?.locations_names)
    ),
    getAlertTypeColumn(AlertTypeFormFieldName.recipient_groups, false, (value, row) =>
      mapGroupsToNames(row?.recipient_groups_names)
    ),
    getAlertTypeColumn(AlertTypeFormFieldName.can_be_lowered_by_raising_user, true, renderCheck),
    getAlertTypeColumn(AlertTypeFormFieldName.calls_e911, true, renderCheck),
    getAlertTypeColumn(AlertTypeFormFieldName.cancellation_timeout),
    getAlertTypeColumn(AlertTypeFormFieldName.order, true),
    getAlertTypeColumn(AlertTypeFormFieldName.language, true),
    getAlertTypeColumn(AlertTypeFormFieldName.change_order, false, (value, row) => (
      <ChangeOrderActionsCell order={row.order} minOrder={1} maxOrder={total} onChange={handleOrderChange(row.id)} />
    )),
  ];

  const handleEvent = (event: string) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event,
      page: 'AlertTypesPage',
      action: event,
    });
  };

  return (
    <>
      <MasterPageTableHeader
        searchInput={searchInput.render()}
        filteringInput={groupFilter.render()}
        leftSection={
          <TableHeaderButton
            type={'primary'}
            onClick={() => {
              handleAddSingle();
              handleEvent('addAlertButton');
            }}
          >
            {translate(translationKeys.buttons.addAlert)}
          </TableHeaderButton>
        }
        content={
          <PageDescription
            title={translate(translationKeys.pages.alertTypes.title)}
            content={
              <>
                <p>{parse(translate(translationKeys.pages.alertTypes.description))}</p>
                <p>{parse(translate(translationKeys.pages.alertTypes.noteTitle))}</p>
                <ul>
                  <li>{translate(translationKeys.pages.alertTypes.note1)}</li>
                  <li>{translate(translationKeys.pages.alertTypes.note2)}</li>
                  <li>{translate(translationKeys.pages.alertTypes.note3)}</li>
                </ul>
                <p>
                  <ResourceLink
                    content={resourceContents[Resource.alertManagementVideoSupportLink]}
                    text={translate(translationKeys.pages.alertTypes.videoLinkDescription)}
                    noPaddingLeft
                  />
                </p>
              </>
            }
          />
        }
      />
      <MasterContent>
        <Table
          tableName={TableName.alert}
          getColumns={getColumns}
          loading={inProgress}
          searchInput={searchInput}
          groupFilter={groupFilter}
          fetchUnfilteredTotal
          initialSorter={{
            field: AlertTypeFormFieldName.order,
            order: 'ascend',
            column: {},
            columnKey: AlertTypeFormFieldName.order,
          }}
        />
      </MasterContent>
      {deleteModal.render()}
    </>
  );
};
