import { TableActions, TableActionTypes, TableName } from './tables.action';
import { TableQuery } from './tables.types';
import { map, reduce, cloneDeep } from 'lodash';

export type QueryStringPrefilters = Record<keyof any, string | number | boolean>;

export interface TableState<T = any> {
  data?: T[];
  prefilters?: QueryStringPrefilters;
  query?: TableQuery;
  selectedRowKeys?: number[];
  unfilteredTotal?: number;
}
export interface TablesState {
  [tableName: string]: TableState | undefined;
}

export const PAGE_SIZE = 10;
export const initialTableQueryPagination = { pageSize: PAGE_SIZE, current: 1, pageSizeOptions: [`${PAGE_SIZE}`] };

const initialTableQuery: TableQuery = {
  pagination: initialTableQueryPagination,
  sorter: { field: 'id', order: 'ascend', column: {}, columnKey: 'id' },
};

const initialState: TablesState = reduce(
  map(TableName, tableName => tableName),
  (result, tableName) => ({ ...result, [tableName]: { query: cloneDeep(initialTableQuery) } }),
  {}
);

export const tablesReducer = (state: TablesState = initialState, action: TableActions): TablesState => {
  switch (action.type) {
    case TableActionTypes.RESET:
      return initialState;
    case TableActionTypes.UPDATE_QUERY:
      return {
        ...state,
        [action.payload.tableName]: {
          ...state[action.payload.tableName],
          query: {
            ...state[action.payload.tableName]?.query,
            ...action.payload.tableQuery,
          },
        },
      };
    case TableActionTypes.SET_SELECTED_ROW_KEYS:
      return {
        ...state,
        [action.payload.tableName]: {
          ...state[action.payload.tableName],
          selectedRowKeys: action.payload.selectedRowKeys,
        },
      };
    case TableActionTypes.SET_DATA:
      return {
        ...state,
        [action.payload.tableName]: {
          ...state[action.payload.tableName],
          data: action.payload.response.data?.results,
          query: {
            ...state[action.payload.tableName]?.query,
            pagination: {
              ...state[action.payload.tableName]?.query?.pagination,
              total: action.payload.response.data?.count,
            },
          },
        },
      };
    case TableActionTypes.SET_UNFILTERED_TOTAL:
      return {
        ...state,
        [action.payload.tableName]: {
          ...state[action.payload.tableName],
          unfilteredTotal: action.payload.response.data?.count,
        },
      };
    case TableActionTypes.SET_PAGE:
      return {
        ...state,
        [action.payload.tableName]: {
          ...state[action.payload.tableName],
          query: {
            ...state[action.payload.tableName]?.query,
            pagination: {
              ...state[action.payload.tableName]?.query?.pagination,
              current: action.payload.page,
            },
          },
        },
      };
    default:
      return state;
  }
};
