import { createAction, ActionsUnion } from '../../../redux/action-creator';
import { Location } from '../../../../api-models/api-models';
import { LocationForm } from '../../../../common/location/location.type';

export enum LocationE911ActionTypes {
  RESET = 'locationE911/RESET',
  CREATE_SINGLE = 'locationE911/CREATE_SINGLE',
  UPDATE_SINGLE = 'locationE911/UPDATE_SINGLE',
  GET_SINGLE_FOR_EDIT = 'locationE911/GET_SINGLE_FOR_EDIT',
  DELETE_SINGLE = 'locationE911/DELETE_SINGLE',
}

export const locationE911ActionCreators = {
  reset: () => createAction(LocationE911ActionTypes.RESET),
  createSingle: (formValues: LocationForm) => createAction(LocationE911ActionTypes.CREATE_SINGLE, { formValues }),
  updateSingle: (formValues: LocationForm) => createAction(LocationE911ActionTypes.UPDATE_SINGLE, { formValues }),
  getSingleForEdit: (id: any, formName: string, mapper: (data: Location) => LocationForm) =>
    createAction(LocationE911ActionTypes.GET_SINGLE_FOR_EDIT, { id, formName, mapper }),
  deleteSingle: (id: number) => createAction(LocationE911ActionTypes.DELETE_SINGLE, { id }),
};

export type LocationActions = ActionsUnion<typeof locationE911ActionCreators>;
