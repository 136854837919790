import { reduxForm } from 'redux-form';
import { compose } from 'redux';
import { translate, translationKeys } from '../../../logic/translations/translations.service';
import { alertActionCreators } from '../../../logic/store/alert/alert.action';
import { ALERT_TYPE_FORM, alertTypeFormInitialValues } from './alert-type-form-template/alert-type-form';
import {
  AlertTypeFormTemplatePage,
  AlertTypeFormPageOwnProps,
} from './alert-type-form-template/alert-type-form-template.page';
import { wrapComponentsWithProps } from '../../../logic/hoc/wrap-components-with-props.hoc';

export const alertTypeAddPageCreator = () =>
  compose(
    wrapComponentsWithProps<AlertTypeFormPageOwnProps>({
      submitButtonText: translate(translationKeys.buttons.addAlert),
      submitFormAction: alertActionCreators.createSingle,
      formName: ALERT_TYPE_FORM,
    }),
    reduxForm({
      form: ALERT_TYPE_FORM,
      initialValues: {
        ...alertTypeFormInitialValues,
      },
    })
  )(AlertTypeFormTemplatePage) as React.FC;
