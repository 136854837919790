import React, { useEffect } from 'react';
import { Form, Button } from 'antd';
import parse from 'html-react-parser';
import { authenticationActionCreators } from '../../../../logic/store/authentication/authentication.actions';
import { useDispatch, useSelector } from 'react-redux';
import { translate, translationKeys } from '../../../../logic/translations/translations.service';
import { reduxForm, InjectedFormProps, Field, getFormValues } from 'redux-form';
import { FormTextInput } from '../../../components/form-inputs/form-text-input/form-text-input';
import { FormContainer, formButtonLayout, formItemLayout } from '../../../components/form-container/form-container';
import { getInProgress } from '../../../../logic/store/process-tracker/process-tracker.selectors';
import { AuthenticationMasterPage } from '../authentication-master/authentication-master.page';
import { Logo } from '../../../components/logo/logo';
import { trimValue } from '../../../../logic/formaters/formaters';
import { axiosAuthenticatedClient } from '../../../../logic/axios/axios-authenticated-client';
import { env } from '../../../../logic/env/env';
import { LOGIN_DISCOVERY_FORM_NAME, LoginDiscoveryForm, validateLoginDiscoveryForm } from './login-discovery.form';
import { navigationActionCreators } from '../../../../logic/store/navigation/navigation.actions';
import { AUTHENTICATION_ROUTES, defaultRoute } from '../../../../logic/routes/routes.const';
import { queryStringStringify } from '../../../../logic/query-string/query-string';
import {
  getAuthenticatedUserRole,
  getIsAuthenticated,
} from '../../../../logic/store/authentication/authentication.selectors';

interface LoginDiscoveryPageProps extends InjectedFormProps<LoginDiscoveryForm> {}

const LoginDiscovery: React.FC<LoginDiscoveryPageProps> = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(getIsAuthenticated);
  const role = useSelector(getAuthenticatedUserRole);
  const formValues: LoginDiscoveryForm = useSelector(getFormValues(LOGIN_DISCOVERY_FORM_NAME)) || {};
  const ssoInProgress = useSelector(getInProgress(authenticationActionCreators.setEnterpriseUser.name));

  const handleLoginDiscovery = async () => {
    try {
      const { email } = formValues;
      const validationErrors = validateLoginDiscoveryForm(formValues);

      if (Object.keys(validationErrors).length > 0) {
        console.error('Form validation failed:', validationErrors);
        return;
      }

      // TODO - auto login flow
      const providerResponse = await axiosAuthenticatedClient().get(
        `${env.API_VERSION}/sso-providers/get-company-sso-provider`,
        {
          params: { email },
        }
      );

      const providerName = providerResponse.data?.provider;
      if (providerName) {
        dispatch(authenticationActionCreators.submitCompany(providerName));
        return;
      }

      dispatch(navigationActionCreators.navigateTo(AUTHENTICATION_ROUTES.Login, `?${queryStringStringify({ email })}`));
    } catch (error: any) {
      console.error('Error occurred:', error.message);
    }
  };

  const handleEvent = (event: string) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event,
      page: 'Login',
      action: event,
    });
  };

  useEffect(() => {
    if (isAuthenticated && role) {
      dispatch(navigationActionCreators.navigateTo(defaultRoute[role]));
    }
  }, [dispatch, isAuthenticated, role]);

  return (
    <AuthenticationMasterPage>
      <FormContainer>
        <Form.Item {...formButtonLayout}>
          <Logo />
        </Form.Item>
        <Field
          name={'email'}
          component={FormTextInput}
          label={translate(translationKeys.forms.fields.user.email)}
          required
          disabled={ssoInProgress}
          onPressEnter={handleLoginDiscovery}
          autoComplete="off"
          onBlurFormater={trimValue}
          {...formItemLayout}
        />
        <Form.Item {...formButtonLayout}>
          <Button
            type={'primary'}
            className={'form-button'}
            onClick={() => {
              handleLoginDiscovery();
              handleEvent('loginDiscoveryButton');
            }}
            disabled={ssoInProgress}
            loading={ssoInProgress}
          >
            {translate(translationKeys.buttons.login)}
          </Button>
        </Form.Item>
        <Form.Item {...formButtonLayout}>
          <Button
            type="link"
            href={'https://teamalert.com/contact/'}
            className={'form-button'}
            onClick={() => {
              handleEvent('contactUs');
            }}
            disabled={ssoInProgress}
          >
            {translate(translationKeys.buttons.contactUs)}
          </Button>
        </Form.Item>
        <Form.Item {...formButtonLayout}>
          <p className={'text-left'}>{parse(translate(translationKeys.messages.noticeAboutBindingAgreements))}</p>
        </Form.Item>
      </FormContainer>
    </AuthenticationMasterPage>
  );
};

export const LoginDiscoveryPage = reduxForm({
  form: LOGIN_DISCOVERY_FORM_NAME,
  validate: validateLoginDiscoveryForm,
})(LoginDiscovery);
