import { takeEvery, put, call } from 'redux-saga/effects';
import { SagaManager } from '../../saga-manager/saga-manager';
import { companyDomainsApi } from './company-domains.api';
import {
  CompanyDomainsActionTypes,
  companyDomainsActionCreators,
  CompanyDomainsActions,
} from './company-domains.action';
import { ReturnActionOfType } from '../../redux/action-creator';
import { fillReduxForm } from '../../redux-form/fill-redux-form';
import {
  COMPANY_DOMAINS_FORM,
  validateCompanyDomainsForm,
} from '../../../pages/company-domains/pages/domains-forms/company-domains-form.form';
import { logActionCreators } from '../log/log.actions';
import { translate, translationKeys } from '../../translations/translations.service';
import { navigationActionCreators } from '../navigation/navigation.actions';
import { mapServerErrorsToReduxFormErrors } from '../../server-error-parser/server-error-parser';
import { tableActionCreators, TableName } from '../tables/tables.action';

export function* companyDomainsSagaWatch() {
  yield takeEvery(CompanyDomainsActionTypes.CREATE_SINGLE, createSingleSagaManaged);
  yield takeEvery(CompanyDomainsActionTypes.UPDATE_SINGLE, updateSingleSagaManaged);
  yield takeEvery(CompanyDomainsActionTypes.GET_SINGLE_FOR_EDIT, getSingleForEditSagaManaged);
  yield takeEvery(CompanyDomainsActionTypes.DELETE_SINGLE, deleteSingleSagaManaged);
}

function* getSingleForEditSagaManaged(
  action: ReturnActionOfType<CompanyDomainsActions, CompanyDomainsActionTypes.GET_SINGLE_FOR_EDIT>
) {
  yield new SagaManager()
    .addTracking(companyDomainsActionCreators.getSingleForEdit.name)
    .execute(getSingleForEdit, action);
}

function* getSingleForEdit(
  action: ReturnActionOfType<CompanyDomainsActions, CompanyDomainsActionTypes.GET_SINGLE_FOR_EDIT>
) {
  const { data } = yield call(companyDomainsApi.getSingle, action.payload.id);
  yield fillReduxForm(action.payload.formName, action.payload.mapper, data);
}

function* updateSingleSagaManaged(
  action: ReturnActionOfType<CompanyDomainsActions, CompanyDomainsActionTypes.UPDATE_SINGLE>
) {
  yield new SagaManager()
    .addTracking(companyDomainsActionCreators.updateSingle.name)
    .addReduxFormValidation(COMPANY_DOMAINS_FORM, action.payload.formValues, validateCompanyDomainsForm)
    .addReduxFormAsyncValidation(COMPANY_DOMAINS_FORM, mapServerErrorsToReduxFormErrors)
    .execute(updateSingleSaga, action);
}

function* updateSingleSaga(action: ReturnActionOfType<CompanyDomainsActions, CompanyDomainsActionTypes.UPDATE_SINGLE>) {
  yield call(companyDomainsApi.updateSingle, action.payload.formValues);
  yield put(logActionCreators.logSuccess(translate(translationKeys.messages.companyDomainWasSuccessfullyUpdated)));
  yield put(navigationActionCreators.back());
}

function* createSingleSagaManaged(
  action: ReturnActionOfType<CompanyDomainsActions, CompanyDomainsActionTypes.CREATE_SINGLE>
) {
  yield new SagaManager()
    .addTracking(companyDomainsActionCreators.createSingle.name)
    .addReduxFormValidation(COMPANY_DOMAINS_FORM, action.payload.formValues, validateCompanyDomainsForm)
    .addReduxFormAsyncValidation(COMPANY_DOMAINS_FORM, mapServerErrorsToReduxFormErrors)
    .execute(createSingleSaga, action);
}

function* createSingleSaga(action: ReturnActionOfType<CompanyDomainsActions, CompanyDomainsActionTypes.CREATE_SINGLE>) {
  yield call(companyDomainsApi.addSingle, action.payload.formValues);
  yield put(logActionCreators.logSuccess(translate(translationKeys.messages.companyDomainWasSuccessfullyCreated)));
  yield put(navigationActionCreators.back());
}

function* deleteSingleSagaManaged(
  action: ReturnActionOfType<CompanyDomainsActions, CompanyDomainsActionTypes.DELETE_SINGLE>
) {
  yield new SagaManager().addTracking(companyDomainsActionCreators.deleteSingle.name).execute(deleteSingleSaga, action);
}

function* deleteSingleSaga(action: ReturnActionOfType<CompanyDomainsActions, CompanyDomainsActionTypes.DELETE_SINGLE>) {
  yield call(companyDomainsApi.deleteSingle, action.payload.id);
  yield put(tableActionCreators.fetchTable(TableName.companyDomains));
  yield put(logActionCreators.logSuccess(translate(translationKeys.messages.companyDomainWasSuccessfullyDeleted)));
}
