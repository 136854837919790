import PhoneNumber from 'awesome-phonenumber';
import { translate, translationKeys } from '../translations/translations.service';
import { getNumbersOnly } from './format-phone-as-type';

export const PHONE_NUMBER_IS_NOT_VALID = translate(translationKeys.validator.form.phoneNumberIsNotValid);

export const isValidPhoneNumber = (
  phoneNumber: string | undefined | null,
  countryCode: string | undefined | null
): string | undefined => {
  if (!phoneNumber || phoneNumber === null || !countryCode || countryCode === null) return PHONE_NUMBER_IS_NOT_VALID;

  const countryCallingCode = getCountryCallingCode(countryCode);
  const pn = new PhoneNumber(`+${countryCallingCode}${getNumbersOnly(phoneNumber)}`);

  return pn.isValid() && pn.getRegionCode() === countryCode ? undefined : PHONE_NUMBER_IS_NOT_VALID;
};

export const getCountryCallingCode = (countryCode: string | null | undefined) => {
  const countryCallingCode = countryCode ? PhoneNumber.getCountryCodeForRegionCode(countryCode) : undefined;
  return countryCallingCode ? countryCallingCode : countryCode;
};
