import { isString } from 'lodash';

export const isAbsoluteUrl = (url: string | undefined | null) => isString(url) && url.trim().indexOf('http') === 0;

export const getResourceUrl = (baseUrl: string | undefined, url: string) => {
  const baseUrlParsed = isString(baseUrl) ? baseUrl.trim() : undefined;
  const urlParsed = isString(url) ? url.trim() : undefined;

  if (!urlParsed) return undefined;
  if (isAbsoluteUrl(urlParsed)) return urlParsed;

  return [baseUrlParsed || '', urlParsed].join('/');
};
