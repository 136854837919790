import { FocusEventHandler } from 'react';
import { WrappedFieldProps } from 'redux-form';
import { FormItemProps } from 'antd/lib/form';
import { Form, InputNumber } from 'antd';
import { LabelWithToolTip } from '../label-with-tooltip/label-with-tooltip';
import { parseEmptyValueAs } from '../../../../logic/formaters/formaters';
import { InputStatus } from 'antd/lib/_util/statusUtils';

interface OwnProps {
  min?: number;
  max?: number;
  toolTipMessage?: string;
  onBlurFormater?: <T>(value: T) => T;
  disabled?: boolean;
}

type FormNumberInputProps = WrappedFieldProps & FormItemProps & OwnProps;

export const FormNumberInput = ({
  label,
  required,
  labelCol,
  wrapperCol,
  toolTipMessage,
  meta,
  input,
  onBlurFormater,
  status,
  disabled,
}: FormNumberInputProps) => {
  const errorMessage = !!meta && meta.error;
  const showError = !!meta && meta.touched && !!meta.error;
  const labelComponent = toolTipMessage ? <LabelWithToolTip label={label} toolTipMessage={toolTipMessage} /> : label;
  const { onBlur, onChange, ...restInput } = input;

  const handleChange = (value: number | string | null | undefined) => {
    const newValue = parseEmptyValueAs(null)(value);
    onChange(newValue);
  };

  const handleBlur: FocusEventHandler<any> = (e) => {
    const newValue = e.target.value;
    const formatedValue = input && onBlurFormater ? onBlurFormater(newValue) : newValue;
    onBlur(parseEmptyValueAs(null)(formatedValue));
  };

  return (
    <Form.Item
      label={labelComponent}
      required={required}
      validateStatus={showError ? 'error' : undefined}
      help={showError ? errorMessage : undefined}
      labelCol={labelCol}
      wrapperCol={wrapperCol}
    >
      <InputNumber
        onChange={handleChange}
        onBlur={handleBlur}
        status={status as InputStatus}
        disabled={disabled}
        {...restInput}
      />
    </Form.Item>
  );
};
