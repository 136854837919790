import { AccountStatus, User } from '../../../../api-models/api-models';

export const canDelete = (userId: number | undefined, authenticatedUserId: number | undefined) =>
  userId !== undefined &&
  userId != null &&
  authenticatedUserId !== undefined &&
  authenticatedUserId != null &&
  userId !== authenticatedUserId;

export const canInvite = (accountStatus: AccountStatus | undefined) => accountStatus === AccountStatus.New;

export const canReinvite = (accountStatus: AccountStatus | undefined) =>
  accountStatus === AccountStatus.InviteSent ||
  accountStatus === AccountStatus.InviteFailed ||
  accountStatus === AccountStatus.NotActive;

export const getUsersToDelete = (users: (User | undefined)[] | undefined, authenticatedUserId: number | undefined) =>
  users?.filter(user => canDelete(user?.id, authenticatedUserId));

export const getUsersToInvite = (users: (User | undefined)[] | undefined) =>
  users?.filter(user => canInvite(user?.status));

export const getUsersToReinvite = (users: (User | undefined)[] | undefined) =>
  users?.filter(user => canReinvite(user?.status));
