import React, { PropsWithChildren } from 'react';
import './master.page.styles.scss';
import { Layout } from 'antd';
import { SideMenu } from './components/side-menu/side-menu';
import { MasterHeader } from './components/master-header/master-header';
import { MasterFooter } from './components/master-footer/master-footer';

export const MasterPage: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Layout className={'master-page-layout'}>
      <MasterHeader />
      <Layout>
        <SideMenu />
        <Layout>
          <Layout>{children}</Layout>
          <MasterFooter />
        </Layout>
      </Layout>
    </Layout>
  );
};
