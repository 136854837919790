import { Role, SsoGroupMapping } from '../../../../api-models/api-models';
import { translate, translationKeys } from '../../../../logic/translations/translations.service';
import { getSortableFilterableColumnCreator } from '../../../components/table/table.column';
import { SsoGroupMappingFormFieldName } from '../sso-group-mapping-form-template/sso-group-mapping-form-field-name';

const getSsoGroupMappingColumn = getSortableFilterableColumnCreator<SsoGroupMappingFormFieldName, SsoGroupMapping>(
  translationKeys.forms.fields.ssoGroupMapping
);

const ssoGroupMappingColumns = {
  [SsoGroupMappingFormFieldName.company]: getSsoGroupMappingColumn(SsoGroupMappingFormFieldName.company),
  [SsoGroupMappingFormFieldName.company_name]: getSsoGroupMappingColumn(SsoGroupMappingFormFieldName.company_name),
  [SsoGroupMappingFormFieldName.sso_group_name]: getSsoGroupMappingColumn(SsoGroupMappingFormFieldName.sso_group_name),
  [SsoGroupMappingFormFieldName.corresponding_permission_level]: getSsoGroupMappingColumn(
    SsoGroupMappingFormFieldName.corresponding_permission_level,
    true,
    (_, row) =>
      `${
        !!row.corresponding_permission_level &&
        translate(translationKeys.role[row.corresponding_permission_level as Role])
      }`
  ),
};

export const getSsoGroupMappingColumns = (fieldNames: SsoGroupMappingFormFieldName[]) =>
  fieldNames.map((fieldName) => ssoGroupMappingColumns[fieldName]);
