export enum GroupFormFieldName {
  id = 'id',
  name = 'name',
  dept = 'dept',
  notes = 'notes',
  company_id = 'company_id',
  address = 'address',
  city = 'city',
  phone_country_code = 'phone_country_code',
  phone = 'phone',
  zip_code = 'zip_code',
  state = 'state',
  users_ids = 'users_ids',
  number_of_users = 'number_of_users',
  admins_ids = 'admins_ids',
  location_id = 'location_id',
  location_name = 'location_name',
}
