import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {
  getLocationColumn,
  shouldShowBackButton,
  shouldShowEditAction,
  shouldShowViewAction,
} from '../../../logic/location/location.helper';
import { LocationFormFieldName } from '../../../common/location/location-form.field-name';
import { useSearchInput } from '../../components/search-input/search-input';
import { translate, translationKeys } from '../../../logic/translations/translations.service';
import { MasterPageTableHeader } from '../../components/master-page-table-header/master-page-table-header';
import { MasterContent } from '../../master-page/components/master-content/master-content';
import { Table } from '../../components/table/table';
import { TableName } from '../../../logic/store/tables/tables.action';
import { useConfirmActionModal } from '../../components/confirm-modal/confirm-modal';
import { Location } from '../../../api-models/api-models';
import { useDispatch, useSelector } from 'react-redux';
import { TableHeaderButton } from '../../components/master-page-table-header/master-page-table-header.components';
import queryString from 'query-string';
import { parseId } from '../../../logic/utils/parse-id';
import { navigationActionCreators } from '../../../logic/store/navigation/navigation.actions';
import { AUTHENTICATED_ROUTES } from '../../../logic/routes/routes.const';
import { getAuthenticatedUserRole } from '../../../logic/store/authentication/authentication.selectors';
import { ColumnProps } from 'antd/lib/table';
import { Button } from 'antd';
import { locationActionCreators } from '../../../logic/store/location/location/location.action';
import parse from 'html-react-parser';
import { PageDescription } from '../../components/page-description/page-description';
import { ResourceLink } from '../../components/resources/resource-link';
import { Resource, resourceContents } from '../../components/resources/resource.const';
import { renderTooltip } from '../../components/table/table.column';

type LocationPageProps = RouteComponentProps;

declare global {
  interface Window {
    dataLayer: any[];
  }
}

export const LocationsPage: React.FC<LocationPageProps> = ({ location }) => {
  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'setupLocationsTab_view',
    });
  }, []);

  const searchInput = useSearchInput();
  const dispatch = useDispatch();
  const authenticatedUserRole = useSelector(getAuthenticatedUserRole);
  const { company_id } = queryString.parse(location.search);
  const company_id_parsed = parseId(company_id);

  const addSingleLocation = () =>
    dispatch(
      navigationActionCreators.navigateTo(
        AUTHENTICATED_ROUTES.LocationsAdd,
        `?${queryString.stringify({ company_id: company_id_parsed })}`
      )
    );

  const editLocation = (row: Location) => () =>
    dispatch(
      navigationActionCreators.navigateTo(
        AUTHENTICATED_ROUTES.LocationsEdit,
        `?${queryString.stringify({ location_id: row.id })}`
      )
    );

  const viewLocation = (row: Location) => () =>
    dispatch(
      navigationActionCreators.navigateTo(
        AUTHENTICATED_ROUTES.LocationsView,
        `?${queryString.stringify({ location_id: row.id })}`
      )
    );

  const deleteLocation = (location: Location | undefined) => {
    if (location && location.id) {
      dispatch(locationActionCreators.deleteSingle(location.id));
    }
  };

  const deleteModal = useConfirmActionModal(
    translate(translationKeys.modalDialog.deleteLocation),
    translate(translationKeys.modalDialog.areYouSureYouWantToRemoveThisLocation),
    deleteLocation,
    locationActionCreators.deleteSingle.name
  );

  const actionsColumn = (): ColumnProps<Location> => ({
    title: translate(translationKeys.forms.fields.actions),
    dataIndex: 'actions',
    key: 'actions',
    render: (value, row) => (
      <span>
        {shouldShowEditAction(authenticatedUserRole) && (
          <Button type={'link'} onClick={editLocation(row)}>
            {translate(translationKeys.table.action.edit)}
          </Button>
        )}
        {shouldShowViewAction(authenticatedUserRole) && (
          <Button type={'link'} onClick={viewLocation(row)}>
            {translate(translationKeys.table.action.view)}
          </Button>
        )}
        <Button type={'link'} onClick={deleteModal.tryToPerformAction(row)}>
          {translate(translationKeys.table.action.delete)}
        </Button>
      </span>
    ),
  });

  const handleEvent = (event: string) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event,
      page: 'LocationsPage',
      action: event,
    });
  };

  const AddLocationBtn = () => (
    <TableHeaderButton
      type={'primary'}
      onClick={() => {
        addSingleLocation();
        handleEvent('addNewLocation');
      }}
    >
      {translate(translationKeys.buttons.addLocation)}
    </TableHeaderButton>
  );

  const getColumns = [
    actionsColumn,
    getLocationColumn(LocationFormFieldName.name),
    getLocationColumn(LocationFormFieldName.site),
    getLocationColumn(LocationFormFieldName.address),
    getLocationColumn(LocationFormFieldName.city),
    getLocationColumn(LocationFormFieldName.zip_code),
    getLocationColumn(LocationFormFieldName.state),
    getLocationColumn(LocationFormFieldName.country),
    getLocationColumn(LocationFormFieldName.notes, false, (value) => renderTooltip(value)),
  ];

  return (
    <>
      <MasterPageTableHeader
        searchInput={searchInput.render()}
        leftSection={<AddLocationBtn />}
        content={
          <PageDescription
            title={translate(translationKeys.pages.locations.title)}
            content={
              <>
                <p>{parse(translate(translationKeys.pages.locations.note))}</p>
                <p>
                  <ResourceLink
                    content={resourceContents[Resource.locationsManagementVideoSupportLink]}
                    text={translate(translationKeys.pages.locations.videoLinkDescription)}
                    noPaddingLeft
                  />
                </p>
              </>
            }
          />
        }
        showBack={shouldShowBackButton(authenticatedUserRole)}
      />
      <MasterContent>
        <Table
          tableName={TableName.location}
          getColumns={getColumns}
          searchInput={searchInput}
          prefilters={{
            ...(company_id_parsed ? { company_id: company_id_parsed } : {}),
            ...{ is_e911_enabled: false },
          }}
        />
      </MasterContent>
      {deleteModal.render()}
    </>
  );
};
