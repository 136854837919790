import React, { useCallback, useState } from 'react';
import Cropper, { Area } from 'react-easy-crop';
import { Slider } from 'antd';

import './avatar-change.scss';

type AvatarChangeCroppperProps = {
  setCroppedAreaPixels(area: any): void;
  uploadedImageURL: string;
};

const AvatarChangeCropper: React.FC<AvatarChangeCroppperProps> = ({ setCroppedAreaPixels, uploadedImageURL }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(2);

  const onCropComplete = useCallback(
    (_: Area, croppedAreaPixels: Area) => {
      setCroppedAreaPixels(croppedAreaPixels);
    },
    [setCroppedAreaPixels]
  );

  const onChange = (zoom: any) => {
    setZoom(zoom);
  };

  return (
    <>
      <div className={'avatar-change__crop-area'}>
        <Cropper
          image={uploadedImageURL}
          crop={crop}
          zoom={zoom}
          cropShape="round"
          showGrid={false}
          aspect={1}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
      </div>
      <Slider className={'avatar-change__slider'} min={1} max={3} onChange={onChange} value={zoom} step={0.1} />
    </>
  );
};

export default AvatarChangeCropper;
