import React, { useEffect } from 'react';
import { AuthenticationMasterPage } from '../authentication-master/authentication-master.page';
import { useDispatch } from 'react-redux';
import { authenticationActionCreators } from '../../../../logic/store/authentication/authentication.actions';
import { Loader } from '../../../components/loader/loader';

export const LogoutPage: React.FC = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(authenticationActionCreators.logout());
  });

  return (
    <AuthenticationMasterPage>
      <Loader />
    </AuthenticationMasterPage>
  );
};
