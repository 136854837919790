import { getEnumNumbersOlny } from '../../../../logic/utils/enum';
import { Role } from '../../../../api-models/api-models';

export const getEqualOrLowerRoles = (role: Role | undefined | null) =>
  getEnumNumbersOlny(Role).filter(r => !!role && r <= role);

export const getEqualOrHigherRoles = (role: Role | undefined | null) =>
  getEnumNumbersOlny(Role).filter(r => !!role && r >= role);

export const isSuperAdmin = (role: Role | undefined | null) => isRole(Role.SuperAdmin, role);

export const isRole = (role: Role | undefined | null, roleToCheck: Role | undefined | null) => roleToCheck === role;
