import { FormErrors } from 'redux-form';
import { DEFAULT_PHONE_COUNTRY_CODE } from '../../../authentication/models/country-code/country-code';
import { env, isDevelopment } from '../../../../logic/env/env';
import { isEmailValididator, isRequiredValidator } from '../../../../logic/validators/validators';
import { clearEmptyFieldsAndEmptyObjects } from '../../../../logic/validators/utils';
import {
  AccountStatus,
  LocationTag,
  Role,
  SupportContactMapperType,
  User,
  UserContactInfo,
} from '../../../../api-models/api-models';
import { normalizeEmail } from '../../../../logic/utils/email';
import { mapPhoneToCountryCodeAndNumber, parsePhoneNumberForPayload } from '../../../../logic/phone/phone.mapper';
import { isValidPhoneNumber } from '../../../../logic/phone/phone.validator';
import { mapLocationTagToForm } from '../../../../logic/location/location-tag.mapper';
import { translate, translationKeys } from '../../../../logic/translations/translations.service';
import { isCognitoPasswordValidator } from '../../../../logic/validators/passwordValidator';

export const USER_FORM = 'user';

export interface MapUserToFormArgumentsType {
  user: User;
  locationTag: LocationTag[];
}

export interface UserForm extends User {
  default_location_label?: string;
  contact_info?: UserContactInfoForm;
  location_tag?: LocationTagForm[];
}

export interface LocationTagForm {
  id?: string;
  location_id: string;
  tag_name?: string;
}

export interface UserContactInfoForm extends UserContactInfo {
  voice_call_phone_country_code?: string;
  sms_phone_country_code?: string;
}

const devFields: UserForm = isDevelopment()
  ? {
      first_name: 'John',
      last_name: 'Dow',
      email: env.REACT_APP_DEV_EMAIL,
    }
  : {};

export const userFormInitialValues: UserForm = {
  ...devFields,
  default_location_label: 'Default',
  is_email_verified: true,
  status: AccountStatus.New,
  contact_info: {
    voice_call_phone_country_code: DEFAULT_PHONE_COUNTRY_CODE,
    sms_phone_country_code: DEFAULT_PHONE_COUNTRY_CODE,
  },
};

export type UserFormValidationErrors = FormErrors<UserForm, string>;

export const validateUserForm = (formValues: UserForm) => {
  const {
    id,
    first_name,
    last_name,
    email,
    is_email_verified,
    password,
    company_id,
    contact_info,
    permission_level,
    location_tag,
  } = formValues;

  const errors: FormErrors<UserForm, any> = {
    first_name: isRequiredValidator(first_name),
    last_name: isRequiredValidator(last_name),
    email: isRequiredValidator(email) || isEmailValididator(email),
    password:
      !isEditing(id) && !is_email_verified
        ? isRequiredValidator(password) || isCognitoPasswordValidator(password)
        : undefined,
    company_id: permission_level !== Role.SuperAdmin ? isRequiredValidator(company_id) : undefined,
    contact_info: clearEmptyFieldsAndEmptyObjects({
      voice_call_phone: !!contact_info?.voice_call_phone
        ? isValidPhoneNumber(contact_info?.voice_call_phone, contact_info.voice_call_phone_country_code)
        : undefined,
      sms_phone: !!contact_info?.sms_phone
        ? isValidPhoneNumber(contact_info?.sms_phone, contact_info.sms_phone_country_code)
        : undefined,
    }),
    permission_level: isRequiredValidator(permission_level),
  };

  errors.location_tag = [];
  location_tag?.forEach((locationTag, index) => {
    const isLocationTagRequired = isRequiredValidator(locationTag.tag_name);
    const isLocationNameRequired = isRequiredValidator(locationTag.location_id);

    if (isLocationTagRequired) {
      errors.location_tag[index] = { tag_name: isLocationTagRequired };
    }

    if (isLocationNameRequired) {
      errors.location_tag[index] = { ...errors.location_tag[index], location_id: isLocationNameRequired };
    }
  });

  // Find duplicate location_ids and assign errors to location_tags index
  location_tag?.forEach((locationTag, locationTagIndex) => {
    const locationsWithTag = location_tag.filter((x) => x.location_id === locationTag.location_id);
    if (locationsWithTag.length > 1) {
      errors.location_tag[locationTagIndex] = {
        location_id: translate(translationKeys.validator.form.duplicateLocation),
      };
    }
  });

  return clearEmptyFieldsAndEmptyObjects(errors);
};

const isEditing = (id: number | undefined) => !!id;

export const mapUserToForm = (userLocationTagData: MapUserToFormArgumentsType): UserForm => {
  const { user, locationTag } = userLocationTagData;

  const sms_phone_split = mapPhoneToCountryCodeAndNumber(user.contact_info?.sms_phone);
  const sms_phone_country_code = sms_phone_split?.code || DEFAULT_PHONE_COUNTRY_CODE;
  const sms_phone = sms_phone_split?.number || undefined;

  const voice_call_phone_split = mapPhoneToCountryCodeAndNumber(user.contact_info?.voice_call_phone);
  const voice_call_phone_country_code = voice_call_phone_split?.code || DEFAULT_PHONE_COUNTRY_CODE;
  const voice_call_phone = voice_call_phone_split?.number || undefined;

  const formValues: UserForm = {
    ...user,
    contact_info: {
      ...user.contact_info,
      sms_phone_country_code,
      sms_phone,
      voice_call_phone_country_code,
      voice_call_phone,
    },
    location_tag: mapLocationTagToForm(locationTag),
  };
  return formValues;
};

export const mapFormToUser = (formValues: UserForm): User => {
  const { contact_info } = formValues;

  return {
    ...formValues,
    email: normalizeEmail(formValues.email),
    contact_info: {
      ...contact_info,
      sms_phone: parsePhoneNumberForPayload(contact_info?.sms_phone_country_code, contact_info?.sms_phone),
      voice_call_phone: parsePhoneNumberForPayload(
        contact_info?.voice_call_phone_country_code,
        contact_info?.voice_call_phone
      ),
    },
  };
};

export const mapFormToLocationTag = (userId: string, locationTagForm: LocationTagForm): LocationTag => {
  const { location_id, tag_name } = locationTagForm;

  return {
    location_id,
    tag: tag_name,
    user_id: userId,
  };
};

export const mapUserDataToContactSupportForm = (user: User): SupportContactMapperType => {
  const { first_name, last_name, company_name, contact_info, email } = user;
  return {
    first_name: first_name || '',
    last_name: last_name || '',
    company: company_name || '',
    phone: contact_info?.voice_call_phone || '',
    email: email || '',
  };
};
