import React, { useEffect, useCallback, useMemo, ReactNode } from 'react';
import { Field, change, getFormValues } from 'redux-form';
import { FormTextInput } from '../../../components/form-inputs/form-text-input/form-text-input';
import { translate, translationKeys } from '../../../../logic/translations/translations.service';
import { trimValue, STATE_CODE_MAX_LENGTH } from '../../../../logic/formaters/formaters';
import { FormSelectInput } from '../../../components/form-inputs/form-select-input/form-select-input';
import { formItemLayout, formBlockHeaderLayout } from '../../../components/form-container/form-container';
import { FormNumberInput } from '../../../components/form-inputs/form-number-input/form-number-input';
import { FormSwitchInput } from '../../../components/form-inputs/form-switch-input/form-switch-input';
import { CompanyFormFieldName } from './company-form-field-name';
import { useDispatch, useSelector } from 'react-redux';
import { SwitchChangeEventHandler } from 'antd/lib/switch';
import Form from 'antd/lib/form';
import { getListData } from '../../../../logic/store/lists/lists.selectors';
import { ListName, getFetchListTrackName, listsActionCreators } from '../../../../logic/store/lists/lists.action';
import { mapIndustryToSelectOption } from '../../models/industry.select-options';
import { getInProgress } from '../../../../logic/store/process-tracker/process-tracker.selectors';
import { getSelectOptions } from '../../../components/form-inputs/select-option/select-option';
import { Industry, Country } from '../../../../api-models/api-models';
import { mapCountryToSelectOption } from '../../models/country.select-options';
import { CompanyForm } from './company-form.form';
import { FormFieldPhone } from '../../../components/form-fields/form-field-phone';
import { FormDateInput } from '../../../components/form-inputs/form-date-input/form-date-input';
import { formatDateTimeForPayload } from '../../../../logic/date-time/date-time.format';
import { now } from '../../../../logic/date-time/date-time.now';
import { FormItemSectionTitle } from '../../../components/form-item-section-title/form-item-section-title';
import parse from 'html-react-parser';

interface CompanyFormProps {
  onPressEnter?: any;
  inProgress?: boolean;
  fieldsToDisplay: CompanyFormFieldName[];
  disabledFields: CompanyFormFieldName[];
  formName: string;
}

export const CompanyFormFields: React.FC<CompanyFormProps> = ({
  onPressEnter,
  inProgress,
  fieldsToDisplay,
  disabledFields = [],
  formName,
}) => {
  const dispatch = useDispatch();
  const formValues: CompanyForm = useSelector(getFormValues(formName)) || {};
  const { is_trial, is_sso_enabled } = formValues;

  const isDisabled = useCallback(
    (fieldName: CompanyFormFieldName) => {
      switch (fieldName) {
        case CompanyFormFieldName.trial_to_date:
        case CompanyFormFieldName.trial_alerts_limit:
          return !is_trial;
        default:
          return disabledFields.indexOf(fieldName) >= 0 || inProgress;
      }
    },
    [disabledFields, is_trial, inProgress]
  );

  const { phone_country_code }: CompanyForm = useSelector(getFormValues(formName)) || {};

  const isVisible = useCallback(
    (fieldName: CompanyFormFieldName) => fieldsToDisplay.indexOf(fieldName) >= 0,
    [fieldsToDisplay]
  );

  const commonAttributes = useCallback(
    (fieldName: CompanyFormFieldName) => ({
      ...formItemLayout,
      disabled: isDisabled(fieldName),
      onPressEnter,
    }),
    [isDisabled, onPressEnter]
  );

  useEffect(() => {
    isVisible(CompanyFormFieldName.industry) && dispatch(listsActionCreators.fetchList(ListName.industry));
    dispatch(listsActionCreators.fetchList(ListName.country));
  }, [dispatch, isVisible]);

  const translateFieldLabel = useCallback(
    (fieldName: CompanyFormFieldName) => translate(translationKeys.forms.fields.company[fieldName]),
    []
  );

  const handleCheckInputToggle = useCallback(
    (fieldName: CompanyFormFieldName): SwitchChangeEventHandler =>
      (newCheckedValue) => {
        switch (fieldName) {
          case CompanyFormFieldName.is_e911_enabled:
            !newCheckedValue && dispatch(change(formName, CompanyFormFieldName.is_e911_test_enabled, false));
            break;
          default:
            newCheckedValue && dispatch(change(formName, fieldName, true));
        }
      },
    [dispatch, formName]
  );

  const industries = useSelector(getListData<Industry>(ListName.industry));
  const industriesOptions = useMemo(() => getSelectOptions(industries, mapIndustryToSelectOption), [industries]);
  const fetchIndustriesInProgress = useSelector(getInProgress(getFetchListTrackName(ListName.industry)));

  const countries = useSelector(getListData<Country>(ListName.country));
  const countriesOptions = useMemo(() => getSelectOptions(countries, mapCountryToSelectOption), [countries]);
  const fetchCountriesInProgress = useSelector(getInProgress(getFetchListTrackName(ListName.country)));

  const getTextField = useCallback(
    (fieldName: CompanyFormFieldName, required?: boolean, rows?: number, maxLength?: number) => (
      <Field
        name={fieldName}
        component={FormTextInput}
        label={translateFieldLabel(fieldName)}
        required={required}
        maxLength={maxLength}
        onBlurFormater={trimValue}
        rows={rows}
        {...commonAttributes(fieldName)}
      />
    ),
    [commonAttributes, translateFieldLabel]
  );

  const getNumberField = useCallback(
    (fieldName: CompanyFormFieldName, required?: boolean, min?: number, max?: number) => (
      <Field
        name={fieldName}
        required={required}
        component={FormNumberInput}
        className={'form-number-input'}
        min={min}
        max={max}
        label={translateFieldLabel(fieldName)}
        {...commonAttributes(fieldName)}
      />
    ),
    [commonAttributes, translateFieldLabel]
  );

  const getDateField = useCallback(
    (fieldName: CompanyFormFieldName, required?: boolean, min?: string, max?: string) => (
      <Field
        name={fieldName}
        required={required}
        component={FormDateInput}
        min={min}
        max={max}
        label={translateFieldLabel(fieldName)}
        {...commonAttributes(fieldName)}
      />
    ),
    [commonAttributes, translateFieldLabel]
  );

  const getSwitchField = useCallback(
    (
      fieldName: CompanyFormFieldName,
      required?: boolean,
      toolTipMessage?: string | ReactNode,
      onToggle?: SwitchChangeEventHandler
    ) => (
      <Field
        name={fieldName}
        required={required}
        component={FormSwitchInput}
        onToggle={onToggle}
        toolTipMessage={toolTipMessage}
        label={translateFieldLabel(fieldName)}
        {...commonAttributes(fieldName)}
      />
    ),
    [commonAttributes, translateFieldLabel]
  );

  const fields = useMemo(
    () => ({
      [CompanyFormFieldName.companyInfoHeader]: (
        <FormItemSectionTitle {...formBlockHeaderLayout} marginTop={0}>
          {translate(translationKeys.forms.fields.company.companyInfoHeader)}
        </FormItemSectionTitle>
      ),
      [CompanyFormFieldName.address]: getTextField(CompanyFormFieldName.address),
      [CompanyFormFieldName.admins]: null,
      [CompanyFormFieldName.alerts]: null,
      [CompanyFormFieldName.city]: getTextField(CompanyFormFieldName.city),
      [CompanyFormFieldName.groups]: null,
      [CompanyFormFieldName.id]: null,
      [CompanyFormFieldName.isActive]: getSwitchField(CompanyFormFieldName.isActive),
      [CompanyFormFieldName.trialHeader]: (
        <FormItemSectionTitle {...formBlockHeaderLayout}>
          {translate(translationKeys.forms.fields.company.trialHeader)}
        </FormItemSectionTitle>
      ),
      [CompanyFormFieldName.isTrial]: getSwitchField(CompanyFormFieldName.isTrial),
      [CompanyFormFieldName.created_at]: null,
      [CompanyFormFieldName.trial_to_date]: getDateField(
        CompanyFormFieldName.trial_to_date,
        is_trial,
        formatDateTimeForPayload(now().toJSDate())
      ),
      [CompanyFormFieldName.trial_alerts_limit]: getNumberField(CompanyFormFieldName.trial_alerts_limit, is_trial),
      [CompanyFormFieldName.name]: getTextField(CompanyFormFieldName.name, true),
      [CompanyFormFieldName.phone_country_code]: null,
      [CompanyFormFieldName.phone]: (
        <FormFieldPhone
          name={CompanyFormFieldName.phone}
          countryCodeFieldName={CompanyFormFieldName.phone_country_code}
          countryCode={phone_country_code}
          label={translateFieldLabel(CompanyFormFieldName.phone)}
          inProgress={inProgress}
          commonAttributes={commonAttributes(CompanyFormFieldName.phone)}
        />
      ),
      [CompanyFormFieldName.state]: getTextField(CompanyFormFieldName.state, false, 1, STATE_CODE_MAX_LENGTH),
      [CompanyFormFieldName.users]: null,
      [CompanyFormFieldName.zip_code]: getTextField(CompanyFormFieldName.zip_code),
      [CompanyFormFieldName.country]: (
        <Field
          name={CompanyFormFieldName.country}
          component={FormSelectInput}
          label={translateFieldLabel(CompanyFormFieldName.country)}
          options={countriesOptions}
          showSearch
          filterOption={true}
          optionFilterProp={'label'}
          allowClear
          loading={fetchCountriesInProgress}
          {...commonAttributes(CompanyFormFieldName.country)}
          disabled={fetchCountriesInProgress || inProgress}
        />
      ),
      [CompanyFormFieldName.industry]: (
        <Field
          name={CompanyFormFieldName.industry}
          component={FormSelectInput}
          label={translateFieldLabel(CompanyFormFieldName.industry)}
          options={industriesOptions}
          showSearch
          filterOption={true}
          optionFilterProp={'label'}
          allowClear
          loading={fetchIndustriesInProgress}
          {...commonAttributes(CompanyFormFieldName.industry)}
          disabled={fetchIndustriesInProgress || inProgress}
        />
      ),
      [CompanyFormFieldName.footer_note]: getTextField(CompanyFormFieldName.footer_note),
      [CompanyFormFieldName.tokens]: getNumberField(CompanyFormFieldName.tokens),
      [CompanyFormFieldName.users_no]: getNumberField(CompanyFormFieldName.users_no),
      [CompanyFormFieldName.max_users_no]: getNumberField(CompanyFormFieldName.max_users_no, true),
      [CompanyFormFieldName.e911Header]: (
        <FormItemSectionTitle {...formBlockHeaderLayout}>
          {translate(translationKeys.forms.fields.company.e911Header)}
        </FormItemSectionTitle>
      ),
      [CompanyFormFieldName.e911HeaderDescription]: (
        <Form.Item {...formBlockHeaderLayout}>
          <div className={'text-justified'}>
            {translate(translationKeys.forms.fields.company.e911HeaderDescription)}
          </div>
        </Form.Item>
      ),
      [CompanyFormFieldName.is_e911_enabled]: getSwitchField(
        CompanyFormFieldName.is_e911_enabled,
        false,
        undefined,
        handleCheckInputToggle(CompanyFormFieldName.is_e911_enabled)
      ),
      [CompanyFormFieldName.is_e911_test_enabled]: getSwitchField(
        CompanyFormFieldName.is_e911_test_enabled,
        false,
        translate(translationKeys.forms.fields.company.is_e911_test_enabled_tooltip),
        handleCheckInputToggle(CompanyFormFieldName.is_e911_test_enabled)
      ),
      [CompanyFormFieldName.ssoHeader]: (
        <FormItemSectionTitle {...formBlockHeaderLayout}>
          {translate(translationKeys.forms.fields.company.ssoHeader)}
        </FormItemSectionTitle>
      ),
      [CompanyFormFieldName.is_sso_enabled]: getSwitchField(
        CompanyFormFieldName.is_sso_enabled,
        false,
        is_sso_enabled ? (
          <div>{parse(translate(translationKeys.forms.fields.company.is_sso_enabled_tooltip))}</div>
        ) : (
          translate(translationKeys.forms.fields.company.is_sso_disabled_tooltip)
        ),
        handleCheckInputToggle(CompanyFormFieldName.is_sso_enabled)
      ),
      [CompanyFormFieldName.multisite_header]: (
        <FormItemSectionTitle {...formBlockHeaderLayout}>
          {translate(translationKeys.forms.fields.company.multisite_header)}
        </FormItemSectionTitle>
      ),
      [CompanyFormFieldName.is_multisite_enabled]: getSwitchField(
        CompanyFormFieldName.is_multisite_enabled,
        false,
        null,
        handleCheckInputToggle(CompanyFormFieldName.is_multisite_enabled)
      ),
      [CompanyFormFieldName.is_permission_mapping_enabled]: getSwitchField(
        CompanyFormFieldName.is_permission_mapping_enabled,
        false,
        translate(translationKeys.forms.fields.company.is_permission_mapping_enabled_tooltip),
        handleCheckInputToggle(CompanyFormFieldName.is_permission_mapping_enabled)
      ),
      [CompanyFormFieldName.is_company_group_mapping_enabled]: getSwitchField(
        CompanyFormFieldName.is_company_group_mapping_enabled,
        false,
        translate(translationKeys.forms.fields.company.is_company_group_mapping_enabled_tooltip),
        handleCheckInputToggle(CompanyFormFieldName.is_company_group_mapping_enabled)
      ),
      [CompanyFormFieldName.appsHeader]: (
        <FormItemSectionTitle {...formBlockHeaderLayout}>
          {translate(translationKeys.forms.fields.company.appsHeader)}
        </FormItemSectionTitle>
      ),
      [CompanyFormFieldName.is_updates_on]: getSwitchField(
        CompanyFormFieldName.is_updates_on,
        false,
        '',
        handleCheckInputToggle(CompanyFormFieldName.is_updates_on)
      ),
      [CompanyFormFieldName.locations_no]: getNumberField(CompanyFormFieldName.locations_no),
      [CompanyFormFieldName.max_locations_no]: getNumberField(CompanyFormFieldName.max_locations_no, true),
    }),
    [
      getTextField,
      getSwitchField,
      getDateField,
      is_trial,
      getNumberField,
      phone_country_code,
      translateFieldLabel,
      inProgress,
      commonAttributes,
      countriesOptions,
      fetchCountriesInProgress,
      industriesOptions,
      fetchIndustriesInProgress,
      handleCheckInputToggle,
      is_sso_enabled,
    ]
  );

  return (
    <>
      {fieldsToDisplay.map((fieldName) => (
        <div key={fieldName}>{fields[fieldName]}</div>
      ))}
    </>
  );
};
