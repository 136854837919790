import styled from 'styled-components';
import { colors } from '../../../../styles/colors';
import { LastActiveBreak } from './last-active-break.model';

export const badgeColorByBreak: ObjectByKey<string> = {
  [LastActiveBreak.NoBreak]: colors.sushi,
  [LastActiveBreak.ShortBreak]: colors.sun,
  [LastActiveBreak.LongBreak]: colors.sunsetOrange,
};

export const ActivityBadge = styled.div<{ color: string | undefined }>`
  background: ${({ color = colors.white }) => color};
  border-radius: 50%;
  height: 10px;
  width: 10px;
  box-shadow: 0px 0px 7px ${({ color = colors.white }) => color};
  margin-right: 10px;
`;
