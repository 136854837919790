import React from 'react';
import { useModal } from '../../../logic/hooks/use-modal';
import { Image, Modal } from 'antd';
import { translate, translationKeys } from '../../../logic/translations/translations.service';
import styled from 'styled-components';

interface qrCodeModalProps {
  title: string;
  qrCodeUrl: string;
}

export const useQrCodeModal = ({ title, qrCodeUrl }: qrCodeModalProps) => {
  const modal = useModal<any>();
  const visible = modal.visible;

  const content = (
    <ContentContainer>
      {translate(translationKeys.pages.dashboard.installers.modals.scanQrCode)}
      <Image width={200} src={qrCodeUrl} preview={false} />
    </ContentContainer>
  );

  return {
    render: () =>
      visible ? (
        <Modal
          title={title}
          visible={visible}
          onOk={modal.hide}
          onCancel={modal.hide}
          cancelButtonProps={{ hidden: true }}
        >
          {content}
        </Modal>
      ) : null,
    show: modal.show,
  };
};

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
