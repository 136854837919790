import { reduxForm } from 'redux-form';
import { compose } from 'redux';
import { translate, translationKeys } from '../../../logic/translations/translations.service';
import { companyActionCreators } from '../../../logic/store/company/company.action';
import { COMPANY_FORM, companyFormInitialValues, validateCompanyForm } from './company-form-template/company-form.form';
import { CompanyFormPage, CompanyFormPageOwnProps } from './company-form-template/company-form.page';
import { CompanyFormFieldName } from './company-form-template/company-form-field-name';
import { wrapComponentsWithProps } from '../../../logic/hoc/wrap-components-with-props.hoc';
import { getAuthenticatedUserRole } from '../../../logic/store/authentication/authentication.selectors';
import { store } from '../../../app/app.store';
import { isSuperAdmin } from '../../authentication/models/role/role.utils';

export const CompanyAddPageCreator = () => {
  const role = getAuthenticatedUserRole(store.getState());

  return compose(
    wrapComponentsWithProps<CompanyFormPageOwnProps>({
      submitButtonText: translate(translationKeys.buttons.addCompany),
      submitFormAction: companyActionCreators.addSingle,
      formName: COMPANY_FORM,
      fieldsToDisplay: [
        CompanyFormFieldName.companyInfoHeader,
        CompanyFormFieldName.name,

        ...(isSuperAdmin(role) ? [CompanyFormFieldName.industry] : []),
        ...(isSuperAdmin(role) ? [CompanyFormFieldName.country] : []),

        CompanyFormFieldName.address,
        CompanyFormFieldName.city,
        CompanyFormFieldName.state,
        CompanyFormFieldName.zip_code,

        CompanyFormFieldName.phone_country_code,
        CompanyFormFieldName.phone,
        CompanyFormFieldName.footer_note,

        CompanyFormFieldName.max_users_no,
        CompanyFormFieldName.users_no,

        CompanyFormFieldName.max_locations_no,
        CompanyFormFieldName.locations_no,

        CompanyFormFieldName.isActive,

        CompanyFormFieldName.e911Header,
        CompanyFormFieldName.is_e911_enabled,
        CompanyFormFieldName.is_e911_test_enabled,

        CompanyFormFieldName.ssoHeader,
        CompanyFormFieldName.is_sso_enabled,
      ],
      disabledFields: [
        CompanyFormFieldName.users_no,
        CompanyFormFieldName.locations_no,
        CompanyFormFieldName.isTrial,
        CompanyFormFieldName.trial_to_date,
        CompanyFormFieldName.trial_alerts_limit,
      ],
    }),
    reduxForm({
      form: COMPANY_FORM,
      initialValues: { ...companyFormInitialValues, is_trial: false },
      validate: validateCompanyForm,
    })
  )(CompanyFormPage) as React.FC;
};
