import React from 'react';
import { Button } from 'antd';
import { useDispatch } from 'react-redux';
import { ColumnProps } from 'antd/lib/table';
import { companyActionCreators } from '../../../../logic/store/company/company.action';
import { navigationActionCreators } from '../../../../logic/store/navigation/navigation.actions';
import { AUTHENTICATED_ROUTES } from '../../../../logic/routes/routes.const';
import { translate, translationKeys } from '../../../../logic/translations/translations.service';
import { MasterContent } from '../../../master-page/components/master-content/master-content';
import { CompanyFormFieldName } from '../company-form-template/company-form-field-name';
import { useConfirmActionModal } from '../../../components/confirm-modal/confirm-modal';
import { Table } from '../../../components/table/table';
import { Company } from '../../../../api-models/api-models';
import { TableName } from '../../../../logic/store/tables/tables.action';
import { useSearchInput } from '../../../components/search-input/search-input';
import { MasterPageTableHeader } from '../../../components/master-page-table-header/master-page-table-header';
import { TableHeaderButton } from '../../../components/master-page-table-header/master-page-table-header.components';
import { QueryStringPrefilters } from '../../../../logic/store/tables/tables.reducer';
import { getCompanyColumns } from './companies-template.columns';
import { TableButton } from '../../../../logic/store/tables/tables.types';

export interface CompaniesTemplatePageOwnProps {
  columnsFields: CompanyFormFieldName[];
  prefilters?: QueryStringPrefilters;
  tableName: TableName;
  buttons: TableButton[];
  editCompanyRoute: string;
}

export const CompaniesTemplatePage: React.FC<CompaniesTemplatePageOwnProps> = ({
  columnsFields,
  prefilters,
  tableName,
  buttons,
  editCompanyRoute,
}) => {
  const searchInput = useSearchInput();

  const dispatch = useDispatch();

  const handleDeleteCompany = (company: Company | undefined) => {
    if (company && company.id) {
      dispatch(companyActionCreators.deleteSingle(company.id));
    }
  };

  const handleTableButton = (tableButton: TableButton) => () =>
    dispatch(navigationActionCreators.navigateTo(tableButton.route));

  const handleEditCompany = (row: Company) => () =>
    dispatch(navigationActionCreators.navigateTo(editCompanyRoute, `?id=${row.id}`));

  const handleManageLocations = (row: Company) => () =>
    dispatch(navigationActionCreators.navigateTo(AUTHENTICATED_ROUTES.EnhancedLocations, `?company_id=${row.id}`));

  const handleManageDomains = (row: Company) => () =>
    dispatch(navigationActionCreators.navigateTo(AUTHENTICATED_ROUTES.CompanyDomains, `?company=${row.id}`));

  const deleteCompanyModal = useConfirmActionModal(
    translate(translationKeys.modalDialog.deleteCompany),
    translate(translationKeys.modalDialog.areYouSureYouWantToRemoveThisCompany),
    handleDeleteCompany,
    companyActionCreators.deleteSingle.name
  );

  const actionsColumn = (): ColumnProps<Company> => ({
    title: translate(translationKeys.forms.fields.actions),
    dataIndex: 'actions',
    key: 'actions',
    render: (value, row) => (
      <span>
        <Button type={'link'} onClick={handleEditCompany(row)}>
          {translate(translationKeys.table.action.edit)}
        </Button>
        <Button type={'link'} onClick={deleteCompanyModal.tryToPerformAction(row)}>
          {translate(translationKeys.table.action.delete)}
        </Button>
        <Button type={'link'} onClick={handleManageLocations(row)}>
          {translate(translationKeys.table.action.manageLocations)}
        </Button>
        <Button type={'link'} onClick={handleManageDomains(row)}>
          {translate(translationKeys.table.action.manageDomains)}
        </Button>
      </span>
    ),
  });

  const getColumns = [actionsColumn, ...getCompanyColumns(columnsFields)];

  return (
    <>
      <MasterPageTableHeader
        leftSection={
          <>
            {buttons.map((button, index) => (
              <TableHeaderButton type={button.type || 'primary'} key={index} onClick={handleTableButton(button)}>
                {button.text}
              </TableHeaderButton>
            ))}
          </>
        }
        searchInput={searchInput.render()}
      />
      <MasterContent>
        <Table tableName={tableName} getColumns={getColumns} prefilters={prefilters} searchInput={searchInput} />
      </MasterContent>
      {deleteCompanyModal.render()}
    </>
  );
};
