import { put } from 'redux-saga/effects';
import { logActionCreators } from '../log/log.actions';
import { translate, translationKeys } from '../../translations/translations.service';

const ONLY_RADIX_ARE_SUPPORTED_ERROR_MESSAGE = 'Only radix 2, 4, 8, 16, 32 are supported';
const PASSWORD_ATTEMPTS_EXCEEDED = 'Password attempts exceeded';

export function* cognitoErrorHandler(error: Error) {
  const message = error.message;

  if (message === ONLY_RADIX_ARE_SUPPORTED_ERROR_MESSAGE) {
    yield put(logActionCreators.logError(translate(translationKeys.errors.incorrectUserName)));
    return true;
  }

  if (message === PASSWORD_ATTEMPTS_EXCEEDED) {
    yield put(logActionCreators.logError(translate(translationKeys.errors.passwordAttemptsExceeded)));
    return true;
  }

  return false;
}
