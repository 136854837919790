import { RouteComponentProps } from 'react-router-dom';
import { SsoGroupMappingFormFieldName } from './sso-group-mapping-form-field-name';
import { SsoGroupMappingForm, ssoGroupMappingToFormValuesMapper } from './sso-group-mapping-form.form';
import { AnyAction } from 'redux';
import { InjectedFormProps, getFormValues } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { getInProgress } from '../../../../logic/store/process-tracker/process-tracker.selectors';
import { MasterPageHeader } from '../../../components/master-page-header/master-page-header';
import { MasterContent } from '../../../master-page/components/master-content/master-content';
import { FormContainer, formButtonLayout } from '../../../components/form-container/form-container';
import { SsoGroupMappingFormFields } from './sso-group-mapping-form-fields';
import { Button, Form } from 'antd';
import { useEffect } from 'react';
import queryString from 'query-string';
import { ssoGroupMappingActionCreators } from '../../../../logic/store/sso-group-mapping/sso-group-mapping.actions';

export interface SsoGroupMappingFormPageOwnProps {
  submitButtonText: string;
  submitFormAction: (formValues: SsoGroupMappingForm) => AnyAction;
  formName: string;
  fieldsToDisplay: SsoGroupMappingFormFieldName[];
  disabledFields: SsoGroupMappingFormFieldName[];
}

interface SsoGroupMappingFormTemplatePageProps
  extends RouteComponentProps,
    SsoGroupMappingFormPageOwnProps,
    InjectedFormProps<SsoGroupMappingForm> {}

export const SsoGroupMappingFormPage: React.FC<SsoGroupMappingFormTemplatePageProps> = ({
  fieldsToDisplay,
  disabledFields,
  formName,
  submitFormAction,
  submitButtonText,
  location,
}) => {
  const dispatch = useDispatch();
  const formValues: SsoGroupMappingForm = useSelector(getFormValues(formName)) || {};
  const submitInProgress = useSelector(getInProgress(submitFormAction.name));

  const inProgress = submitInProgress;

  const handleSubmitAction = () => {
    dispatch(submitFormAction(formValues));
  };

  useEffect(() => {
    const { id } = queryString.parse(location.search);
    if (id) {
      dispatch(ssoGroupMappingActionCreators.getSingleForEdit(id, formName, ssoGroupMappingToFormValuesMapper));
    }
  }, [dispatch, formName, location.search]);

  return (
    <>
      <MasterPageHeader showBack />
      <MasterContent>
        <FormContainer>
          <SsoGroupMappingFormFields
            fieldsToDisplay={fieldsToDisplay}
            onPressEnter={handleSubmitAction}
            disabledFields={disabledFields}
            formName={formName}
          />
          <Form.Item {...formButtonLayout}>
            <Button
              type={'primary'}
              className={'form-button'}
              onClick={handleSubmitAction}
              disabled={inProgress}
              loading={submitInProgress}
            >
              {submitButtonText}
            </Button>
          </Form.Item>
        </FormContainer>
      </MasterContent>
    </>
  );
};
