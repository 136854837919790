import { createAction, ActionsUnion } from '../../redux/action-creator';
import { TableQuery } from './tables.types';
import { ApiListResponse } from '../../axios/axios-api-response';

export enum TableName {
  user = 'user',
  group = 'group',
  locationE911 = 'locationE911',
  location = 'location',
  alert = 'alert',
  alertChannels = 'alertChannels',
  activeAlert = 'activeAlert',
  alertHistory = 'alertHistory',
  alertIcon = 'alertIcon',
  company = 'company',
  companyDomains = 'companyDomains',
  trialCompany = 'trialCompany',
  channel = 'channel',
  ssoCompanyAdmins = 'ssoCompanyAdmins',
  ssoGroupMapping = 'ssoGroupMapping',
  ssoCompanyGroupMapping = 'ssoCompanyGroupMapping',
}

export const tableEndpoints = {
  [TableName.user]: '/user',
  [TableName.group]: '/group',
  [TableName.locationE911]: '/location',
  [TableName.location]: '/location',
  [TableName.alert]: '/alert',
  [TableName.alertChannels]: '/alert',
  [TableName.activeAlert]: '/chat',
  [TableName.alertHistory]: '/chat',
  [TableName.alertIcon]: '/alert-icon',
  [TableName.company]: '/company',
  [TableName.companyDomains]: '/v2/company-sso-domains',
  [TableName.trialCompany]: '/company',
  [TableName.channel]: '/channel-settings',
  [TableName.ssoCompanyAdmins]: '/v2/sso-company-admins',
  [TableName.ssoGroupMapping]: '/v2/sso-group-mapping',
  [TableName.ssoCompanyGroupMapping]: '/v2/sso-company-group-mapping',
};

export enum TableActionTypes {
  RESET = 'table/RESET',
  UPDATE_QUERY = 'table/UPDATE_QUERY',
  SET_SELECTED_ROW_KEYS = 'table/SET_SELECTED_ROW_KEYS',
  FETCH_TABLE = 'table/FETCH_TABLE',
  SET_DATA = 'table/SET_DATA',
  SET_UNFILTERED_TOTAL = 'table/SET_UNFILTERED_TOTAL',
  SET_PAGE = 'table/SET_PAGE',
}

export const tableActionCreators = {
  reset: () => createAction(TableActionTypes.RESET),
  updateQuery: (tableName: string, tableQuery: TableQuery | undefined) =>
    createAction(TableActionTypes.UPDATE_QUERY, { tableName, tableQuery }),
  setSelectedRowKeys: (tableName: string, selectedRowKeys: number[]) =>
    createAction(TableActionTypes.SET_SELECTED_ROW_KEYS, { tableName, selectedRowKeys }),
  fetchTable: (tableName: TableName) => createAction(TableActionTypes.FETCH_TABLE, { tableName }),
  setData: <T>(tableName: string, response: ApiListResponse<T>) =>
    createAction(TableActionTypes.SET_DATA, { tableName, response }),
  setUnfilteredTotal: <T>(tableName: string, response: ApiListResponse<T>) =>
    createAction(TableActionTypes.SET_UNFILTERED_TOTAL, { tableName, response }),
  setPage: (tableName: string, page: number) => createAction(TableActionTypes.SET_PAGE, { tableName, page }),
};

export const getFetchTableTrackName = (tableName: TableName) => `${tableActionCreators.fetchTable.name}_${tableName}`;

export type TableActions = ActionsUnion<typeof tableActionCreators>;
