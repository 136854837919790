import React, { useState } from 'react';
import Search from 'antd/lib/input/Search';
import { translate, translationKeys } from '../../../logic/translations/translations.service';
import styled from 'styled-components';

export const useSearchInput = () => {
  const [innerSearchText, setInnerSearchText] = useState<string>();
  const [searchText, setSearchText] = useState<string>();

  const handleSearchTextChanged = (value: string) => {
    setSearchText(value);
  };

  const handleChangeTextChanged = (e: any) => {
    setInnerSearchText(e.target.value);
  };

  const initialize = (value: string) => {
    if (searchText === undefined) {
      setInnerSearchText(value);
      setSearchText(value);
    }
  };

  return {
    render: () => (
      <SearchStyled
        placeholder={translate(translationKeys.buttons.placeholders.inputSearchText)}
        onSearch={handleSearchTextChanged}
        onChange={handleChangeTextChanged}
        value={innerSearchText}
      />
    ),
    searchText,
    initialize,
  };
};

const SearchStyled = styled(Search)`
  margin-bottom: 8px;
`;

export type SearchInput = ReturnType<typeof useSearchInput>;
