import { useCallback, useEffect, useMemo } from 'react';
import { SsoCompanyAdminsFormFieldName } from './sso-company-admins-form-field-name';
import { Field } from 'redux-form';
import { FormTextInput } from '../../../components/form-inputs/form-text-input/form-text-input';
import { trimValue } from '../../../../logic/formaters/formaters';
import { formItemLayout } from '../../../components/form-container/form-container';
import { translate, translationKeys } from '../../../../logic/translations/translations.service';
import { useDispatch, useSelector } from 'react-redux';
import { getListData } from '../../../../logic/store/lists/lists.selectors';
import { ListName, getFetchListTrackName, listsActionCreators } from '../../../../logic/store/lists/lists.action';
import { getDefaultSelectOptions } from '../../../components/form-inputs/select-option/select-option';
import { getInProgress } from '../../../../logic/store/process-tracker/process-tracker.selectors';
import { FormSelectInput } from '../../../components/form-inputs/form-select-input/form-select-input';

interface SsoCompanyAdminsFormProps {
  onPressEnter?: any;
  inProgress?: boolean;
  fieldsToDisplay: SsoCompanyAdminsFormFieldName[];
  disabledFields: SsoCompanyAdminsFormFieldName[];
  formName: string;
}

export const SsoCompanyAdminsFormFields: React.FC<SsoCompanyAdminsFormProps> = ({
  onPressEnter,
  disabledFields,
  inProgress,
  fieldsToDisplay,
}) => {
  const dispatch = useDispatch();

  const isDisabled = useCallback(
    (fieldName: SsoCompanyAdminsFormFieldName) => {
      return disabledFields.indexOf(fieldName) >= 0 || inProgress;
    },
    [disabledFields, inProgress]
  );

  const commonAttributes = useCallback(
    (fieldName: SsoCompanyAdminsFormFieldName) => ({
      ...formItemLayout,
      disabled: isDisabled(fieldName),
      onPressEnter,
    }),
    [isDisabled, onPressEnter]
  );

  const translateFieldLabel = useCallback(
    (fieldName: SsoCompanyAdminsFormFieldName) => translate(translationKeys.forms.fields.ssoCompanyAdmins[fieldName]),
    []
  );

  useEffect(() => {
    dispatch(listsActionCreators.fetchList(ListName.company));
  }, [dispatch]);

  const companies = useSelector(getListData(ListName.company)) || [];
  const companiesOptions = getDefaultSelectOptions(companies);
  const fetchCompaniesInProgress = useSelector(getInProgress(getFetchListTrackName(ListName.company)));

  const getTextField = useCallback(
    (fieldName: SsoCompanyAdminsFormFieldName, required?: boolean, rows?: number, maxLength?: number) => (
      <Field
        name={fieldName}
        component={FormTextInput}
        label={translateFieldLabel(fieldName)}
        required={required}
        maxLength={maxLength}
        onBlurFormater={trimValue}
        rows={rows}
        {...commonAttributes(fieldName)}
      />
    ),
    [commonAttributes, translateFieldLabel]
  );

  const fields = useMemo(
    () => ({
      [SsoCompanyAdminsFormFieldName.company]: (
        <Field
          name={SsoCompanyAdminsFormFieldName.company}
          component={FormSelectInput}
          label={translateFieldLabel(SsoCompanyAdminsFormFieldName.company)}
          options={companiesOptions}
          showSearch
          filterOption={true}
          optionFilterProp={'label'}
          allowClear
          loading={fetchCompaniesInProgress}
          {...commonAttributes(SsoCompanyAdminsFormFieldName.company)}
        />
      ),
      [SsoCompanyAdminsFormFieldName.company_name]: null,
      [SsoCompanyAdminsFormFieldName.email]: getTextField(SsoCompanyAdminsFormFieldName.email),
    }),
    [translateFieldLabel, companiesOptions, fetchCompaniesInProgress, commonAttributes, getTextField]
  );

  return (
    <>
      {fieldsToDisplay.map((fieldName) => (
        <div key={fieldName}>{fields[fieldName]}</div>
      ))}
    </>
  );
};
