import { env } from '../env/env';
import { createAxios, getCommonHeaders, getAuthenticationHeaders } from './axios';

export const axiosClient = (headers?: object) =>
  createAxios(
    {
      baseURL: env.REACT_APP_API_URL,
      headers: {
        ...getCommonHeaders(),
        ...getAuthenticationHeaders(),
        ...headers,
      },
    },
  );

