import PhoneNumber from 'awesome-phonenumber';
import { isString } from 'lodash';

export const useNormalizePhoneAsType = (phoneCode: string | null | undefined) => normalizePhoneAsType(phoneCode);

export const normalizePhoneAsType =
  (countryCode: string | null | undefined) =>
  (nextValue: string | null | undefined, prevValue: string | null | undefined) => {
    const next = isString(nextValue) ? nextValue : undefined;
    if (!countryCode) return undefined;
    const ayt = PhoneNumber.getAsYouType(countryCode);

    return ayt.reset(getNumbersOnly(next));
  };

const MATCH_NUMBERS_REGEX = /\d+/g;

export const getNumbersOnly = (value: string | null | undefined) =>
  value && value !== null ? value.match(MATCH_NUMBERS_REGEX)?.join('') : undefined;
