import { createAction, ActionsUnion } from '../../redux/action-creator';

export enum NavigationActionTypes {
  RESET = 'navigation/RESET',
  NAVIGATE_TO = 'navigation/NAVIGATE_TO',
  BACK = 'navigation/BACK',
}

export const navigationActionCreators = {
  reset: () => createAction(NavigationActionTypes.RESET),
  navigateTo: (routeName: string, queryString?: string) =>
    createAction(NavigationActionTypes.NAVIGATE_TO, { routeName, queryString }),
  back: () => createAction(NavigationActionTypes.BACK),
};

export type NavigateToAction = ReturnType<typeof navigationActionCreators.navigateTo>;

export type NavigationActions = ActionsUnion<typeof navigationActionCreators>;
