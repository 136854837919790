import { reduxForm } from 'redux-form';
import { UserFormTemplatePage, UserFormPageOwnProps } from './user-form-template/user-form-template.page';
import { compose } from 'redux';
import { translate, translationKeys } from '../../../logic/translations/translations.service';
import { USER_FORM, userFormInitialValues, validateUserForm } from './user-form-template/user-form.form';
import { UserFormFieldName, ContactInfoFieldName } from './user-form-template/user-form-field-name';
import { userActionCreators } from '../../../logic/store/user/user.action';
import { AUTHENTICATED_ROUTES } from '../../../logic/routes/routes.const';
import { Role } from '../../../api-models/api-models';
import { wrapComponentsWithProps } from '../../../logic/hoc/wrap-components-with-props.hoc';

export const SuperAdminAddCompanyAdminPage = compose(
  wrapComponentsWithProps<UserFormPageOwnProps>({
    fieldsToDisplay: [
      UserFormFieldName.first_name,
      UserFormFieldName.last_name,
      UserFormFieldName.email,
      UserFormFieldName.company_id,
      ContactInfoFieldName.address,
      ContactInfoFieldName.city,
      ContactInfoFieldName.state,
      ContactInfoFieldName.zip_code,
      ContactInfoFieldName.voice_call_phone,

      ContactInfoFieldName.department,
      ContactInfoFieldName.sms_phone,

      UserFormFieldName.permission_level,
    ],
    requiredFields: [
      UserFormFieldName.first_name,
      UserFormFieldName.last_name,
      UserFormFieldName.email,
      UserFormFieldName.company_id,
      UserFormFieldName.permission_level,
    ],
    disabledFields: [UserFormFieldName.permission_level],
    submitButtonText: translate(translationKeys.buttons.addCompanyAdmin),
    submitFormAction: userActionCreators.addSingle,
    submitSuccessMessage: translate(translationKeys.messages.companyAdminWasSuccessfullyCreated),
    submitSuccessRedirectionRoute: AUTHENTICATED_ROUTES.SuperAdminCompanyAdminUsers,
    formName: USER_FORM,
  }),
  reduxForm({
    form: USER_FORM,
    initialValues: { ...userFormInitialValues, permission_level: Role.CompanyAdmin },
    validate: validateUserForm,
  })
)(UserFormTemplatePage) as React.FC;
