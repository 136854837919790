import { isNumber } from 'lodash';
import { range } from 'lodash';

/**
 * returns all pages except first
 * @param totalCount
 * @param pageSize
 */
export const getRestPages = (totalCount: number | undefined, pageSize: number | undefined) => {
  if (!isNumber(totalCount) || !isNumber(pageSize)) {
    return [];
  }

  const totalPages = Math.ceil(totalCount / pageSize);

  return range(2, totalPages + 1);
};
