import { Select } from 'antd';
import styled from 'styled-components';
import { translate, translationKeys } from '../../../logic/translations/translations.service';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getListData } from '../../../logic/store/lists/lists.selectors';
import { ListName, listsActionCreators } from '../../../logic/store/lists/lists.action';
import { getDefaultSelectOptions } from '../form-inputs/select-option/select-option';

export const useGroupFilter = () => {
  const [selectedGroups, setSelectedGroups] = useState<string[]>();

  const dispatch = useDispatch();

  const groups = useSelector(getListData(ListName.group)) || [];
  const groupsOptions = getDefaultSelectOptions(groups);

  useEffect(() => {
    dispatch(listsActionCreators.fetchList(ListName.group, undefined, 250));
  }, [dispatch]);

  const handleOnChange = (value?: string[]) => {
    setSelectedGroups(value);
  };

  return {
    render: () => {
      return (
        <GroupFilterStyled
          mode="multiple"
          placeholder={translate(translationKeys.buttons.placeholders.groupFilterText)}
          options={groupsOptions}
          filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
          onChange={(value) => handleOnChange(value as string[])}
          allowClear
        />
      );
    },
    selectedGroups,
  };
};

const GroupFilterStyled = styled(Select)`
  margin-bottom: 8px;
  margin-left: 10px;
  min-width: 200px;
`;

export type GroupsFilter = ReturnType<typeof useGroupFilter>;
