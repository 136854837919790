import { validateLocationForm } from './location-e911/location-e911-form';
import { LocationE911FormPage } from './location-e911/location-e911-form.page';
import { translate, translationKeys } from '../../../logic/translations/translations.service';

import { compose } from 'redux';
import { locationE911ActionCreators } from '../../../logic/store/location/location-e911/location-e911.action';
import { reduxForm } from 'redux-form';
import { wrapComponentsWithProps } from '../../../logic/hoc/wrap-components-with-props.hoc';
import { LocationFormPageOwnProps } from '../../../common/location/location.type';
import { LOCATION_FORM } from '../../../common/location/location.form';

export const LocationEditPageCreator = () => {
  return compose(
    wrapComponentsWithProps<LocationFormPageOwnProps>({
      submitButtonText: translate(translationKeys.buttons.updateLocation911),
      submitFormAction: locationE911ActionCreators.updateSingle,
      formName: LOCATION_FORM,
      disabledFields: [],
    }),
    reduxForm({
      form: LOCATION_FORM,
      validate: validateLocationForm,
      initialValues: {},
    })
  )(LocationE911FormPage) as React.FC;
};
