import { AxiosError } from 'axios';
import { NetworkCode } from '../../../models/network-code';
import { put } from 'redux-saga/effects';
import { tableActionCreators } from './tables.action';

const INVALID_PAGE_ERROR_DETAILS = 'Invalid page.';

export function* invalidPageHandler(error: AxiosError, tableName: string) {
  const statusCode = error.response && error.response.status;

  if (statusCode === NetworkCode.NotFound && error.response?.data?.detail === INVALID_PAGE_ERROR_DETAILS) {
    yield put(tableActionCreators.setPage(tableName, 1));
    return true;
  }
  return false;
}
