import { FormErrors } from 'redux-form';
import { SsoCompanyAdmin } from '../../../../api-models/api-models';
import { clearEmptyFieldsAndEmptyObjects } from '../../../../logic/validators/utils';
import { isRequiredValidator } from '../../../../logic/validators/validators';

export interface SsoCompanyAdminsForm extends SsoCompanyAdmin {}

export const SSO_COMPANY_ADMINS_FORM = 'ssoCompanyAdmins';

export const validateSsoCompanyAdminsForm = (
  formValues: SsoCompanyAdminsForm
): FormErrors<SsoCompanyAdminsForm, string> => {
  const { company, user_email } = formValues;

  const validation = clearEmptyFieldsAndEmptyObjects({
    company: isRequiredValidator(company),
    user_email: isRequiredValidator(user_email),
  });

  return validation;
};

export const formValuesToSsoCompanyAdminsMapper = (formValues: SsoCompanyAdminsForm): SsoCompanyAdmin => {
  return {
    ...formValues,
  };
};
