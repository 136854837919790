import { RouteComponentProps } from 'react-router-dom';
import { FormContainer, formButtonLayout } from '../../../components/form-container/form-container';
import { MasterPageHeader } from '../../../components/master-page-header/master-page-header';
import { MasterContent } from '../../../master-page/components/master-content/master-content';
import { SsoCompanyAdminsFormFieldName } from './sso-company-admins-form-field-name';
import { SsoCompanyAdminsFormFields } from './sso-company-admins-form-fields';
import { AnyAction } from 'redux';
import { InjectedFormProps, getFormValues } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { SsoCompanyAdminsForm } from './sso-company-admins-form.form';
import { Button, Form } from 'antd';
import { getInProgress } from '../../../../logic/store/process-tracker/process-tracker.selectors';

export interface SsoCompanyAdminsFormPageOwnProps {
  submitButtonText: string;
  submitFormAction: (formValues: SsoCompanyAdminsForm) => AnyAction;
  formName: string;
  fieldsToDisplay: SsoCompanyAdminsFormFieldName[];
  disabledFields: SsoCompanyAdminsFormFieldName[];
}

interface SsoCompanyAdminsFormTemplatePageProps
  extends RouteComponentProps,
    SsoCompanyAdminsFormPageOwnProps,
    InjectedFormProps<SsoCompanyAdminsForm> {}

export const SsoCompanyAdminsFormPage: React.FC<SsoCompanyAdminsFormTemplatePageProps> = ({
  fieldsToDisplay,
  disabledFields,
  formName,
  submitFormAction,
  submitButtonText,
}) => {
  const dispatch = useDispatch();
  const formValues: SsoCompanyAdminsForm = useSelector(getFormValues(formName)) || {};
  const submitInProgress = useSelector(getInProgress(submitFormAction.name));

  const inProgress = submitInProgress;

  const handleSubmitAction = () => {
    dispatch(submitFormAction(formValues));
  };

  return (
    <>
      <MasterPageHeader showBack />
      <MasterContent>
        <FormContainer>
          <SsoCompanyAdminsFormFields
            fieldsToDisplay={fieldsToDisplay}
            onPressEnter={handleSubmitAction}
            disabledFields={disabledFields}
            formName={formName}
          />
          <Form.Item {...formButtonLayout}>
            <Button
              type={'primary'}
              className={'form-button'}
              onClick={handleSubmitAction}
              disabled={inProgress}
              loading={submitInProgress}
            >
              {submitButtonText}
            </Button>
          </Form.Item>
        </FormContainer>
      </MasterContent>
    </>
  );
};
