import React, { useState } from 'react';
import { WrappedFieldProps } from 'redux-form';
import { CustomGoogleMap } from '../../../components/google-map/GoogleMap';
import { GeoPosition } from '../../../components/google-map/GoogleMap.types';
import { Form, Select, Button, Alert } from 'antd';
import { formItemLayout } from '../../components/form-container/form-container';
import { pick } from 'lodash';
import { useGoogleGeocoding } from '../../../logic/hooks/use-google-geocoding';
import { translate, translationKeys } from '../../../logic/translations/translations.service';
import { RadiusRange } from '../pages/location-e911/location-e911-form.radius-name';
import { untouch } from 'redux-form';
import { useDispatch } from 'react-redux';
import './location-e911-map-form-field.scss';
import { env } from '../../../logic/env/env';
import { LocationForm } from '../../../common/location/location.type';
import { LOCATION_FORM } from '../../../common/location/location.form';

const { Option } = Select;

const RANGE_OPTIONS = [RadiusRange.SMALL, RadiusRange.MEDIUM, RadiusRange.LARGE];

const mapRangeRadiusValue = {
  [RadiusRange.SMALL]: 100,
  [RadiusRange.MEDIUM]: 500,
  [RadiusRange.LARGE]: 1000,
};

const ADDRESS_FORM_FIELDS: Partial<keyof LocationForm>[] = ['address', 'zip_code', 'country', 'state', 'city'];

type LocationMapFormFieldProps = WrappedFieldProps & { formValues: Partial<LocationForm> };

export const LocationE911MapFormField = ({ input: { onChange }, meta, formValues }: LocationMapFormFieldProps) => {
  const initialRadiusValue = formValues.geolocation?.radius || RadiusRange.LARGE;
  const initialMarkerPosition = formValues.geolocation
    ? { lat: formValues.geolocation.lat, lng: formValues.geolocation.lng, radius: initialRadiusValue }
    : undefined;
  const key = env.REACT_APP_GOOGLE_API;

  const [radius, setRadius] = useState(initialRadiusValue);
  const [markerPosition, setMarkerPosition] = useState<GeoPosition | undefined>(initialMarkerPosition);
  const { isLoading, fetchByAddress, data: hookGeoPosition, isError, isSuccess } = useGoogleGeocoding();
  const dispatch = useDispatch();

  const addressFormValues = Object.values(pick(formValues, ADDRESS_FORM_FIELDS));
  const isFetchDisabled = addressFormValues.filter(Boolean).length < ADDRESS_FORM_FIELDS.length;

  const handleMarkerSet = (newPosition: GeoPosition) => {
    onChange({ ...newPosition, radius });
    setMarkerPosition({ ...newPosition });
  };

  const handleSelectChange = (value: RadiusRange) => {
    setRadius(value);
    onChange({
      ...markerPosition,
      radius: value,
    });
  };

  const handleFindClick = async () => {
    const prepareGeocodingAddress = addressFormValues.join(',').replaceAll(' ', '+');
    await fetchByAddress(prepareGeocodingAddress);
  };

  React.useEffect(() => {
    if (hookGeoPosition) {
      setMarkerPosition({ ...hookGeoPosition });
      onChange({ ...hookGeoPosition, radius });
    }
    //eslint-disable-next-line
  }, [hookGeoPosition]);

  React.useEffect(() => {
    return () => {
      dispatch(untouch(LOCATION_FORM, 'geolocation'));
    };
  }, [dispatch]);

  return (
    <>
      <Form.Item {...formItemLayout} label={translate(translationKeys.forms.fields.location.geoLocationRadiusLabel)}>
        <Select value={radius} placeholder={'Select radius range'} onChange={handleSelectChange}>
          {RANGE_OPTIONS.map((radiusRange) => (
            <Option key={radiusRange} value={radiusRange}>
              {translate(translationKeys.forms.fields.location[radiusRange])}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item {...formItemLayout} label="E911 alert area picker">
        {!isError ? (
          <Button
            className="map-field-form__button"
            onClick={handleFindClick}
            disabled={isFetchDisabled}
            loading={isLoading}
          >
            {translate(translationKeys.forms.fields.location.findLocationAddress)}
          </Button>
        ) : null}

        {isSuccess ? (
          <Alert
            className="map-field-form__alert"
            type={'success'}
            message={translate(translationKeys.forms.fields.location.geolocationFindSuccess)}
            showIcon
          />
        ) : null}

        {isError ? (
          <Alert
            className="map-field-form__alert"
            type={'warning'}
            message={translate(translationKeys.forms.fields.location.geolocationFindFailed)}
            showIcon
          />
        ) : null}

        <CustomGoogleMap
          withMarkerRadius
          withMarker
          radiusRange={mapRangeRadiusValue[radius]}
          onMarkerChange={handleMarkerSet}
          markerPosition={markerPosition}
          mapCenterPosition={markerPosition || undefined}
          apiKey={key}
        />

        {meta.error && meta.touched ? (
          <Alert
            style={{ marginTop: 8 }}
            type={'error'}
            showIcon
            message={translate(translationKeys.forms.fields.location.geolocationError)}
          />
        ) : null}
      </Form.Item>
    </>
  );
};
