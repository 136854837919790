import { createAction, ActionsUnion } from '../../redux/action-creator';
import { CompanyForm } from '../../../pages/company/pages/company-form-template/company-form.form';
import { Company } from '../../../api-models/api-models';
import { SetupUserDetailsForm } from '../../../pages/dashboard/setup-company/setup-user-details-form/setup-user-details.form';

export enum CompanyActionTypes {
  RESET = 'company/RESET',
  ADD_SINGLE = 'company/ADD_SINGLE',
  UPDATE_SINGLE = 'company/UPDATE_SINGLE',
  FETCH_SINGLE_FOR_EDIT = 'company/FETCH_SINGLE_FOR_EDIT',
  DELETE_SINGLE = 'company/DELETE_SINGLE',
  SETUP_COMPANY = 'company/SETUP_COMPANY',
  SETUP_COMPANY_WITCH_USER_DETAILS = 'company/SETUP_COMPANY_WITCH_USER_DETAILS',
}

export const companyActionCreators = {
  reset: () => createAction(CompanyActionTypes.RESET),
  addSingle: (formValues: CompanyForm) => createAction(CompanyActionTypes.ADD_SINGLE, { formValues }),
  updateSingle: (formValues: CompanyForm) => createAction(CompanyActionTypes.UPDATE_SINGLE, { formValues }),
  fetchSingleForEdit: (id: any, formName: string, mapper: (data: Company) => CompanyForm) =>
    createAction(CompanyActionTypes.FETCH_SINGLE_FOR_EDIT, { id, formName, mapper }),
  deleteSingle: (id: number) => createAction(CompanyActionTypes.DELETE_SINGLE, { id }),
  setupCompany: () => createAction(CompanyActionTypes.SETUP_COMPANY),
  setupCompanyWithUserDetails: (formValues: SetupUserDetailsForm) =>
    createAction(CompanyActionTypes.SETUP_COMPANY_WITCH_USER_DETAILS, { formValues }),
};

export type CompaniesActions = ActionsUnion<typeof companyActionCreators>;
