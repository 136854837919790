import { ComponentType } from 'react';
import React from 'react';

export function wrapComponentsWithProps<P>(props: P) {
  return (WrappedComponent: ComponentType<P>) => {
    class ResultComponent extends React.Component<P> {
      render() {
        return <WrappedComponent {...this.props} {...props} />;
      }
    }
    return ResultComponent;
  };
}
