import { compose } from 'redux';
import { wrapComponentsWithProps } from '../../../logic/hoc/wrap-components-with-props.hoc';
import { LocationFormPageOwnProps } from '../../../common/location/location.type';
import { translate, translationKeys } from '../../../logic/translations/translations.service';
import { reduxForm } from 'redux-form';
import { LocationFormPage } from './location/location-form.page';
import { devFieldsLocation } from '../../../logic/location/location.helper';
import { LOCATION_FORM } from '../../../common/location/location.form';
import { locationActionCreators } from '../../../logic/store/location/location/location.action';

export const LocationAddPage = compose(
  wrapComponentsWithProps<LocationFormPageOwnProps>({
    submitButtonText: translate(translationKeys.buttons.addLocation),
    submitFormAction: locationActionCreators.createSingle,
    formName: LOCATION_FORM,
    disabledFields: [],
  }),
  reduxForm({
    form: LOCATION_FORM,
    initialValues: {
      is_e911_enabled: false,
      ...devFieldsLocation,
    },
  }),
)(LocationFormPage) as React.FC;
