import { StoreState } from '../../../app/app.reducers';

export const getAuthenticatedUser = (state: StoreState) => state.authentication.user || undefined;

export const getIsAuthenticated = (state: StoreState) =>
  !!getIdToken(state) && !!getAuthenticatedUserId(state) && !!getAuthenticatedUser(state);

export const getAuthenticatedUserId = (state: StoreState) => state.authentication?.userId;

export const getIdToken = (state: StoreState) => state.authentication?.idToken;

export const getAuthenticatedUserRole = (state: StoreState) =>
  state.authentication && state.authentication.user && state.authentication.user.permission_level;

export const getAuthenticatedUserCompanyId = (state: StoreState) =>
  state.authentication && state.authentication.user?.company_id;

export const getAuthenticatedUserCompany = (state: StoreState) => state.authentication?.userCompany;

export const getIsInitialSetupDone = (state: StoreState) => getAuthenticatedUserCompany(state)?.is_initial_setup_done;
