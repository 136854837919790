import queryString from 'query-string';
import { TableQuery } from '../../../logic/store/tables/tables.types';
import { clearEmptyFields } from '../../../logic/validators/utils';
import { SorterResult } from 'antd/lib/table/interface';
import { isArray } from 'lodash';

export const getFirstSorter = (sorter: SorterResult<any> | SorterResult<any>[] | undefined) =>
  isArray(sorter) ? sorter[0] : sorter;

export const getTableQueryString = (query: TableQuery | undefined) =>
  queryString.stringify(
    clearEmptyFields({
      page: query?.pagination?.current || 1,
      page_size: query?.pagination?.pageSize,
      ordering: getOrdering(getFirstSorter(query?.sorter), 'id'),
      search: query?.search,
      groups: query?.group,
      ...query?.prefilters,
      ...query?.filters,
    })
  );

export const getOrdering = (sorter: SorterResult<any> | undefined, defaultSorter: string) =>
  !!sorter && sorter.field && sorter.order ? `${sorter.order === 'descend' ? '-' : ''}${sorter.field}` : defaultSorter;
