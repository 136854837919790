import { ListsActions, ListsActionTypes } from './lists.action';

export interface ListState<T = any> {
  data?: T[];
}
export interface ListsState {
  [tableName: string]: ListState | undefined;
}

const initialState: ListsState = {};

export const listsReducer = (state: ListsState = initialState, action: ListsActions): ListsState => {
  switch (action.type) {
    case ListsActionTypes.RESET:
      return initialState;
    case ListsActionTypes.SET_DATA:
      return {
        ...state,
        [action.payload.listName]: {
          data: action.payload.data,
        },
      };
    default:
      return state;
  }
};
