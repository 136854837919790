import { createAction, ActionsUnion } from '../../redux/action-creator';
import { AlertTypeForm } from '../../../pages/alerts/pages/alert-type-form-template/alert-type-form';
import { AlertType } from '../../../api-models/api-models';
import { ApiResponse } from '../../axios/axios-api-response';
import { AlertLinkForm } from '../../../pages/alerts/pages/alert-documents/alert-link-form/alert-link-form.form';
import { AlertFileForm } from '../../../pages/alerts/pages/alert-documents/alert-file-form/alert-file-form.form';
import { AlertCustomResponsesForm } from '../../../pages/alerts/pages/alert-custom-responses/alert-costom-responses-form/alert-custom-responses.form';

export enum AlertActionTypes {
  RESET = 'alert/RESET',
  CREATE_SINGLE = 'alert/CREATE_SINGLE',
  UPDATE_SINGLE = 'alert/UPDATE_SINGLE',
  GET_SINGLE_FOR_EDIT = 'alert/GET_SINGLE_FOR_EDIT',
  GET_TABLE = 'alert/GET_TABLE',
  FETCH_SINGLE = 'alert/FETCH_SINGLE',
  SET_SINGLE = 'alert/SET_SINGLE',
  DELETE_SINGLE = 'alert/DELETE_SINGLE',
  RISE = 'alert/RISE',
  LOWER = 'alert/LOWER',
  UPLOAD_FILE = 'alert/UPLOAD_FILE',
  UPLOAD_LINK = 'alert/UPLOAD_LINK',
  DELETE_DOCUMENT = 'alert/DELETE_DOCUMENT',
  CHANGE_ORDER = 'alert/CHANGE_ORDER',
  FETCH_PDF_REPORT = 'alert/FETCH_PDF_REPORT',
  ADD_CUSTOM_RESPONSE = 'alert/ADD_CUSTOM_RESPONSE',
  DELETE_CUSTOM_RESPONSE = 'alert/DELETE_CUSTOM_RESPONSE',
}

export const alertActionCreators = {
  reset: () => createAction(AlertActionTypes.RESET),
  createSingle: (formValues: AlertTypeForm) => createAction(AlertActionTypes.CREATE_SINGLE, { formValues }),
  updateSingle: (formValues: AlertTypeForm) => createAction(AlertActionTypes.UPDATE_SINGLE, { formValues }),
  getSingleForEdit: (id: any, formName: string, mapper: (data: AlertType) => AlertTypeForm) =>
    createAction(AlertActionTypes.GET_SINGLE_FOR_EDIT, { id, formName, mapper }),
  fetchSingle: (id: number | string) => createAction(AlertActionTypes.FETCH_SINGLE, { id }),
  setSingle: (response: ApiResponse<AlertType>) => createAction(AlertActionTypes.SET_SINGLE, { response }),
  deleteSingle: (id: number) => createAction(AlertActionTypes.DELETE_SINGLE, { id }),
  rise: (id: number) => createAction(AlertActionTypes.RISE, { id }),
  lower: (id: number) => createAction(AlertActionTypes.LOWER, { id }),
  uploadFile: (alertId: number, formValues: AlertFileForm) =>
    createAction(AlertActionTypes.UPLOAD_FILE, { alertId, formValues }),
  uploadLink: (alertId: number, formValues: AlertLinkForm) =>
    createAction(AlertActionTypes.UPLOAD_LINK, { alertId, formValues }),
  addCustomResponse: (alertId: number, formValues: AlertCustomResponsesForm) =>
    createAction(AlertActionTypes.ADD_CUSTOM_RESPONSE, { alertId, formValues }),
  deleteCustomResponse: (alertId: number, customResponseId: number) =>
    createAction(AlertActionTypes.DELETE_CUSTOM_RESPONSE, { alertId, customResponseId }),
  deleteDocument: (alertId: number, documentId: number) =>
    createAction(AlertActionTypes.DELETE_DOCUMENT, { alertId, documentId }),
  changeOrder: (alertId: number | undefined, order: number | undefined) =>
    createAction(AlertActionTypes.CHANGE_ORDER, { alertId, order }),
  fetchPdfReport: (id: number) => createAction(AlertActionTypes.FETCH_PDF_REPORT, { id }),
};

export type AlertActions = ActionsUnion<typeof alertActionCreators>;
