import { translationKeys, translate } from '../../../../logic/translations/translations.service';
import { getSortableFilterableColumnCreator } from '../../../components/table/table.column';
import { Alert } from '../../../../api-models/api-models';
import { AlertFieldName } from './alerts.field-name';
import { stringifyFields } from '../../../../logic/utils/stringify-fields';
import { formatDateTimeForDisplay } from '../../../../logic/date-time/date-time.format';

const getAlertColumn = getSortableFilterableColumnCreator<AlertFieldName, Alert>(translationKeys.forms.fields.alert);

const alertColumns = {
  [AlertFieldName.id]: getAlertColumn(AlertFieldName.id),
  [AlertFieldName.name]: getAlertColumn(AlertFieldName.name, false, (value, row) => row.alert_type?.name),
  [AlertFieldName.raising_user]: getAlertColumn(AlertFieldName.raising_user, false, (value, row) =>
    stringifyFields(' ', row.raising_user?.first_name, row.raising_user?.last_name)
  ),
  [AlertFieldName.lowering_user]: getAlertColumn(AlertFieldName.lowering_user, false, (value, row) =>
    stringifyFields(' ', row.lowering_user?.first_name, row.lowering_user?.last_name)
  ),
  [AlertFieldName.created_at]: getAlertColumn(AlertFieldName.created_at, true, (value, row) =>
    formatDateTimeForDisplay(row.created_at)
  ),
  [AlertFieldName.lowered_at]: getAlertColumn(AlertFieldName.lowered_at, true, (value, row) =>
    formatDateTimeForDisplay(row.lowered_at)
  ),
  [AlertFieldName.messages]: getAlertColumn(AlertFieldName.messages, false, (value, row) =>
    row.messages?.length
      ? `${row.messages?.length} ${translate(
          row.messages?.length === 1
            ? translationKeys.forms.fields.alert.chatMessage
            : translationKeys.forms.fields.alert.chatMessages
        )}`
      : ''
  ),
};

export const getAlertColumns = (fieldNames: AlertFieldName[]) => fieldNames.map(fieldName => alertColumns[fieldName]);
