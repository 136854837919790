import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';

interface RichTextEditorProps {
  input: WrappedFieldInputProps;
  meta: WrappedFieldMetaProps;
}

const editorModules = {
  toolbar: [
    ['bold'],
    ['underline'],
    [{ 'list': 'ordered'}],
    ['link'],
    [{ 'align': [] }],
  ]
};

const RichTextEditor: React.FC<RichTextEditorProps> = ({ input, meta }) => {
  return (
    <ReactQuill
    value={input.value || ''}
    modules={editorModules}
    onChange={(content, delta, source, editor) => {
      const html = editor.getHTML();
      input.onChange(html);
    }}
    onBlur={(range, source, quill) => {
      const html = quill.getHTML();
      input.onBlur(html);
    }}
  />
  );
};

export default RichTextEditor;
