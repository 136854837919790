export enum ChannelFormFieldName {
  id = 'id',

  user_id = 'user_id',
  alert_type_id = 'alert_type_id',

  is_email_enabled = 'is_email_enabled',
  is_call_enabled = 'is_call_enabled',
  is_sms_enabled = 'is_sms_enabled',
  is_desktop_enabled = 'is_desktop_enabled',

  user_first_name = 'user_first_name',
  user_last_name = 'user_last_name',
  user_department = 'user_department',
}
