import React from 'react';
import { WrappedFieldProps } from 'redux-form';
import { FormItemProps } from 'antd/lib/form';
import { Form, Select } from 'antd';
import { LabelWithToolTip } from '../label-with-tooltip/label-with-tooltip';
import { SelectOption } from '../select-option/select-option';
import { SelectProps } from 'antd/lib/select';
import './form-select-input.styles.scss';
import { parseEmptyValueAs } from '../../../../logic/formaters/formaters';
import { isArray } from 'lodash';

const { Option } = Select;

interface OwnProps {
  toolTipMessage?: string;
  options?: SelectOption<any, any>[];
  isInsideOtherField?: boolean;
  onPressEnter: any;
  renderTag?: (tag: any) => string;
}

type FormSelectInputProps = WrappedFieldProps & SelectProps<any> & FormItemProps & OwnProps;

export const FormSelectInput = ({
  value: componentValue,
  onPressEnter,
  label,
  required,
  validateStatus,
  labelCol,
  wrapperCol,
  toolTipMessage,
  options = [],
  isInsideOtherField = false,
  meta,
  mode,
  input,
  renderTag,
  ...rest
}: FormSelectInputProps) => {
  const errorMessage = !!meta && meta.error;
  const showError = !!meta && meta.touched && !!meta.error;
  const labelComponent = toolTipMessage ? <LabelWithToolTip label={label} toolTipMessage={toolTipMessage} /> : label;
  const renderOptions = () => {
    return options.map((option: SelectOption, index) => {
      return (
        <Option key={index} value={option.value || ''} label={option.label} disabled={option.disabled} tag={option.tag}>
          {option.imageUrl && (
            <span role={'img'}>
              <img src={option.imageUrl} className={'select-option-image'} alt={''} />
            </span>
          )}
          {option.label}
          {renderTag && option.tag && <span className={'select-option-tag'}>{renderTag(option.tag)}</span>}
        </Option>
      );
    });
  };
  const { value, onFocus, onBlur, onChange, ...restInput } = input;

  const handleFocus = () => {
    onFocus({} as React.FocusEvent);
  };

  const handleChange = (value: any) => {
    const parsedValue = parseEmptyValueAs(mode === 'multiple' ? [] : null)(value);
    onChange(parsedValue);
  };

  const parseValue = (value: any) => {
    if (mode === 'multiple') {
      return isArray(value) ? value : [];
    }
    return value;
  };

  const renderSelect = () => (
    <Select<any>
      value={parseValue(value)}
      onFocus={handleFocus}
      onChange={handleChange}
      mode={mode}
      {...restInput}
      {...rest}
    >
      {renderOptions()}
    </Select>
  );

  return isInsideOtherField ? (
    renderSelect()
  ) : (
    <Form.Item
      label={labelComponent}
      required={required}
      validateStatus={showError ? 'error' : undefined}
      help={showError ? errorMessage : undefined}
      labelCol={labelCol}
      wrapperCol={wrapperCol}
    >
      {renderSelect()}
    </Form.Item>
  );
};
