import { createAction, ActionsUnion } from '../../redux/action-creator';

export enum ListName {
  user = 'user',
  group = 'group',
  location = 'location',
  editUserLocationTag = 'edit-user-location-tag',
  alert = 'alert',
  alertIcon = 'alert-icon',
  company = 'company',
  companyDomains = 'companyDomains',
  industry = 'industry',
  country = 'country',
  supportedLanguages = 'supportedLanguages',
}

export const ListEndpoints = {
  [ListName.user]: '/user',
  [ListName.group]: '/group',
  [ListName.location]: '/location',
  [ListName.alert]: '/alert',
  [ListName.alertIcon]: '/alert-icon',
  [ListName.company]: '/company',
  [ListName.companyDomains]: '/v2/company-sso-domains',
  [ListName.industry]: '/company/industry',
  [ListName.country]: '/country',
  [ListName.supportedLanguages]: '/alert/supported_languages',
  [ListName.editUserLocationTag]: '',
};

export type ListExtraParams = { [key: string]: number | boolean | string | undefined | null };

export enum ListsActionTypes {
  RESET = 'list/RESET',
  FETCH_LIST = 'list/FETCH_LIST',
  SET_DATA = 'list/SET_DATA',
}

export const listsActionCreators = {
  reset: () => createAction(ListsActionTypes.RESET),
  fetchList: (listName: ListName, extraParams?: ListExtraParams, pageSize?: number) =>
    createAction(ListsActionTypes.FETCH_LIST, { listName, extraParams, pageSize }),
  setData: <T>(listName: ListName, data: T[]) => createAction(ListsActionTypes.SET_DATA, { listName, data: data }),
};

export const getFetchListTrackName = (listName: ListName) => `${listsActionCreators.fetchList.name}_${listName}`;

export type ListsActions = ActionsUnion<typeof listsActionCreators>;
