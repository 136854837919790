export const parseNumber = (id: string | null | number | undefined): number | undefined => {
  if (id === undefined || id === null) {
    return undefined;
  }

  const result = parseInt(`${id}`, 10);

  if (`${result}` === `${id}`) {
    return result;
  }

  return undefined;
};
