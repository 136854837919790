import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MasterContent } from '../master-page/components/master-content/master-content';
import { userActionCreators } from '../../logic/store/user/user.action';
import { Tabs, Statistic, Space, Table, Row, Col } from 'antd';
import { getAnalytics } from '../../logic/store/user/user.selector';
import { translationKeys, translate } from '../../logic/translations/translations.service';
import { map } from 'lodash';
import { AnalyticSectionName } from '../../api-models/api-models';
import { AnalyticsSection, AnalyticColumnName } from './analytics.model';
import { getInProgress } from '../../logic/store/process-tracker/process-tracker.selectors';
import { Render } from '../components/table/table.column';
import { chunk } from 'lodash';
import { SortOrder } from 'antd/lib/table/interface';
import { parseNumber } from '../../logic/utils/number';

const { TabPane } = Tabs;

export const getAnalyticsColumn = (
  title: string,
  fieldName: AnalyticColumnName,
  render: Render<AnalyticsSection>,
  sorter?: (a: AnalyticsSection, b: AnalyticsSection) => number
) => ({
  title,
  dataIndex: fieldName,
  key: fieldName,
  render,
  sorter,
  sortDirections: ['descend', 'ascend'] as SortOrder[],
});

export const AnalyticsPage: React.FC = () => {
  const dispatch = useDispatch();
  const fetchAnalyticsInProgress = useSelector(getInProgress(userActionCreators.fetchAnalytics.name));
  const analytics = useSelector(getAnalytics);

  useEffect(() => {
    dispatch(userActionCreators.fetchAnalytics());
  }, [dispatch]);

  const renderAnalyticSummaryTabPane = () => (
    <TabPane
      tab={translate(translationKeys.pages.anlytics.section[AnalyticSectionName.summary])}
      key={AnalyticSectionName.summary}
    >
      <Space size="large" direction={'horizontal'}>
        <Space direction={'vertical'}>
          <Statistic
            title={translate(translationKeys.pages.anlytics.totalActiveCompanies)}
            value={analytics?.companies_active}
          />
          <Statistic
            title={translate(translationKeys.pages.anlytics.totalTrialCompanies)}
            value={analytics?.companies_trial}
          />
        </Space>
        <Space direction={'vertical'}>
          <Statistic
            title={translate(translationKeys.pages.anlytics.totalActiveUsers)}
            value={analytics?.users_active}
          />
          <Statistic title={translate(translationKeys.pages.anlytics.totalTrialUsers)} value={analytics?.users_trial} />
        </Space>
      </Space>
    </TabPane>
  );

  const renderAnalyticTabPane = (analyticSection: AnalyticSectionName) => {
    if (analyticSection === AnalyticSectionName.summary) return null;

    const data = analytics ? analytics[analyticSection] : undefined;
    const getFieldName = (key: string) => (key === 'null' ? translate(translationKeys.pages.anlytics.empty) : key);
    const mappedData: AnalyticsSection[] = map(data, (value, key) => ({
      field: getFieldName(key),
      count: value?.length,
      companies: value,
    }));

    return (
      <TabPane tab={translate(translationKeys.pages.anlytics.section[analyticSection])} key={analyticSection}>
        <Table
          rowKey={(record) => `${record[AnalyticColumnName.Field]}`}
          expandable={{
            expandedRowRender: (record) => (
              <Row>
                {chunk(record.companies, 3).map((x) => (
                  <Col {...{ md: 12, lg: 8 }}>
                    {x.map((x) => (
                      <div>
                        {x}
                        <br />
                      </div>
                    ))}
                  </Col>
                ))}
              </Row>
            ),
            rowExpandable: (record) => record.companies?.length !== 0,
          }}
          columns={[
            getAnalyticsColumn(
              translate(translationKeys.pages.anlytics.section[analyticSection]),
              AnalyticColumnName.Field,
              (value) => value
            ),
            getAnalyticsColumn(
              translate(translationKeys.pages.anlytics.column.count),
              AnalyticColumnName.Count,
              (value) => value,
              (a, b) => (parseNumber(a.count) || 0) - (parseNumber(b.count) || 0)
            ),
          ]}
          dataSource={mappedData}
        />
      </TabPane>
    );
  };

  return (
    <MasterContent inProgress={fetchAnalyticsInProgress}>
      <Tabs>
        {renderAnalyticSummaryTabPane()}
        {renderAnalyticTabPane(AnalyticSectionName.city)}
        {renderAnalyticTabPane(AnalyticSectionName.zip_code)}
        {renderAnalyticTabPane(AnalyticSectionName.state)}
        {renderAnalyticTabPane(AnalyticSectionName.country)}
        {renderAnalyticTabPane(AnalyticSectionName.industry)}
      </Tabs>
    </MasterContent>
  );
};
