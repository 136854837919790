import React from 'react';
import { Button } from 'antd';
import './back-button.styles.scss';
import { translate, translationKeys } from '../../../logic/translations/translations.service';
import { ArrowLeftOutlined } from '@ant-design/icons';

export const BackButton = () => (
  <Button className={'back-button'} icon={<ArrowLeftOutlined />} type={'link'}>
    {translate(translationKeys.buttons.back)}
  </Button>
);
