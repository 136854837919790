import { takeEvery, put, call, all } from 'redux-saga/effects';
import {
  ListsActionTypes,
  listsActionCreators,
  ListsActions,
  ListEndpoints,
  getFetchListTrackName,
  ListName,
} from './lists.action';
import { SagaManager } from '../../saga-manager/saga-manager';
import { ReturnActionOfType } from '../../redux/action-creator';
import { listsApi } from './lists.api';
import { ApiListResponse, ApiIdustryListResponse, ApiCountryListResponse } from '../../axios/axios-api-response';
import { getRestPages } from './lists.utils';
import { reduce } from 'lodash';
import { PAGE_SIZE } from '../tables/tables.reducer';

export function* listsSagaWatch() {
  yield takeEvery(ListsActionTypes.FETCH_LIST, fetchListSagaManaged);
}

function* fetchListSagaManaged(action: ReturnActionOfType<ListsActions, ListsActionTypes.FETCH_LIST>) {
  yield new SagaManager().addTracking(getFetchListTrackName(action.payload.listName)).execute(fetchListSaga, action);
}

function* fetchListSaga(action: ReturnActionOfType<ListsActions, ListsActionTypes.FETCH_LIST>) {
  switch (action.payload.listName) {
    case ListName.industry:
      return yield fetchIndustryListSaga(action);
    case ListName.country:
      return yield fetchCountryListSaga(action);
    case ListName.supportedLanguages:
      return yield fetchSupportedLanguagesSaga(action);
    default:
      return yield fetchDefaultListSaga(action);
  }
}

function* fetchDefaultListSaga(action: ReturnActionOfType<ListsActions, ListsActionTypes.FETCH_LIST>) {
  const endpoint = ListEndpoints[action.payload.listName];
  const firstPage: ApiListResponse = yield call(listsApi.fetchList, endpoint, 1, action.payload.extraParams, action.payload.pageSize);
  const data = firstPage.data;

  if (data?.count) {
    const firstResult = data.results || [];
    const pages = getRestPages(data.count, action.payload.pageSize || PAGE_SIZE);
    const responses: ApiListResponse[] = yield all(
      pages.map((page) => call(listsApi.fetchList, endpoint, page, action.payload.extraParams, action.payload.pageSize))
    );
    const result = reduce(
      responses,
      (results, response) => {
        return [...results, ...(response.data?.results || [])];
      },
      firstResult
    );

    yield put(listsActionCreators.setData(action.payload.listName, result));
  }
}

function* fetchSupportedLanguagesSaga(action: ReturnActionOfType<ListsActions, ListsActionTypes.FETCH_LIST>) {
  const endpoint = ListEndpoints[action.payload.listName];
  const response: ApiListResponse = yield call(listsApi.fetchList, endpoint);
  const { data } = response;

  if (data?.languages?.length) {
    yield put(listsActionCreators.setData(action.payload.listName, data.languages));
  }
}

function* fetchIndustryListSaga(action: ReturnActionOfType<ListsActions, ListsActionTypes.FETCH_LIST>) {
  const response: ApiIdustryListResponse = yield call(
    listsApi.fetchList,
    ListEndpoints[action.payload.listName],
    undefined
  );

  if (response.data?.industry?.length) {
    yield put(listsActionCreators.setData(action.payload.listName, response.data.industry));
  }
}

function* fetchCountryListSaga(action: ReturnActionOfType<ListsActions, ListsActionTypes.FETCH_LIST>) {
  const response: ApiCountryListResponse = yield call(
    listsApi.fetchList,
    ListEndpoints[action.payload.listName],
    undefined
  );

  if (response.data?.length) {
    yield put(listsActionCreators.setData(action.payload.listName, response.data));
  }
}
