import React, { PropsWithChildren } from 'react';
import './master-content.styles.scss';
import { Spin } from 'antd';
import { SpinProps } from 'antd/lib/spin';

interface MasterContentProps {
  className?: string;
  inProgress?: boolean;
  progressSpinner?: SpinProps;
}

interface Props extends MasterContentProps, PropsWithChildren {}

export const MasterContent: React.FC<Props> = ({
  progressSpinner = undefined,
  inProgress = false,
  children,
  className = '',
}) => (
  <Spin spinning={!progressSpinner && inProgress} {...progressSpinner}>
    <div className={`master-content ${className}`}>{children}</div>
  </Spin>
);
