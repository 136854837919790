import { locationActionCreators, LocationActions, LocationActionTypes } from './location.action';
import { tableActionCreators, TableName } from '../../tables/tables.action';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { translate, translationKeys } from '../../../translations/translations.service';

import { ReturnActionOfType } from '../../../redux/action-creator';
import { SagaManager } from '../../../saga-manager/saga-manager';
import { User } from '../../../../api-models/api-models';
import { fillReduxForm } from '../../../redux-form/fill-redux-form';
import { getAuthenticatedUser } from '../../authentication/authentication.selectors';
import { isSuperAdmin } from '../../../../pages/authentication/models/role/role.utils';
import { locationApi } from '../location.api';
import { logActionCreators } from '../../log/log.actions';
import { mapServerErrorsToReduxFormErrors } from '../../../server-error-parser/server-error-parser';
import { navigationActionCreators } from '../../navigation/navigation.actions';
import {
  createLocationFormData,
  validateLocationForm,
} from '../../../../pages/location-e911/pages/location-e911/location-e911-form';
import { LOCATION_FORM } from '../../../../common/location/location.form';

export function* locationSagaWatch() {
  yield takeEvery(LocationActionTypes.CREATE_SINGLE, createSingleSagaManaged);
  yield takeEvery(LocationActionTypes.UPDATE_SINGLE, updateSingleSagaManaged);
  yield takeEvery(LocationActionTypes.GET_SINGLE_FOR_EDIT, getSingleForEditSagaManaged);
  yield takeEvery(LocationActionTypes.DELETE_SINGLE, deleteSingleSagaManaged);
}

function* getSingleForEditSagaManaged(
  action: ReturnActionOfType<LocationActions, LocationActionTypes.GET_SINGLE_FOR_EDIT>,
) {
  yield new SagaManager().addTracking(locationActionCreators.getSingleForEdit.name).execute(getSingleForEdit, action);
}

function* getSingleForEdit(action: ReturnActionOfType<LocationActions, LocationActionTypes.GET_SINGLE_FOR_EDIT>) {
  const { data } = yield call(locationApi.getSingle, action.payload.id);
  const locationFormData = createLocationFormData(data);

  yield fillReduxForm(action.payload.formName, action.payload.mapper, locationFormData);
}

function* updateSingleSagaManaged(action: ReturnActionOfType<LocationActions, LocationActionTypes.UPDATE_SINGLE>) {
  yield new SagaManager()
    .addTracking(locationActionCreators.updateSingle.name)
    .addReduxFormValidation(LOCATION_FORM, action.payload.formValues, validateLocationForm)
    .addReduxFormAsyncValidation(LOCATION_FORM, mapServerErrorsToReduxFormErrors)
    .execute(updateSingleSaga, action);
}

function* updateSingleSaga(action: ReturnActionOfType<LocationActions, LocationActionTypes.UPDATE_SINGLE>) {
  yield call(locationApi.updateSingle, action.payload.formValues);
  const authenticatedUser: User = yield select(getAuthenticatedUser);
  if (isSuperAdmin(authenticatedUser.permission_level)) {
    yield put(logActionCreators.logSuccess(translate(translationKeys.messages.locationWasSuccessfullyUpdated)));
  } else {
    yield put(logActionCreators.logSuccess(translate(translationKeys.messages.locationSentEmailAboutUpdate)));
  }

  yield put(navigationActionCreators.back());
}

function* createSingleSagaManaged(action: ReturnActionOfType<LocationActions, LocationActionTypes.CREATE_SINGLE>) {
  yield new SagaManager()
    .addTracking(locationActionCreators.createSingle.name)
    .addReduxFormValidation(LOCATION_FORM, action.payload.formValues, validateLocationForm)
    .addReduxFormAsyncValidation(LOCATION_FORM, mapServerErrorsToReduxFormErrors)
    .execute(createSingleSaga, action);
}

function* createSingleSaga(action: ReturnActionOfType<LocationActions, LocationActionTypes.CREATE_SINGLE>) {
  yield call(locationApi.addSingle, action.payload.formValues);
  yield put(logActionCreators.logSuccess(translate(translationKeys.messages.locationWasSuccessfullyCreated)));
  yield put(navigationActionCreators.back());
}

function* deleteSingleSagaManaged(action: ReturnActionOfType<LocationActions, LocationActionTypes.DELETE_SINGLE>) {
  yield new SagaManager().addTracking(locationActionCreators.deleteSingle.name).execute(deleteSingleSaga, action);
}

function* deleteSingleSaga(action: ReturnActionOfType<LocationActions, LocationActionTypes.DELETE_SINGLE>) {
  yield call(locationApi.deleteSingle, action.payload.id);
  yield put(tableActionCreators.fetchTable(TableName.location));
  yield put(logActionCreators.logSuccess(translate(translationKeys.messages.locationWasSuccessfullyDeleted)));
}
