import React from 'react';
import { Form, Button } from 'antd';
import { authenticationActionCreators } from '../../../../logic/store/authentication/authentication.actions';
import { useDispatch, useSelector } from 'react-redux';
import { translate, translationKeys } from '../../../../logic/translations/translations.service';
import { reduxForm, InjectedFormProps, Field, getFormValues } from 'redux-form';
import { FormTextInput } from '../../../components/form-inputs/form-text-input/form-text-input';
import { FormContainer, formButtonLayout, formItemLayout } from '../../../components/form-container/form-container';
import { getInProgress } from '../../../../logic/store/process-tracker/process-tracker.selectors';
import { ResetPasswordForm, RESET_PASSWORD_FORM_NAME, validateResetPasswordForm } from './reset-password.form';
import { AuthenticationMasterPage } from '../authentication-master/authentication-master.page';
import { isDevelopment, env } from '../../../../logic/env/env';
import { Logo } from '../../../components/logo/logo';
import { trimValue } from '../../../../logic/formaters/formaters';

interface ResetPasswordPageProps extends InjectedFormProps<ResetPasswordForm> {}

const ResetPassword: React.FC<ResetPasswordPageProps> = () => {
  const dispatch = useDispatch();
  const formValues: ResetPasswordForm = useSelector(getFormValues(RESET_PASSWORD_FORM_NAME)) || {};
  const inProgress = useSelector(getInProgress(authenticationActionCreators.resetPassword.name));

  const handleResetPassword = () => {
    dispatch(authenticationActionCreators.resetPassword(formValues));
  };

  return (
    <AuthenticationMasterPage>
      <FormContainer>
        <Form.Item {...formButtonLayout}>
          <Logo />
          {translate(translationKeys.messages.checkYourEmail)}
        </Form.Item>
        <Field
          name={'email'}
          component={FormTextInput}
          label={translate(translationKeys.forms.fields.user.email)}
          required
          disabled={inProgress}
          onPressEnter={handleResetPassword}
          onBlurFormater={trimValue}
          {...formItemLayout}
        />
        <Field
          name={'code'}
          component={FormTextInput}
          label={translate(translationKeys.forms.fields.code)}
          required
          disabled={inProgress}
          onPressEnter={handleResetPassword}
          onBlurFormater={trimValue}
          {...formItemLayout}
        />
        <Field
          name={'newPassword'}
          component={FormTextInput}
          label={translate(translationKeys.forms.fields.newPassword)}
          isPassword
          required
          toolTipMessage={translate(translationKeys.forms.fields.passwordToolTip)}
          disabled={inProgress}
          onPressEnter={handleResetPassword}
          onBlurFormater={trimValue}
          {...formItemLayout}
        />
        <Form.Item {...formButtonLayout}>
          <Button
            type={'primary'}
            className={'form-button'}
            onClick={handleResetPassword}
            disabled={inProgress}
            loading={inProgress}
          >
            {translate(translationKeys.buttons.resetPassword)}
          </Button>
        </Form.Item>
      </FormContainer>
    </AuthenticationMasterPage>
  );
};

const initialValues = isDevelopment()
  ? {
      email: env.REACT_APP_DEV_EMAIL,
      newPassword: env.REACT_APP_DEV_PASSWORD,
    }
  : {};

export const ResetPasswordPage = reduxForm({
  form: RESET_PASSWORD_FORM_NAME,
  validate: validateResetPasswordForm,
  initialValues,
})(ResetPassword);
