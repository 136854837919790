import React from 'react';
import { LOGO } from '../../../assets/icons';
import './logo.styles.scss';

interface LogoProps {
  className?: string;
}

export const Logo: React.FC<LogoProps> = ({ className = '' }) => (
  <div className={`logo-container ${className}`}>
    <img className={'logo'} src={LOGO} alt={''} />
  </div>
);
