import React from 'react';
import { WrappedFieldProps } from 'redux-form';
import { Form, Upload, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { FormItemProps } from 'antd/lib/form';
import { translate, translationKeys } from '../../../../logic/translations/translations.service';
import { UploadChangeParam } from 'antd/lib/upload';
import { FileExtenssion } from './file-accept';
import './form-file-input.styles.scss';

interface OwnProps {
  disabled?: boolean;
  multiple?: boolean;
  fileFormatAccept?: FileExtenssion[];
  selectButtonLabel?: boolean;
}

type FormFileInputProps = WrappedFieldProps & FormItemProps & OwnProps;

export const FormFileInput = ({
  label,
  selectButtonLabel,
  required,
  labelCol,
  wrapperCol,
  meta,
  input,
  disabled,
  fileFormatAccept = [],
  multiple = false,
}: FormFileInputProps) => {
  const errorMessage = !!meta && meta.error;
  const showError = !!meta && meta.touched && !!meta.error;
  const { value, onChange } = input;

  const handleBeforeUpload = () => false;

  const handleOnChange = (info: UploadChangeParam) => {
    const newValue = multiple ? info.fileList : info.fileList.pop();
    onChange(newValue);
  };

  const getValue = () => (value && (multiple ? value : [value])) || [];

  return (
    <Form.Item
      label={label}
      required={required}
      validateStatus={showError ? 'error' : undefined}
      help={showError ? errorMessage : undefined}
      labelCol={labelCol}
      wrapperCol={wrapperCol}
    >
      <div className={'upload-container'}>
        <Upload
          className={'upload-input'}
          fileList={getValue()}
          disabled={disabled}
          beforeUpload={handleBeforeUpload}
          multiple={multiple}
          onChange={handleOnChange}
          accept={fileFormatAccept.join(', ')}
        >
          <Button className={'upload-input'} disabled={disabled}>
            <UploadOutlined />
            {selectButtonLabel || translate(translationKeys.buttons.selectFile)}
          </Button>
        </Upload>
      </div>
    </Form.Item>
  );
};
