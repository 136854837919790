import { reduxForm } from 'redux-form';
import { UserFormPageOwnProps, UserFormTemplatePage } from './user-form-template/user-form-template.page';
import { compose } from 'redux';
import { translate, translationKeys } from '../../../logic/translations/translations.service';
import { USER_FORM, validateUserForm } from './user-form-template/user-form.form';
import { UserFormFieldName, ContactInfoFieldName, LocationTagSection } from './user-form-template/user-form-field-name';
import { userActionCreators } from '../../../logic/store/user/user.action';
import { AUTHENTICATED_ROUTES } from '../../../logic/routes/routes.const';
import { getRoleSelectOptions } from '../../authentication/models/role/role.select-options';
import { getEqualOrLowerRoles } from '../../authentication/models/role/role.utils';
import {
  getAuthenticatedUserRole,
} from '../../../logic/store/authentication/authentication.selectors';
import { store } from '../../../app/app.store';
import { wrapComponentsWithProps } from '../../../logic/hoc/wrap-components-with-props.hoc';

export const EditUserPageCreator = () => {
  const role = getAuthenticatedUserRole(store.getState());
  const roles = getEqualOrLowerRoles(role);

  return compose(
    wrapComponentsWithProps<UserFormPageOwnProps>({
      fieldsToDisplay: [
        UserFormFieldName.first_name,
        UserFormFieldName.last_name,
        UserFormFieldName.email,
        UserFormFieldName.setNewPasswordButton,
        ContactInfoFieldName.address,
        ContactInfoFieldName.city,
        ContactInfoFieldName.state,
        ContactInfoFieldName.zip_code,
        ContactInfoFieldName.voice_call_phone,

        ContactInfoFieldName.department,
        ContactInfoFieldName.sms_phone,

        UserFormFieldName.company_id,
        UserFormFieldName.groups_ids,
        UserFormFieldName.permission_level,

        UserFormFieldName.default_location_header,
        UserFormFieldName.default_location_label,

        LocationTagSection.header,
        LocationTagSection.section,

        UserFormFieldName.main_alerts_block_Header,
        UserFormFieldName.main1_alert_type_id,
        UserFormFieldName.main2_alert_type_id,

        UserFormFieldName.hardware_devices_block_Header,
        UserFormFieldName.if_button_1_pressed_header,
        UserFormFieldName.hb1_alert_type_id,
        UserFormFieldName.hb1_message,
        UserFormFieldName.if_button_2_pressed_header,
        UserFormFieldName.hb2_alert_type_id,
        UserFormFieldName.hb2_message,
      ],
      disabledFields: [
        UserFormFieldName.email,
        UserFormFieldName.company_id,
      ],
      requiredFields: [
        UserFormFieldName.first_name,
        UserFormFieldName.last_name,
        UserFormFieldName.email,
        UserFormFieldName.company_id,
        UserFormFieldName.permission_level,
      ],
      disabledForHisself: [UserFormFieldName.permission_level],
      roleFieldOptions: getRoleSelectOptions(roles),
      submitButtonText: translate(translationKeys.buttons.updateUser),
      submitFormAction: userActionCreators.updateSingle,
      submitSuccessMessage: translate(translationKeys.messages.userWasSuccessfullyUpdated),
      submitSuccessRedirectionRoute: AUTHENTICATED_ROUTES.Users,
      formName: USER_FORM,
    }),
    reduxForm({
      form: USER_FORM,
      validate: validateUserForm,
    })
  )(UserFormTemplatePage) as React.FC;
};
