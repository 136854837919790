import { SsoGroupMappingFormFieldName } from './sso-group-mapping-form-field-name';
import { useCallback, useMemo } from 'react';
import { formItemLayout } from '../../../components/form-container/form-container';
import { translate, translationKeys } from '../../../../logic/translations/translations.service';
import { FormTextInput } from '../../../components/form-inputs/form-text-input/form-text-input';
import { trimValue } from '../../../../logic/formaters/formaters';
import { FormSelectInput } from '../../../components/form-inputs/form-select-input/form-select-input';
import { getAllRoleSelectOptions } from '../../../authentication/models/role/role.select-options';
import { Field } from 'redux-form';
import { Role } from '../../../../api-models/api-models';

interface SsoGroupMappingFormProps {
  onPressEnter?: any;
  inProgress?: boolean;
  fieldsToDisplay: SsoGroupMappingFormFieldName[];
  disabledFields: SsoGroupMappingFormFieldName[];
  formName: string;
}

export const SsoGroupMappingFormFields: React.FC<SsoGroupMappingFormProps> = ({
  onPressEnter,
  disabledFields,
  inProgress,
  fieldsToDisplay,
}) => {
  const isDisabled = useCallback(
    (fieldName: SsoGroupMappingFormFieldName) => {
      return disabledFields.indexOf(fieldName) >= 0 || inProgress;
    },
    [disabledFields, inProgress]
  );

  const commonAttributes = useCallback(
    (fieldName: SsoGroupMappingFormFieldName) => ({
      ...formItemLayout,
      disabled: isDisabled(fieldName),
      onPressEnter,
    }),
    [isDisabled, onPressEnter]
  );

  const translateFieldLabel = useCallback(
    (fieldName: SsoGroupMappingFormFieldName) => translate(translationKeys.forms.fields.ssoGroupMapping[fieldName]),
    []
  );

  const getTextField = useCallback(
    (fieldName: SsoGroupMappingFormFieldName, required?: boolean, rows?: number, maxLength?: number) => (
      <Field
        name={fieldName}
        component={FormTextInput}
        label={translateFieldLabel(fieldName)}
        required={required}
        maxLength={maxLength}
        onBlurFormater={trimValue}
        rows={rows}
        {...commonAttributes(fieldName)}
      />
    ),
    [commonAttributes, translateFieldLabel]
  );

  const fields = useMemo(
    () => ({
      [SsoGroupMappingFormFieldName.sso_group_name]: getTextField(SsoGroupMappingFormFieldName.sso_group_name),
      [SsoGroupMappingFormFieldName.corresponding_permission_level]: (
        <Field
          name={SsoGroupMappingFormFieldName.corresponding_permission_level}
          component={FormSelectInput}
          label={translateFieldLabel(SsoGroupMappingFormFieldName.corresponding_permission_level)}
          options={getAllRoleSelectOptions().filter((x) => x.value <= Role.CompanyAdmin)}
          {...commonAttributes(SsoGroupMappingFormFieldName.corresponding_permission_level)}
        />
      ),
      [SsoGroupMappingFormFieldName.company]: null,
      [SsoGroupMappingFormFieldName.company_name]: null,
    }),
    [translateFieldLabel, commonAttributes, getTextField]
  );

  return (
    <>
      {fieldsToDisplay.map((fieldName) => (
        <div key={fieldName}>{fields[fieldName]}</div>
      ))}
    </>
  );
};
