import React from 'react';
import { translate, translationKeys } from '../../../../../../logic/translations/translations.service';
import { OK_ICON, PAYMENT_ICON, SUPPORT_ICON, NOTIFICATION_ICON } from '../../../../../../assets/icons';
import {
  AboutTrial,
  AboutTrialContent,
  IconContainer,
  TrialFeatures,
  TrialFeaturesItem,
  TrialFeaturesRow,
} from './about-trial.components';
import { Heading, SubHeading } from '../common.components';
import { colors } from '../../../../../../styles/colors';

export const SignupPageAboutTrial: React.FC<{}> = () => {
  return (
    <AboutTrial>
      <AboutTrialContent>
        <h2>
          <SubHeading color={colors.paleSky}>{translate(translationKeys.signUp.aboutTrial)}</SubHeading>
        </h2>
        <h2>
          <Heading color={colors.white}>{translate(translationKeys.signUp.whatIsIncluded)}</Heading>
        </h2>
        <TrialFeatures>
          <TrialFeaturesRow>
            <TrialFeaturesItem>
              <IconContainer>
                <img src={OK_ICON} alt={''} />
              </IconContainer>
              <span>{translate(translationKeys.signUp.unlimitedAccess)}</span>
            </TrialFeaturesItem>
            <TrialFeaturesItem>
              <IconContainer>
                <img src={PAYMENT_ICON} alt={''} />
              </IconContainer>
              <span>{translate(translationKeys.signUp.noStringsAttached)}</span>
            </TrialFeaturesItem>
          </TrialFeaturesRow>
          <TrialFeaturesRow>
            <TrialFeaturesItem>
              <IconContainer>
                <img src={NOTIFICATION_ICON} alt={''} />
              </IconContainer>
              <span>{translate(translationKeys.signUp.industryLeadingTool)}</span>
            </TrialFeaturesItem>
            <TrialFeaturesItem>
              <IconContainer>
                <img src={SUPPORT_ICON} alt={''} />
              </IconContainer>
              <span>{translate(translationKeys.signUp.directSupportFromTeam)}</span>
            </TrialFeaturesItem>
          </TrialFeaturesRow>
        </TrialFeatures>
      </AboutTrialContent>
    </AboutTrial>
  );
};
