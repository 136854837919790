import React, { FocusEventHandler, useEffect, useState } from 'react';
import { WrappedFieldProps } from 'redux-form';
import { FormItemProps } from 'antd/lib/form';
import { AutoComplete, Form } from 'antd';
import { LabelWithToolTip } from '../label-with-tooltip/label-with-tooltip';
import { parseEmptyValueAs } from '../../../../logic/formaters/formaters';
import { AutoCompleteProps } from 'antd/lib/auto-complete';
const { Option } = AutoComplete;

const AUTOCOMPLETE_DISABLE_WORKAROUND = 'chrome-off';

interface OwnProps extends AutoCompleteProps {
  toolTipMessage?: string;
  onBlurFormater?: <T>(value: T) => T;
  textOptions?: string[];
  onPressEnter?: () => void;
}

type FormAutocompleteTextInputProps = WrappedFieldProps & FormItemProps & OwnProps;

export const FormAutocompleteTextInput = ({
  label,
  required,
  disabled,
  validateStatus,
  labelCol,
  wrapperCol,
  toolTipMessage,
  onBlurFormater,
  textOptions = [],
  meta,
  input,
  onPressEnter,
  ...rest
}: FormAutocompleteTextInputProps) => {
  const errorMessage = !!meta && meta.error;
  const showError = !!meta && meta.touched && !!meta.error;
  const labelComponent = toolTipMessage ? <LabelWithToolTip label={label} toolTipMessage={toolTipMessage} /> : label;
  const { value: inputValue, onBlur, onChange, ...restInput } = input;
  const [value, setValue] = useState<string | null | undefined>(undefined);

  const [autocompleteSearchResults, setAutocompleteSearchResults] = useState<string[]>(textOptions);

  useEffect(() => {
    setValue(inputValue);
  }, [inputValue]);

  const handleSearch = (value: string) => {
    const res = textOptions.filter((option) => option.toLowerCase().startsWith(value.toLowerCase()));
    setAutocompleteSearchResults(res);
  };

  const handleBlur: FocusEventHandler<any> = (e) => {
    const newValue = e.target.value;
    const formatedValue = onBlurFormater ? onBlurFormater(newValue) : newValue;
    onBlur(parseEmptyValueAs(null)(formatedValue));
  };

  const handleChange: AutoCompleteProps['onChange'] = (e) => {
    const newValue = parseEmptyValueAs(null)(e);
    onChange(newValue);
    setValue(newValue);
  };

  return (
    <Form.Item
      label={labelComponent}
      required={required}
      validateStatus={showError ? 'error' : undefined}
      help={showError ? errorMessage : undefined}
      labelCol={labelCol}
      wrapperCol={wrapperCol}
    >
      <>
        <AutoComplete
          value={value ? value : undefined}
          onBlur={handleBlur}
          onChange={handleChange}
          {...restInput}
          {...rest}
          disabled={disabled}
          onSearch={handleSearch}
          // @ts-ignore
          autoComplete={AUTOCOMPLETE_DISABLE_WORKAROUND}
        >
          {autocompleteSearchResults.map((option, index) => (
            <Option key={index} value={option}>
              {option}
            </Option>
          ))}
        </AutoComplete>
      </>
    </Form.Item>
  );
};
