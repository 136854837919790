import { SelectOption, getSelectOptions } from '../form-inputs/select-option/select-option';
import { AlertIcon } from '../../../api-models/api-models';

export const getAlertIconSelectOptions = (icons: AlertIcon[]): SelectOption[] =>
  getSelectOptions(icons, getAlertIconSelectOption);

export const getAlertIconSelectOption = (icon: AlertIcon): SelectOption => ({
  label: icon.name,
  imageUrl: icon.icon,
  value: icon.id,
});
