import { AlertType } from '../../../api-models/api-models';
import { AlertActionTypes, AlertActions } from './alert.action';

export interface AlertState {
  alertType?: AlertType;
}

const initialState: AlertState = {
  alertType: undefined,
};

export const alertReducer = (state = initialState, action: AlertActions): AlertState => {
  switch (action.type) {
    case AlertActionTypes.RESET:
      return initialState;
    case AlertActionTypes.SET_SINGLE:
      return {
        ...state,
        alertType: action.payload.response.data,
      };
    default:
      return state;
  }
};
