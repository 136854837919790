import { call, put, takeEvery } from 'redux-saga/effects';
import {
  SsoCompanyAdminsActionTypes,
  SsoCompanyAdminsActions,
  ssoCompanyAdminsActionCreators,
} from './sso-company-admins.actions';
import { ReturnActionOfType } from '../../redux/action-creator';
import { SagaManager } from '../../saga-manager/saga-manager';
import {
  SSO_COMPANY_ADMINS_FORM,
  validateSsoCompanyAdminsForm,
} from '../../../pages/sso-company-admins/pages/sso-company-admins-form-template/sso-company-admins-form.form';
import { mapServerErrorsToReduxFormErrors } from '../../server-error-parser/server-error-parser';
import { ssoCompanyAdminsApi } from './sso-company-admins.api';
import { logActionCreators } from '../log/log.actions';
import { translate, translationKeys } from '../../translations/translations.service';
import { navigationActionCreators } from '../navigation/navigation.actions';
import { AUTHENTICATED_ROUTES } from '../../routes/routes.const';
import { TableName, tableActionCreators } from '../tables/tables.action';

export function* ssoCompanyAdminsSagaWatch() {
  yield takeEvery(SsoCompanyAdminsActionTypes.CREATE_SINGLE, createSingleSagaManaged);
  yield takeEvery(SsoCompanyAdminsActionTypes.DELETE_SINGLE, deleteSingleSagaManaged);
}

function* createSingleSagaManaged(
  action: ReturnActionOfType<SsoCompanyAdminsActions, SsoCompanyAdminsActionTypes.CREATE_SINGLE>
) {
  yield new SagaManager()
    .addTracking(ssoCompanyAdminsActionCreators.createSingle.name)
    .addReduxFormValidation(SSO_COMPANY_ADMINS_FORM, action.payload.formValues, validateSsoCompanyAdminsForm)
    .addReduxFormAsyncValidation(SSO_COMPANY_ADMINS_FORM, mapServerErrorsToReduxFormErrors)
    .execute(createSingleSaga, action);
}

function* createSingleSaga(
  action: ReturnActionOfType<SsoCompanyAdminsActions, SsoCompanyAdminsActionTypes.CREATE_SINGLE>
) {
  yield call(ssoCompanyAdminsApi.addSingle, action.payload.formValues);
  yield put(logActionCreators.logSuccess(translate(translationKeys.messages.ssoCompanyAdminsSuccessfullyCreate)));
  yield put(navigationActionCreators.navigateTo(AUTHENTICATED_ROUTES.SsoCompanyAdmins));
}

function* deleteSingleSagaManaged(
  action: ReturnActionOfType<SsoCompanyAdminsActions, SsoCompanyAdminsActionTypes.DELETE_SINGLE>
) {
  yield new SagaManager()
    .addTracking(ssoCompanyAdminsActionCreators.deleteSingle.name)
    .execute(deleteSingleSaga, action);
}

function* deleteSingleSaga(
  action: ReturnActionOfType<SsoCompanyAdminsActions, SsoCompanyAdminsActionTypes.DELETE_SINGLE>
) {
  yield call(ssoCompanyAdminsApi.deleteSingle, action.payload.id);
  yield put(tableActionCreators.fetchTable(TableName.ssoCompanyAdmins));
}
