import React from 'react';
import './color-swatch.styles.scss';

interface ColorSwatchProp {
  color: string | undefined;
  className?: string;
}

export const ColorSwatch: React.FC<ColorSwatchProp> = ({ className = '', color }) => (
  <div className={`color-box ${className}`} style={{ backgroundColor: color }} />
);
