import { createAction, ActionsUnion } from '../../redux/action-creator';
import { CompanyDomainsForm } from '../../../pages/company-domains/pages/domains-forms/company-domains-form.form';
import { CompanySsoDomain } from '../../../api-models/api-models';

export enum CompanyDomainsActionTypes {
  RESET = 'company-domains/RESET',
  CREATE_SINGLE = 'company-domains/CREATE_SINGLE',
  UPDATE_SINGLE = 'company-domains/UPDATE_SINGLE',
  GET_SINGLE_FOR_EDIT = 'company-domains/GET_SINGLE_FOR_EDIT',
  DELETE_SINGLE = 'company-domains/DELETE_SINGLE',
}

export const companyDomainsActionCreators = {
  reset: () => createAction(CompanyDomainsActionTypes.RESET),
  createSingle: (formValues: CompanyDomainsForm) =>
    createAction(CompanyDomainsActionTypes.CREATE_SINGLE, { formValues }),
  updateSingle: (formValues: CompanyDomainsForm) =>
    createAction(CompanyDomainsActionTypes.UPDATE_SINGLE, { formValues }),
  getSingleForEdit: (id: any, formName: string, mapper: (data: CompanySsoDomain) => CompanyDomainsForm) =>
    createAction(CompanyDomainsActionTypes.GET_SINGLE_FOR_EDIT, { id, formName, mapper }),
  deleteSingle: (id: number) => createAction(CompanyDomainsActionTypes.DELETE_SINGLE, { id }),
};

export type CompanyDomainsActions = ActionsUnion<typeof companyDomainsActionCreators>;
