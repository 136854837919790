import { ChannelFormFieldName } from '../pages/channels/channel-form.field-name';
import { UserAlertChannelSettings } from '../../../api-models/api-models';

export const getActiveChannels = (
  channels: UserAlertChannelSettings[] = [],
  tableSelectedRowKeys: number[] = [],
  fieldName: ChannelFormFieldName
) =>
  channels.filter(
    channel => tableSelectedRowKeys.findIndex(id => `${id}` === `${channel.id}`) >= 0 && channel[fieldName]
  );

export const getNotActiveChannels = (
  channels: UserAlertChannelSettings[] = [],
  tableSelectedRowKeys: number[] = [],
  fieldName: ChannelFormFieldName
) =>
  channels.filter(
    channel => tableSelectedRowKeys.findIndex(id => `${id}` === `${channel.id}`) >= 0 && !channel[fieldName]
  );
