import React, { useEffect } from 'react';
import { Field, getFormValues } from 'redux-form';
import { translate, translationKeys } from '../../../../logic/translations/translations.service';
import { trimValue, STATE_CODE_MAX_LENGTH } from '../../../../logic/formaters/formaters';
import { FormSelectInput } from '../../../components/form-inputs/form-select-input/form-select-input';
import { GroupFormFieldName } from './group-form.field-name';
import { formItemLayout, formBlockHeaderLayout } from '../../../components/form-container/form-container';
import { useSelector, useDispatch } from 'react-redux';
import { getUserSelectOptions } from '../../../authentication/models/user.select-options';
import { getInProgress } from '../../../../logic/store/process-tracker/process-tracker.selectors';
import { listsActionCreators, ListName, getFetchListTrackName } from '../../../../logic/store/lists/lists.action';
import { getListData } from '../../../../logic/store/lists/lists.selectors';
import { AutocompleteClass, EmergencyMode, Location, Role, User } from '../../../../api-models/api-models';
import { Form } from 'antd';
import {
  getCustomSelectOptions,
  getDefaultSelectOptions,
} from '../../../components/form-inputs/select-option/select-option';
import { GroupForm } from './group-form.form';
import { FormFieldPhone } from '../../../components/form-fields/form-field-phone';
import { FormAutocompleteTextInput } from '../../../components/form-inputs/form-autocomplete-text-input/form-autocomplete-text-input';
import { getAutocomplete } from '../../../../logic/store/autocomplete/autocomplete.selectors';
import { getAuthenticatedUserCompany } from '../../../../logic/store/authentication/authentication.selectors';

interface GroupFormProps {
  onPressEnter?: any;
  inProgress?: boolean;
  formName: string;
}

export const GroupFormFields: React.FC<GroupFormProps> = ({ onPressEnter, inProgress, formName }) => {
  const dispatch = useDispatch();
  const commonAttributes = {
    ...formItemLayout,
    disabled: inProgress,
    onPressEnter,
  };
  const autocomplete = useSelector(getAutocomplete(AutocompleteClass.Group)) || {};

  useEffect(() => {
    dispatch(listsActionCreators.fetchList(ListName.company));
    dispatch(listsActionCreators.fetchList(ListName.user));
    dispatch(listsActionCreators.fetchList(ListName.location, { is_e911_enabled: true }));
  }, [dispatch]);

  const translateFieldLabel = (fieldName: GroupFormFieldName) =>
    translate(translationKeys.forms.fields.group[fieldName]);

  const getTextField = (fieldName: GroupFormFieldName, required?: boolean, maxLength?: number) => (
    <Field
      name={fieldName}
      component={FormAutocompleteTextInput}
      textOptions={autocomplete[fieldName] || []}
      label={translateFieldLabel(fieldName)}
      required={required}
      maxLength={maxLength}
      onBlurFormater={trimValue}
      {...commonAttributes}
    />
  );

  const companies = useSelector(getListData(ListName.company)) || [];
  const companiesOptions = getDefaultSelectOptions(companies);
  const fetchCompaniesInProgress = useSelector(getInProgress(getFetchListTrackName(ListName.company)));

  const users = useSelector(getListData<User>(ListName.user)) || [];
  const userOptions = getUserSelectOptions(users);
  const userAdmins = users.filter((user) => user.permission_level && user.permission_level >= Role.GroupAdmin);
  const userAdminsOptions = getUserSelectOptions(userAdmins);
  const fetchUsersInProgress = useSelector(getInProgress(getFetchListTrackName(ListName.user)));

  const getLocationOptionsValue = (item: Location) => ({
    value: item.id,
    label: `${item.name}`,
    disabled: item.emergency_mode === EmergencyMode.Monitoring && !item.monitoring_company_phone_number,
  });
  const locations = useSelector(getListData(ListName.location)) || [];
  const locationsOptions = getCustomSelectOptions(locations, getLocationOptionsValue);

  const fetchLocationsInProgress = useSelector(getInProgress(getFetchListTrackName(ListName.location)));
  const company = useSelector(getAuthenticatedUserCompany);

  const { phone_country_code }: GroupForm = useSelector(getFormValues(formName)) || {};

  return (
    <>
      {getTextField(GroupFormFieldName.name, true)}
      {getTextField(GroupFormFieldName.dept)}
      {getTextField(GroupFormFieldName.notes)}
      <Field
        name={GroupFormFieldName.company_id}
        component={FormSelectInput}
        label={translateFieldLabel(GroupFormFieldName.company_id)}
        options={companiesOptions}
        loading={fetchCompaniesInProgress}
        {...commonAttributes}
        disabled={true}
      />
      <Field
        name={GroupFormFieldName.admins_ids}
        component={FormSelectInput}
        label={translateFieldLabel(GroupFormFieldName.admins_ids)}
        options={userAdminsOptions}
        mode={'multiple'}
        showSearch
        filterOption={true}
        optionFilterProp={'label'}
        toolTipMessage={translate(translationKeys.forms.fields.group.adminsIdsTooltip)}
        allowClear
        required
        loading={fetchUsersInProgress}
        {...commonAttributes}
        disabled={fetchUsersInProgress || inProgress}
      />
      <Field
        name={GroupFormFieldName.users_ids}
        component={FormSelectInput}
        label={translateFieldLabel(GroupFormFieldName.users_ids)}
        options={userOptions}
        mode={'multiple'}
        showSearch
        filterOption={true}
        optionFilterProp={'label'}
        allowClear
        loading={fetchUsersInProgress}
        {...commonAttributes}
        disabled={fetchUsersInProgress || inProgress || company?.is_company_group_mapping_enabled}
      />
      <Form.Item {...formBlockHeaderLayout}>
        <div className={'text-justified'}>
          {translate(translationKeys.forms.fields.group.optionalSectionDescription)}
        </div>
      </Form.Item>
      {getTextField(GroupFormFieldName.address)}
      {getTextField(GroupFormFieldName.city)}
      <FormFieldPhone
        name={GroupFormFieldName.phone}
        countryCodeFieldName={GroupFormFieldName.phone_country_code}
        countryCode={phone_country_code}
        label={translateFieldLabel(GroupFormFieldName.phone)}
        inProgress={inProgress}
        commonAttributes={commonAttributes}
      />
      {getTextField(GroupFormFieldName.zip_code)}
      {getTextField(GroupFormFieldName.state, false, STATE_CODE_MAX_LENGTH)}
      <Field
        name={GroupFormFieldName.location_id}
        component={FormSelectInput}
        label={translateFieldLabel(GroupFormFieldName.location_id)}
        options={locationsOptions}
        showSearch
        filterOption={true}
        optionFilterProp={'label'}
        allowClear
        loading={fetchLocationsInProgress}
        {...commonAttributes}
        disabled={fetchLocationsInProgress || inProgress}
      />
    </>
  );
};
