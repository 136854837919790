import { RouteComponentProps } from 'react-router-dom';
import { SsoCompanyGroupMappingFormFieldName } from './sso-company-group-mapping-form-field-name';
import {
  SsoCompanyGroupMappingForm,
  ssoCompanyGroupMappingToFormValuesMapper,
} from './sso-company-group-mapping-form.form';
import { AnyAction } from 'redux';
import { InjectedFormProps, getFormValues } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { getInProgress } from '../../../../logic/store/process-tracker/process-tracker.selectors';
import { MasterPageHeader } from '../../../components/master-page-header/master-page-header';
import { MasterContent } from '../../../master-page/components/master-content/master-content';
import { FormContainer, formButtonLayout } from '../../../components/form-container/form-container';
import { Button, Form } from 'antd';
import { SsoCompanyGroupMappingFormFields } from './sso-company-group-mapping-form-fields';
import { useEffect } from 'react';
import queryString from 'query-string';
import { ssoCompanyGroupMappingActionCreators } from '../../../../logic/store/sso-company-group-mapping/sso-company-group-mapping.actions';

export interface SsoCompanyGroupMappingFormPageOwnProps {
  submitButtonText: string;
  submitFormAction: (formValues: SsoCompanyGroupMappingForm) => AnyAction;
  formName: string;
  fieldsToDisplay: SsoCompanyGroupMappingFormFieldName[];
  disabledFields: SsoCompanyGroupMappingFormFieldName[];
}

interface SsoGroupMappingFormTemplatePageProps
  extends RouteComponentProps,
    SsoCompanyGroupMappingFormPageOwnProps,
    InjectedFormProps<SsoCompanyGroupMappingForm> {}

export const SsoCompanyGroupMappingFormPage: React.FC<SsoGroupMappingFormTemplatePageProps> = ({
  fieldsToDisplay,
  disabledFields,
  formName,
  submitFormAction,
  submitButtonText,
  location,
}) => {
  const dispatch = useDispatch();
  const formValues: SsoCompanyGroupMappingForm = useSelector(getFormValues(formName)) || {};
  const submitInProgress = useSelector(getInProgress(submitFormAction.name));

  const inProgress = submitInProgress;

  const handleSubmitAction = () => {
    dispatch(submitFormAction(formValues));
  };

  useEffect(() => {
    const { id } = queryString.parse(location.search);
    if (id) {
      dispatch(
        ssoCompanyGroupMappingActionCreators.getSingleForEdit(id, formName, ssoCompanyGroupMappingToFormValuesMapper)
      );
    }
  }, [dispatch, formName, location.search]);

  return (
    <>
      <MasterPageHeader showBack />
      <MasterContent>
        <FormContainer>
          <SsoCompanyGroupMappingFormFields
            fieldsToDisplay={fieldsToDisplay}
            onPressEnter={handleSubmitAction}
            disabledFields={disabledFields}
            formName={formName}
          />
          <Form.Item {...formButtonLayout}>
            <Button
              type={'primary'}
              className={'form-button'}
              onClick={handleSubmitAction}
              disabled={inProgress}
              loading={submitInProgress}
            >
              {submitButtonText}
            </Button>
          </Form.Item>
        </FormContainer>
      </MasterContent>
    </>
  );
};
