import { put, select } from 'redux-saga/effects';
import { processTrackerActionCreators, ProcessStatus } from '../store/process-tracker/process-tracker.actions';
import { isEmpty } from 'lodash';
import { ReduxFormValidator, ReduxFormAsyncValidator } from '../validators/validators';
import { reduxFormErrorHandler, reduxFormAsyncErrorsHandler } from '../error-handlers/redux-form-error-handler';
import { ReduxFormError } from '../errors/redux-form-error';
import { NetworkCode } from '../../models/network-code';
import { noAuthenticationErrorHandler } from '../error-handlers/no-authentication-error-handler';
import { serverErrorHandler } from '../error-handlers/server-error-handler';
import { defaultErrorHandler } from '../error-handlers/default-error-handler';
import { customMessageErrorHandler } from '../error-handlers/custom-message-error-handler';
import { errorTypeField, ErrorType } from '../errors/error-type';
import { FormErrors, stopSubmit } from 'redux-form';
import { ApiError, getApiErrorData, getApiErrorStatusCode } from '../axios/axios-api-error';
import { getReduxFormRegisteredFields } from '../redux-form/get-redux-form-registered-fields.selector';
import { ErrorHandler } from '../error-handlers/error-handler';
import { forbiddenErrorHandler } from '../error-handlers/forbidden-error-handler';
import { notFoundErrorHandler } from '../error-handlers/not-found-error-handler';
import { serverValidationDefaultErrorHandler } from '../error-handlers/server-validation-default-error-handler';

export class SagaManager<TOnErrorResult> {
  static isServerValidationError(error: ApiError) {
    return getApiErrorStatusCode(error) === NetworkCode.BadRequest;
  }

  static isAuthenticationError(error: ApiError) {
    return getApiErrorStatusCode(error) === NetworkCode.Unauthorized;
  }

  static isForbiddenError(error: ApiError) {
    return getApiErrorStatusCode(error) === NetworkCode.Forbidden;
  }

  static isNotFoundError(error: ApiError) {
    return getApiErrorStatusCode(error) === NetworkCode.NotFound;
  }

  static isServerError(error: ApiError) {
    return getApiErrorStatusCode(error) === NetworkCode.ServerError;
  }

  private shouldTrack: boolean = false;
  private trackingName?: string = undefined;

  private shouldValidateReduxForm: boolean = false;
  private shouldValidateAsyncReduxForm: boolean = false;
  private reduxFormName?: string;
  private reduxFormValues?: object = undefined;
  private reduxFormValidator?: ReduxFormValidator<object> = undefined;
  private reduxFormAsyncValidator?: ReduxFormAsyncValidator = undefined;

  private shouldShowCustomErrorMessage: boolean = false;
  private customErrorMessage?: string;

  private onErrorResult?: TOnErrorResult = undefined;
  private shouldShowError: boolean = true;

  private shouldHandleCustomError: boolean = false;
  private customErrorHandler?: ErrorHandler;
  private customErrorHandlerParams?: any;

  addCustomErrorHandler(handler: ErrorHandler, ...params: any[]) {
    this.shouldHandleCustomError = true;
    this.customErrorHandler = handler;
    this.customErrorHandlerParams = params;
    return this;
  }

  addTracking(trackingName: string) {
    this.shouldTrack = true;
    this.trackingName = trackingName;
    return this;
  }

  addOnErrorResult(onErrorResult: TOnErrorResult) {
    this.onErrorResult = onErrorResult;
    return this;
  }

  addShowError(shouldShowError: boolean) {
    this.shouldShowError = shouldShowError;
    return this;
  }

  addReduxFormValidation<V extends object>(formName: string, formValues: V, formValidator: ReduxFormValidator<V>) {
    this.shouldValidateReduxForm = true;
    this.reduxFormName = formName;
    this.reduxFormValues = formValues;
    this.reduxFormValidator = formValidator as ReduxFormValidator<object>;
    return this;
  }

  addReduxFormAsyncValidation(
    formName: string,
    formValidator: (serverErrors: any, formFields?: string[]) => FormErrors<any, any>
  ) {
    this.shouldValidateAsyncReduxForm = true;
    this.reduxFormName = formName;
    this.reduxFormAsyncValidator = formValidator;
    return this;
  }

  addCustomErrorMessage(customErrorMessage: string) {
    this.shouldShowCustomErrorMessage = true;
    this.customErrorMessage = customErrorMessage;
    return this;
  }

  execute<R>(worker: WorkerWithoutAction<R>): IterableIterator<R>;
  execute<A, R>(worker: WorkerWithAction<A, R>, action: A): IterableIterator<R>;
  *execute<A, R>(worker: Worker<A, R>, action?: A) {
    try {
      yield this.changeProcessStatus(ProcessStatus.InProgress);
      yield this.validateReduxForm();
      yield this.resetReduxFormErrors();
      const result: object | undefined = yield worker(action as A);
      yield this.changeProcessStatus(ProcessStatus.Success, result);
      return result;
    } catch (error) {
      yield this.changeProcessStatus(ProcessStatus.Failure, error);
      yield this.handleError(error);
      return this.onErrorResult;
    }
  }

  private validateReduxForm() {
    if (this.shouldValidateReduxForm && this.reduxFormName && this.reduxFormValues && this.reduxFormValidator) {
      const errors = this.reduxFormValidator(this.reduxFormValues);
      const hasErrors = !isEmpty(errors);

      if (hasErrors) {
        throw new ReduxFormError();
      }
    }
  }

  private *resetReduxFormErrors() {
    if (this.shouldValidateReduxForm && this.reduxFormName) {
      yield put(stopSubmit(this.reduxFormName, {}));
    }
  }

  private *changeProcessStatus(processStatus: ProcessStatus, result?: object) {
    if (this.shouldTrack && this.trackingName) {
      yield put(processTrackerActionCreators.changeProcessStatus(this.trackingName, processStatus, result));
    }
  }

  private *handleError(error: Error) {
    if (this.shouldHandleCustomError && this.customErrorHandler) {
      const result: boolean = yield this.customErrorHandler(error, ...this.customErrorHandlerParams);

      if (result) {
        return;
      }
    }

    if (this.shouldShowCustomErrorMessage && this.customErrorMessage) {
      yield customMessageErrorHandler(this.customErrorMessage, error, this.shouldShowError);
      return;
    }
    if (
      (error as any)[errorTypeField] === ErrorType.ReduxFormError &&
      this.reduxFormName &&
      this.reduxFormValues &&
      this.reduxFormValidator
    ) {
      yield reduxFormErrorHandler(this.reduxFormName, this.reduxFormValidator(this.reduxFormValues));
      return;
    }

    if (
      SagaManager.isServerValidationError(error as ApiError) &&
      this.shouldValidateAsyncReduxForm &&
      this.reduxFormName &&
      this.reduxFormAsyncValidator
    ) {
      const errorMessage = getApiErrorData(error);
      const formFields: string[] = yield select(getReduxFormRegisteredFields(this.reduxFormName));
      const reduxFormErrors = !!errorMessage ? this.reduxFormAsyncValidator(errorMessage, formFields) : undefined;
      if (reduxFormErrors && !isEmpty(reduxFormErrors)) {
        yield reduxFormAsyncErrorsHandler(this.reduxFormName, reduxFormErrors);
        return;
      }
    }

    if (SagaManager.isServerValidationError(error as ApiError)) {
      yield serverValidationDefaultErrorHandler(error);
      return;
    }

    if (SagaManager.isAuthenticationError(error)) {
      yield noAuthenticationErrorHandler();
      return;
    }

    if (SagaManager.isForbiddenError(error)) {
      yield forbiddenErrorHandler();
      return;
    }

    if (SagaManager.isNotFoundError(error)) {
      yield notFoundErrorHandler();
      return;
    }

    if (SagaManager.isServerError(error as ApiError)) {
      yield serverErrorHandler(error, this.shouldShowError);
      return;
    }

    yield defaultErrorHandler(error, this.shouldShowError);
  }
}

type WorkerWithoutAction<R> = () => R;
type WorkerWithAction<A, R> = (action: A) => R;
type Worker<A, R> = WorkerWithoutAction<R> | WorkerWithAction<A, R>;
