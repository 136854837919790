import { getSelectOptions } from '../../components/form-inputs/select-option/select-option';
import { User } from '../../../api-models/api-models';
import { stringifyFields } from '../../../logic/utils/stringify-fields';

export const getUserSelectOptions = (users: User[]) => getSelectOptions(users, getUserSelectOption);

export const renderUserFullName = (user: User | undefined) => stringifyFields(' ', user?.first_name, user?.last_name);

export const getUserSelectOption = (user: User) => ({
  label: renderUserFullName(user),
  value: user.id,
});
