import React from 'react';
import styled from 'styled-components';
import { LastActiveBreak } from './last-active-break.model';
import { map } from 'lodash';
import { ActivityBadge, badgeColorByBreak } from './last-active.common';
import { translate, translationKeys } from '../../../../logic/translations/translations.service';

export const LastActiveLegend: React.FC = () => (
  <Items>
    {map(LastActiveBreak).map(lastActiveBreak => (
      <Item>
        <ActivityBadge color={badgeColorByBreak[lastActiveBreak]} />
        {translate(translationKeys.pages.users.lastActive.legend[lastActiveBreak])}
      </Item>
    ))}
  </Items>
);

const Items = styled.div`
  display: flex;
  flex-direction: column;
`;

const Item = styled.div`
  display: flex;
  flex-direction: row;
  color: black;
  align-items: center;
`;
