import {
  SsoGroupMappingForm,
  formValuesToSsoGroupMappingMapper,
} from '../../../pages/sso-group-mapping/pages/sso-group-mapping-form-template/sso-group-mapping-form.form';
import { axiosAuthenticatedClient } from '../../axios/axios-authenticated-client';
import { env } from '../../env/env';

export const ssoGroupMappingApi = {
  getSingle: (id: number) => axiosAuthenticatedClient().get(`${env.API_VERSION}/sso-group-mapping/${id}`),
  addSingle: (formValues: SsoGroupMappingForm) =>
    axiosAuthenticatedClient().post(`${env.API_VERSION}/sso-group-mapping/`, { ...formValues }),
  updateSingle: (formValues: SsoGroupMappingForm) =>
    axiosAuthenticatedClient().put(
      `${env.API_VERSION}/sso-group-mapping/${formValues.id}/`,
      formValuesToSsoGroupMappingMapper(formValues)
    ),
  deleteSingle: (id: number) => axiosAuthenticatedClient().delete(`${env.API_VERSION}/sso-group-mapping/${id}`),
};
