export enum ResourceType {
  Link,
  CommingSoon,
  Download,
  OpenInNewTab,
  CustomAction,
  Divider,
  SupportLink,
}

enum SupportLinksAnchors {
  alertManagement = 'alert-management',
  channelManagement = 'channel-management',
  groupManagement = 'group-management',
  userManagement = 'user-management',
  locationManagement = 'locations-management',
}

export interface ResourceContent {
  url?: string;
  resourceType?: ResourceType;
  linkType?: string;
  anchor?: SupportLinksAnchors;
}

interface ResourceContents {
  [x: number]: ResourceContent;
}

export enum Resource {
  // Windows
  desktopAppInstallerWindowsRegularUser,
  desktopAppInstallerWindowsRegularUserPrevious,
  desktopAppInstallerWindowsDomainAdminUser,
  desktopAppInstallerWindowsDomainAdminUserPrevious,

  // Mac
  desktopAppInstallerMac,
  desktopAppInstallerMacPrevious,

  testingInstructions,

  // Mobile
  androidApp,
  seeAndroidAppQrCode,
  iOSApp,
  seeiOSAppQrCode,
  appleWatch,
  seeAppleWatchQrCode,

  crsLanding,
  crsConnect,
  welcomeToTeamAlert,
  userGuide,
  crsNotifyQuickStartGuide,
  usageRecommendations,
  endUserLicenseAgreement,
  termsOfUse,
  privacyPolicy,
  quickSetupGuide,
  policyTemplate,
  importUsersTemplateCSV,
  dashboardVideo,
  alertManagementVideo,
  alertManagementVideoSupportLink,
  channelManagementVideo,
  channelManagementVideoSupportLink,
  groupManagementVideo,
  groupManagementVideoSupportLink,
  userManagementVideo,
  userManagementVideoSupportLink,
  locationsManagmentVideo,
  locationsManagementVideoSupportLink,
  teamAlertAcademy,

  divider,

  newUserVideo,
  newUserCover,
}

export const resourceContents: ResourceContents = {
  [Resource.desktopAppInstallerWindowsRegularUser]: {
    url: 'https://crsnotify-update.s3.amazonaws.com/newest/teamalert.exe',
    resourceType: ResourceType.Download,
  },
  [Resource.desktopAppInstallerWindowsRegularUserPrevious]: {
    url: 'https://crsnotify-update.s3.amazonaws.com/previous/teamalert.exe',
    resourceType: ResourceType.Download,
  },
  [Resource.desktopAppInstallerWindowsDomainAdminUser]: {
    url: 'https://crsnotify-update.s3.amazonaws.com/newest/teamalert.msi',
    resourceType: ResourceType.Download,
  },
  [Resource.desktopAppInstallerWindowsDomainAdminUserPrevious]: {
    url: 'https://crsnotify-update.s3.amazonaws.com/previous/teamalert.msi',
    resourceType: ResourceType.Download,
  },
  [Resource.desktopAppInstallerMac]: {
    url: 'https://apps.apple.com/app/teamalertdesktop/id6454930101',
    resourceType: ResourceType.OpenInNewTab,
  },
  [Resource.desktopAppInstallerMacPrevious]: {
    url: 'https://crsnotify-update.s3.amazonaws.com/previous/teamalert.dmg',
    resourceType: ResourceType.Download,
  },
  [Resource.testingInstructions]: {
    url: 'https://crs-dev-bucket.s3.amazonaws.com/downloads/testing-instructions.pdf',
    resourceType: ResourceType.Download,
  },
  [Resource.androidApp]: {
    url: 'https://play.google.com/store/apps/details?id=com.communityresponsesystems.crsnotify&hl=en&gl=US',
    resourceType: ResourceType.OpenInNewTab,
  },
  [Resource.seeAndroidAppQrCode]: {
    resourceType: ResourceType.CustomAction,
  },
  [Resource.iOSApp]: {
    url: 'https://apps.apple.com/us/app/teamalert-panic-app/id1508139767',
    resourceType: ResourceType.OpenInNewTab,
  },
  [Resource.seeiOSAppQrCode]: {
    resourceType: ResourceType.CustomAction,
  },
  [Resource.appleWatch]: {
    url: 'https://apps.apple.com/us/app/teamalert-watch/id1535952932',
    resourceType: ResourceType.OpenInNewTab,
  },
  [Resource.seeAppleWatchQrCode]: {
    resourceType: ResourceType.CustomAction,
  },
  [Resource.crsLanding]: {
    url: 'https://www.communityresponsesystems.com',
    resourceType: ResourceType.OpenInNewTab,
  },
  [Resource.crsConnect]: {
    url: 'https://wilsoncs.screenconnect.com/',
    resourceType: ResourceType.OpenInNewTab,
  },
  [Resource.userGuide]: {
    url: '2.2.user-manual.pdf',
    resourceType: ResourceType.OpenInNewTab,
  },
  [Resource.crsNotifyQuickStartGuide]: {
    url: '2.2.employee-quick-start-guide.pdf',
    resourceType: ResourceType.OpenInNewTab,
  },
  [Resource.usageRecommendations]: {
    url: '2.2.usage-recommendations.pdf',
    resourceType: ResourceType.OpenInNewTab,
  },
  [Resource.endUserLicenseAgreement]: {
    url: 'https://teamalert.com/eula',
    resourceType: ResourceType.OpenInNewTab,
  },
  [Resource.termsOfUse]: {
    url: 'https://teamalert.com/terms-of-use',
    resourceType: ResourceType.OpenInNewTab,
  },
  [Resource.privacyPolicy]: {
    url: 'https://teamalert.com/privacy-policy',
    resourceType: ResourceType.OpenInNewTab,
  },
  [Resource.quickSetupGuide]: {
    url: '2.2.quick-setup-guide.pdf',
    resourceType: ResourceType.OpenInNewTab,
  },
  [Resource.welcomeToTeamAlert]: {
    url: 'welcome_to_team_alert.pdf',
    resourceType: ResourceType.OpenInNewTab,
  },
  [Resource.policyTemplate]: {
    url: '2.2.policy-emplate.docx',
    resourceType: ResourceType.OpenInNewTab,
  },
  [Resource.importUsersTemplateCSV]: {
    url: 'import-users-template.csv',
    resourceType: ResourceType.Download,
  },
  [Resource.dashboardVideo]: {
    url: '2.2.dashboard.mp4',
    linkType: 'video/mp4',
  },
  [Resource.alertManagementVideo]: {
    url: '2.2.alert.mp4',
    linkType: 'video/mp4',
    anchor: SupportLinksAnchors.alertManagement,
  },
  [Resource.alertManagementVideoSupportLink]: {
    resourceType: ResourceType.SupportLink,
    anchor: SupportLinksAnchors.alertManagement,
  },
  [Resource.channelManagementVideo]: {
    url: '2.2.channel.mp4',
    linkType: 'video/mp4',
    anchor: SupportLinksAnchors.channelManagement,
  },
  [Resource.channelManagementVideoSupportLink]: {
    resourceType: ResourceType.SupportLink,
    anchor: SupportLinksAnchors.channelManagement,
  },
  [Resource.groupManagementVideo]: {
    url: '2.2.group.mp4',
    linkType: 'video/mp4',
    anchor: SupportLinksAnchors.groupManagement,
  },
  [Resource.groupManagementVideoSupportLink]: {
    resourceType: ResourceType.SupportLink,
    anchor: SupportLinksAnchors.groupManagement,
  },
  [Resource.userManagementVideo]: {
    url: '2.2.user.mp4',
    linkType: 'video/mp4',
    anchor: SupportLinksAnchors.userManagement,
  },
  [Resource.userManagementVideoSupportLink]: {
    resourceType: ResourceType.SupportLink,
    anchor: SupportLinksAnchors.userManagement,
  },
  [Resource.locationsManagmentVideo]: {
    url: '2.2.locations.mp4',
    linkType: 'video/mp4',
    anchor: SupportLinksAnchors.locationManagement,
  },
  [Resource.locationsManagementVideoSupportLink]: {
    resourceType: ResourceType.SupportLink,
    anchor: SupportLinksAnchors.locationManagement,
  },
  [Resource.teamAlertAcademy]: {
    url: '2.2.academy.mp4',
    linkType: 'video/mp4',
  },
  [Resource.divider]: {
    resourceType: ResourceType.Divider,
  },
  [Resource.newUserVideo]: {
    url: 'new_user.mp4',
    linkType: 'video/mp4',
  },
  [Resource.newUserCover]: {
    url: 'new_user_cover.png',
    linkType: 'image/png',
  },
};
