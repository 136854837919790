import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useModal } from '../../../logic/hooks/use-modal';
import { getInProgress, getProcessTrackerState } from '../../../logic/store/process-tracker/process-tracker.selectors';
import { usePrevious } from '../../../logic/hooks/use-previous';
import { willSucceed } from '../../../logic/store/process-tracker/process-tracker.actions';
import { renderConfirmModal } from './render-modal';

export const useConfirmActionModal = <T>(
  title: string,
  description: string,
  action: (data: T | undefined) => void,
  processName: string
) => {
  const modal = useModal<T>();
  const inProgress = useSelector(getInProgress(processName));
  const actionState = useSelector(getProcessTrackerState(processName));
  const previousActionState = usePrevious(actionState);

  useEffect(() => {
    if (modal.visible && willSucceed(previousActionState, actionState)) {
      modal.hide();
    }
  }, [modal, previousActionState, actionState]);

  const tryToPerformAction = (data: T | undefined) => () => !!data && modal.show(data);

  const handleConfirmAction = (data: T | undefined) => () => {
    if (data) {
      action(data);
    }
  };

  return {
    render: () =>
      renderConfirmModal(
        {
          title,
          visible: modal.visible,
          onOk: handleConfirmAction(modal.data),
          confirmLoading: inProgress,
          onCancel: modal.hide,
        },
        description
      ),
    tryToPerformAction,
  };
};
