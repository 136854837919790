import { Button, Typography } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import React from 'react';
import { useDispatch } from 'react-redux';
import { navigationActionCreators } from '../../../../../../logic/store/navigation/navigation.actions';
import { AUTHENTICATION_ROUTES } from '../../../../../../logic/routes/routes.const';
import { translate, translationKeys } from '../../../../../../logic/translations/translations.service';
import styled from 'styled-components';

const { Text } = Typography;

type SignInButtonProps = ButtonProps & {
  text?: string;
};

export const SignInButton: React.FC<SignInButtonProps> = ({ text, ...rest }) => {
  const dispatch = useDispatch();
  const handleNavigateToLogin = () => dispatch(navigationActionCreators.navigateTo(AUTHENTICATION_ROUTES.Login));

  return (
    <ButtonContainer>
      <Text>{text}</Text>
      <ButtonStyled type="link" onClick={handleNavigateToLogin} {...rest}>
        {translate(translationKeys.signUp.signIn)}
      </ButtonStyled>
    </ButtonContainer>
  );
};

const ButtonContainer = styled.div`
text-align: center;`;

const ButtonStyled = styled(Button)`
  // color: #313131;
  // text-decoration: underline;
  padding-left: 5px;
`;
