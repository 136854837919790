import { Auth } from 'aws-amplify';
import { Hub } from '@aws-amplify/core';
import { CompleteNewPasswordForm } from '../../../pages/authentication/pages/complete-new-password/complete-new-password.form';
import { CognitoAuthenticatedUser, CognitoChallengeUser } from './authentication.models';
import { ForgotPasswordForm } from '../../../pages/authentication/pages/forgot-password/forgot-password.form';
import { ResetPasswordForm } from '../../../pages/authentication/pages/reset-password/reset-password.form';
import { axiosAuthenticatedClient } from '../../axios/axios-authenticated-client';
import { handleThirdPartySignIn } from './authentication.sso-handlers';
import { axiosClient } from '../../axios/axios-client';
import { normalizeEmail } from '../../utils/email';
import { trimValue } from '../../formaters/formaters';
import { env } from '../../env/env';
import { logActionCreators } from '../log/log.actions';
import { translate, translationKeys } from '../../translations/translations.service';
import { store } from '../../../app/app.store';
import { parsePhoneNumberForPayload } from '../../phone/phone.mapper';

Hub.listen('auth', async ({ payload: { event, data } }) => {
  if (event === 'signIn') {
    const userData: CognitoAuthenticatedUser = data;
    const externalIdentities = userData?.signInUserSession?.idToken?.payload?.identities;

    if (externalIdentities !== undefined && externalIdentities?.length > 0) {
      await handleThirdPartySignIn();
    }
  } else if (event === 'signIn_failure') {
    if (data.message.includes('groups')) {
      store.dispatch(logActionCreators.logError(translate(translationKeys.errors.ssoCognitoTooManyGroupsError)));
      return;
    }
    store.dispatch(logActionCreators.logError(translate(translationKeys.errors.ssoCognitoGeneralError)));
  }
});

export const authenticationApi = {
  login: (email: string | undefined, password: string | undefined) =>
    Auth.signIn(normalizeEmail(email), trimValue(password)),

  completePasswordCognitoUser: undefined as CognitoChallengeUser | undefined,

  isCompletePasswordSessionExpired: () => !authenticationApi.completePasswordCognitoUser,

  completeNewPassword: (formValues: CompleteNewPasswordForm) =>
    Auth.completeNewPassword(
      authenticationApi.completePasswordCognitoUser,
      trimValue(formValues.newPassword) || '',
      {}
    ),

  forgotPassword: (formValues: ForgotPasswordForm) =>
    axiosAuthenticatedClient().post(`${env.API_VERSION}/user/reset_password/`, {
      email: normalizeEmail(formValues.email),
    }),

  forgotPasswordSubmit: (formValues: ResetPasswordForm) =>
    Auth.forgotPasswordSubmit(
      normalizeEmail(formValues.email) || '',
      trimValue(formValues.code) || '',
      trimValue(formValues.newPassword) || ''
    ),

  submitCompanyAuthorization: async function (providerName: string | undefined) {
    const cognitoUser: any = await Auth.federatedSignIn({ customProvider: providerName || 'Okta-Dev' });
    return cognitoUser;
  },

  fetchUser: (userId: number | undefined) => {
    let userResponse = null;
    if (userId !== undefined) {
      userResponse = axiosAuthenticatedClient().get(`${env.API_VERSION}/user/${userId}`);
    }
    return userResponse;
  },

  getCurrentAuthenticatedUser: () => Auth.currentAuthenticatedUser(),

  getCurrentSession: () => Auth.currentSession(),

  refreshSession: (cognitoUser?: any, refreshToken?: any) =>
    new Promise<any>((resolve, reject) => {
      cognitoUser.refreshSession(refreshToken, (err: any, session: any) => {
        if (err) {
          reject(err);
        }
        resolve(session);
      });
    }),

  logout: () => Auth.signOut(),

  signUp: (email?: string, name?: string, phone_number?: string, country_code?: string) => {
    const parsedPhoneNumber = parsePhoneNumberForPayload(country_code, phone_number);

    return axiosClient().post(`${env.API_VERSION}/trial_sign_up/`, {
      email: normalizeEmail(email),
      name,
      phone: parsedPhoneNumber,
      contact_info: {
        voice_call_phone: parsedPhoneNumber,
      },
    });
  },
};
