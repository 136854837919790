import { Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { ResourceContent } from './resource.const';
import { getResourceUrl } from './resource-link.logic';
import { ButtonLink } from './resource-link';
import styled from 'styled-components';

interface ResourceTooltipProps {
  tooltipMessage?: string;
}

export const ResourceTooltip: React.FC<ResourceTooltipProps> = ({ tooltipMessage }) => (
  <Tooltip title={tooltipMessage}>
    <QuestionCircleOutlined style={{ paddingLeft: 2.5, paddingRight: 5 }} />
  </Tooltip>
);

interface ButtonLinkAddonsProps {
  baseUrl?: string;
  tooltipMessage?: string;
  previous?: ResourceContent;
  previousVersionTitle?: string;
  notes?: string;
}

export const ButtonLinkAddons: React.FC<ButtonLinkAddonsProps> = ({
  baseUrl,
  tooltipMessage,
  previous,
  previousVersionTitle,
  notes,
}) => (
  <>
    {tooltipMessage && <ResourceTooltip tooltipMessage={tooltipMessage} />}
    {previous && previous.url && (
      <ButtonLink
        href={getResourceUrl(baseUrl, previous.url)}
        download={getResourceUrl(baseUrl, previous.url)}
        fontStyle="italic"
        fontSize={12}
        noPaddingleft
      >
        <div>{previousVersionTitle}</div>
      </ButtonLink>
    )}
    {notes && <ButtonLinkAddonsNotes>{notes}</ButtonLinkAddonsNotes>}
  </>
);

const ButtonLinkAddonsNotes = styled.div`
  font-style: italic;
  font-size: 12px;
`;
