import { StoreState } from '../../../app/app.reducers';
import { inProgress, succeeded, failed, ProcessState, progress } from './process-tracker.actions';

export const getInProgress = (processName: string | undefined) => (state: StoreState) =>
  inProgress(getProcessTrackerState(processName)(state));

export const getProgress = (processName: string | undefined) => (state: StoreState) =>
  progress(getProcessTrackerState(processName)(state));

export const getSucceeded = (processName: string | undefined) => (state: StoreState) =>
  succeeded(getProcessTrackerState(processName)(state));

export const getFailed = (processName: string | undefined) => (state: StoreState) =>
  failed(getProcessTrackerState(processName)(state));

export const getProcessTrackerState = <R = any>(processName: string | undefined) => (state: StoreState) =>
  (state.processTracker && processName && (state.processTracker[processName] as ProcessState<R>)) || undefined;
