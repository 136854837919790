import { axiosAuthenticatedClient } from '../../axios/axios-authenticated-client';
import { UserForm, mapFormToUser } from '../../../pages/users/pages/user-form-template/user-form.form';
import { User, UserBulkOperation } from '../../../api-models/api-models';
import { SupportContactForm } from '../../../pages/support/support.contact-form.form';
import { ImportUsersForm } from '../../../pages/users/pages/import-users/import-users.form';
import { getFileHeaders } from '../../axios/axios';
import { AxiosRequestConfig } from 'axios';
import omit from 'lodash.omit';
import { trimValue } from '../../formaters/formaters';
import { env } from '../../env/env';

export const userApi = {
  fetchSingle: (id: any) => axiosAuthenticatedClient().get(`${env.API_VERSION}/user/${id}`),
  addSingle: (formValues: UserForm) =>
    axiosAuthenticatedClient().post(`${env.API_VERSION}/user/`, mapFormToUser(formValues)),
  updateSingle: (formValues: UserForm) => {
    const mappedFormValues = mapFormToUser(formValues);
    const valuesWithoutPhotos = omit(mappedFormValues, 'photo', 'photo_big', 'photo_small');

    return axiosAuthenticatedClient().put(`${env.API_VERSION}/user/${formValues?.id}/`, valuesWithoutPhotos);
  },
  setPhoto: (id: string, photo: File | undefined) => {
    const formData = new FormData();
    if (photo !== undefined) formData.append('photo', photo);

    return axiosAuthenticatedClient(getFileHeaders()).post(`${env.API_VERSION}/user/${id}/photo/`, formData);
  },
  deletePhoto: (id: string) => axiosAuthenticatedClient().delete(`${env.API_VERSION}/user/${id}/photo/`),
  activateUser: (user: User) => axiosAuthenticatedClient().patch(`${env.API_VERSION}/user/${user.id}/activate/`),
  requestSuport: (user: User, formValues: SupportContactForm) =>
    axiosAuthenticatedClient().post(`${env.API_VERSION}/user/${user.id}/contact/`, formValues),
  importUsers: (
    formValues: ImportUsersForm,
    onUploadProgress?: AxiosRequestConfig['onUploadProgress'],
    onDownloadProgress?: AxiosRequestConfig['onDownloadProgress']
  ) => {
    const { file } = formValues;
    if (file) {
      var formData = new FormData();
      formData.append('file', file.originFileObj as Blob);

      return axiosAuthenticatedClient(getFileHeaders()).post(`${env.API_VERSION}/upload_csv/`, formData, {
        onUploadProgress,
        onDownloadProgress,
      });
    }
  },
  fetchAnalytics: () => axiosAuthenticatedClient().get(`${env.API_VERSION}/analytics/`),
  downloadCsv: () => axiosAuthenticatedClient().get(`${env.API_VERSION}/user/csv_export/`),
  performBulkAction: (users_ids: (number | undefined)[], action_type: UserBulkOperation) =>
    axiosAuthenticatedClient().post(`${env.API_VERSION}/user/users_actions/`, {
      users_ids,
      action_type,
    }),
  setNewPassword: (id: number | undefined, password: string) =>
    axiosAuthenticatedClient().patch(`${env.API_VERSION}/user/${id}/set_password/`, { password: trimValue(password) }),
  setWelcomePopupDone: (user_id: number) =>
    axiosAuthenticatedClient().post(`${env.API_VERSION}/user/${user_id}/welcome_popup_done/`),
};
