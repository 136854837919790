import { applyMiddleware, compose, createStore, Middleware, StoreEnhancer, Store } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistCombineReducers, Persistor, persistStore } from 'redux-persist';
import { WindowWithDevTools } from '../models/window-with-dev-tools';
import { isProduction } from '../logic/env/env';
import { StoreState, reducers } from './app.reducers';
import storage from 'redux-persist/lib/storage';
import { runSagaWatchers } from './app.saga-watch';
import * as reduxLogger from 'redux-logger';

const composeEnhancers = ((window as unknown) as WindowWithDevTools).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const sagaMiddleware = createSagaMiddleware();
const middlewares: Middleware[] = [sagaMiddleware];
let enhancer: StoreEnhancer;

if (isProduction()) {
  enhancer = compose(applyMiddleware(...middlewares));
} else {
  const logger: Middleware = reduxLogger.createLogger({ collapsed: true });
  middlewares.push(logger);
  enhancer = composeEnhancers(applyMiddleware(...middlewares));
}

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['authentication'],
};

const reducer = persistCombineReducers<StoreState>(persistConfig, {
  ...reducers,
});

export const configureStore = () => {
  const preStore: Store<StoreState> = createStore(reducer, enhancer);
  const prePersistor: Persistor = persistStore(preStore);

  runSagaWatchers(sagaMiddleware);

  return {
    persistor: prePersistor,
    store: preStore,
  };
};

export const { store, persistor } = configureStore();
