import { reduxForm } from 'redux-form';
import { UserFormTemplatePage, UserFormPageOwnProps } from './user-form-template/user-form-template.page';
import { compose } from 'redux';
import { translate, translationKeys } from '../../../logic/translations/translations.service';
import { USER_FORM, validateUserForm } from './user-form-template/user-form.form';
import { UserFormFieldName, ContactInfoFieldName } from './user-form-template/user-form-field-name';
import { userActionCreators } from '../../../logic/store/user/user.action';
import { AUTHENTICATED_ROUTES } from '../../../logic/routes/routes.const';
import { wrapComponentsWithProps } from '../../../logic/hoc/wrap-components-with-props.hoc';

export const SuperAdminEditCompanyAdminPage = compose(
  wrapComponentsWithProps<UserFormPageOwnProps>({
    fieldsToDisplay: [
      UserFormFieldName.first_name,
      UserFormFieldName.last_name,
      UserFormFieldName.email,
      UserFormFieldName.company_id,
      ContactInfoFieldName.address,
      ContactInfoFieldName.city,
      ContactInfoFieldName.state,
      ContactInfoFieldName.zip_code,
      ContactInfoFieldName.voice_call_phone,

      ContactInfoFieldName.department,
      ContactInfoFieldName.sms_phone,

      UserFormFieldName.permission_level,
    ],
    disabledFields: [UserFormFieldName.permission_level, UserFormFieldName.email],
    requiredFields: [
      UserFormFieldName.first_name,
      UserFormFieldName.last_name,
      UserFormFieldName.email,
      UserFormFieldName.company_id,
      UserFormFieldName.permission_level,
    ],
    submitButtonText: translate(translationKeys.buttons.updateCompanyAdmin),
    submitFormAction: userActionCreators.updateSingle,
    submitSuccessMessage: translate(translationKeys.messages.companyAdminWasSuccessfullyUpdated),
    submitSuccessRedirectionRoute: AUTHENTICATED_ROUTES.SuperAdminCompanyAdminUsers,
    formName: USER_FORM,
  }),
  reduxForm({
    form: USER_FORM,
    validate: validateUserForm,
  })
)(UserFormTemplatePage) as React.FC;
