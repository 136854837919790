import React, { ReactNode } from 'react';
import { PageHeader } from 'antd';
import './master-page-header.styles.scss';
import { PageHeaderProps } from 'antd/lib/page-header';
import { BackButton } from '../back-button/back-button';
import { PageDescription } from '../page-description/page-description';

interface MasterPageHeaderProps extends Omit<PageHeaderProps, 'title'> {
  showBack?: boolean;
  showAd?: boolean;
  extra?: ReactNode;
  title?: string;
  content?: ReactNode | string;
}

export const MasterPageHeader: React.FC<MasterPageHeaderProps> = ({
  title,
  content,
  children,
  showBack,
  showAd,
  extra = [],
  ...rest
}) => {
  return (
    <PageHeader
      className={'master-page-header'}
      title={''}
      {...rest}
      extra={extra}
      onBack={showBack ? () => window.history.back() : undefined}
      backIcon={<BackButton />}
    >
      {(title || content) && <PageDescription showAd={showAd} title={title} content={content} />}
      {children}
    </PageHeader>
  );
};
