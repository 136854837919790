import React, { PropsWithChildren } from 'react';
import './form-container.styles.scss';
import { Form, Spin } from 'antd';
import { SpinProps } from 'antd/lib/spin';

interface FormContainerProps {
  inProgress?: boolean;
  progressSpinner?: SpinProps;
  className?: string;
}

interface Props extends FormContainerProps, PropsWithChildren {}

export const FormContainer: React.FC<Props> = ({ className = '', children, progressSpinner, inProgress = false }) => (
  <Spin spinning={!progressSpinner && inProgress} {...progressSpinner}>
    <div className={`form-container ${className}`}>
      <Form className={'form'} autoComplete={'chrome-off'} labelWrap>
        {children}
      </Form>
    </div>
  </Spin>
);

export const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12 },
  },
};

export const formButtonLayout = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 12, offset: 10 },
  },
};

export const formBlockHeaderLayout = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 22, offset: 0 },
  },
};
