import { createAction, ActionsUnion } from '../../../redux/action-creator';
import { Location } from '../../../../api-models/api-models';
import { LocationForm } from '../../../../common/location/location.type';

export enum LocationActionTypes {
  RESET = 'location/RESET',
  CREATE_SINGLE = 'location/CREATE_SINGLE',
  UPDATE_SINGLE = 'location/UPDATE_SINGLE',
  GET_SINGLE_FOR_EDIT = 'location/GET_SINGLE_FOR_EDIT',
  DELETE_SINGLE = 'location/DELETE_SINGLE',
}

export const locationActionCreators = {
  reset: () => createAction(LocationActionTypes.RESET),
  createSingle: (formValues: LocationForm) => createAction(LocationActionTypes.CREATE_SINGLE, { formValues }),
  updateSingle: (formValues: LocationForm) => createAction(LocationActionTypes.UPDATE_SINGLE, { formValues }),
  getSingleForEdit: (id: any, formName: string, mapper: (data: Location) => LocationForm) =>
    createAction(LocationActionTypes.GET_SINGLE_FOR_EDIT, { id, formName, mapper }),
  deleteSingle: (id: number) => createAction(LocationActionTypes.DELETE_SINGLE, { id }),
};

export type LocationActions = ActionsUnion<typeof locationActionCreators>;
