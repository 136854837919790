import { FormErrors } from 'redux-form';
import { clearEmptyFieldsAndEmptyObjects } from '../../../../logic/validators/utils';
import { UserForm } from '../../../users/pages/user-form-template/user-form.form';
import { isRequiredValidator } from '../../../../logic/validators/validators';
import { isValidPhoneNumber } from '../../../../logic/phone/phone.validator';
import { DEFAULT_PHONE_COUNTRY_CODE } from '../../../authentication/models/country-code/country-code';

export const SETUP_USER_DETAILS_FORM = 'setupUserDetails';

export type SetupUserDetailsForm = Pick<UserForm, 'first_name' | 'last_name' | 'contact_info'>;

export const validateSetupUserDetailsForm = (formValues: SetupUserDetailsForm): FormErrors<UserForm, string> => {
  const { first_name, last_name, contact_info } = formValues;

  const errors: FormErrors<SetupUserDetailsForm, any> = {
    first_name: isRequiredValidator(first_name),
    last_name: isRequiredValidator(last_name),
    contact_info: clearEmptyFieldsAndEmptyObjects({
      voice_call_phone:
        isRequiredValidator(contact_info?.voice_call_phone) ||
        isValidPhoneNumber(contact_info?.voice_call_phone, contact_info?.voice_call_phone_country_code),
    }),
  };

  return clearEmptyFieldsAndEmptyObjects(errors);
};

export const setupUserDatailsFormInitialValues: UserForm = {
  contact_info: {
    voice_call_phone_country_code: DEFAULT_PHONE_COUNTRY_CODE,
    sms_phone_country_code: DEFAULT_PHONE_COUNTRY_CODE,
  },
};
