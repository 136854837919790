import { put } from 'redux-saga/effects';
import { logActionCreators } from '../store/log/log.actions';
import { ApiError } from '../axios/axios-api-error';
import { flatten } from '../utils/flatten';
import { errorFieldValuesToString } from '../server-error-parser/server-error-parser';

export function* serverValidationDefaultErrorHandler(error: ApiError) {
  const errorMessages = errorFieldValuesToString(flatten(error.response?.data));

  if (errorMessages) {
    yield put(logActionCreators.logError(errorMessages, error));
  }
}
