import { Grid } from 'antd';

export const useGridBreakpoints = () => {
  const { useBreakpoint } = Grid;
  const { xs, sm, md, xl, xxl } = useBreakpoint();

  return {
    xs, sm, md, xl, xxl,
  };
};
