import { FormErrors } from 'redux-form';
import { clearEmptyFields } from '../../../../logic/validators/utils';
import { isRequiredValidator, isEmailValididator } from '../../../../logic/validators/validators';
import { isCognitoPasswordValidator } from '../../../../logic/validators/passwordValidator';

export const RESET_PASSWORD_FORM_NAME = 'resetPassword';

export interface ResetPasswordForm {
  email?: string;
  code?: string;
  newPassword?: string;
}

export type ResetPasswordFormValidationErrors = FormErrors<ResetPasswordForm>;

export const validateResetPasswordForm = (formValues: ResetPasswordForm) => {
  const { email, code, newPassword } = formValues;

  const errors: FormErrors<ResetPasswordForm, any> = {
    email: isRequiredValidator(email) || isEmailValididator(email),
    code: isRequiredValidator(code),
    newPassword: isRequiredValidator(newPassword) || isCognitoPasswordValidator(newPassword),
  };

  return clearEmptyFields(errors);
};
