import { reduxForm } from 'redux-form';
import { compose } from 'redux';
import { translate, translationKeys } from '../../../logic/translations/translations.service';
import { companyDomainsActionCreators } from '../../../logic/store/company-domains/company-domains.action';
import {
  COMPANY_DOMAINS_FORM,
  companyDomainsFormInitialValues,
  validateCompanyDomainsForm,
} from './domains-forms/company-domains-form.form';
import {
  CompanyDomainsFormPage,
  CompanyDomainsFormPageOwnProps,
} from './domains-forms/company-domains-form-template.page';
import { getAuthenticatedUserCompanyId } from '../../../logic/store/authentication/authentication.selectors';
import { store } from '../../../app/app.store';
import { wrapComponentsWithProps } from '../../../logic/hoc/wrap-components-with-props.hoc';
import { CompanyDomainsFormFieldName } from './domains-forms/company-domain-form.field-name';

export const CompanyDomainsPageCreator = () =>
  compose(
    wrapComponentsWithProps<CompanyDomainsFormPageOwnProps>({
      submitButtonText: translate(translationKeys.buttons.addCompanyDomain),
      submitFormAction: companyDomainsActionCreators.createSingle,
      formName: COMPANY_DOMAINS_FORM,
      fieldsToDisplay: [
        CompanyDomainsFormFieldName.company,
        CompanyDomainsFormFieldName.company_name,
        CompanyDomainsFormFieldName.domain,
      ],
      disabledFields: [CompanyDomainsFormFieldName.company_name],
    }),
    reduxForm({
      form: COMPANY_DOMAINS_FORM,
      initialValues: {
        ...companyDomainsFormInitialValues,
        company: getAuthenticatedUserCompanyId(store.getState()),
      },
      validate: validateCompanyDomainsForm,
    })
  )(CompanyDomainsFormPage) as React.FC;
