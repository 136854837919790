import { compose } from 'redux';
import { wrapComponentsWithProps } from '../../../logic/hoc/wrap-components-with-props.hoc';
import {
  SSO_COMPANY_ADMINS_FORM,
  validateSsoCompanyAdminsForm,
} from './sso-company-admins-form-template/sso-company-admins-form.form';
import { reduxForm } from 'redux-form';
import { translate, translationKeys } from '../../../logic/translations/translations.service';
import { SsoCompanyAdminsFormFieldName } from './sso-company-admins-form-template/sso-company-admins-form-field-name';
import {
  SsoCompanyAdminsFormPage,
  SsoCompanyAdminsFormPageOwnProps,
} from './sso-company-admins-form-template/sso-company-admins-form.page';
import { ssoCompanyAdminsActionCreators } from '../../../logic/store/sso-company-admins/sso-company-admins.actions';

export const SsoCompanyAdminsAddPageCreator = () => {
  return compose(
    wrapComponentsWithProps<SsoCompanyAdminsFormPageOwnProps>({
      submitButtonText: translate(translationKeys.buttons.addSsoCompanyAdmin),
      submitFormAction: ssoCompanyAdminsActionCreators.createSingle,
      formName: SSO_COMPANY_ADMINS_FORM,
      fieldsToDisplay: [SsoCompanyAdminsFormFieldName.company, SsoCompanyAdminsFormFieldName.email],
      disabledFields: [],
    }),
    reduxForm({
      form: SSO_COMPANY_ADMINS_FORM,
      validate: validateSsoCompanyAdminsForm,
    })
  )(SsoCompanyAdminsFormPage) as React.FC;
};
