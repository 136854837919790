import React from 'react';
import { Route, RouteProps, Redirect, RouteComponentProps } from 'react-router';
import { useSelector } from 'react-redux';
import {
  getAuthenticatedUserRole,
  getAuthenticatedUser,
} from '../../store/authentication/authentication.selectors';
import { AUTHENTICATION_ROUTES } from '../routes.const';
import { Role, User } from '../../../api-models/api-models';

type RouteCompnent = React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;

interface AuthorizedRouteProps extends RouteProps {
  roles?: Role[];
  component?: RouteCompnent;
  componentCreator?: () => RouteCompnent;
}

export const AuthorizedRoute: React.FC<AuthorizedRouteProps> = ({
  roles,
  component: Component,
  componentCreator,
  ...rest
}: AuthorizedRouteProps) => {
  const currentUser = useSelector(getAuthenticatedUser);
  const currentRole = useSelector(getAuthenticatedUserRole);

  const render = (props: any) => {
    if (!isAuthenticated(currentUser)) {
      return <Redirect to={AUTHENTICATION_ROUTES.NoAuthentication} />;
    }

    if (!isAuthorized(roles, currentRole)) {
      return <Redirect to={AUTHENTICATION_ROUTES.NoAuthorization} />;
    }

    if (Component) {
      return <Component {...props} />;
    }

    if (componentCreator) {
      Component = componentCreator();
      return <Component {...props} />;
    }
  };

  return <Route {...rest} render={render} />;
};

export const isAuthenticated = (user: User | undefined) => !!user;

export const isAuthorized = (roles: Role[] | undefined | null, currentRole: Role | undefined | null): boolean =>
  roles === undefined || roles === null || (!!currentRole && roles.indexOf(currentRole) >= 0);
