import { FormErrors } from 'redux-form';

import { translationKeys, translate } from '../translations/translations.service';
import { formatFileSize } from '../utils/file';

export const IS_REQUIRED = translate(translationKeys.validator.form.isRequired);
export const EMAIL_FORMAT_IS_NOT_VALID = translate(translationKeys.validator.form.emailFormatIsNotValid);

export const getFileSizeCannotBeMoreThanTranslation = (maxFileSize: number) =>
  translate(translationKeys.validator.form.fileSizeCannotBeMoreThan, { maxFileSize: formatFileSize(maxFileSize, 0) });

export const EMAIL_REG =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const isRequiredValidator = <T extends string | number | object>(value: T | undefined | null) =>
  Array.isArray(value)
    ? value.length > 0
      ? undefined
      : IS_REQUIRED
    : !!value || value === 0
    ? undefined
    : IS_REQUIRED;

export const isEmailValididator = (email: string | undefined | null) =>
  EMAIL_REG.test((email || '').toLowerCase()) ? undefined : EMAIL_FORMAT_IS_NOT_VALID;

export type ReduxFormValidator<V extends object> = (formValues: V) => FormErrors<V, string>;

export type ReduxFormAsyncValidator = (serverErrors: any, formFieldsKeys?: string[]) => FormErrors;

export const isFileSizeLessOrEqual = (file: File | undefined, maxFileSize: number) =>
  (file?.size || 0) > maxFileSize ? getFileSizeCannotBeMoreThanTranslation(maxFileSize) : undefined;
