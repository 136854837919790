import React from 'react';
import Form, { FormItemProps } from 'antd/lib/form';
import styled from 'styled-components';
import Title from 'antd/lib/typography/Title';

type FormItemSectionTitleProps = FormItemProps & {
  marginTop?: number;
  children?: React.ReactNode;
};

interface Props extends FormItemSectionTitleProps {}

export const FormItemSectionTitle: React.FC<Props> = ({ children, marginTop, ...props }) => (
  <FormItemStyled {...props} style={{ marginTop }}>
    <Title className={'text-centered'} level={4}>
      {children}
    </Title>
  </FormItemStyled>
);

const FormItemStyled = styled(Form.Item)`
  margin-top: 36px;
  margin-bottom: 10px;
`;
