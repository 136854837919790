import React, { useCallback } from 'react';
import { Space, Button, Tooltip } from 'antd';
import { UpOutlined, DownOutlined, DoubleLeftOutlined, DoubleRightOutlined } from '@ant-design/icons';
import { OrderChangeAction } from '../../../../logic/change-order/change-order.model';
import { isChangeOrderEnabled, changeOrder } from '../../../../logic/change-order/change-order.logic';
import { translate, translationKeys } from '../../../../logic/translations/translations.service';

interface ChangeOrderActionsCellProps {
  order: number | undefined;
  minOrder: number | undefined;
  maxOrder: number | undefined;
  onChange: (order: number | undefined) => void;
}

export const ChangeOrderActionsCell: React.FC<ChangeOrderActionsCellProps> = ({
  order,
  minOrder,
  maxOrder,
  onChange,
}) => {
  const handleOrderChangeActionCallback = useCallback(
    (orderChangeAction: OrderChangeAction) => () => {
      isChangeOrderEnabled(order, minOrder, maxOrder, orderChangeAction) &&
        onChange(changeOrder(order, minOrder, maxOrder, orderChangeAction));
    },
    [order, minOrder, maxOrder, onChange]
  );

  const isChangeOrderEnabledCallback = useCallback(
    (orderChangeAction: OrderChangeAction): boolean =>
      isChangeOrderEnabled(order, minOrder, maxOrder, orderChangeAction),

    [order, minOrder, maxOrder]
  );

  return (
    <Space>
      <Space>
        <Space direction={'vertical'}>
          <Tooltip title={translate(translationKeys.buttons.tooltips.moveUp)}>
            <Button
              type={'primary'}
              icon={<UpOutlined />}
              size={'small'}
              disabled={!isChangeOrderEnabledCallback(OrderChangeAction.Decrease)}
              onClick={handleOrderChangeActionCallback(OrderChangeAction.Decrease)}
            />
          </Tooltip>
          <Tooltip title={translate(translationKeys.buttons.tooltips.moveDown)}>
            <Button
              type={'primary'}
              icon={<DownOutlined />}
              size={'small'}
              disabled={!isChangeOrderEnabledCallback(OrderChangeAction.Increase)}
              onClick={handleOrderChangeActionCallback(OrderChangeAction.Increase)}
            />
          </Tooltip>
        </Space>
        <Space direction={'vertical'}>
          <Tooltip title={translate(translationKeys.buttons.tooltips.moveToTheTop)}>
            <Button
              type={'default'}
              icon={<DoubleLeftOutlined rotate={90} />}
              size={'small'}
              disabled={!isChangeOrderEnabledCallback(OrderChangeAction.SetMin)}
              onClick={handleOrderChangeActionCallback(OrderChangeAction.SetMin)}
            />
          </Tooltip>
          <Tooltip title={translate(translationKeys.buttons.tooltips.moveToTheBottom)}>
            <Button
              type={'default'}
              icon={<DoubleRightOutlined rotate={90} />}
              size={'small'}
              disabled={!isChangeOrderEnabledCallback(OrderChangeAction.SetMax)}
              onClick={handleOrderChangeActionCallback(OrderChangeAction.SetMax)}
            />
          </Tooltip>
        </Space>
      </Space>
    </Space>
  );
};
