import { translationKeys, translate } from '../translations/translations.service';
import passwordValidator from 'password-validator';

export const IS_REQUIRED = translate(translationKeys.validator.form.isRequired);
export const PASSWORD_FORMAT_IS_NOT_VALID = translate(translationKeys.validator.form.passwordFormatIsNotValid);
export const PASSWORD_LENGTH_IS_NOT_VALID = translate(translationKeys.validator.form.passwordLengthIsNotValid);
export const PASSWORD_SHOULD_CONTAIN_LOWERCASE = translate(
  translationKeys.validator.form.passwordShouldContainLowercase
);
export const PASSWORD_SHOULD_CONTAIN_UPPERCASE = translate(
  translationKeys.validator.form.passwordShouldContainUppercase
);
export const PASSWORD_SHOULD_CONTAIN_DIGITS = translate(translationKeys.validator.form.passwordShouldContainDigits);
export const PASSWORD_SHOULD_CONTAIN_SYMBOLS = translate(translationKeys.validator.form.passwordShouldContainSymbols);

//cognito password scheme
const passwordSchema = new passwordValidator();
passwordSchema.is().min(8).has().uppercase().has().lowercase().has().digits().has().symbols();

export const isCognitoPasswordValidator = (password: string | undefined | null) => {
  if (!password) {
    return IS_REQUIRED;
  }

  const passwordSchemaResult = passwordSchema.validate(password, { list: true }) as Array<string>;
  if (passwordSchemaResult.includes('min')) {
    return PASSWORD_LENGTH_IS_NOT_VALID;
  } else if (passwordSchemaResult.includes('lowercase')) {
    return PASSWORD_SHOULD_CONTAIN_LOWERCASE;
  } else if (passwordSchemaResult.includes('uppercase')) {
    return PASSWORD_SHOULD_CONTAIN_UPPERCASE;
  } else if (passwordSchemaResult.includes('digits')) {
    return PASSWORD_SHOULD_CONTAIN_DIGITS;
  } else if (passwordSchemaResult.includes('symbols')) {
    return PASSWORD_SHOULD_CONTAIN_SYMBOLS;
  }
};
