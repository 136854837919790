import { Alert, Form } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { getAuthenticatedUserId } from '../../../../logic/store/authentication/authentication.selectors';
import { getInProgress } from '../../../../logic/store/process-tracker/process-tracker.selectors';
import { userActionCreators } from '../../../../logic/store/user/user.action';
import { translate, translationKeys } from '../../../../logic/translations/translations.service';
import { formButtonLayout } from '../../../components/form-container/form-container';
import { ContactInfoFieldName, UserFormFieldName } from '../../../users/pages/user-form-template/user-form-field-name';
import { UserFormFields } from '../../../users/pages/user-form-template/user-form-fields';
import { mapUserToForm } from '../../../users/pages/user-form-template/user-form.form';
import {
  setupUserDatailsFormInitialValues,
  SetupUserDetailsForm,
  SETUP_USER_DETAILS_FORM,
  validateSetupUserDetailsForm,
} from './setup-user-details.form';

interface SetupUserDetailsOwnProps {
  onPressEnter: () => void;
  formValues: SetupUserDetailsForm;
}

type SetupUserDetailsProps = InjectedFormProps<SetupUserDetailsForm, SetupUserDetailsOwnProps> &
  SetupUserDetailsOwnProps;

const SetupUserDetails: React.FC<SetupUserDetailsProps> = ({ formValues, onPressEnter, error }) => {
  const dispatch = useDispatch();
  const userId = useSelector(getAuthenticatedUserId);
  const fetchUserInProgress = useSelector(getInProgress(userActionCreators.fetchSingleForEdit.name));

  useEffect(() => {
    if (userId) {
      dispatch(userActionCreators.fetchSingleForEdit(userId, SETUP_USER_DETAILS_FORM, mapUserToForm));
    }
  }, [dispatch, userId]);

  return (
    <>
      <UserFormFields
        formValues={formValues}
        onPressEnter={onPressEnter}
        inProgress={fetchUserInProgress}
        fieldsToDisplay={[
          UserFormFieldName.first_name,
          UserFormFieldName.last_name,
          ContactInfoFieldName.voice_call_phone,
        ]}
        disabledFields={[]}
        requiredFields={[
          UserFormFieldName.first_name,
          UserFormFieldName.last_name,
          ContactInfoFieldName.voice_call_phone,
        ]}
      />

      {error && (
        <Form.Item {...formButtonLayout}>
          <Alert message={translate(translationKeys.errors.error)} description={error} type={'error'} showIcon />
        </Form.Item>
      )}
    </>
  );
};

export const SetupUserDetailsFormFields = reduxForm<SetupUserDetailsForm, SetupUserDetailsOwnProps>({
  form: SETUP_USER_DETAILS_FORM,
  initialValues: setupUserDatailsFormInitialValues,
  validate: validateSetupUserDetailsForm,
})(SetupUserDetails);
