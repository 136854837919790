import { CompanySsoDomain } from '../../../api-models/api-models';
import { translate, translationKeys } from '../../../logic/translations/translations.service';
import { useDispatch, useSelector } from 'react-redux';
import { AUTHENTICATED_ROUTES } from '../../../logic/routes/routes.const';
import { Button } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { MasterContent } from '../../master-page/components/master-content/master-content';
import { MasterPageTableHeader } from '../../components/master-page-table-header/master-page-table-header';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Table } from '../../components/table/table';
import { TableHeaderButton } from '../../components/master-page-table-header/master-page-table-header.components';
import { TableName } from '../../../logic/store/tables/tables.action';
import { companyDomainsActionCreators } from '../../../logic/store/company-domains/company-domains.action';
import { navigationActionCreators } from '../../../logic/store/navigation/navigation.actions';
import queryString from 'query-string';
import { useConfirmActionModal } from '../../components/confirm-modal/confirm-modal';
import { useSearchInput } from '../../components/search-input/search-input';
import { CompanyDomainsFormFieldName } from './domains-forms/company-domain-form.field-name';
import { getSortableFilterableColumnCreator } from '../../components/table/table.column';
import { shouldShowBackButton } from '../../../logic/location/location.helper';
import { getAuthenticatedUserRole } from '../../../logic/store/authentication/authentication.selectors';
import { parseId } from '../../../logic/utils/parse-id';

type CompanyDomainsPageProps = RouteComponentProps;

export const CompanyDomainsPage: React.FC<CompanyDomainsPageProps> = ({ location }) => {
  const dispatch = useDispatch();
  const searchInput = useSearchInput();
  const authenticatedUserRole = useSelector(getAuthenticatedUserRole);
  const { company } = queryString.parse(location.search);
  const company_id_parsed = parseId(company) as number;

  const handleAddSingle = () =>
    dispatch(
      navigationActionCreators.navigateTo(
        AUTHENTICATED_ROUTES.CompanyDomainsAdd,
        `?${queryString.stringify({ company: company_id_parsed })}`
      )
    );

  const handleDelete = (ssoDomain: CompanySsoDomain | undefined) => {
    if (ssoDomain && ssoDomain.id) {
      dispatch(companyDomainsActionCreators.deleteSingle(ssoDomain.id));
    }
  };

  const handleEditSingle = (row: CompanySsoDomain) => () =>
    dispatch(
      navigationActionCreators.navigateTo(
        AUTHENTICATED_ROUTES.CompanyDomainsEdit,
        `?${queryString.stringify({ domain_id: row.id })}`
      )
    );

  const deleteModal = useConfirmActionModal(
    translate(translationKeys.modalDialog.deleteCompanyDomain),
    translate(translationKeys.modalDialog.areYouSureYouWantToRemoveThisCompanyDomain),
    handleDelete,
    companyDomainsActionCreators.deleteSingle.name
  );

  const actionsColumn = (): ColumnProps<CompanySsoDomain> => ({
    title: translate(translationKeys.forms.fields.actions),
    dataIndex: 'actions',
    key: 'actions',
    render: (value, row) => (
      <span>
        <Button type={'link'} onClick={handleEditSingle(row)}>
          {translate(translationKeys.table.action.edit)}
        </Button>
        <Button type={'link'} onClick={deleteModal.tryToPerformAction(row)}>
          {translate(translationKeys.table.action.delete)}
        </Button>
      </span>
    ),
  });

  const getCompanyDomainsColumn = getSortableFilterableColumnCreator<CompanyDomainsFormFieldName, CompanySsoDomain>(
    translationKeys.forms.fields.companyDomains
  );

  const getColumns = [
    actionsColumn,
    getCompanyDomainsColumn(CompanyDomainsFormFieldName.company_name),
    getCompanyDomainsColumn(CompanyDomainsFormFieldName.domain),
  ];

  const renderAddSingleButton = () => (
    <TableHeaderButton type={'primary'} onClick={handleAddSingle}>
      {translate(translationKeys.buttons.addCompanyDomain)}
    </TableHeaderButton>
  );

  return (
    <>
      <MasterPageTableHeader
        searchInput={searchInput.render()}
        leftSection={renderAddSingleButton()}
        showBack={shouldShowBackButton(authenticatedUserRole)}
      />
      <MasterContent>
        <Table
          tableName={TableName.companyDomains}
          getColumns={getColumns}
          searchInput={searchInput}
          prefilters={{ ...(company_id_parsed ? { company: company_id_parsed } : {}) }}
        />
      </MasterContent>
      {deleteModal.render()}
    </>
  );
};
