import React from 'react';
import './master-footer.styles.scss';
import { Layout } from 'antd';
import { useSelector } from 'react-redux';
import { getAuthenticatedUserCompany } from '../../../../logic/store/authentication/authentication.selectors';
const { Footer } = Layout;

export const MasterFooter: React.FC = () => {
  const company = useSelector(getAuthenticatedUserCompany);
  return (
    <>
      <Footer className={'master-footer'}>{`${company?.footer_note || ''}`}</Footer>
    </>
  );
};
