import React, { useEffect } from 'react';
import { MasterPageHeader } from '../../../components/master-page-header/master-page-header';
import { Form, Button, Alert } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { MasterContent } from '../../../master-page/components/master-content/master-content';
import { InjectedFormProps, change, getFormValues } from 'redux-form';
import { CompanyDomainsForm, domainsToFormValuesMapper } from './company-domains-form.form';
import { FormContainer, formButtonLayout } from '../../../components/form-container/form-container';
import { getInProgress } from '../../../../logic/store/process-tracker/process-tracker.selectors';
import { AnyAction } from 'redux';
import queryString from 'query-string';
import { RouteComponentProps } from 'react-router-dom';
import { companyDomainsActionCreators } from '../../../../logic/store/company-domains/company-domains.action';
import { CompanyDomainsFormFields } from './company-domains-form.fields';
import { translate, translationKeys } from '../../../../logic/translations/translations.service';
import { CompanyDomainsFormFieldName } from './company-domain-form.field-name';
import { parseId } from '../../../../logic/utils/parse-id';
import { getAuthenticatedUserCompanyId } from '../../../../logic/store/authentication/authentication.selectors';
import { useConfirmActionModal } from '../../../components/confirm-modal/confirm-modal';

export interface CompanyDomainsFormPageOwnProps {
  submitButtonText: string;
  submitFormAction: (formValues: CompanyDomainsForm) => AnyAction;
  formName: string;
  fieldsToDisplay: CompanyDomainsFormFieldName[];
  disabledFields: CompanyDomainsFormFieldName[];
}

interface CompanyDomainsFormTemplatePageProps
  extends RouteComponentProps,
    CompanyDomainsFormPageOwnProps,
    InjectedFormProps<CompanyDomainsForm> {}

export const CompanyDomainsFormPage: React.FC<CompanyDomainsFormTemplatePageProps> = ({
  submitButtonText,
  submitFormAction,
  fieldsToDisplay,
  disabledFields,
  formName,
  location,
  error,
}) => {
  const dispatch = useDispatch();
  const formValues: CompanyDomainsForm = useSelector(getFormValues(formName)) || {};

  const getSingleForEditInProgress = useSelector(getInProgress(companyDomainsActionCreators.getSingleForEdit.name));

  const submitInProgress = useSelector(getInProgress(submitFormAction.name));
  const inProgress = getSingleForEditInProgress || submitInProgress;
  const authenticatedUserCompanyId = useSelector(getAuthenticatedUserCompanyId);

  const { company, domain_id } = queryString.parse(location.search);
  const company_id_parsed = parseId(company);
  const domain_id_parsed = parseId(domain_id);

  const companyId = authenticatedUserCompanyId || company_id_parsed;

  useEffect(() => {
    if (domain_id_parsed !== undefined) {
      dispatch(companyDomainsActionCreators.getSingleForEdit(domain_id_parsed, formName, domainsToFormValuesMapper));
    } else if (companyId !== undefined) {
      dispatch(change(formName, CompanyDomainsFormFieldName.company, companyId));
    }
  }, [dispatch, formName, location.search, companyId, domain_id_parsed]);

  const handleSubmitAction = () => {
    dispatch(submitFormAction(formValues));
  };

  const updateModal = useConfirmActionModal(
    translate(translationKeys.modalDialog.updateLocationE911),
    translate(translationKeys.modalDialog.areYouSureYouWantToUpdateThisLocationE911),
    handleSubmitAction,
    companyDomainsActionCreators.updateSingle.name
  );

  return (
    <>
      <MasterPageHeader showBack />
      <MasterContent>
        <FormContainer inProgress={getSingleForEditInProgress}>
          <CompanyDomainsFormFields
            inProgress={inProgress}
            onPressEnter={handleSubmitAction}
            formName={formName}
            fieldsToDisplay={fieldsToDisplay}
            disabledFields={disabledFields}
          />

          {error && (
            <Form.Item {...formButtonLayout}>
              <Alert message={translate(translationKeys.errors.error)} description={error} type={'error'} showIcon />
            </Form.Item>
          )}

          <Form.Item {...formButtonLayout}>
            <Button
              type={'primary'}
              className={'form-button'}
              onClick={handleSubmitAction}
              disabled={inProgress}
              loading={submitInProgress}
            >
              {submitButtonText}
            </Button>
          </Form.Item>
        </FormContainer>
      </MasterContent>
      {updateModal.render()}
    </>
  );
};
