import { Button } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFormValues } from 'redux-form';
import styled from 'styled-components';
import { userActionCreators } from '../../../../../../logic/store/user/user.action';
import { translate, translationKeys } from '../../../../../../logic/translations/translations.service';
import { useFormModal } from '../../../../../components/form-modal/form-modal';
import { SetNewPasswordForm, SET_NEW_PASSWORD_FORM_NAME } from '../set-new-password-form/set-new-password.form';
import { SetNewPasswordFormFields } from '../set-new-password-form/set-new-password.form-fields';

interface SetPasswordButtonProps extends ButtonProps {
  userId: number | undefined;
  userName: string;
}

export const SetPasswordButton: React.FC<SetPasswordButtonProps> = ({ userId, userName, ...buttonProps }) => {
  const dispatch = useDispatch();
  const formValues: SetNewPasswordForm = useSelector(getFormValues(SET_NEW_PASSWORD_FORM_NAME));

  const handleSetNewPassword = useCallback(() => {
    dispatch(userActionCreators.setNewPassword(userId, userName, SET_NEW_PASSWORD_FORM_NAME, formValues));
  }, [dispatch, userId, userName, formValues]);

  const setPasswordModal = useFormModal({
    title: translate(translationKeys.forms.fields.user.setNewPasswordModalTitle, { userName }),
    formName: SET_NEW_PASSWORD_FORM_NAME,
    submit: handleSetNewPassword,
    processName: userActionCreators.setNewPassword.name,
    getContent: () => <SetNewPasswordFormFields onPressEnter={handleSetNewPassword} />,
    showContentInProgress: true,
    okText: translate(translationKeys.forms.fields.user.setNewPasswordButton),
  });

  const handleOpenSetPasswordModal = useCallback(() => setPasswordModal.show(), [setPasswordModal]);

  return (
    <>
      <ChangePasswordButton onClick={handleOpenSetPasswordModal} {...buttonProps}>
        {translate(translationKeys.forms.fields.user.setNewPasswordButton)}
      </ChangePasswordButton>
      {setPasswordModal.render()}
    </>
  );
};

export const ChangePasswordButton = styled(Button).attrs<ButtonProps>({ type: 'link' })`
  padding-left: 0px;
`;
