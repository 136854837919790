import { compose } from 'redux';
import { wrapComponentsWithProps } from '../../../logic/hoc/wrap-components-with-props.hoc';
import {
  SsoCompanyGroupMappingFormPage,
  SsoCompanyGroupMappingFormPageOwnProps,
} from './sso-company-group-mapping-form-template/sso-company-group-mapping-form.page';
import { translate, translationKeys } from '../../../logic/translations/translations.service';
import {
  SSO_COMPANY_GROUP_MAPPING_FORM,
  validateSsoCompanyGroupMappingForm,
} from './sso-company-group-mapping-form-template/sso-company-group-mapping-form.form';
import { reduxForm } from 'redux-form';
import { SsoCompanyGroupMappingFormFieldName } from './sso-company-group-mapping-form-template/sso-company-group-mapping-form-field-name';
import { ssoCompanyGroupMappingActionCreators } from '../../../logic/store/sso-company-group-mapping/sso-company-group-mapping.actions';

export const SsoCompanyGroupMappingAddPageCreator = () => {
  return compose(
    wrapComponentsWithProps<SsoCompanyGroupMappingFormPageOwnProps>({
      submitButtonText: translate(translationKeys.buttons.addSsoCompanyGroupMapping),
      submitFormAction: ssoCompanyGroupMappingActionCreators.createSingle,
      formName: SSO_COMPANY_GROUP_MAPPING_FORM,
      fieldsToDisplay: [
        SsoCompanyGroupMappingFormFieldName.sso_group_name,
        SsoCompanyGroupMappingFormFieldName.company_group,
      ],
      disabledFields: [],
    }),
    reduxForm({
      form: SSO_COMPANY_GROUP_MAPPING_FORM,
      validate: validateSsoCompanyGroupMappingForm,
    })
  )(SsoCompanyGroupMappingFormPage) as React.FC;
};
