import { createAction, ActionsUnion } from '../../redux/action-creator';
import { LoginForm } from '../../../pages/authentication/pages/login/login.form';
import { CompleteNewPasswordForm } from '../../../pages/authentication/pages/complete-new-password/complete-new-password.form';
import { ForgotPasswordForm } from '../../../pages/authentication/pages/forgot-password/forgot-password.form';
import { ResetPasswordForm } from '../../../pages/authentication/pages/reset-password/reset-password.form';
import { SignupForm } from '../../../pages/authentication/pages/signup/signup.form';
import { CognitoAuthenticatedUser } from './authentication.models';
import { User, Company } from '../../../api-models/api-models';

export enum AuthenticationActionTypes {
  RESET = 'authentication/RESET',
  INITIALIZE_LOGIN_FORM = 'authentication/INITIALIZE_LOGIN_FORM',
  LOGIN = 'authentication/LOGIN',
  COMPLETE_NEW_PASSWORD = 'authentication/COMPLETE_NEW_PASSWORD',
  FORGOT_PASSWORD = 'authentication/FORGOT_PASSWORD',
  RESET_PASSWORD = 'authentication/RESET_PASSWORD',
  SUBMIT_COMPANY = 'authentication/SUBMIT_COMPANY',
  LOGOUT = 'authentication/LOGOUT',
  SET_AUTHENTICATED_USER = 'authentication/SET_AUTHENTICATED_USER',
  SET_USER_ID = 'authentication/SET_USER_ID',
  SET_ID_TOKEN = 'authentication/SET_ID_TOKEN',
  SET_USER_COMPANY = 'authentication/SET_USER_COMPANY',
  START_REFRESH_TOKEN_BACKGROUND_PROCESS = 'authentication/START_REFRESH_TOKEN_BACKGROUND_PROCESS',
  STOP_REFRESH_TOKEN_BACKGROUND_PROCESS = 'authentication/STOP_REFRESH_TOKEN_BACKGROUND_PROCESS',
  REFRESH_USER_COMPANY = 'authentication/REFRESH_USER_COMPANY',
  REFRESH_AUTHENTICATED_USER = 'authentication/REFRESH_AUTHENTICATED_USER',
  SIGN_UP = 'authentication/SIGN_UP',
  SET_ENTERPRISE_USER = 'authentication/SET_CURRENT_USER',
  LOGIN_DISCOVERY = 'authentication/LOGIN_DISCOVERY',
}

export const authenticationActionCreators = {
  reset: () => createAction(AuthenticationActionTypes.RESET),
  initializeLoginForm: (formName: string, formValues: { code?: string; email?: string }) =>
    createAction(AuthenticationActionTypes.INITIALIZE_LOGIN_FORM, { formName, formValues }),
  login: (formValues: LoginForm) => createAction(AuthenticationActionTypes.LOGIN, { formValues }),
  loginDiscovery: (providerName: string | undefined) =>
    createAction(AuthenticationActionTypes.LOGIN_DISCOVERY, { providerName }),
  completeNewPassword: (formValues: CompleteNewPasswordForm) =>
    createAction(AuthenticationActionTypes.COMPLETE_NEW_PASSWORD, { formValues }),
  forgotPassword: (formValues: ForgotPasswordForm) =>
    createAction(AuthenticationActionTypes.FORGOT_PASSWORD, { formValues }),
  resetPassword: (formValues: ResetPasswordForm) =>
    createAction(AuthenticationActionTypes.RESET_PASSWORD, { formValues }),
  submitCompany: (providerName: string | undefined) =>
    createAction(AuthenticationActionTypes.SUBMIT_COMPANY, { providerName }),
  logout: () => createAction(AuthenticationActionTypes.LOGOUT),
  setAuthenticatedUser: (user: User) => createAction(AuthenticationActionTypes.SET_AUTHENTICATED_USER, { user }),
  setUserId: (userId: number | undefined) => createAction(AuthenticationActionTypes.SET_USER_ID, { userId }),
  setIdToken: (idToken: string | undefined, cognitoUser: CognitoAuthenticatedUser | undefined) =>
    createAction(AuthenticationActionTypes.SET_ID_TOKEN, { idToken, cognitoUser }),
  setUserCompany: (userCompany: Company | undefined) =>
    createAction(AuthenticationActionTypes.SET_USER_COMPANY, { userCompany }),
  startRefreshTokenBackgroudProcess: () =>
    createAction(AuthenticationActionTypes.START_REFRESH_TOKEN_BACKGROUND_PROCESS),
  stopRefreshTokenBackgroudProcess: () => createAction(AuthenticationActionTypes.STOP_REFRESH_TOKEN_BACKGROUND_PROCESS),
  refreshUserCompany: () => createAction(AuthenticationActionTypes.REFRESH_USER_COMPANY),
  refreshAuthenticatedUser: () => createAction(AuthenticationActionTypes.REFRESH_AUTHENTICATED_USER),
  signUp: (formValues: SignupForm) => createAction(AuthenticationActionTypes.SIGN_UP, { formValues }),
  setEnterpriseUser: (cognitoUser: CognitoAuthenticatedUser | undefined) =>
    createAction(AuthenticationActionTypes.SET_ENTERPRISE_USER, { cognitoUser }),
};

export type AuthenticationActions = ActionsUnion<typeof authenticationActionCreators>;
