import { AutocompleteClass, AutocompleteResponse } from '../../../api-models/api-models';
import { createAction, ActionsUnion } from '../../redux/action-creator';

export enum AutocompleteActionTypes {
  RESET = 'autocomplete/RESET',
  FETCH_AUTOCOMPLETE = 'autocomplete/FETCH_AUTOCOMPLETE',
  SET_AUTOCOMPLETE = 'autocomplete/SET_AUTOCOMPLETE',
}

export const autocompleteActionCreators = {
  reset: () => createAction(AutocompleteActionTypes.RESET),
  fetchAutocomplete: (companyId: number, autocompleteClass: AutocompleteClass) =>
    createAction(AutocompleteActionTypes.FETCH_AUTOCOMPLETE, { companyId, autocompleteClass }),
  setAutocomplete: (autocompleteClass: AutocompleteClass, result: AutocompleteResponse) =>
    createAction(AutocompleteActionTypes.SET_AUTOCOMPLETE, { autocompleteClass, result }),
};

export type AutocompleteActions = ActionsUnion<typeof autocompleteActionCreators>;
