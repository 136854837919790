import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MasterPageHeader } from '../../../components/master-page-header/master-page-header';
import { MasterContent } from '../../../master-page/components/master-content/master-content';
import { getInProgress } from '../../../../logic/store/process-tracker/process-tracker.selectors';
import { alertActionCreators } from '../../../../logic/store/alert/alert.action';
import { getAlertType } from '../../../../logic/store/alert/alert.selectors';
import { useQuery } from '../../../../logic/hooks/use-query';
import { Button, Table } from 'antd';
import { translate, translationKeys } from '../../../../logic/translations/translations.service';
import { AlertCustomResponsesFormFields } from './alert-costom-responses-form/alert-custom-responses.fields';
import { ALERT_CUSTOM_RESPONSES_FORM } from './alert-costom-responses-form/alert-custom-responses.form';
import { useFormModal } from '../../../components/form-modal/form-modal';
import { AlertCustomResponsesForm } from './alert-costom-responses-form/alert-custom-responses.form';
import { AlertCustomResponsesFieldName } from './alert-costom-responses-form/alert-custom-responses.field-name';
import { parseId } from '../../../../logic/utils/parse-id';
import { ColumnProps } from 'antd/lib/table';
import { AlertCustomResponse } from '../../../../api-models/api-models';
import { useConfirmActionModal } from '../../../components/confirm-modal/confirm-modal';
import { getColumnCreator } from '../../../components/table/table.column';

export const AlertQuickResponsesPage: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const fetchSingleInProgress = useSelector(getInProgress(alertActionCreators.fetchSingle.name));
  const deleteCustomResponseInProgress = useSelector(getInProgress(alertActionCreators.deleteCustomResponse.name));
  const alertType = useSelector(getAlertType);
  const query = useQuery();
  const alertId = parseId(query.get('alert_id')) || -1;
  const alertName = (!fetchSingleInProgress && alertType?.name) || '...';
  const quickResponses = alertType?.custom_responses;
  const inProgress = fetchSingleInProgress || deleteCustomResponseInProgress;

  useEffect(() => {
    dispatch(alertActionCreators.fetchSingle(alertId));
  }, [dispatch, alertId]);

  const handleAddCustomResponse = useCallback(
    (formValues: AlertCustomResponsesForm) => dispatch(alertActionCreators.addCustomResponse(alertId, formValues)),
    [dispatch, alertId]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getAlertCustomResponseColumn = useCallback(
    getColumnCreator<AlertCustomResponsesFieldName, AlertCustomResponse>(
      translationKeys.forms.fields.alertCustomResponses
    ),
    []
  );

  const addCustomResponseModal = useFormModal({
    title: translate(translationKeys.buttons.addQuickResponse),
    formName: ALERT_CUSTOM_RESPONSES_FORM,
    submit: handleAddCustomResponse,
    processName: alertActionCreators.addCustomResponse.name,
    getContent: () => <AlertCustomResponsesFormFields />,
    showContentInProgress: true,
  });

  const handleAddCustomResponseModal = () => {
    addCustomResponseModal.show();
  };

  const handleDelete = useCallback(
    (customResponse: AlertCustomResponse | undefined) => {
      if (customResponse && customResponse.id) {
        dispatch(alertActionCreators.deleteCustomResponse(alertId, customResponse.id));
      }
    },
    [dispatch, alertId]
  );

  const deleteModal = useConfirmActionModal(
    translate(translationKeys.modalDialog.deleteCustomResponse),
    translate(translationKeys.modalDialog.areYouSureYouWantToRemoveThisQuickResponse),
    handleDelete,
    alertActionCreators.deleteCustomResponse.name
  );

  const actionsColumn: ColumnProps<AlertCustomResponse> = useMemo(
    () => ({
      title: translate(translationKeys.forms.fields.actions),
      dataIndex: 'actions',
      key: 'actions',
      render: (value, row) => (
        <span>
          <Button type={'link'} onClick={deleteModal.tryToPerformAction(row)}>
            {translate(translationKeys.table.action.delete)}
          </Button>
        </span>
      ),
    }),
    [deleteModal]
  );

  const columns = useMemo(
    () => [
      actionsColumn,
      getAlertCustomResponseColumn(
        AlertCustomResponsesFieldName.label_text,
        (a, b) => (a.label_text || '').localeCompare(b.label_text || ''),
        (value, row) => row.label_text
      ),
      getAlertCustomResponseColumn(
        AlertCustomResponsesFieldName.text,
        (a, b) => (a.text || '').localeCompare(b.text || ''),
        (value, row) => row.text
      ),
    ],
    [actionsColumn, getAlertCustomResponseColumn]
  );

  return (
    <>
      <MasterPageHeader showBack title={alertName} />
      <MasterContent>
        <Button
          style={{ width: '200px', marginBottom: '20px' }}
          type={'primary'}
          onClick={handleAddCustomResponseModal}
        >
          {translate(translationKeys.buttons.addQuickResponse)}
        </Button>
        <Table
          className={'table'}
          columns={columns}
          rowKey={(record) => `${record.id}`}
          dataSource={quickResponses}
          loading={inProgress}
        />
      </MasterContent>
      {addCustomResponseModal.render()}
      {deleteModal.render()}
    </>
  );
};
