import React from 'react'
import { Upload } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { UploadChangeParam } from 'antd/lib/upload';
import { translate, translationKeys } from '../../../../../logic/translations/translations.service';

import './avatar-change.scss'

type AvatarChangeUploadProps = {
  setUploadedImageURL(url: string): void
}

const AvatarChangeUpload: React.FC<AvatarChangeUploadProps> = ({setUploadedImageURL}) => {
  const handleUpload = (info: UploadChangeParam) => {
    setUploadedImageURL(URL.createObjectURL(info.file));
  };

  const handleBeforeUpload = () => false;

  return (
      <Upload
        name="avatar"
        listType="picture-card"
        className="avatar-uploader"
        onChange={handleUpload}
        beforeUpload={handleBeforeUpload}
      >
        <div>
          <PlusOutlined />
          <div style={{ marginTop: 8 }}>{translate(translationKeys.avatar.upload)}</div>
        </div>
      </Upload>
  )

}

export default AvatarChangeUpload
