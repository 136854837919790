import { isString, map } from 'lodash';
import { UserDevice } from '../../../../api-models/api-models';
import { translate, translationKeys } from '../../../../logic/translations/translations.service';
import { LastActiveBreak } from './last-active-break.model';
import { DateTime } from 'luxon';

export const LastActiveTresholdDay = {
  [LastActiveBreak.NoBreak]: 0,
  [LastActiveBreak.ShortBreak]: 3,
  [LastActiveBreak.LongBreak]: 7,
};

export const getLastActiveBreak =
  (now: DateTime) =>
  (minDayByBreak: typeof LastActiveTresholdDay) =>
  (lastActive: string | undefined): LastActiveBreak | undefined => {
    if (!lastActive || !isString(lastActive)) return undefined;
    const lastSeenDate = DateTime.fromISO(lastActive);
    if (!lastSeenDate.isValid) return undefined;

    const result = map(LastActiveBreak)
      .reverse()
      .find((lastActiveBreak) => {
        const daysElapsed = now.diff(lastSeenDate, 'days').days | 0;
        const tresholdDays = minDayByBreak[lastActiveBreak];
        return daysElapsed >= tresholdDays || tresholdDays === 0;
      });

    return result;
  };

export const getLastActive = (lastDevice: UserDevice | undefined) => lastDevice?.modified_at || lastDevice?.created_at;

export const getLastActiveDevice = (lastDevices: UserDevice[] | undefined) =>
  lastDevices?.length
    ? lastDevices?.sort((a, b) => {
        const lastActiveA = getLastActive(a);
        const lastActiveB = getLastActive(b);

        if (!lastActiveA && !lastActiveB) return 0;
        if (!lastActiveA && !!lastActiveB) return 1;
        if (!!lastActiveA && !lastActiveB) return -1;

        if (lastActiveA && lastActiveB) {
          const lastActiveA_DT = DateTime.fromISO(lastActiveA);
          const lastActiveB_DT = DateTime.fromISO(lastActiveB);

          return lastActiveB_DT.diff(lastActiveA_DT, 'days').days | 0;
        }

        return 0;
      })[0]
    : undefined;

export const getLastUsedOsText = (lastDevice: UserDevice | undefined) => {
  const osType = lastDevice?.os_type;
  return osType ? translate(translationKeys.pages.users.lastActive.osType[osType]) : '';
};

export const getLastUsedAppVersionText = (lastDevice: UserDevice | undefined) => {
  const appVersion = lastDevice?.app_version;
  return appVersion ? translate(translationKeys.application.name, { appVersion }) : '';
};
