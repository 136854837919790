import { TableName } from '../../../../logic/store/tables/tables.action';
import { TableButton } from '../../../../logic/store/tables/tables.types';
import { MasterContent } from '../../../master-page/components/master-content/master-content';
import { Table } from '../../../components/table/table';
import { getCompanyAdminsSsoColumns } from './sso-company-admins.template.columns';
import { SsoCompanyAdminsFormFieldName } from '../sso-company-admins-form-template/sso-company-admins-form-field-name';
import { MasterPageTableHeader } from '../../../components/master-page-table-header/master-page-table-header';
import { TableHeaderButton } from '../../../components/master-page-table-header/master-page-table-header.components';
import { useDispatch } from 'react-redux';
import { navigationActionCreators } from '../../../../logic/store/navigation/navigation.actions';
import { ColumnProps } from 'antd/lib/table';
import { SsoCompanyAdmin } from '../../../../api-models/api-models';
import { translate, translationKeys } from '../../../../logic/translations/translations.service';
import { Button } from 'antd';
import { useConfirmActionModal } from '../../../components/confirm-modal/confirm-modal';
import { ssoCompanyAdminsActionCreators } from '../../../../logic/store/sso-company-admins/sso-company-admins.actions';

export interface SsoCompanyAdminsPageOwnProps {
  columnsFields: SsoCompanyAdminsFormFieldName[];
  tableName: TableName;
  buttons: TableButton[];
}

export const SsoCompanyAdminsTemplate: React.FC<SsoCompanyAdminsPageOwnProps> = ({
  columnsFields,
  tableName,
  buttons,
}) => {
  const dispatch = useDispatch();

  const handleDeleteSsoCompanyAdmin = (ssoCompanyAdmin: SsoCompanyAdmin | undefined) => {
    if (ssoCompanyAdmin && ssoCompanyAdmin.id) {
      dispatch(ssoCompanyAdminsActionCreators.deleteSingle(ssoCompanyAdmin.id));
    }
  };

  const deleteCompanyModal = useConfirmActionModal(
    translate(translationKeys.modalDialog.deleteSsoAdmin),
    translate(translationKeys.modalDialog.areYouSureYouWantToRemoveThisSsoCompanyAdmin),
    handleDeleteSsoCompanyAdmin,
    ssoCompanyAdminsActionCreators.deleteSingle.name
  );

  const actionsColumn = (): ColumnProps<SsoCompanyAdmin> => ({
    title: translate(translationKeys.forms.fields.actions),
    dataIndex: 'actions',
    key: 'actions',
    render: (_, row) => (
      <span>
        <Button type={'link'} onClick={deleteCompanyModal.tryToPerformAction(row)}>
          {translate(translationKeys.table.action.delete)}
        </Button>
      </span>
    ),
  });

  const getColumns = [actionsColumn, ...getCompanyAdminsSsoColumns(columnsFields)];

  const handleTableButton = (tableButton: TableButton) => () =>
    dispatch(navigationActionCreators.navigateTo(tableButton.route));

  return (
    <>
      <MasterPageTableHeader
        leftSection={
          <>
            {buttons.map((button, index) => (
              <TableHeaderButton type={button.type || 'primary'} key={index} onClick={handleTableButton(button)}>
                {button.text}
              </TableHeaderButton>
            ))}
          </>
        }
      />
      <MasterContent>
        <Table tableName={tableName} getColumns={getColumns} />
      </MasterContent>
      {deleteCompanyModal.render()}
    </>
  );
};
