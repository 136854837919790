import { map } from 'lodash';

export enum UserFormFieldName {
  company_id = 'company_id',
  contact_info = 'contact_info',
  email = 'email',
  is_email_verified = 'is_email_verified',
  password = 'password',
  setNewPasswordButton = 'setNewPasswordButton',
  last_active = 'last_active',
  first_name = 'first_name',
  groups = 'groups',
  groups_ids = 'groups_ids',
  id = 'id',
  last_name = 'last_name',
  permission_level = 'permission_level',
  status = 'status',

  default_location_header = 'default_location_header',
  default_location_label = 'default_location_label',

  main_alerts_block_Header = 'main_alerts_block_Header',
  main1_alert_type_id = 'main1_alert_type_id',
  main2_alert_type_id = 'main2_alert_type_id',

  hardware_devices_block_Header = 'hardware_devices_block_Header',
  if_button_1_pressed_header = 'if_button_1_pressed_header',
  if_button_2_pressed_header = 'if_button_2_pressed_header',
  hb1_alert_type_id = 'hb1_alert_type_id',
  hb2_alert_type_id = 'hb2_alert_type_id',
  hb1_message = 'hb1_message',
  hb2_message = 'hb2_message',
}

export enum ContactInfoFieldName {
  address = 'address',
  sms_phone_country_code = 'sms_phone_country_code',
  sms_phone = 'sms_phone',
  city = 'city',
  department = 'department',
  voice_call_phone_country_code = 'voice_call_phone_country_code',
  voice_call_phone = 'voice_call_phone',
  state = 'state',
  zip_code = 'zip_code',
}

export enum LocationTagSection {
  header = 'header',
  section = 'location_tag',
}

export enum LocationTagFieldName {
  location_id = 'location_id',
  tag_name = 'tag_name',
}

export const contactInfoFieldNames = map(ContactInfoFieldName, (value) => value);
