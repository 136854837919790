import { Alert, Spin } from 'antd';
import { Circle, GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { GeoPosition, MapProps } from './GoogleMap.types';
import { translate, translationKeys } from '../../logic/translations/translations.service';

import React from 'react';

const DEFAULT_CONTAINER_STYLE = {
  width: '100%',
  height: '350px',
};

const DEFAULT_MAP_CENTER = {
  lat: 33.441085,
  lng: -86.832229,
};

const GOOGLE_MAP_ZOOM = 12;

export const CustomGoogleMap = ({
  markerPosition,
  mapCenterPosition,
  withMarkerRadius,
  withMarker,
  apiKey,
  onMarkerChange,
  radiusRange = 1500,
  mapStyles,
  mapOptions,
}: MapProps) => {
  const { isLoaded, loadError } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: apiKey || '',
  });
  const [marker, setMarker] = React.useState<GeoPosition | null>(null);
  // Radius is a value expressed in meters.
  const [radius, setRadius] = React.useState(radiusRange);

  const mapCenter = mapCenterPosition || DEFAULT_MAP_CENTER;

  const handleMarkerSet = (e: any) => {
    setMarker({
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    });

    onMarkerChange &&
      onMarkerChange({
        lat: e.latLng.lat(),
        lng: e.latLng.lng(),
      });
  };

  React.useEffect(() => {
    if (markerPosition) {
      setMarker({
        lat: markerPosition?.lat,
        lng: markerPosition?.lng,
      });
    }
  }, [markerPosition]);

  React.useEffect(() => {
    setRadius(radiusRange);
  }, [radiusRange]);

  if (loadError) {
    return <Alert message={translate(translationKeys.googleMap.alert)} type={'error'} />;
  }

  if (!isLoaded) {
    return <Spin tip={translate(translationKeys.messages.loading)} />;
  }

  return (
    <GoogleMap
      mapContainerStyle={{ ...DEFAULT_CONTAINER_STYLE, ...mapStyles }}
      center={mapCenter}
      zoom={GOOGLE_MAP_ZOOM}
      options={{
        disableDefaultUI: true,
        zoomControl: true,
        fullscreenControl: true,
        disableDoubleClickZoom: true,
        ...mapOptions,
      }}
      onClick={handleMarkerSet}
    >
      {withMarker && marker ? <Marker key={`${marker.lat}-${marker.lng}`} position={marker} /> : null}

      {withMarkerRadius && marker ? (
        <Circle
          center={marker}
          radius={radius}
          options={{
            clickable: false,
            draggable: false,
            editable: false,
            strokeColor: '#F14E4E',
            fillColor: '#FF9292',
          }}
        />
      ) : null}
    </GoogleMap>
  );
};
