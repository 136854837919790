import { ActionsUnion, createAction } from '../../redux/action-creator';
import { MapUserToFormArgumentsType, UserForm } from '../../../pages/users/pages/user-form-template/user-form.form';
import { Analytics, SupportContactMapperType, User, UserBulkOperation } from '../../../api-models/api-models';
import { ImportUsersForm } from '../../../pages/users/pages/import-users/import-users.form';
import { ImportUsersResults } from './user.model';
import { AxiosResponse } from 'axios';
import { SetNewPasswordForm } from '../../../pages/users/pages/components/set-new-password/set-new-password-form/set-new-password.form';

export enum UserActionTypes {
  RESET = 'user/RESET',
  ADD_SINGLE = 'user/ADD_SINGLE',
  UPDATE_SINGLE = 'user/UPDATE_SINGLE',
  FETCH_SINGLE_FOR_EDIT = 'user/FETCH_SINGLE_FOR_EDIT',
  INITIALIZE_SUPPORT_FORM = 'user/INITIALIZE_SUPPORT_FORM',
  SUBMIT_SUPPORT_FORM = 'user/SUBMIT_SUPPORT_FORM',
  IMPORT_USERS = 'user/IMPORT_USERS',
  SET_IMPORT_USERS_RESULTS = 'user/SET_IMPORT_USERS_RESULTS',
  FETCH_ANALYTICS = 'user/FETCH_ANALYTICS',
  SET_ANALYTICS = 'user/SET_ANALYTICS',
  SET_PHOTO = 'user/SET_PHOTO',
  DELETE_PHOTO = 'user/DELETE_PHOTO',
  DOWNLOAD_CSV = 'user/DOWNLOAD_CSV',
  PERFORM_USERS_BULK_OPERATION = 'user/PERFORM_USERS_BULK_OPERATION',
  SET_NEW_PASSWORD = 'user/SET_NEW_PASSWORD',
  WELCOME_POPUP_DONE = 'user/WELCOME_POPUP_DONE',
}

export const userActionCreators = {
  reset: () => createAction(UserActionTypes.RESET),
  addSingle: (formName: string, formValues: UserForm, successMessage: string, successRedirectRoute: string) =>
    createAction(UserActionTypes.ADD_SINGLE, {
      formName,
      formValues,
      successMessage,
      successRedirectRoute,
    }),
  updateSingle: (
    formName: string,
    formValues: UserForm,
    successMessage: string | undefined,
    successRedirectRoute: string | undefined
  ) =>
    createAction(UserActionTypes.UPDATE_SINGLE, {
      formName,
      formValues,
      successMessage,
      successRedirectRoute,
    }),
  fetchSingleForEdit: (id: string | number, formName: string, mapper: (data: MapUserToFormArgumentsType) => UserForm) =>
    createAction(UserActionTypes.FETCH_SINGLE_FOR_EDIT, { id, formName, mapper }),
  initializeSupportForm: (formName: string, mapper: (data: User) => SupportContactMapperType) =>
    createAction(UserActionTypes.INITIALIZE_SUPPORT_FORM, { formName, mapper }),
  submitSupportForm: (formName: string, mapper: (data: User) => SupportContactMapperType) =>
    createAction(UserActionTypes.SUBMIT_SUPPORT_FORM, { formName, mapper }),
  importUsers: (formName: string, formValues: ImportUsersForm) =>
    createAction(UserActionTypes.IMPORT_USERS, { formName, formValues }),
  setImportUsersResult: (importUsersResult: ImportUsersResults | undefined) =>
    createAction(UserActionTypes.SET_IMPORT_USERS_RESULTS, { importUsersResult }),
  fetchAnalytics: () => createAction(UserActionTypes.FETCH_ANALYTICS, {}),
  setAnalytics: (response: AxiosResponse<Analytics>) => createAction(UserActionTypes.SET_ANALYTICS, { response }),
  setPhoto: (id: string, photo: File | undefined) => createAction(UserActionTypes.SET_PHOTO, { id, photo }),
  deletePhoto: (id: string) => createAction(UserActionTypes.DELETE_PHOTO, { id }),
  downloadCsv: () => createAction(UserActionTypes.DOWNLOAD_CSV),
  performUsersBulkOperation: (users: (User | undefined)[] | undefined, bulkOperation: UserBulkOperation) =>
    createAction(UserActionTypes.PERFORM_USERS_BULK_OPERATION, { users, bulkOperation }),
  setNewPassword: (userId: number | undefined, userName: string, formName: string, formValues: SetNewPasswordForm) =>
    createAction(UserActionTypes.SET_NEW_PASSWORD, { userId, userName, formName, formValues }),
  setWelcomePopupDone: (userId: number | undefined) => createAction(UserActionTypes.WELCOME_POPUP_DONE, { userId }),
};

export type UserActions = ActionsUnion<typeof userActionCreators>;
