import React, { useCallback, useEffect, useMemo } from 'react';
import {
  formBlockHeaderLayout,
  formButtonLayout,
  formItemLayout,
} from '../../../components/form-container/form-container';
import { Field, FormSection } from 'redux-form';
import {
  ContactInfoFieldName,
  contactInfoFieldNames,
  LocationTagSection,
  UserFormFieldName,
} from './user-form-field-name';
import { translate, translationKeys } from '../../../../logic/translations/translations.service';
import { STATE_CODE_MAX_LENGTH, trimValue } from '../../../../logic/formaters/formaters';
import { FormSelectInput } from '../../../components/form-inputs/form-select-input/form-select-input';
import { getAllRoleSelectOptions, RoleSelectOption } from '../../../authentication/models/role/role.select-options';
import { useDispatch, useSelector } from 'react-redux';
import { getInProgress } from '../../../../logic/store/process-tracker/process-tracker.selectors';
import { getAuthenticatedUser } from '../../../../logic/store/authentication/authentication.selectors';
import { getListData } from '../../../../logic/store/lists/lists.selectors';
import { getFetchListTrackName, ListName, listsActionCreators } from '../../../../logic/store/lists/lists.action';
import { UserForm } from './user-form.form';
import { AlertType, AutocompleteClass } from '../../../../api-models/api-models';
import Title from 'antd/lib/typography/Title';
import { Form, Typography } from 'antd';
import { getDefaultSelectOptions } from '../../../components/form-inputs/select-option/select-option';
import { FormFieldPhone } from '../../../components/form-fields/form-field-phone';
import { FormAutocompleteTextInput } from '../../../components/form-inputs/form-autocomplete-text-input/form-autocomplete-text-input';
import { getAutocomplete } from '../../../../logic/store/autocomplete/autocomplete.selectors';
import { SwitchChangeEventHandler } from 'antd/lib/switch';
import { FormSwitchInput } from '../../../components/form-inputs/form-switch-input/form-switch-input';
import { FormTextInput } from '../../../components/form-inputs/form-text-input/form-text-input';
import { SetPasswordButton } from '../components/set-new-password/set-new-password-button/set-new-password-button';
import LocationTagFields from '../components/location-tag-fields/location-tag-fields';

const { Text } = Typography;

interface UserFormProps {
  onPressEnter?: any;
  inProgress?: boolean;
  roleFieldOptions?: RoleSelectOption[];
  fieldsToDisplay: Array<UserFormFieldName | ContactInfoFieldName | LocationTagSection>;
  disabledFields: Array<UserFormFieldName | ContactInfoFieldName | LocationTagSection>;
  disabledForHisself?: Array<UserFormFieldName | ContactInfoFieldName | LocationTagSection>;
  requiredFields: Array<UserFormFieldName | ContactInfoFieldName | LocationTagSection>;
  id?: number;
  formValues?: UserForm;
}

export const UserFormFields: React.FC<UserFormProps> = ({
  onPressEnter,
  inProgress,
  roleFieldOptions,
  fieldsToDisplay,
  disabledFields,
  disabledForHisself,
  requiredFields,
  id,
  formValues,
}) => {
  const dispatch = useDispatch();
  const authenticatedUserId = useSelector(getAuthenticatedUser)?.id;
  const { id: userId, first_name, last_name, is_email_verified } = formValues || {};
  const userName = `${first_name} ${last_name}`;
  const shouldSeeSetNewPassword = is_email_verified === false;

  useEffect(() => {
    const containsField = (field: UserFormFieldName | ContactInfoFieldName) => fieldsToDisplay.indexOf(field) >= 0;
    if (containsField(UserFormFieldName.company_id)) {
      dispatch(listsActionCreators.fetchList(ListName.company));
    }
    if (containsField(UserFormFieldName.groups_ids)) {
      dispatch(listsActionCreators.fetchList(ListName.group));
    }
    if (containsField(UserFormFieldName.hb1_alert_type_id) || containsField(UserFormFieldName.hb2_alert_type_id)) {
      dispatch(listsActionCreators.fetchList(ListName.alert));
    }
  }, [dispatch, fieldsToDisplay]);

  const getDisabledFields = useCallback(
    () => [...disabledFields, ...((id === authenticatedUserId && disabledForHisself) || [])],
    [disabledFields, disabledForHisself, id, authenticatedUserId]
  );

  const isDisabled = useCallback(
    (fieldName: UserFormFieldName | ContactInfoFieldName | LocationTagSection) => {
      switch (fieldName) {
        case UserFormFieldName.password:
          return is_email_verified;
        default:
          return getDisabledFields().indexOf(fieldName) >= 0 || inProgress;
      }
    },
    [getDisabledFields, is_email_verified, inProgress]
  );

  const isRequired = useCallback(
    (fieldName: UserFormFieldName | ContactInfoFieldName | LocationTagSection) => {
      switch (fieldName) {
        case UserFormFieldName.password:
          return !is_email_verified;
        default:
          return requiredFields.indexOf(fieldName) >= 0;
      }
    },
    [requiredFields, is_email_verified]
  );

  const commonAttributes = useCallback(
    (fieldName: UserFormFieldName | ContactInfoFieldName | LocationTagSection) => ({
      ...formItemLayout,
      disabled: isDisabled(fieldName),
      onPressEnter,
      required: isRequired(fieldName),
    }),
    [isDisabled, isRequired, onPressEnter]
  );

  const filterAlertsBelongsToUserGroup = useCallback(
    (alerts: AlertType[]) =>
      alerts.filter((alert) => (formValues?.groups_ids || []).includes(alert.sender_group_id || -1)),
    [formValues]
  );

  const companies = useSelector(getListData(ListName.company)) || [];
  const companiesOptions = getDefaultSelectOptions(companies);
  const fetchCompaniesInProgress = useSelector(getInProgress(getFetchListTrackName(ListName.company)));

  const groups = useSelector(getListData(ListName.group)) || [];
  const groupsOptions = getDefaultSelectOptions(groups);
  const fetchGroupsInProgress = useSelector(getInProgress(getFetchListTrackName(ListName.group)));

  const alerts = useSelector(getListData<AlertType>(ListName.alert)) || [];
  const filteredAlerts = filterAlertsBelongsToUserGroup(alerts);
  const alertsOptions = getDefaultSelectOptions(filteredAlerts);
  const fetchAlertsInProgress = useSelector(getInProgress(getFetchListTrackName(ListName.alert)));

  const translateUserFieldLabel = useCallback(
    (fieldName: UserFormFieldName) => translate(translationKeys.forms.fields.user[fieldName]),
    []
  );

  const translateContactInfoFieldLabel = useCallback(
    (fieldName: ContactInfoFieldName) => translate(translationKeys.forms.fields.contact_info[fieldName]),
    []
  );

  const autocomplete = useSelector(getAutocomplete(AutocompleteClass.User));

  const getUserTextField = useCallback(
    (fieldName: UserFormFieldName, maxLength?: number) => (
      <Field
        name={fieldName}
        component={FormAutocompleteTextInput}
        label={translateUserFieldLabel(fieldName)}
        textOptions={autocomplete?.[fieldName]}
        maxLength={maxLength}
        onBlurFormater={trimValue}
        {...commonAttributes(fieldName)}
      />
    ),
    [translateUserFieldLabel, autocomplete, commonAttributes]
  );

  const getUserPasswordField = useCallback(
    (fieldName: UserFormFieldName) => (
      <Field
        name={fieldName}
        component={FormTextInput}
        label={translateUserFieldLabel(fieldName)}
        isPassword
        onBlurFormater={trimValue}
        {...commonAttributes(fieldName)}
      />
    ),
    [translateUserFieldLabel, commonAttributes]
  );

  const getContactInfoTextField = useCallback(
    (fieldName: ContactInfoFieldName, maxLength?: number) => (
      <Field
        name={fieldName}
        component={FormAutocompleteTextInput}
        label={translateContactInfoFieldLabel(fieldName)}
        maxLength={maxLength}
        onBlurFormater={trimValue}
        textOptions={autocomplete?.[fieldName]}
        {...commonAttributes(fieldName)}
      />
    ),
    [translateContactInfoFieldLabel, autocomplete, commonAttributes]
  );

  const getSwitchField = useCallback(
    (fieldName: UserFormFieldName, toolTipMessage?: string, onToggle?: SwitchChangeEventHandler) => (
      <Field
        name={fieldName}
        component={FormSwitchInput}
        onToggle={onToggle}
        toolTipMessage={toolTipMessage}
        label={translateUserFieldLabel(fieldName)}
        {...commonAttributes(fieldName)}
      />
    ),
    [commonAttributes, translateUserFieldLabel]
  );

  const fields = useMemo(
    () => ({
      [UserFormFieldName.company_id]: (
        <Field
          name={UserFormFieldName.company_id}
          component={FormSelectInput}
          label={translateUserFieldLabel(UserFormFieldName.company_id)}
          options={companiesOptions}
          showSearch
          filterOption={true}
          optionFilterProp={'label'}
          loading={fetchCompaniesInProgress}
          {...commonAttributes(UserFormFieldName.company_id)}
          disabled={isDisabled(UserFormFieldName.company_id) || fetchCompaniesInProgress}
        />
      ),
      [UserFormFieldName.contact_info]: null,
      [UserFormFieldName.email]: getUserTextField(UserFormFieldName.email),
      [UserFormFieldName.is_email_verified]: getSwitchField(
        UserFormFieldName.is_email_verified,
        translate(translationKeys.forms.fields.user.isEmailVerifiedTooltip)
      ),
      [UserFormFieldName.setNewPasswordButton]: shouldSeeSetNewPassword ? (
        <Form.Item
          label={translateUserFieldLabel(UserFormFieldName.password)}
          labelCol={formItemLayout.labelCol}
          wrapperCol={formItemLayout.wrapperCol}
        >
          <SetPasswordButton userId={userId} userName={userName} disabled={inProgress} />
        </Form.Item>
      ) : null,
      [UserFormFieldName.password]: getUserPasswordField(UserFormFieldName.password),
      [UserFormFieldName.first_name]: getUserTextField(UserFormFieldName.first_name),
      [UserFormFieldName.groups]: null,
      [UserFormFieldName.groups_ids]: (
        <Field
          name={UserFormFieldName.groups_ids}
          component={FormSelectInput}
          label={translateUserFieldLabel(UserFormFieldName.groups_ids)}
          options={groupsOptions}
          mode={'multiple'}
          showSearch
          filterOption={true}
          optionFilterProp={'label'}
          loading={fetchGroupsInProgress}
          {...commonAttributes(UserFormFieldName.groups_ids)}
          disabled={isDisabled(UserFormFieldName.groups_ids) || fetchGroupsInProgress}
        />
      ),
      [UserFormFieldName.id]: null,
      [UserFormFieldName.last_name]: getUserTextField(UserFormFieldName.last_name),
      [UserFormFieldName.permission_level]: (
        <Field
          name={UserFormFieldName.permission_level}
          component={FormSelectInput}
          label={translateUserFieldLabel(UserFormFieldName.permission_level)}
          options={roleFieldOptions || getAllRoleSelectOptions()}
          {...commonAttributes(UserFormFieldName.permission_level)}
        />
      ),
      [UserFormFieldName.default_location_header]: (
        <Form.Item {...formBlockHeaderLayout}>
          <Title className={'text-centered'} level={4}>
            {translate(translationKeys.forms.fields.user.default_location_header)}
          </Title>
        </Form.Item>
      ),
      [UserFormFieldName.default_location_label]: (
        <Field
          name={UserFormFieldName.default_location_label}
          component={FormAutocompleteTextInput}
          label={translateUserFieldLabel(UserFormFieldName.default_location_label)}
          textOptions={autocomplete?.[UserFormFieldName.default_location_label]}
          onBlurFormater={trimValue}
          {...commonAttributes(UserFormFieldName.default_location_label)}
        />
      ),
      [UserFormFieldName.status]: null,
      [UserFormFieldName.main_alerts_block_Header]: (
        <Form.Item {...formBlockHeaderLayout}>
          <Title className={'text-centered'} level={4}>
            {translate(translationKeys.forms.fields.user.main_alerts_block_Title)}
          </Title>
          <div className={'text-justified'}>
            {translate(translationKeys.forms.fields.user.main_alerts_block_Description)}
          </div>
        </Form.Item>
      ),
      [LocationTagSection.header]: (
        <>
          <Form.Item {...formBlockHeaderLayout}>
            <Title className={'text-centered'} level={4}>
              {translate(translationKeys.forms.fields.user[LocationTagSection.header])}
            </Title>
          </Form.Item>
        </>
      ),
      [LocationTagSection.section]: <LocationTagFields />,
      [UserFormFieldName.main1_alert_type_id]: (
        <Field
          name={UserFormFieldName.main1_alert_type_id}
          component={FormSelectInput}
          label={translateUserFieldLabel(UserFormFieldName.main1_alert_type_id)}
          options={alertsOptions}
          showSearch
          allowClear
          loading={fetchAlertsInProgress}
          {...commonAttributes(UserFormFieldName.main1_alert_type_id)}
        />
      ),
      [UserFormFieldName.main2_alert_type_id]: (
        <Field
          name={UserFormFieldName.main2_alert_type_id}
          component={FormSelectInput}
          label={translateUserFieldLabel(UserFormFieldName.main2_alert_type_id)}
          options={alertsOptions}
          showSearch
          allowClear
          loading={fetchAlertsInProgress}
          {...commonAttributes(UserFormFieldName.main2_alert_type_id)}
        />
      ),
      [UserFormFieldName.hardware_devices_block_Header]: (
        <Form.Item {...formBlockHeaderLayout}>
          <Title className={'text-centered'} level={4}>
            {translate(translationKeys.forms.fields.user.hardware_devices_block_Title)}
          </Title>
          <div className={'text-justified'}>
            {translate(translationKeys.forms.fields.user.hardware_devices_block_Description)}
          </div>
        </Form.Item>
      ),
      [UserFormFieldName.if_button_1_pressed_header]: (
        <Form.Item {...formButtonLayout}>
          <Text strong>
            {translate(translationKeys.forms.fields.user[UserFormFieldName.if_button_1_pressed_header])}
          </Text>
        </Form.Item>
      ),
      [UserFormFieldName.if_button_2_pressed_header]: (
        <Form.Item {...formButtonLayout}>
          <Text strong>
            {translate(translationKeys.forms.fields.user[UserFormFieldName.if_button_2_pressed_header])}
          </Text>
        </Form.Item>
      ),
      [UserFormFieldName.hb1_alert_type_id]: (
        <Field
          name={UserFormFieldName.hb1_alert_type_id}
          component={FormSelectInput}
          label={translateUserFieldLabel(UserFormFieldName.hb1_alert_type_id)}
          options={alertsOptions}
          showSearch
          allowClear
          loading={fetchAlertsInProgress}
          {...commonAttributes(UserFormFieldName.hb1_alert_type_id)}
        />
      ),
      [UserFormFieldName.hb2_alert_type_id]: (
        <Field
          name={UserFormFieldName.hb2_alert_type_id}
          component={FormSelectInput}
          label={translateUserFieldLabel(UserFormFieldName.hb2_alert_type_id)}
          options={alertsOptions}
          showSearch
          allowClear
          loading={fetchAlertsInProgress}
          {...commonAttributes(UserFormFieldName.hb2_alert_type_id)}
        />
      ),
      [UserFormFieldName.hb1_message]: getUserTextField(UserFormFieldName.hb1_message),
      [UserFormFieldName.hb2_message]: getUserTextField(UserFormFieldName.hb2_message),
      [UserFormFieldName.last_active]: null,
      [ContactInfoFieldName.address]: getContactInfoTextField(ContactInfoFieldName.address),
      [ContactInfoFieldName.sms_phone_country_code]: null,
      [ContactInfoFieldName.sms_phone]: (
        <FormFieldPhone
          name={ContactInfoFieldName.sms_phone}
          countryCodeFieldName={ContactInfoFieldName.sms_phone_country_code}
          countryCode={formValues?.contact_info?.sms_phone_country_code}
          label={translateContactInfoFieldLabel(ContactInfoFieldName.sms_phone)}
          inProgress={inProgress}
          commonAttributes={commonAttributes(ContactInfoFieldName.sms_phone)}
        />
      ),
      [ContactInfoFieldName.city]: getContactInfoTextField(ContactInfoFieldName.city),
      [ContactInfoFieldName.department]: getContactInfoTextField(ContactInfoFieldName.department),
      [ContactInfoFieldName.voice_call_phone_country_code]: null,
      [ContactInfoFieldName.voice_call_phone]: (
        <FormFieldPhone
          name={ContactInfoFieldName.voice_call_phone}
          countryCodeFieldName={ContactInfoFieldName.voice_call_phone_country_code}
          countryCode={formValues?.contact_info?.voice_call_phone_country_code}
          label={translateContactInfoFieldLabel(ContactInfoFieldName.voice_call_phone)}
          inProgress={inProgress}
          commonAttributes={commonAttributes(ContactInfoFieldName.voice_call_phone)}
        />
      ),
      [ContactInfoFieldName.state]: getContactInfoTextField(ContactInfoFieldName.state, STATE_CODE_MAX_LENGTH),
      [ContactInfoFieldName.zip_code]: getContactInfoTextField(ContactInfoFieldName.zip_code),
    }),
    [
      translateUserFieldLabel,
      companiesOptions,
      fetchCompaniesInProgress,
      commonAttributes,
      isDisabled,
      getUserTextField,
      getSwitchField,
      shouldSeeSetNewPassword,
      userId,
      userName,
      inProgress,
      getUserPasswordField,
      groupsOptions,
      fetchGroupsInProgress,
      roleFieldOptions,
      autocomplete,
      alertsOptions,
      fetchAlertsInProgress,
      getContactInfoTextField,
      formValues?.contact_info?.sms_phone_country_code,
      formValues?.contact_info?.voice_call_phone_country_code,
      translateContactInfoFieldLabel,
    ]
  );

  const rendefField = useCallback(
    (fieldName: UserFormFieldName | ContactInfoFieldName | LocationTagSection) => (
      <div key={fieldName}>{fields[fieldName]}</div>
    ),
    [fields]
  );

  return (
    <>
      {fieldsToDisplay.map((fieldName) => {
        const isContactInfoFieldName = contactInfoFieldNames.indexOf(fieldName as any) >= 0;

        return isContactInfoFieldName ? (
          <FormSection key={fieldName} name={UserFormFieldName.contact_info}>
            {' '}
            {rendefField(fieldName)}{' '}
          </FormSection>
        ) : (
          rendefField(fieldName)
        );
      })}
    </>
  );
};
