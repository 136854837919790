import React from 'react';
import { store, persistor } from './app.store';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { Routes } from '../logic/routes/routes';
import { history } from '../logic/routes/history';
import { PersistGate } from 'redux-persist/integration/react';
import { LogHandler } from '../pages/components/log-handler/log-handler';
import { initializeCognito } from '../logic/cognito/cognito';
import { AppSpin } from '../components/app-spin/app-spin';
import { CacheCleanProvider } from '../logic/cache-clean-provider/cache-clean-provider';
import { TrackingScript } from '../components/tracking-script/tracking-script';
import { initializeHotjar } from '../logic/hotjar/hotjar';

initializeHotjar();
initializeCognito();

export const App: React.FC = () => {
  return (
    <>
      <TrackingScript />
      <Provider store={store}>
        <CacheCleanProvider>
          <PersistGate loading={<AppSpin />} persistor={persistor}>
            <Router history={history}>
              <Routes />
            </Router>
            <LogHandler />
          </PersistGate>
        </CacheCleanProvider>
      </Provider>
    </>
  );
};
