import { compose } from 'redux';
import { wrapComponentsWithProps } from '../../../logic/hoc/wrap-components-with-props.hoc';
import {
  SsoGroupMappingFormPage,
  SsoGroupMappingFormPageOwnProps,
} from './sso-group-mapping-form-template/sso-group-mapping-form.page';
import { reduxForm } from 'redux-form';
import { translate, translationKeys } from '../../../logic/translations/translations.service';
import {
  SSO_GROUP_MAPPING_FORM,
  validateSsoGroupMappingForm,
} from './sso-group-mapping-form-template/sso-group-mapping-form.form';
import { SsoGroupMappingFormFieldName } from './sso-group-mapping-form-template/sso-group-mapping-form-field-name';
import { ssoGroupMappingActionCreators } from '../../../logic/store/sso-group-mapping/sso-group-mapping.actions';

export const SsoGroupMappingAddPageCreator = () => {
  return compose(
    wrapComponentsWithProps<SsoGroupMappingFormPageOwnProps>({
      submitButtonText: translate(translationKeys.buttons.addSsoPermissionMapping),
      submitFormAction: ssoGroupMappingActionCreators.createSingle,
      formName: SSO_GROUP_MAPPING_FORM,
      fieldsToDisplay: [
        SsoGroupMappingFormFieldName.sso_group_name,
        SsoGroupMappingFormFieldName.corresponding_permission_level,
      ],
      disabledFields: [],
    }),
    reduxForm({
      form: SSO_GROUP_MAPPING_FORM,
      validate: validateSsoGroupMappingForm,
    })
  )(SsoGroupMappingFormPage) as React.FC;
};
