import { LocationTagForm } from '../../pages/users/pages/user-form-template/user-form.form';

const hasLocationIdAndTagNameNotEmpty = (locationId: string) => !!locationId;

export const getLocationTagToUpdate = (locationTagForm: LocationTagForm[] = []): LocationTagForm[] => {
  return locationTagForm.filter(({id, location_id, tag_name}) => !!id && hasLocationIdAndTagNameNotEmpty(location_id));
}

export const getLocationTagToAdd = (locationTagForm: LocationTagForm[] = []): LocationTagForm[] => {
  return locationTagForm.filter(({id, location_id, tag_name}) => !id && hasLocationIdAndTagNameNotEmpty(location_id));
}

export const getLocationTagToDelete = (locationTagForm: LocationTagForm[] = [], savedLocationTagForm: LocationTagForm[] = []) => {
  const locationTagFormSet = new Set(locationTagForm.map(locationTag => locationTag.id));
  return savedLocationTagForm.filter((savedLocationTag) => !locationTagFormSet.has(savedLocationTag.id))
};
