import React from 'react';
import { map } from 'lodash';
import { env } from '../../../logic/env/env';
import { ResourceType, Resource, resourceContents, ResourceContent } from './resource.const';
import { Button } from 'antd';
import { translate, translationKeys } from '../../../logic/translations/translations.service';
import { getResourceUrl } from './resource-link.logic';
import './resource-link.styles.scss';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { navigationActionCreators } from '../../../logic/store/navigation/navigation.actions';
import { AUTHENTICATED_ROUTES } from '../../../logic/routes/routes.const';
import { ButtonLinkAddons } from './resource.components';

interface ResourceLinksProps {
  resources: Resource[];
}

export const ResourceLinks: React.FC<ResourceLinksProps> = ({ resources }) => (
  <>
    {map(resources, (resource, index) => (
      <ResourceLink
        key={index}
        content={resourceContents[resource]}
        text={translate(translationKeys.resources[resource])}
        baseUrl={env.REACT_APP_DOWNLOADS_FOLDER_URL}
      />
    ))}
  </>
);

interface ResourceLinkProps {
  content: ResourceContent;
  text: string | undefined;
  baseUrl?: string;
  textClassName?: string;
  noPaddingLeft?: boolean;
  action?: () => void;
  tooltipMessage?: string;
  previous?: ResourceContent;
  notes?: string;
}

export const ResourceLink: React.FC<ResourceLinkProps> = ({
  baseUrl,
  content,
  text,
  textClassName = '',
  noPaddingLeft,
  tooltipMessage,
  previous,
  action,
  notes,
}) => {
  const dispatch = useDispatch();

  const onSupportLinkClick = () => {
    if (content.anchor) {
      dispatch(navigationActionCreators.navigateTo(AUTHENTICATED_ROUTES.Support, `#${content.anchor}`));
    }
  };

  return (
    <div key={content.url}>
      {content.resourceType === ResourceType.Link && content.url && (
        <ButtonLink href={getResourceUrl(baseUrl, content.url)}>
          <div className={`${textClassName}`}>{text}</div>
        </ButtonLink>
      )}
      {content.resourceType === ResourceType.CommingSoon && (
        <ButtonLink disabled>
          <div className={`${textClassName}`}>{`${text} - ${translate(translationKeys.resources.comingSoon)}`}</div>
        </ButtonLink>
      )}
      {content.resourceType === ResourceType.Download && content.url && (
        <>
          <ButtonLink
            href={getResourceUrl(baseUrl, content.url)}
            download={getResourceUrl(baseUrl, content.url)}
            noPaddingleft
            noPaddingRight
          >
            <div className={`${textClassName}`}>{text}</div>
          </ButtonLink>
          <ButtonLinkAddons
            baseUrl={baseUrl}
            tooltipMessage={tooltipMessage}
            previous={previous}
            previousVersionTitle={translate(translationKeys.resources.previousVersion)}
            notes={notes}
          />
        </>
      )}
      {content.resourceType === ResourceType.OpenInNewTab && content.url && (
        <>
          <ButtonLink
            href={getResourceUrl(baseUrl, content.url)}
            noPaddingleft
            style={{ paddingRight: 5 }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className={`${textClassName}`}>{text}</div>
          </ButtonLink>
          <ButtonLinkAddons
            baseUrl={baseUrl}
            tooltipMessage={tooltipMessage}
            previous={previous}
            previousVersionTitle={translate(translationKeys.resources.previousVersion)}
            notes={notes}
          />
        </>
      )}
      {content.resourceType === ResourceType.CustomAction && action && (
        <ButtonLink noPaddingleft={noPaddingLeft} fontStyle="italic" fontSize={12} onClick={action}>
          <div className={`${textClassName}`}>{text}</div>
        </ButtonLink>
      )}
      {content.resourceType === ResourceType.SupportLink && (
        <ButtonLink noPaddingleft={noPaddingLeft} onClick={onSupportLinkClick}>
          <div className={`${textClassName}`}>{text}</div>
        </ButtonLink>
      )}
    </div>
  );
};

export const ResourcesRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ButtonLink = styled(Button).attrs({ type: 'link', size: 'small' })<{
  noPaddingleft?: boolean;
  noPaddingRight?: boolean;
  fontStyle?: 'italic';
  fontSize?: number;
}>`
  ${({ noPaddingleft }) => (noPaddingleft ? 'padding-left: 0' : '')};
  ${({ noPaddingRight }) => (noPaddingRight ? 'padding-right: 0' : '')};
  ${({ fontStyle }) => (fontStyle ? `font-style: ${fontStyle}` : '')};
  ${({ fontSize }) => (fontSize ? `font-size: ${fontSize}px` : '')};
`;
