import { AUTHENTICATED_ROUTES } from '../../../logic/routes/routes.const';
import { UsersTemplatePage, UsersPageOwnProps } from './users-template/users-template.page';
import { translate, translationKeys } from '../../../logic/translations/translations.service';
import { UserFormFieldName, ContactInfoFieldName } from './user-form-template/user-form-field-name';
import { Role } from '../../../api-models/api-models';
import { wrapComponentsWithProps } from '../../../logic/hoc/wrap-components-with-props.hoc';

export const SuperAdminCompanyAdminUsersPage = wrapComponentsWithProps<UsersPageOwnProps>({
  editUserRoute: () => AUTHENTICATED_ROUTES.SuperAdminEditCompanyAdmin,
  shoudShowActions: user => [Role.SuperAdmin, Role.CompanyAdmin].indexOf(user?.permission_level || -1) >= 0,
  buttons: [
    {
      text: translate(translationKeys.buttons.addCompanyAdmin),
      route: AUTHENTICATED_ROUTES.SuperAdminAddCompanyAdmin,
    },
  ],
  columnsFields: [
    UserFormFieldName.first_name,
    UserFormFieldName.last_name,
    UserFormFieldName.email,
    UserFormFieldName.company_id,
    ContactInfoFieldName.address,
    ContactInfoFieldName.city,

    UserFormFieldName.last_active,
    UserFormFieldName.status,
    UserFormFieldName.permission_level,
  ],
  prefilters: { permission_level: Role.CompanyAdmin },
})(UsersTemplatePage);
