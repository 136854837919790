import { parseNumber } from './number';
import { isArray, isObject } from 'lodash';

export const FILE_SIZE_POWER_BASE = 1000;

export const formatFileSize = (
  fileSizeInBytesValue: string | number | null | undefined,
  megaBytesDecimalPlaces: number = 1
) => {
  const fileSizeInBytes = parseNumber(fileSizeInBytesValue);

  if (fileSizeInBytes === undefined) {
    return undefined;
  }

  const fileSizeInKBytes = Math.round(fileSizeInBytes / FILE_SIZE_POWER_BASE);
  const fileSizeInMBytes = fileSizeInBytes / FILE_SIZE_POWER_BASE / FILE_SIZE_POWER_BASE;

  if (fileSizeInBytes < FILE_SIZE_POWER_BASE) {
    return `${fileSizeInBytes} B`;
  }

  if (fileSizeInKBytes < FILE_SIZE_POWER_BASE) {
    return `${fileSizeInKBytes} KB`;
  }

  return `${fileSizeInMBytes.toFixed(megaBytesDecimalPlaces)} MB`;
};

export const getFileName = (fileUrl: string | undefined | null): string | undefined => {
  if (isArray(fileUrl) || isObject(fileUrl) || !fileUrl || fileUrl === null) return undefined;

  return fileUrl.substring(fileUrl.lastIndexOf('/') + 1);
};
