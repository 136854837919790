export enum AnalyticColumnName {
  Field = 'field',
  Count = 'count',
}

export interface AnalyticsSection {
  [AnalyticColumnName.Field]?: string;
  [AnalyticColumnName.Count]?: number;
  companies?: string[];
}
