import { groupBy } from 'lodash';
import { StoreState } from '../../../app/app.reducers';
import { TableName } from './tables.action';

export const getTableData = <D = any>(tableName: TableName) => (state: StoreState) =>
  state.tables[tableName]?.data as D[] | undefined;

export const getTableTotalData = (tableName: TableName) => (state: StoreState) =>
  state.tables[tableName]?.query?.pagination?.total;

export const getTableUnfilteredTotalData = (tableName: TableName) => (state: StoreState) =>
  state.tables[tableName]?.unfilteredTotal;

export const getTableQuery = (tableName: TableName) => (state: StoreState) => state.tables[tableName]?.query;

export const getTableSelectedRowKeys = (tableName: TableName) => (state: StoreState) =>
  state.tables[tableName]?.selectedRowKeys;

export const getTableSelectedRowCount = (tableName: TableName) => (state: StoreState) =>
  state.tables[tableName]?.selectedRowKeys?.length || 0;

export const getTableSelectedRows = <D extends { id?: number }>(tableName: TableName) => (state: StoreState) => {
  const selectedRowKeys = getTableSelectedRowKeys(tableName)(state);
  const allRowValues = getTableData<D>(tableName)(state);
  const allRowValuePairs = groupBy(allRowValues, 'id');
  const selectedRows = selectedRowKeys?.map(key => allRowValuePairs[key]).flat();
  return selectedRows;
};
