import { isString } from 'lodash';
import { CountryCodeSelectOption } from '../../authentication/models/country-code/country-code.select-options';

export const filterPhoneOption = (inputValue: string, option: CountryCodeSelectOption) => {
  if (!inputValue || !isString(inputValue) || !option) return true;

  const searchPhrase = inputValue.toLocaleLowerCase();
  const value = isString(option.value) ? option.value.toLocaleLowerCase() : '';
  const label = option?.label?.toString().toLocaleLowerCase() || '';
  const name = option.tag?.name?.toLocaleLowerCase() || '';
  const countryCode = option.tag?.iso_code_alpha_2?.toLocaleLowerCase() || '';
  const callingCountryCode = `+${option.tag?.country_code?.toLocaleLowerCase()}` || '';

  return (
    !isString(searchPhrase) ||
    !searchPhrase ||
    value.indexOf(searchPhrase) >= 0 ||
    label.indexOf(searchPhrase) >= 0 ||
    name.indexOf(searchPhrase) >= 0 ||
    countryCode.indexOf(searchPhrase) >= 0 ||
    callingCountryCode.indexOf(searchPhrase) >= 0
  );
};
