export enum CognitoChallengeName {
  NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED',
}

export interface CognitoChallengeUser {
  challengeName?: CognitoChallengeName;
  challengeParam?: {
    userAttributes?: CognitoUserAttributes;
  };
}

export interface CognitoAuthenticatedUser {
  signInUserSession?: CognitoAuthenticatedUserSession;
  attributes?: CognitoUserAttributes;
}

interface CognitoUserAttributes {
  'custom:crs_id'?: string;
  email?: string;
  name?: string;
}

export interface CognitoAuthenticatedUserSession {
  idToken?: {
    jwtToken?: string;
    payload?: {
      auth_time?: number;
      exp?: number;
      iat?: number;
      identities?: [];
    };
  };
}
