import React from 'react';
import { WrappedFieldProps } from 'redux-form';
import { FormItemProps } from 'antd/lib/form';
import { Form, Switch } from 'antd';
import { parseBool } from '../../../../logic/utils/parse-bool';
import { SwitchChangeEventHandler } from 'antd/lib/switch';
import { LabelWithToolTip } from '../label-with-tooltip/label-with-tooltip';

interface OwnProps {
  disabled?: boolean;
  onToggle?: SwitchChangeEventHandler;
  toolTipMessage?: string;
}

type FormSwitchInputProps = WrappedFieldProps & FormItemProps & OwnProps;

export const FormSwitchInput = ({
  label,
  required,
  labelCol,
  wrapperCol,
  meta,
  input,
  disabled,
  onToggle,
  toolTipMessage,
}: FormSwitchInputProps) => {
  const errorMessage = !!meta && meta.error;
  const showError = !!meta && meta.touched && !!meta.error;
  const { value, onChange } = input;
  const handlOnChange: SwitchChangeEventHandler = (checked, event) => {
    if (onToggle) {
      onToggle(checked, event);
    }
    return onChange(checked as any);
  };
  const labelComponent = toolTipMessage ? <LabelWithToolTip label={label} toolTipMessage={toolTipMessage} /> : label;

  return (
    <Form.Item
      label={labelComponent}
      required={required}
      validateStatus={showError ? 'error' : undefined}
      help={showError ? errorMessage : undefined}
      labelCol={labelCol}
      wrapperCol={wrapperCol}
    >
      <Switch checked={parseBool(value)} onChange={handlOnChange} disabled={disabled} />
    </Form.Item>
  );
};
