import React, { useEffect } from 'react';
import { MasterPageHeader } from '../../../components/master-page-header/master-page-header';
import { Form, Button, Alert } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { MasterContent } from '../../../master-page/components/master-content/master-content';
import { InjectedFormProps, getFormValues } from 'redux-form';
import { GroupForm, groupToFormValuesMapper } from './group-form.form';
import { FormContainer, formButtonLayout } from '../../../components/form-container/form-container';
import { getInProgress } from '../../../../logic/store/process-tracker/process-tracker.selectors';
import { AnyAction } from 'redux';
import queryString from 'query-string';
import { RouteComponentProps } from 'react-router-dom';
import { groupActionCreators } from '../../../../logic/store/group/group.action';
import { GroupFormFields } from './group-form.fields';
import { translate, translationKeys } from '../../../../logic/translations/translations.service';
import { autocompleteActionCreators } from '../../../../logic/store/autocomplete/autocomplete.action';
import { AutocompleteClass } from '../../../../api-models/api-models';

export interface GroupFormPageOwnProps {
  submitButtonText: string;
  submitFormAction: (formValues: GroupForm) => AnyAction;
  formName: string;
}

interface GroupFormTemplatePageProps extends RouteComponentProps, GroupFormPageOwnProps, InjectedFormProps<GroupForm> {}

export const GroupFormPage: React.FC<GroupFormTemplatePageProps> = ({
  submitButtonText,
  submitFormAction,
  formName,
  location,
  error,
}) => {
  const dispatch = useDispatch();
  const formValues: GroupForm = useSelector(getFormValues(formName)) || {};
  const { company_id } = formValues;
  const getSingleForEditInProgress = useSelector(getInProgress(groupActionCreators.getSingleForEdit.name));
  const submitInProgress = useSelector(getInProgress(submitFormAction.name));
  const inProgress = getSingleForEditInProgress || submitInProgress;

  useEffect(() => {
    const { id } = queryString.parse(location.search);
    if (!!id) {
      dispatch(groupActionCreators.getSingleForEdit(id, formName, groupToFormValuesMapper));
    }
    if (company_id) {
      dispatch(autocompleteActionCreators.fetchAutocomplete(company_id, AutocompleteClass.Group));
    }
  }, [dispatch, formName, location.search, company_id]);

  const handleSubmitAction = () => {
    dispatch(submitFormAction(formValues));
  };

  return (
    <>
      <MasterPageHeader showBack />
      <MasterContent>
        <FormContainer inProgress={getSingleForEditInProgress}>
          <GroupFormFields inProgress={inProgress} onPressEnter={handleSubmitAction} formName={formName} />

          {error && (
            <Form.Item {...formButtonLayout}>
              <Alert message={translate(translationKeys.errors.error)} description={error} type={'error'} showIcon />
            </Form.Item>
          )}

          <Form.Item {...formButtonLayout}>
            <Button
              type={'primary'}
              className={'form-button'}
              onClick={handleSubmitAction}
              disabled={inProgress}
              loading={submitInProgress}
            >
              {submitButtonText}
            </Button>
          </Form.Item>
        </FormContainer>
      </MasterContent>
    </>
  );
};
