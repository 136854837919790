import React, { ReactNode } from 'react';
import { Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

interface LabelWithToolTipProps {
  label: ReactNode;
  toolTipMessage: string | ReactNode;
}

export const LabelWithToolTip: React.FC<LabelWithToolTipProps> = ({ label, toolTipMessage }) => (
  <span>
    {label}&nbsp;
    <Tooltip title={toolTipMessage}>
      <QuestionCircleOutlined />
    </Tooltip>
  </span>
);
