import { AutocompleteClass } from '../../../api-models/api-models';
import { AutocompleteActionTypes, AutocompleteActions } from './autocomplete.action';

export interface AutocompleteState {
  [AutocompleteClass.Alert]?: ObjectByKey<string[]>;
  [AutocompleteClass.Group]?: ObjectByKey<string[]>;
  [AutocompleteClass.User]?: ObjectByKey<string[]>;
  [AutocompleteClass.Location]?: ObjectByKey<string[]>;
}

const initialState: AutocompleteState = {};

export const autocompleteReducer = (state = initialState, action: AutocompleteActions): AutocompleteState => {
  switch (action.type) {
    case AutocompleteActionTypes.RESET:
      return initialState;
    case AutocompleteActionTypes.SET_AUTOCOMPLETE:
      return {
        ...state,
        [action.payload.autocompleteClass]: action.payload.result,
      };
    default:
      return state;
  }
};
