import {
  ProcessTrackerActionTypes,
  ProcessTrackerActions,
  ProcessStatus,
  ProcessState,
} from './process-tracker.actions';
import { StoreState } from '../../../app/app.reducers';

const initialProcessState: ProcessState = {
  status: undefined,
  response: undefined,
  error: undefined,
  progress: undefined,
};

export interface ProcessTrackerState {
  [processName: string]: ProcessState;
}

const initialState: ProcessTrackerState = {};

export const processTrackerReducer = (state = initialState, action: ProcessTrackerActions): ProcessTrackerState => {
  switch (action.type) {
    case ProcessTrackerActionTypes.RESET:
      return initialState;
    case ProcessTrackerActionTypes.PROCESS_STATUS_CHANGED:
      return {
        ...state,
        [action.payload.processName]: {
          ...initialProcessState,
          status: action.payload.status,
          response: action.payload.status === ProcessStatus.Success ? action.payload.result : undefined,
          error: action.payload.status === ProcessStatus.Failure ? action.payload.result : undefined,
        },
      };
    case ProcessTrackerActionTypes.PROCESS_PROGRESS_CHANGED:
      return {
        ...state,
        [action.payload.processName]: {
          ...state[action.payload.processName],
          progress: action.payload.progress,
        },
      };
    default:
      return state;
  }
};

export const getProcessTrackerState = (processName: string) => (state: StoreState) =>
  state.processTracker && state.processTracker[processName];
