import { SsoCompanyGroupMapping } from '../../../../api-models/api-models';
import { translationKeys } from '../../../../logic/translations/translations.service';
import { getSortableFilterableColumnCreator } from '../../../components/table/table.column';
import { SsoCompanyGroupMappingFormFieldName } from '../sso-company-group-mapping-form-template/sso-company-group-mapping-form-field-name';

const getSsoCompanyGroupMappingColumn = getSortableFilterableColumnCreator<
  SsoCompanyGroupMappingFormFieldName,
  SsoCompanyGroupMapping
>(translationKeys.forms.fields.ssoCompanyGroupMapping);

const ssoCompanyGroupMappingColumns = {
  [SsoCompanyGroupMappingFormFieldName.company]: getSsoCompanyGroupMappingColumn(
    SsoCompanyGroupMappingFormFieldName.company
  ),
  [SsoCompanyGroupMappingFormFieldName.company_name]: getSsoCompanyGroupMappingColumn(
    SsoCompanyGroupMappingFormFieldName.company_name
  ),
  [SsoCompanyGroupMappingFormFieldName.sso_group_name]: getSsoCompanyGroupMappingColumn(
    SsoCompanyGroupMappingFormFieldName.sso_group_name
  ),
  [SsoCompanyGroupMappingFormFieldName.company_group]: getSsoCompanyGroupMappingColumn(
    SsoCompanyGroupMappingFormFieldName.company_group
  ),
  [SsoCompanyGroupMappingFormFieldName.company_group_name]: getSsoCompanyGroupMappingColumn(
    SsoCompanyGroupMappingFormFieldName.company_group_name
  ),
};

export const getSsoCompanyGroupMappingColumns = (fieldNames: SsoCompanyGroupMappingFormFieldName[]) =>
  fieldNames.map((fieldName) => ssoCompanyGroupMappingColumns[fieldName]);
