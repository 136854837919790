import { RouteComponentProps } from 'react-router-dom';
import { LocationForm, LocationFormPageOwnProps } from '../../../../common/location/location.type';
import { change, getFormValues, InjectedFormProps } from 'redux-form';
import React, { useEffect } from 'react';
import { MasterPageHeader } from '../../../components/master-page-header/master-page-header';
import { MasterContent } from '../../../master-page/components/master-content/master-content';
import {
  formBlockHeaderLayout,
  formButtonLayout,
  FormContainer,
} from '../../../components/form-container/form-container';
import { Alert, Button, Form } from 'antd';
import { translate, translationKeys } from '../../../../logic/translations/translations.service';
import { getAuthenticatedUserCompanyId } from '../../../../logic/store/authentication/authentication.selectors';
import { useDispatch, useSelector } from 'react-redux';
import { getInProgress } from '../../../../logic/store/process-tracker/process-tracker.selectors';
import queryString from 'query-string';
import { parseId } from '../../../../logic/utils/parse-id';
import { locationToFormValuesMapper } from '../../../location-e911/pages/location-e911/location-e911-form';
import { LocationFormFieldName } from '../../../../common/location/location-form.field-name';
import { LocationFormFields } from './location-form.fields';
import { locationActionCreators } from '../../../../logic/store/location/location/location.action';

import { autocompleteActionCreators } from '../../../../logic/store/autocomplete/autocomplete.action';
import { AutocompleteClass } from '../../../../api-models/api-models';

interface LocationFormPageProps
  extends RouteComponentProps,
    LocationFormPageOwnProps,
    InjectedFormProps<LocationForm> {}

export const LocationFormPage: React.FC<LocationFormPageProps> = (props) => {
  const { submitButtonText, submitFormAction, formName, disabledFields, location, error, isViewOnly } = props;

  const dispatch = useDispatch();
  const formValues: LocationForm = useSelector(getFormValues(formName)) || {};

  const getSingleForEditInProgress = useSelector(getInProgress(locationActionCreators.getSingleForEdit.name));
  const submitInProgress = useSelector(getInProgress(submitFormAction.name));
  const inProgress = getSingleForEditInProgress || submitInProgress;
  const authenticatedUserCompanyId = useSelector(getAuthenticatedUserCompanyId);

  const { company_id, location_id } = queryString.parse(location.search);

  const company_id_parsed = parseId(company_id);
  const location_id_parsed = parseId(location_id);

  const companyId = authenticatedUserCompanyId || company_id_parsed;

  useEffect(() => {
    if (location_id_parsed !== undefined) {
      dispatch(locationActionCreators.getSingleForEdit(location_id_parsed, formName, locationToFormValuesMapper));
    } else if (companyId !== undefined) {
      dispatch(change(formName, LocationFormFieldName.company_id, companyId));
    }
    if (companyId) {
      dispatch(autocompleteActionCreators.fetchAutocomplete(companyId, AutocompleteClass.Location));
    }
  }, [dispatch, formName, location_id_parsed, companyId]);

  const handleSubmitAction = () => {
    dispatch(submitFormAction(formValues));
  };

  return (
    <>
      <MasterPageHeader showBack />
      <MasterContent>
        <FormContainer inProgress={getSingleForEditInProgress}>
          <LocationFormFields
            inProgress={inProgress}
            onPressEnter={handleSubmitAction}
            disabledFields={disabledFields}
            isViewOnly={isViewOnly}
            formName={formName}
          />

          {error && (
            <Form.Item {...formButtonLayout}>
              <Alert message={translate(translationKeys.errors.error)} description={error} type={'error'} showIcon />
            </Form.Item>
          )}

          {isViewOnly !== true ? (
            <Form.Item {...formBlockHeaderLayout}>
              <Form.Item {...formButtonLayout}>
                <Button
                  type={'primary'}
                  className={'form-button'}
                  onClick={handleSubmitAction}
                  disabled={inProgress}
                  loading={submitInProgress}
                >
                  {submitButtonText}
                </Button>
              </Form.Item>
            </Form.Item>
          ) : (
            ''
          )}
        </FormContainer>
      </MasterContent>
    </>
  );
};
