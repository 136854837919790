import { compose } from 'redux';
import { AlertsTemplatePage, AlertsTemplatePageProps } from './alert-template/alerts-template.page';
import { AlertFieldName } from './alert-template/alerts.field-name';
import { wrapComponentsWithProps } from '../../../logic/hoc/wrap-components-with-props.hoc';
import { QueryStringPrefilters } from '../../../logic/store/tables/tables.reducer';
import { TableName } from '../../../logic/store/tables/tables.action';

export const ActiveAlertPageCreator = () =>
  compose(
    wrapComponentsWithProps<AlertsTemplatePageProps>({
      columnsFields: [
        AlertFieldName.name,
        AlertFieldName.created_at,
        AlertFieldName.raising_user,
        AlertFieldName.messages,
      ],
      prefilters: { active: true } as QueryStringPrefilters,
      tableName: TableName.activeAlert,
      showLowerAlertAction: true,
      showFetchPdfReportAction: false,
    })
  )(AlertsTemplatePage);
