import { reducer as formReducer, FormStateMap } from 'redux-form';
import { AuthenticationState, authenticationReducer } from '../logic/store/authentication/authentication.reducer';
import { ProcessTrackerState, processTrackerReducer } from '../logic/store/process-tracker/process-tracker.reducer';
import { LogState, logReducer } from '../logic/store/log/log.reducer';
import { TablesState, tablesReducer } from '../logic/store/tables/tables.reducer';
import { listsReducer, ListsState } from '../logic/store/lists/lists.reducer';
import { AlertState, alertReducer } from '../logic/store/alert/alert.reducer';
import { userReducer, UserState } from '../logic/store/user/user.reducer';
import { autocompleteReducer, AutocompleteState } from '../logic/store/autocomplete/autocomplete.reducer';

export interface StoreState {
  authentication: AuthenticationState;
  tables: TablesState;
  lists: ListsState;
  alert: AlertState;
  user: UserState;
  autocomplete: AutocompleteState;

  log: LogState;
  form: FormStateMap;
  processTracker?: ProcessTrackerState;
}

export const reducers = {
  authentication: authenticationReducer,
  tables: tablesReducer,
  lists: listsReducer,
  alert: alertReducer,
  user: userReducer,
  autocomplete: autocompleteReducer,

  log: logReducer,
  form: formReducer.plugin({}),
  processTracker: processTrackerReducer,
};
