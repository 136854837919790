import { takeEvery } from 'redux-saga/effects';
import { InitializationActionTypes, initializationActionCreators } from './initialization.actions';
import { SagaManager } from '../../saga-manager/saga-manager';
import { initializationApi } from './initialization.api';
import { ApiResponse } from '../../axios/axios-api-response';
import { AppVersion } from './initialization.models';
import { getAppVersion } from '../../version/version';
import { versionIsGreaterThan } from '../../version/version-utils';
import { appVersionNotFound } from './initialization.error-handlers';

export function* initializationSagaWatch() {
  yield takeEvery(InitializationActionTypes.REFRESH_BROWSER_CACHE_IF_NEEDED, refreshBrowserCachIfNeededSagaManaged);
}

function* refreshBrowserCachIfNeededSagaManaged() {
  yield new SagaManager()
    .addTracking(initializationActionCreators.refreshBrowserCacheIfNeeded.name)
    .addCustomErrorHandler(appVersionNotFound)
    .execute(refreshBrowserCachIfNeededSaga);
}

function* refreshBrowserCachIfNeededSaga() {
  const latestVersionResp: ApiResponse<AppVersion> = yield initializationApi.getCurrentAppVersion();
  const latestVersion = latestVersionResp.data?.version;
  const currentVersion = getAppVersion();

  const shouldForceRefresh = getShouldForceRefresh(latestVersion, currentVersion);

  if (shouldForceRefresh) {
    console.log(`latest appVersion: ${latestVersion}, current appVersion: ${currentVersion}. Should force refresh`);
    refreshCacheAndReload();
  } else {
    console.log(`latest appVersion: ${latestVersion}, current appVersion: ${currentVersion}. No cache refresh needed.`);
  }
}

const getShouldForceRefresh = (latest: string | null | undefined, current: string | null | undefined) =>
  versionIsGreaterThan(latest, current);

const refreshCacheAndReload = () => {
  console.log('Clearing cache and hard reloading...');
  if (caches) {
    // Service worker cache should be cleared with caches.delete()
    caches.keys().then(function(names) {
      for (let name of names) caches.delete(name);
    });
  }
  // delete browser cache and hard reload
  window.location.reload(true);
};
