import React, { useCallback } from 'react';
import { Button, Col, ColProps, Form, Row } from 'antd';
import { formBlockHeaderLayout, formItemLayout } from '../../../../components/form-container/form-container';
import { translate, translationKeys } from '../../../../../logic/translations/translations.service';
import { Field, FieldArray, change, formValueSelector } from 'redux-form';
import { LocationTagFieldName, LocationTagSection } from '../../user-form-template/user-form-field-name';
import { FormSelectInput } from '../../../../components/form-inputs/form-select-input/form-select-input';
import { getDefaultSelectOptions } from '../../../../components/form-inputs/select-option/select-option';
import { useSelector } from 'react-redux';
import { getListData } from '../../../../../logic/store/lists/lists.selectors';
import { ListName } from '../../../../../logic/store/lists/lists.action';
import { DeleteOutlined } from '@ant-design/icons';
import { useGridBreakpoints } from '../../../../../logic/hooks/use-grid-breakpoints';
import { FormTextInput } from '../../../../components/form-inputs/form-text-input/form-text-input';
import { useDispatch } from 'react-redux';
import { store } from '../../../../../app/app.store';

const translateLocationFieldLabel = (fieldName: LocationTagFieldName) =>
  translate(translationKeys.forms.fields.user[fieldName]);

const LocationSelectField = ({ member }: any) => {
  const dispatch = useDispatch();
  const locations = useSelector(getListData(ListName.location));
  const locationsOptions = getDefaultSelectOptions(locations);

  const selector = formValueSelector('user');
  const tagNameValue = selector(store.getState(), `${member}${LocationTagFieldName.tag_name}`);

  const renderLocationField = ({ input, meta, value, ...rest }: any) => (
    <FormSelectInput input={input} meta={meta} {...rest} />
  );

  const onSelectionChanged = (value: any) => {
    const selectedLocationName = locationsOptions.find((x) => x.value === value)?.label;

    // Change Tag name only when there is no value already there.
    if (!tagNameValue) {
      dispatch(change('user', `${member}${LocationTagFieldName.tag_name}`, selectedLocationName));
    }
  };

  return (
    <Field
      name={`${member}${LocationTagFieldName.location_id}`}
      component={renderLocationField}
      label={translateLocationFieldLabel(LocationTagFieldName.location_id)}
      options={locationsOptions}
      filterOption={true}
      optionFilterProp={'label'}
      showSearch
      onChange={onSelectionChanged}
      required
      {...formItemLayout}
    />
  );
};
const LocationTagFields = () => {
  const { xs } = useGridBreakpoints();

  const renderTagField = ({ input, ...rest }: any) => (
    <FormTextInput input={input} richText={false} required {...rest} {...formItemLayout} />
  );

  const renderMembers = useCallback(
    ({ fields }: any) => {
      const fieldsCol = {
        xs: 24,
        sm: 22,
        order: xs ? 2 : 1,
      } as ColProps;

      const removeBtnCol = {
        xs: 24,
        sm: 2,
        order: xs ? 1 : 2,
      } as ColProps;

      const removeBtnRow = {
        align: 'middle',
        justify: xs ? 'end' : 'center',
        style: { height: xs ? '0' : '85%' },
      } as ColProps;

      return (
        <>
          <Form.Item {...formBlockHeaderLayout} className={'text-centered'}>
            <Button type={'primary'} onClick={() => fields.push({})}>
              {translate(translationKeys.buttons.addLocationTag)}
            </Button>
          </Form.Item>
          <Form.Item {...formBlockHeaderLayout}>
            {fields.map((member: any, index: any) => (
              <Row key={index}>
                <Col {...fieldsCol}>
                  <LocationSelectField member={member} />
                  <Field
                    name={`${member}${LocationTagFieldName.tag_name}`}
                    component={renderTagField}
                    label={translateLocationFieldLabel(LocationTagFieldName.tag_name)}
                    {...formItemLayout}
                  />
                </Col>
                <Col {...removeBtnCol}>
                  <Row {...removeBtnRow}>
                    <Button
                      type="default"
                      icon={<DeleteOutlined />}
                      size={'middle'}
                      onClick={() => fields.remove(index)}
                    ></Button>
                  </Row>
                </Col>
              </Row>
            ))}
          </Form.Item>
        </>
      );
    },
    [xs]
  );

  return <FieldArray name={LocationTagSection.section} component={renderMembers} />;
};

export default React.memo(LocationTagFields);
