import { NetworkCode } from '../../models/network-code';

export interface ApiError extends Error {
  response?: {
    data?: any;
    status?: NetworkCode;
  };
}

export const getApiErrorData = (error: ApiError | undefined): any => error?.response?.data;

export const getApiErrorStatusCode = (error: ApiError | undefined): NetworkCode | undefined => error?.response?.status;
