export enum CompanyFormFieldName {
  address = 'address',
  admins = 'admins',
  alerts = 'alerts',
  city = 'city',
  groups = 'groups',
  id = 'id',
  isActive = 'is_active',
  isTrial = 'is_trial',
  name = 'name',
  phone_country_code = 'phone_country_code',
  phone = 'phone',
  state = 'state',
  tokens = 'tokens',
  users_no = 'users_no',
  max_users_no = 'max_users_no',
  users = 'users',
  zip_code = 'zip_code',
  country = 'country',
  industry = 'industry',
  footer_note = 'footer_note',
  is_e911_enabled = 'is_e911_enabled',
  is_e911_test_enabled = 'is_e911_test_enabled',
  trialHeader = 'trialHeader',
  created_at = 'created_at',
  trial_to_date = 'trial_to_date',
  trial_alerts_limit = 'trial_alerts_limit',
  companyInfoHeader = 'companyInfoHeader',
  e911Header = 'e911Header',
  e911HeaderDescription = 'e911HeaderDescription',
  ssoHeader = 'ssoHeader',
  is_sso_enabled = 'is_sso_enabled',
  is_permission_mapping_enabled = 'is_permission_mapping_enabled',
  is_company_group_mapping_enabled = 'is_company_group_mapping_enabled',
  appsHeader = 'appsHeader',
  is_updates_on = 'is_updates_on',
  multisite_header = 'multisite_header',
  is_multisite_enabled = 'is_multisite_enabled',
  locations_no = 'locations_no',
  max_locations_no = 'max_locations_no',
}
