import React from 'react';
import { AUTHENTICATED_ROUTES } from '../../../logic/routes/routes.const';
import { UsersTemplatePage, UsersPageOwnProps } from './users-template/users-template.page';
import { translate, translationKeys } from '../../../logic/translations/translations.service';
import { UserFormFieldName, ContactInfoFieldName } from './user-form-template/user-form-field-name';
import { User } from '../../../api-models/api-models';
import { wrapComponentsWithProps } from '../../../logic/hoc/wrap-components-with-props.hoc';
import parse from 'html-react-parser';
import { PageDescription } from '../../components/page-description/page-description';
import { ResourceLink } from '../../components/resources/resource-link';
import { Resource, resourceContents } from '../../components/resources/resource.const';

export const UsersPage = wrapComponentsWithProps<UsersPageOwnProps>({
  headerContent: (
    <PageDescription
      title={translate(translationKeys.pages.users.title)}
      content={
        <>
          <p>{parse(translate(translationKeys.pages.users.description))}</p>
          <p>{parse(translate(translationKeys.pages.users.noteTitle))}</p>
          <ul>
            <li>{parse(translate(translationKeys.pages.users.note1))}</li>
            <li>{parse(translate(translationKeys.pages.users.note2))}</li>
          </ul>
          <p>
            <ResourceLink
              content={resourceContents[Resource.userManagementVideoSupportLink]}
              text={translate(translationKeys.pages.users.videoLinkDescription)}
              noPaddingLeft
            />
          </p>
        </>
      }
    />
  ),
  editUserRoute: (user: User) => AUTHENTICATED_ROUTES.EditUser,
  buttons: [
    {
      text: translate(translationKeys.buttons.addUser),
      route: AUTHENTICATED_ROUTES.AddUser,
      event: 'addUserButton',
    },
    {
      text: translate(translationKeys.buttons.importUsers),
      route: AUTHENTICATED_ROUTES.ImportUsers,
      type: 'default',
      event: 'importUsersButton',
    },
  ],
  shoudShowActions: (user, authenticatedUser) =>
    (authenticatedUser?.permission_level &&
      user?.permission_level &&
      authenticatedUser?.permission_level >= user.permission_level) ||
    false,
  columnsFields: [
    UserFormFieldName.first_name,
    UserFormFieldName.last_name,
    UserFormFieldName.email,
    UserFormFieldName.groups,
    UserFormFieldName.company_id,
    ContactInfoFieldName.city,
    ContactInfoFieldName.state,

    ContactInfoFieldName.department,

    UserFormFieldName.last_active,
    UserFormFieldName.status,
    UserFormFieldName.permission_level,
  ],
})(UsersTemplatePage);
