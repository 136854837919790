import { SsoGroupMapping } from '../../../api-models/api-models';
import { SsoGroupMappingForm } from '../../../pages/sso-group-mapping/pages/sso-group-mapping-form-template/sso-group-mapping-form.form';
import { ActionsUnion, createAction } from '../../redux/action-creator';

export enum SsoGroupMappingActionTypes {
  RESET = 'sso-group-mapping/RESET',
  CREATE_SINGLE = 'sso-group-mapping/CREATE_SINGLE',
  GET_SINGLE_FOR_EDIT = 'sso-group-mapping/GET_SINGLE_FOR_EDIT',
  UPDATE_SINGLE = 'sso-group-mapping/UPDATE_SINGLE',
  DELETE_SINGLE = 'sso-group-mapping/DELETE_SINGLE',
}

export const ssoGroupMappingActionCreators = {
  reset: () => createAction(SsoGroupMappingActionTypes.RESET),
  createSingle: (formValues: SsoGroupMappingForm) =>
    createAction(SsoGroupMappingActionTypes.CREATE_SINGLE, { formValues }),
  updateSingle: (formValues: SsoGroupMappingForm) =>
    createAction(SsoGroupMappingActionTypes.UPDATE_SINGLE, { formValues }),
  getSingleForEdit: (id: any, formName: string, mapper: (data: SsoGroupMapping) => SsoGroupMappingForm) =>
    createAction(SsoGroupMappingActionTypes.GET_SINGLE_FOR_EDIT, { id, formName, mapper }),
  deleteSingle: (id: number) => createAction(SsoGroupMappingActionTypes.DELETE_SINGLE, { id }),
};

export type SsoGroupMappingActions = ActionsUnion<typeof ssoGroupMappingActionCreators>;
