import { put } from 'redux-saga/effects';
import { logActionCreators } from '../store/log/log.actions';
import { translate, translationKeys } from '../translations/translations.service';
import { isProduction, isTest } from '../env/env';

export function* defaultErrorHandler(error: Error, shouldShowError: boolean) {
  yield put(
    logActionCreators.logError(error.message || translate(translationKeys.errors.unknown), error, shouldShowError)
  );

  if (!isProduction() && !isTest()) {
    console.log(`${error.message}, error:`, error);
  }
}
