import { ImportUsersResults } from './user.model';
import { UserActions, UserActionTypes } from './user.action';
import { Analytics } from '../../../api-models/api-models';

export interface UserState {
  importUsersResult?: ImportUsersResults;
  analytics?: Analytics;
}

const initialState: UserState = {
  importUsersResult: undefined,
  analytics: undefined,
};

export const userReducer = (state = initialState, action: UserActions): UserState => {
  switch (action.type) {
    case UserActionTypes.RESET:
      return initialState;
    case UserActionTypes.SET_IMPORT_USERS_RESULTS:
      return {
        ...state,
        importUsersResult: action.payload.importUsersResult,
      };
    case UserActionTypes.SET_ANALYTICS:
      return {
        ...state,
        analytics: action.payload.response.data,
      };
    default:
      return state;
  }
};
