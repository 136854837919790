import React, { useEffect } from 'react';
import { Field, FormSection } from 'redux-form';
import { FormTextInput } from '../../../components/form-inputs/form-text-input/form-text-input';
import { translate, translationKeys } from '../../../../logic/translations/translations.service';
import { trimValue, parseEmptyValueAs } from '../../../../logic/formaters/formaters';
import { FormSelectInput } from '../../../components/form-inputs/form-select-input/form-select-input';
import { AlertTypeFormFieldName, AlertTypeButtonFieldName, E911Behavior } from './alert-type.form.field-name';
import { formItemLayout } from '../../../components/form-container/form-container';
import { useSelector, useDispatch } from 'react-redux';
import { FormNumberInput } from '../../../components/form-inputs/form-number-input/form-number-input';
import { FormSwitchInput } from '../../../components/form-inputs/form-switch-input/form-switch-input';
import { getInProgress } from '../../../../logic/store/process-tracker/process-tracker.selectors';
import { FormColorInput } from '../../../components/form-inputs/form-color-input/form-color-input';
import { getAlertIconSelectOptions } from '../../../components/alert-icon/alert-icon.select-options';
import { ListName, listsActionCreators, getFetchListTrackName } from '../../../../logic/store/lists/lists.action';
import { getListData } from '../../../../logic/store/lists/lists.selectors';
import {
  getDefaultSelectOptions,
  getSupportedLanguagesSelectOptions,
} from '../../../components/form-inputs/select-option/select-option';
import parse from 'html-react-parser';

interface AlertTypeFormProps {
  onPressEnter?: any;
  inProgress?: boolean;
}

const e911BehaviorOptions = [
  { value: 1, label: translate(translationKeys.forms.fields.e911Behavior[E911Behavior.ASK_WITH_TIMEOUT]) },
  { value: 2, label: translate(translationKeys.forms.fields.e911Behavior[E911Behavior.ASK_AND_WAIT]) },
  { value: 3, label: translate(translationKeys.forms.fields.e911Behavior[E911Behavior.RAISE_IMMEDIATELY]) },
];

export const AlertTypeFormFields: React.FC<AlertTypeFormProps> = ({ onPressEnter, inProgress }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(listsActionCreators.fetchList(ListName.group));
    dispatch(listsActionCreators.fetchList(ListName.alertIcon));
    dispatch(listsActionCreators.fetchList(ListName.supportedLanguages));
    dispatch(listsActionCreators.fetchList(ListName.location));
  }, [dispatch]);

  const commonAttributes = {
    ...formItemLayout,
    disabled: inProgress,
    onPressEnter,
  };

  const groups = useSelector(getListData(ListName.group)) || [];
  const groupsOptions = getDefaultSelectOptions(groups);
  const fetchGroupsInProgress = useSelector(getInProgress(getFetchListTrackName(ListName.group)));

  const alertIcons = useSelector(getListData(ListName.alertIcon)) || [];
  const alertIconsOptions = getAlertIconSelectOptions(alertIcons);
  const fetchAlertIconsInProgress = useSelector(getInProgress(getFetchListTrackName(ListName.alertIcon)));

  const supportedLanguages = useSelector(getListData(ListName.supportedLanguages)) || [];
  const supportedLanguagesOptions = getSupportedLanguagesSelectOptions(supportedLanguages);
  const fetchSupportedLanguagesProgress = useSelector(
    getInProgress(getFetchListTrackName(ListName.supportedLanguages))
  );

  const locations = useSelector(getListData(ListName.location));
  const locationsOptions = getDefaultSelectOptions(locations);
  const fetchLocationsInProgress = useSelector(getInProgress(getFetchListTrackName(ListName.location)));

  const translateAlertFieldLabel = (fieldName: AlertTypeFormFieldName) =>
    translate(translationKeys.forms.fields.alertType[fieldName]);

  const translateAlertButtonFieldLabel = (fieldName: AlertTypeButtonFieldName) =>
    translate(translationKeys.forms.fields.alertTypeButon[fieldName]);

  const getTextField = (fieldName: string, label: string, required?: boolean) => (
    <Field
      name={fieldName}
      component={FormTextInput}
      label={label}
      required={required}
      onBlurFormater={trimValue}
      {...commonAttributes}
    />
  );

  return (
    <>
      {getTextField(AlertTypeFormFieldName.name, translateAlertFieldLabel(AlertTypeFormFieldName.name), true)}
      <Field
        name={AlertTypeFormFieldName.cancellation_timeout}
        component={FormNumberInput}
        className={'form-number-input'}
        onBlurFormater={parseEmptyValueAs(0)}
        min={0}
        max={10}
        label={translateAlertFieldLabel(AlertTypeFormFieldName.cancellation_timeout)}
        {...commonAttributes}
      />
      <Field
        name={AlertTypeFormFieldName.sender_group_id}
        component={FormSelectInput}
        label={translateAlertFieldLabel(AlertTypeFormFieldName.sender_group_id)}
        options={groupsOptions}
        showSearch
        filterOption={true}
        optionFilterProp={'label'}
        loading={fetchGroupsInProgress}
        required
        {...commonAttributes}
        disabled={inProgress || fetchGroupsInProgress}
      />
      <Field
        name={AlertTypeFormFieldName.recipient_groups_ids}
        component={FormSelectInput}
        label={translateAlertFieldLabel(AlertTypeFormFieldName.recipient_groups_ids)}
        options={groupsOptions}
        mode={'multiple'}
        showSearch
        filterOption={true}
        optionFilterProp={'label'}
        loading={fetchGroupsInProgress}
        required
        {...commonAttributes}
        disabled={inProgress || fetchGroupsInProgress}
      />
      <Field
        name={AlertTypeFormFieldName.locations_ids}
        component={FormSelectInput}
        toolTipMessage={translate(translationKeys.forms.fields.alertType.locationIdsTooltip)}
        label={translateAlertFieldLabel(AlertTypeFormFieldName.locations_ids)}
        options={locationsOptions}
        mode={'multiple'}
        showSearch
        filterOption={true}
        optionFilterProp={'label'}
        loading={fetchLocationsInProgress}
        {...commonAttributes}
        disabled={inProgress || fetchGroupsInProgress}
      />
      <Field
        name={AlertTypeFormFieldName.instructions}
        component={FormTextInput}
        label={translateAlertFieldLabel(AlertTypeFormFieldName.instructions)}
        onBlurFormater={trimValue}
        richText={true}
        rows={5}
        {...commonAttributes}
      />
      <Field
        name={AlertTypeFormFieldName.language}
        component={FormSelectInput}
        label={translateAlertFieldLabel(AlertTypeFormFieldName.language)}
        options={supportedLanguagesOptions}
        showSearch
        filterOption={true}
        optionFilterProp={'label'}
        loading={fetchSupportedLanguagesProgress}
        {...commonAttributes}
        disabled={inProgress || fetchGroupsInProgress}
      />
      <Field
        name={AlertTypeFormFieldName.voice_message}
        component={FormTextInput}
        label={translateAlertFieldLabel(AlertTypeFormFieldName.voice_message)}
        onBlurFormater={trimValue}
        rows={5}
        {...commonAttributes}
      />
      <FormSection name={AlertTypeFormFieldName.button_settings}>
        <Field
          name={AlertTypeButtonFieldName.background_color}
          component={FormColorInput}
          label={translateAlertButtonFieldLabel(AlertTypeButtonFieldName.background_color)}
          {...commonAttributes}
        />
        <Field
          name={AlertTypeButtonFieldName.text_color}
          component={FormColorInput}
          label={translateAlertButtonFieldLabel(AlertTypeButtonFieldName.text_color)}
          {...commonAttributes}
        />
        <Field
          name={AlertTypeButtonFieldName.icon_id}
          component={FormSelectInput}
          label={translateAlertButtonFieldLabel(AlertTypeButtonFieldName.icon_id)}
          options={alertIconsOptions}
          showSearch
          filterOption={true}
          optionFilterProp={'label'}
          loading={fetchAlertIconsInProgress}
          required
          {...commonAttributes}
          disabled={inProgress || fetchAlertIconsInProgress}
        />
      </FormSection>
      <Field
        name={AlertTypeFormFieldName.can_be_lowered_by_raising_user}
        component={FormSwitchInput}
        toolTipMessage={translate(translationKeys.forms.fields.alertType.canBeLoweredByRaisingUserTooltip)}
        label={translateAlertFieldLabel(AlertTypeFormFieldName.can_be_lowered_by_raising_user)}
        {...commonAttributes}
      />
      <Field
        name={AlertTypeFormFieldName.calls_e911}
        component={FormSwitchInput}
        toolTipMessage={translate(translationKeys.forms.fields.alertType.calls_e911Tooltip)}
        label={translateAlertFieldLabel(AlertTypeFormFieldName.calls_e911)}
        {...commonAttributes}
      />
      <Field
        name={AlertTypeFormFieldName.e911_behavior}
        component={FormSelectInput}
        toolTipMessage={parse(translate(translationKeys.forms.fields.alertType.e911_behaviorTooltip))}
        label={translateAlertFieldLabel(AlertTypeFormFieldName.e911_behavior)}
        options={e911BehaviorOptions}
        {...commonAttributes}
      />
    </>
  );
};
