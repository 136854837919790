import { wrapComponentsWithProps } from '../../../../logic/hoc/wrap-components-with-props.hoc';
import { AUTHENTICATED_ROUTES } from '../../../../logic/routes/routes.const';
import { TableName } from '../../../../logic/store/tables/tables.action';
import { translate, translationKeys } from '../../../../logic/translations/translations.service';
import { PageDescription } from '../../../components/page-description/page-description';
import { SsoGroupMappingFormFieldName } from '../sso-group-mapping-form-template/sso-group-mapping-form-field-name';
import {
  SsoGroupMappingPageOwnProps,
  SsoGroupMappingTemplate,
} from '../sso-group-mapping-template/sso-group-mapping.template.page';
import parse from 'html-react-parser';

export const SsoGroupMapping = wrapComponentsWithProps<SsoGroupMappingPageOwnProps>({
  tableName: TableName.ssoGroupMapping,
  headerContent: (
    <PageDescription
      title={translate(translationKeys.sideMenu.ssoGroupMappings)}
      content={parse(translate(translationKeys.pages.sso.content))}
    />
  ),
  ssoDisabledContent: parse(translate(translationKeys.pages.sso.callTeamAlert)),
  ssoEnabledContent: parse(translate(translationKeys.pages.ssoGroupMapping.ssoGroupMappingInstruction)),
  ssoPermissionMappingDisabledContent: translate(translationKeys.pages.ssoGroupMapping.ssoPermissionMappingDisabled),
  buttons: [
    {
      text: translate(translationKeys.buttons.addSsoPermissionMapping),
      route: AUTHENTICATED_ROUTES.SsoGroupMappingAdd,
    },
  ],
  editSsoGroupMappingRoute: AUTHENTICATED_ROUTES.SsoGroupMappingEdit,
  columnsFields: [
    SsoGroupMappingFormFieldName.sso_group_name,
    SsoGroupMappingFormFieldName.corresponding_permission_level,
  ],
})(SsoGroupMappingTemplate);
