import { isDevelopment } from '../../../../logic/env/env';
import { AlertTypeButtonFieldName } from './alert-type.form.field-name';
import { omit } from 'lodash';
import { AlertType } from '../../../../api-models/api-models';

export const ALERT_TYPE_FORM = 'alertType';

export type AlertTypeForm = Undefineable<AlertType>;

const devFields: AlertTypeForm = isDevelopment()
  ? {
      name: 'Alert',
    }
  : {};

export const alertTypeFormInitialValues: AlertTypeForm = {
  ...devFields,

  button_settings: {
    text_color: '#ffffff',
    background_color: '#d0021b',
  },
  cancellation_timeout: 0,
};

export const duplicateAlertTypeToFormValuesMapper = (alert: AlertType): AlertTypeForm => {
  return {
    ...alert,
    name: `${alert.name} (duplicate)`,
  };
};

export const alertTypeToFormValuesMapper = (alert: AlertType): AlertTypeForm => {
  return {
    ...alert,
  };
};

export const formValuesToAlertTypeMapper = (formValues: AlertTypeForm): Undefineable<AlertType> => {
  return {
    ...formValues,
    button_settings: omit(formValues.button_settings, AlertTypeButtonFieldName.icon),
  };
};
