import { wrapComponentsWithProps } from '../../../../logic/hoc/wrap-components-with-props.hoc';
import { AUTHENTICATED_ROUTES } from '../../../../logic/routes/routes.const';
import { TableName } from '../../../../logic/store/tables/tables.action';
import { translate, translationKeys } from '../../../../logic/translations/translations.service';
import { CompaniesTemplatePage, CompaniesTemplatePageOwnProps } from '../companies-template/companies-template.page';
import { CompanyFormFieldName } from '../company-form-template/company-form-field-name';

export const CompaniesPage = wrapComponentsWithProps<CompaniesTemplatePageOwnProps>({
  tableName: TableName.company,
  prefilters: { is_trial: false },
  buttons: [
    {
      text: translate(translationKeys.buttons.addCompany),
      route: AUTHENTICATED_ROUTES.CompaniesAdd,
    },
  ],
  editCompanyRoute: AUTHENTICATED_ROUTES.CompaniesEdit,
  columnsFields: [
    CompanyFormFieldName.name,
    CompanyFormFieldName.address,
    CompanyFormFieldName.city,
    CompanyFormFieldName.state,
    CompanyFormFieldName.zip_code,
    CompanyFormFieldName.phone,
    CompanyFormFieldName.max_users_no,
    CompanyFormFieldName.users_no,
    CompanyFormFieldName.alerts,
    CompanyFormFieldName.isActive,
    CompanyFormFieldName.is_e911_enabled,
    CompanyFormFieldName.is_e911_test_enabled,
    CompanyFormFieldName.is_sso_enabled,
  ],
})(CompaniesTemplatePage);
