import React from 'react';
import { Progress } from 'antd';
import { getPercent } from '../../../logic/utils/get-percent';
import { AxiosProgress } from '../../../logic/axios/axios-progres';
import { SpinProps } from 'antd/lib/spin';

export const getProgressSpinner = (
  inProgress: boolean,
  progress: AxiosProgress | undefined
): SpinProps | undefined => ({
  indicator:
    progress?.loaded !== progress?.total ? (
      <Progress type={'circle'} status={'active'} percent={getPercent(progress?.loaded, progress?.total)} width={60} />
    ) : undefined,
  spinning: inProgress,
});
