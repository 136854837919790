import { Alert, Button, Form } from 'antd';
import {
  formBlockHeaderLayout,
  formButtonLayout,
  FormContainer,
} from '../../../components/form-container/form-container';
import { change, getFormValues, InjectedFormProps } from 'redux-form';
import { locationToFormValuesMapper } from './location-e911-form';
import React, { useEffect } from 'react';
import { translate, translationKeys } from '../../../../logic/translations/translations.service';
import { useDispatch, useSelector } from 'react-redux';
import { LocationFormFieldName } from '../../../../common/location/location-form.field-name';
import { LocationE911FormFields } from './location-e911-form.fields';
import { MasterContent } from '../../../master-page/components/master-content/master-content';
import { MasterPageHeader } from '../../../components/master-page-header/master-page-header';
import { RouteComponentProps } from 'react-router-dom';
import {
  getAuthenticatedUserCompanyId,
  getAuthenticatedUserRole,
} from '../../../../logic/store/authentication/authentication.selectors';
import { getInProgress } from '../../../../logic/store/process-tracker/process-tracker.selectors';
import { isSuperAdmin } from '../../../authentication/models/role/role.utils';
import { locationE911ActionCreators } from '../../../../logic/store/location/location-e911/location-e911.action';
import { parseId } from '../../../../logic/utils/parse-id';
import queryString from 'query-string';
import { store } from '../../../../app/app.store';
import { useConfirmActionModal } from '../../../components/confirm-modal/confirm-modal';
import { LocationForm, LocationFormPageOwnProps } from '../../../../common/location/location.type';

interface LocationE911FormPageProps
  extends RouteComponentProps,
    LocationFormPageOwnProps,
    InjectedFormProps<LocationForm> {}

export const LocationE911FormPage: React.FC<LocationE911FormPageProps> = ({
  submitButtonText,
  submitFormAction,
  formName,
  disabledFields,
  location,
  error,
  isViewOnly,
}) => {
  const dispatch = useDispatch();
  const formValues: LocationForm = useSelector(getFormValues(formName)) || {};

  const getSingleForEditInProgress = useSelector(getInProgress(locationE911ActionCreators.getSingleForEdit.name));
  const submitInProgress = useSelector(getInProgress(submitFormAction.name));
  const inProgress = getSingleForEditInProgress || submitInProgress;
  const authenticatedUserCompanyId = useSelector(getAuthenticatedUserCompanyId);

  const { company_id, location_id } = queryString.parse(location.search);

  const company_id_parsed = parseId(company_id);
  const location_id_parsed = parseId(location_id);

  const companyId = authenticatedUserCompanyId || company_id_parsed;

  useEffect(() => {
    if (location_id_parsed !== undefined) {
      dispatch(locationE911ActionCreators.getSingleForEdit(location_id_parsed, formName, locationToFormValuesMapper));
    } else if (companyId !== undefined) {
      dispatch(change(formName, LocationFormFieldName.company_id, companyId));
    }
  }, [dispatch, formName, location_id_parsed, companyId]);

  const handleSubmitAction = () => {
    dispatch(submitFormAction(formValues));
  };

  const role = getAuthenticatedUserRole(store.getState());

  const updateModal = useConfirmActionModal(
    translate(translationKeys.modalDialog.updateLocationE911),
    translate(translationKeys.modalDialog.areYouSureYouWantToUpdateThisLocationE911),
    handleSubmitAction,
    locationE911ActionCreators.updateSingle.name
  );

  return (
    <>
      <MasterPageHeader showBack />
      <MasterContent>
        <FormContainer inProgress={getSingleForEditInProgress}>
          <LocationE911FormFields
            inProgress={inProgress}
            onPressEnter={handleSubmitAction}
            disabledFields={disabledFields}
            isViewOnly={isViewOnly}
            formName={formName}
          />

          {error && (
            <Form.Item {...formButtonLayout}>
              <Alert message={translate(translationKeys.errors.error)} description={error} type={'error'} showIcon />
            </Form.Item>
          )}

          {isViewOnly !== true ? (
            <Form.Item {...formBlockHeaderLayout}>
              <Form.Item {...formButtonLayout}>
                <Button
                  type={'primary'}
                  className={'form-button'}
                  onClick={isSuperAdmin(role) ? handleSubmitAction : updateModal.tryToPerformAction('empty')}
                  disabled={inProgress}
                  loading={submitInProgress}
                >
                  {submitButtonText}
                </Button>
              </Form.Item>
            </Form.Item>
          ) : (
            <Form.Item {...formButtonLayout}>
              <Button
                type={'primary'}
                className={'form-button'}
                onClick={handleSubmitAction}
                disabled={!formValues.is_geolocation_enabled || inProgress}
                loading={submitInProgress}
              >
                {translate(translationKeys.forms.fields.location.updateGeolocationBtn)}
              </Button>
            </Form.Item>
          )}
        </FormContainer>
      </MasterContent>
      {updateModal.render()}
    </>
  );
};
