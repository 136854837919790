import { useState, useCallback, useMemo } from 'react';
import { GeoPosition } from '../../components/google-map/GoogleMap.types';
import { axiosAppClient } from '../axios/axios-guest-client';
import { env } from '../env/env';

type Geometry = {
  geometry: {
    location: GeoPosition;
    location_type: string;
    place_id: string;
    plus_code: {
      compound_code: string;
      global_code: string;
    };
  };
};

type PartialGeocodingResponse = {
  results: Geometry[];
};

type FetchingState = 'idle' | 'pending' | 'error' | 'success';

export const useGoogleGeocoding = () => {
  const [fetchingState, setFetchingState] = useState<FetchingState>('idle');
  const [data, setData] = useState<GeoPosition | null>(null);
  const [error, setError] = useState<unknown | null>(null);

  const axios = useMemo(() => axiosAppClient(), []);

  const fetchByAddress = useCallback(
    async (address: string) => {
      const endpoint = `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${env.REACT_APP_GOOGLE_API}`;
      try {
        setFetchingState('pending');
        const response = await axios.get<PartialGeocodingResponse>(endpoint);
        const data = response.data.results[0];

        setData({
          lat: data.geometry.location.lat,
          lng: data.geometry.location.lng,
        });
        setFetchingState('success');
      } catch (error) {
        setError(error);
        setFetchingState('error');
      }
    },
    [axios]
  );

  return {
    data,
    fetchByAddress,
    fetchingState,
    error,
    isLoading: fetchingState === 'pending',
    isError: fetchingState === 'error',
    isSuccess: fetchingState === 'success',
  };
};
