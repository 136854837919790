import React, { useEffect } from 'react';
import { MasterPageHeader } from '../../../components/master-page-header/master-page-header';
import { Alert, Button, Descriptions, Form, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { MasterContent } from '../../../master-page/components/master-content/master-content';
import { getFormValues, InjectedFormProps } from 'redux-form';
import { LocationTagForm, mapUserToForm, UserForm } from './user-form.form';
import { formButtonLayout, FormContainer } from '../../../components/form-container/form-container';
import { getInProgress } from '../../../../logic/store/process-tracker/process-tracker.selectors';
import { UserFormFields } from './user-form-fields';
import { ContactInfoFieldName, LocationTagSection, UserFormFieldName } from './user-form-field-name';
import { AnyAction } from 'redux';
import queryString from 'query-string';
import { RouteComponentProps } from 'react-router-dom';
import { userActionCreators } from '../../../../logic/store/user/user.action';
import { RoleSelectOption } from '../../../authentication/models/role/role.select-options';
import { translate, translationKeys } from '../../../../logic/translations/translations.service';
import { getAuthenticatedUserCompany } from '../../../../logic/store/authentication/authentication.selectors';
import { authenticationActionCreators } from '../../../../logic/store/authentication/authentication.actions';
import { UserAvatar } from '../../components/user-avatar/user-avatar';
import { autocompleteActionCreators } from '../../../../logic/store/autocomplete/autocomplete.action';
import { AutocompleteClass } from '../../../../api-models/api-models';
import { ListName, listsActionCreators } from '../../../../logic/store/lists/lists.action';
import { formatParametersToArray } from '../../../../logic/utils/params/params';

export interface UserFormPageOwnProps {
  roleFieldOptions?: RoleSelectOption[];
  fieldsToDisplay: Array<UserFormFieldName | ContactInfoFieldName | LocationTagSection>;
  disabledFields: Array<UserFormFieldName | ContactInfoFieldName | LocationTagSection>;
  disabledForHisself?: Array<UserFormFieldName | ContactInfoFieldName | LocationTagSection>;
  requiredFields: Array<UserFormFieldName | ContactInfoFieldName | LocationTagSection>;
  submitButtonText: string;
  submitFormAction: (
    formName: string,
    formValues: UserForm,
    successMessage: string,
    successRedirectRoute: string,
    editingUserListOfTags?: LocationTagForm[]
  ) => AnyAction;
  submitSuccessMessage: string;
  submitSuccessRedirectionRoute: string;
  formName: string;
  showCompanyUsersLimitSection?: boolean;
}

export interface UserFormTemplatePageProps
  extends RouteComponentProps,
    UserFormPageOwnProps,
    InjectedFormProps<UserForm> {}

export const UserFormTemplatePage: React.FC<UserFormTemplatePageProps> = ({
  roleFieldOptions,
  fieldsToDisplay,
  disabledForHisself,
  disabledFields,
  requiredFields,
  submitButtonText,
  submitFormAction,
  submitSuccessMessage,
  submitSuccessRedirectionRoute,
  formName,
  location,
  error,
  showCompanyUsersLimitSection,
}) => {
  const dispatch = useDispatch();
  const formValues: UserForm = useSelector(getFormValues(formName)) || {};
  const { id, company_id, is_sso_provided } = formValues;
  const company = useSelector(getAuthenticatedUserCompany);
  const fetchUserInProgress = useSelector(getInProgress(userActionCreators.fetchSingleForEdit.name));
  const submitInProgress = useSelector(getInProgress(submitFormAction.name));
  const refreshUserCompanyInProgress = useSelector(getInProgress(authenticationActionCreators.refreshUserCompany.name));
  const inProgress = fetchUserInProgress || submitInProgress;

  useEffect(() => {
    const { id } = queryString.parse(location.search, { arrayFormat: 'comma' });

    const [firstIdParameter] = formatParametersToArray(id);

    if (!!firstIdParameter) {
      dispatch(userActionCreators.fetchSingleForEdit(firstIdParameter, formName, mapUserToForm));
    }
    if (showCompanyUsersLimitSection) {
      dispatch(authenticationActionCreators.refreshUserCompany());
    }
    if (company_id) {
      dispatch(autocompleteActionCreators.fetchAutocomplete(company_id, AutocompleteClass.User));
    }
  }, [dispatch, formName, location.search, showCompanyUsersLimitSection, company_id]);

  useEffect(() => {
    dispatch(listsActionCreators.fetchList(ListName.location, { is_e911_enabled: false }));
  }, [dispatch]);

  const ssoDisabledFields = [
    ...(company?.is_permission_mapping_enabled && is_sso_provided ? [UserFormFieldName.permission_level] : []),
    ...(company?.is_company_group_mapping_enabled && is_sso_provided ? [UserFormFieldName.groups_ids] : []),
  ];

  const handleSubmitFormAction = () =>
    dispatch(submitFormAction(formName, formValues, submitSuccessMessage, submitSuccessRedirectionRoute));

  return (
    <>
      <MasterPageHeader showBack />
      <MasterContent>
        <FormContainer inProgress={fetchUserInProgress}>
          {showCompanyUsersLimitSection && (
            <Form.Item {...formButtonLayout}>
              <Spin spinning={refreshUserCompanyInProgress}>
                <Descriptions column={{ md: 1, sm: 1 }}>
                  <Descriptions.Item label={translate(translationKeys.forms.fields.company.max_users_no)}>
                    {company?.max_users_no}
                  </Descriptions.Item>
                  <Descriptions.Item label={translate(translationKeys.forms.fields.company.users_no)}>
                    {company?.users_no}
                  </Descriptions.Item>
                </Descriptions>
              </Spin>
            </Form.Item>
          )}

          <UserAvatar userId={id} />

          <UserFormFields
            formValues={formValues}
            inProgress={inProgress}
            onPressEnter={handleSubmitFormAction}
            roleFieldOptions={roleFieldOptions}
            fieldsToDisplay={fieldsToDisplay}
            disabledFields={[...disabledFields, ...ssoDisabledFields]}
            disabledForHisself={disabledForHisself}
            requiredFields={requiredFields}
            id={id}
          />

          {error && (
            <Form.Item {...formButtonLayout}>
              <Alert message={translate(translationKeys.errors.error)} description={error} type={'error'} showIcon />
            </Form.Item>
          )}

          <Form.Item {...formButtonLayout}>
            <Button
              type={'primary'}
              className={'form-button'}
              onClick={handleSubmitFormAction}
              disabled={inProgress}
              loading={submitInProgress}
            >
              {submitButtonText}
            </Button>
          </Form.Item>
        </FormContainer>
      </MasterContent>
    </>
  );
};
