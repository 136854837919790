import React from 'react';
import { Form, Button } from 'antd';
import { authenticationActionCreators } from '../../../../logic/store/authentication/authentication.actions';
import { useDispatch, useSelector } from 'react-redux';
import { translate, translationKeys } from '../../../../logic/translations/translations.service';
import { reduxForm, InjectedFormProps, Field, getFormValues } from 'redux-form';
import { FormTextInput } from '../../../components/form-inputs/form-text-input/form-text-input';
import { FormContainer, formButtonLayout, formItemLayout } from '../../../components/form-container/form-container';
import { getInProgress } from '../../../../logic/store/process-tracker/process-tracker.selectors';
import {
  CompleteNewPasswordForm,
  COMPLETE_NEW_PASSWORD_FORM_NAME,
  validateCompleteNewPasswordForm,
} from './complete-new-password.form';
import { AuthenticationMasterPage } from '../authentication-master/authentication-master.page';
import { isDevelopment, env } from '../../../../logic/env/env';
import { Logo } from '../../../components/logo/logo';
import { trimValue } from '../../../../logic/formaters/formaters';

interface CompleteNewPasswordPageProps extends InjectedFormProps<CompleteNewPasswordForm> {}

const CompleteNewPassword: React.FC<CompleteNewPasswordPageProps> = () => {
  const dispatch = useDispatch();
  const formValues: CompleteNewPasswordForm = useSelector(getFormValues(COMPLETE_NEW_PASSWORD_FORM_NAME)) || {};
  const inProgress = useSelector(getInProgress(authenticationActionCreators.completeNewPassword.name));

  const handleCompleteNewPassword = () => {
    dispatch(authenticationActionCreators.completeNewPassword(formValues));
  };

  return (
    <AuthenticationMasterPage>
      <FormContainer>
        <Form.Item {...formButtonLayout}>
          <Logo />
          {translate(translationKeys.messages.setYourPersonalPassword)}
        </Form.Item>
        <Field
          name={'newPassword'}
          component={FormTextInput}
          label={translate(translationKeys.forms.fields.newPassword)}
          isPassword
          required
          toolTipMessage={translate(translationKeys.forms.fields.passwordToolTip)}
          disabled={inProgress}
          onPressEnter={handleCompleteNewPassword}
          onBlurFormater={trimValue}
          {...formItemLayout}
        />
        <Form.Item {...formButtonLayout}>
          <Button
            type={'primary'}
            className={'form-button'}
            onClick={handleCompleteNewPassword}
            disabled={inProgress}
            loading={inProgress}
          >
            {translate(translationKeys.buttons.changePassword)}
          </Button>
        </Form.Item>
      </FormContainer>
    </AuthenticationMasterPage>
  );
};

const initialValues = isDevelopment()
  ? {
      newPassword: env.REACT_APP_DEV_PASSWORD,
    }
  : {};

export const CompleteNewPasswordPage = reduxForm({
  form: COMPLETE_NEW_PASSWORD_FORM_NAME,
  validate: validateCompleteNewPasswordForm,
  initialValues,
})(CompleteNewPassword);
