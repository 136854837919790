import React, { useState } from 'react';
import { WrappedFieldProps } from 'redux-form';
import { FormItemProps } from 'antd/lib/form';
import { Form } from 'antd';
import { SketchPicker, ColorResult } from 'react-color';
import './form-color-input.styles.scss';
import { ColorSwatch } from '../../color-swatch/color-swatch';

interface OwnProps {
  disabled?: boolean;
}

type FormColorInputProps = WrappedFieldProps & FormItemProps & OwnProps;

export const FormColorInput = ({
  label,
  required,
  labelCol,
  wrapperCol,
  meta,
  input,
  disabled,
}: FormColorInputProps) => {
  const [opened, setOpened] = useState(false);
  const errorMessage = !!meta && meta.error;
  const showError = !!meta && meta.touched && !!meta.error;
  const { value, onChange } = input;

  const handleClick = () => setOpened(!opened);
  const handleClose = () => setOpened(false);
  const handleChange = (color: ColorResult) => onChange(color.hex);

  return (
    <Form.Item
      label={label}
      required={required}
      validateStatus={showError ? 'error' : undefined}
      help={showError ? errorMessage : undefined}
      labelCol={labelCol}
      wrapperCol={wrapperCol}
    >
      <div className={'color-input-container'}>
        <div className={`color-button ${disabled ? 'disabled' : ''}`} onClick={!disabled ? handleClick : undefined}>
          <ColorSwatch color={value} />
        </div>
        {opened ? (
          <div className={'popover'}>
            <div className={'cover'} onClick={handleClose} />
            <SketchPicker color={value} onChange={handleChange} />
          </div>
        ) : null}
      </div>
    </Form.Item>
  );
};
