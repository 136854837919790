import { authenticationSagaWatch } from '../logic/store/authentication/authentication.saga';
import { navigationSagaWatch } from '../logic/store/navigation/navigation.saga';
import { userSagaWatch } from '../logic/store/user/user.saga';
import { companySagaWatch } from '../logic/store/company/company.saga';
import { SagaMiddleware } from 'redux-saga';
import { groupSagaWatch } from '../logic/store/group/group.saga';
import { alertSagaWatch } from '../logic/store/alert/alert.saga';
import { channelSagaWatch } from '../logic/store/channel/channel.saga';
import { listsSagaWatch } from '../logic/store/lists/lists.saga';
import { tableSagaWatch } from '../logic/store/tables/tables.saga';
import { locationE911SagaWatch } from '../logic/store/location/location-e911/location-e911.saga';
import { initializationSagaWatch } from '../logic/store/initialization/initialization.saga';
import { autocompleteSagaWatch } from '../logic/store/autocomplete/autocomplete.saga';
import { locationSagaWatch } from '../logic/store/location/location/location.saga';
import { ssoCompanyAdminsSagaWatch } from '../logic/store/sso-company-admins/sso-company-admins.saga';
import { companyDomainsSagaWatch } from '../logic/store/company-domains/company-domains.saga';
import { ssoGroupMappingSagaWatch } from '../logic/store/sso-group-mapping/sso-group-mapping.saga';
import { ssoCompanyGroupMappingSagaWatch } from '../logic/store/sso-company-group-mapping/sso-company-group-mapping.saga';

export const runSagaWatchers = (sagaMiddleware: SagaMiddleware<object>) => {
  sagaMiddleware.run(initializationSagaWatch);
  sagaMiddleware.run(authenticationSagaWatch);
  sagaMiddleware.run(navigationSagaWatch);
  sagaMiddleware.run(userSagaWatch);
  sagaMiddleware.run(companySagaWatch);
  sagaMiddleware.run(groupSagaWatch);
  sagaMiddleware.run(alertSagaWatch);
  sagaMiddleware.run(channelSagaWatch);
  sagaMiddleware.run(listsSagaWatch);
  sagaMiddleware.run(tableSagaWatch);
  sagaMiddleware.run(locationE911SagaWatch);
  sagaMiddleware.run(locationSagaWatch);
  sagaMiddleware.run(autocompleteSagaWatch);
  sagaMiddleware.run(ssoCompanyAdminsSagaWatch);
  sagaMiddleware.run(companyDomainsSagaWatch);
  sagaMiddleware.run(ssoGroupMappingSagaWatch);
  sagaMiddleware.run(ssoCompanyGroupMappingSagaWatch);
};
