import { env, isProduction } from '../env/env';
import { createAxios, getCommonHeaders, getAuthenticationHeaders } from './axios';
import { AxiosError, AxiosResponse } from 'axios';
import { NetworkCode } from '../../models/network-code';
import { store } from '../../app/app.store';
import { getAuthenticatedUserId, getIdToken } from '../store/authentication/authentication.selectors';
import { authenticationApi } from '../store/authentication/authentication.api';
import { authenticationActionCreators } from '../store/authentication/authentication.actions';
import { logActionCreators } from '../store/log/log.actions';
import { translate, translationKeys } from '../translations/translations.service';

export const axiosAuthenticatedClient = (headers?: object) =>
  createAxios(
    {
      baseURL: env.REACT_APP_API_URL,
      headers: {
        ...getCommonHeaders(),
        ...getAuthenticationHeaders(),
        ...headers,
      },
    },
    authenticationInterceptorOnRejected
  );

const authenticationInterceptorOnRejected = async (originalError: AxiosError): Promise<AxiosResponse> => {
  const storeState = store.getState();
  const idToken = getIdToken(storeState);
  const userId = getAuthenticatedUserId(storeState);

  const { config: originalRequest, response } = originalError;

  if (response && response.status === NetworkCode.Unauthorized && userId && idToken) {
    try {
      const cognitoUser = await authenticationApi.getCurrentAuthenticatedUser();
      if (!cognitoUser) {
        throw new Error();
      }

      const currentSession: any = await authenticationApi.getCurrentSession();
      if (!currentSession) {
        throw new Error();
      }

      const { idToken } = await authenticationApi.refreshSession(cognitoUser, currentSession.refreshToken);

      store.dispatch(authenticationActionCreators.setIdToken(idToken?.jwtToken, cognitoUser));
      if (!isProduction()) {
        store.dispatch(logActionCreators.logSuccess(translate(translationKeys.errors.tokenSuccessfullyRefreshed)));
      }

      originalRequest.headers!['Authorization'] = `Bearer ${idToken?.jwtToken}`;

      return axiosAuthenticatedClient().request(originalRequest);
    } catch (error) {
      store.dispatch(authenticationActionCreators.logout());
    }
  }

  return Promise.reject(originalError);
};
