import { AccountStatus, AnalyticSectionName, OsType, Role, UserBulkOperation } from '../../api-models/api-models';
import {
  AlertDocumentFieldName,
  AlertDocumentTypeName,
} from '../../pages/alerts/pages/alert-documents/alert-documents.field-name';
import {
  AlertTypeButtonFieldName,
  AlertTypeFormFieldName,
  E911Behavior,
} from '../../pages/alerts/pages/alert-type-form-template/alert-type.form.field-name';
import {
  ContactInfoFieldName,
  LocationTagFieldName,
  LocationTagSection,
  UserFormFieldName,
} from '../../pages/users/pages/user-form-template/user-form-field-name';

import { AlertCustomResponsesFieldName } from '../../pages/alerts/pages/alert-custom-responses/alert-costom-responses-form/alert-custom-responses.field-name';
import { AlertFieldName } from '../../pages/alerts/pages/alert-template/alerts.field-name';
import { AlertFileFieldName } from '../../pages/alerts/pages/alert-documents/alert-file-form/alert-file-form.field-name';
import { AlertLinkFieldName } from '../../pages/alerts/pages/alert-documents/alert-link-form/alert-link-form.field-name';
import { AnalyticColumnName } from '../../pages/analytics/analytics.model';
import { ChannelFormFieldName } from '../../pages/channels/pages/channels/channel-form.field-name';
import { CompanyFormFieldName } from '../../pages/company/pages/company-form-template/company-form-field-name';
import { GroupFormFieldName } from '../../pages/group/pages/group-form-template/group-form.field-name';
import { LastActiveBreak } from '../../pages/users/components/last-active/last-active-break.model';
import { LocationFormFieldName } from '../../common/location/location-form.field-name';
import { Resource } from '../../pages/components/resources/resource.const';
import { SupportContactFormFieldName } from '../../pages/support/support.contact-form.field-name';
import { RadiusRange } from '../../pages/location-e911/pages/location-e911/location-e911-form.radius-name';
import architectureDiagramImg from '../../assets/architecture-diagram.png';
import { SsoCompanyAdminsFormFieldName } from '../../pages/sso-company-admins/pages/sso-company-admins-form-template/sso-company-admins-form-field-name';
import { CompanyDomainsFormFieldName } from '../../pages/company-domains/pages/domains-forms/company-domain-form.field-name';
import { SsoGroupMappingFormFieldName } from '../../pages/sso-group-mapping/pages/sso-group-mapping-form-template/sso-group-mapping-form-field-name';
import { SsoCompanyGroupMappingFormFieldName } from '../../pages/sso-company-group-mapping/pages/sso-company-group-mapping-form-template/sso-company-group-mapping-form-field-name';

const INSTALLER_PREV = '(previous version)';

export const en = {
  application: {
    name: 'TeamAlert {{appVersion}}',
  },
  errors: {
    login: {
      incorrectCredentials: 'Email or password incorrect',
    },
    unknown: 'Unknown error',
    sessionExpired: 'Session Expired',
    tokenSuccessfullyRefreshed: 'Token successfully refreshed',
    noAuthentication: 'No authentication',
    noAuthorization: 'No authorization',
    notFound: 'Not found',
    error: 'Error',
    incorrectUserName: 'Incorrect username.',
    passwordAttemptsExceeded: 'Password attempts exceeded. Try login later.',
    unableToActivateUser: 'Unable to activate user',
    ssoCognitoGeneralError: 'Signing in failed.',
    ssoCognitoTooManyGroupsError:
      'More than 2048 characters received from user group membership. Decrease the number of characters from your group membership and try again.',
  },
  messages: {
    success: 'Success',
    youCreatedNewPassword: 'You created a new password. Use it to log in to your account.',
    putYourEmail: 'Put your email address and we will send you a reset password code',
    checkYourEmail: 'Check your email. A reset code has been sent to you.',
    setYourPersonalPassword:
      'Thank you for using TeamAlert. Please set your personal password to access the web control panel.',

    superAdminWasSuccessfullyCreated: 'Super admin user was successfully created',
    companyAdminWasSuccessfullyCreated: 'Company admin was successfully created',
    groupAdminWasSuccessfullyCreated: 'Group admin successfully created',

    userWasSuccessfullyCreated: 'User was successfully created',

    superAdminWasSuccessfullyUpdated: 'Super admin was successfully updated',
    companyAdminWasSuccessfullyUpdated: 'Company admin was successfully updated',
    groupAdminWasSuccessfullyUpdated: 'Group admin was successfully updated',
    userWasSuccessfullyUpdated: 'User was successfully updated',

    companyWasSuccessfullyCreated: 'Company was successfully created',
    companyWasSuccessfullyUpdated: 'Company was successfully updated',

    companyDomainWasSuccessfullyCreated: 'Company Domain was successfully created',
    companyDomainWasSuccessfullyUpdated: 'Company Domain was successfully updated',
    companyDomainWasSuccessfullyDeleted: 'Company Domain was successfully deleted',

    ssoCompanyAdminsSuccessfullyCreate: 'SSO Company Admin successfully created.',

    ssoCompanyGroupMappingSuccessfullyCreate: 'SSO Company group mapping successfully created.',
    ssoCompanyGroupMappingSuccessfullyUpdated: 'SSO Company group mapping successfully updated.',
    ssoCompanyGroupMappingSuccessfullyDeleted: 'SSO Company group mapping successfully deleted.',

    ssoGroupMappingSuccessfullyCreate: 'SSO permission mapping successfully created.',
    ssoGroupMappingSuccessfullyUpdated: 'SSO permission mapping successfully updated.',
    ssoGroupMappingSuccessfullyDeleted: 'SSO permission mapping successfully deleted.',

    groupWasSuccessfullyCreated: 'Group was successfully created',
    groupWasSuccessfullyUpdated: 'Group was successfully updated',
    groupWasSuccessfullyDeleted: 'Group was successfully deleted',

    locationE911WasSuccessfullyCreated: 'EC Location was successfully created',
    locationE911WasSuccessfullyUpdated: 'EC Location was successfully updated',
    locationE911SentEmailAboutUpdate:
      'Your request of EC address change has been sent. If you do not see this change or receive a notification of this change within 24 hours, please contact TeamAlert support',
    locationE911WasSuccessfullyDeleted: 'EC Location was successfully deleted',

    locationWasSuccessfullyCreated: 'Location was successfully created',
    locationWasSuccessfullyUpdated: 'Location was successfully updated',
    locationSentEmailAboutUpdate:
      'Your request of address change has been sent. If you do not see this change or receive a notification of this change within 24 hours, please contact TeamAlert support',
    locationWasSuccessfullyDeleted: 'Location was successfully deleted',

    alertWasSuccessfullyCreated: 'Alert was successfully created',
    alertWasSuccessfullyUpdated: 'Alert was successfully updated',
    alertWasSuccessfullyRaised: 'Alert was successfully raised',
    alertWasSuccessfullyLowered: 'Alert was successfully lowered',
    alertWasSuccessfullyDeleted: 'Alert was successfully deleted',

    fileWasSuccessfullyAddedToAlert: 'File was successfully added to alert',
    linkWasSuccessfullyAddedToAlert: 'Link was successfully added to alert',
    customResponseWasSuccessfullyAddedToAlert: 'Custom Response was successfully added to alert',
    customResponseWasSuccessfullyDeleted: 'Custom Response was successfully deleted',
    documentWasSuccessfullyDeleted: 'Document was successfully deleted',

    version: 'version',

    yourSupportRequestWasSentSuccessfully: 'Your support request was sent successfully',
    downloading: 'downloading',

    newPasswordWasSuccessfullySet: 'New password was successfully set for {{userName}}',

    usersBulkOperationSussess: {
      [UserBulkOperation.Delete]: '{{count}} users was successfully deleted',
      [UserBulkOperation.Invite]: '{{count}} users was successfully invited',
      [UserBulkOperation.Reinvite]: '{{count}} users was successfully reinvited',
    },
    userOperationSussess: {
      [UserBulkOperation.Delete]: 'User {{userName}} was successfully deleted',
      [UserBulkOperation.Invite]: 'User {{userName}} was successfully invited',
      [UserBulkOperation.Reinvite]: 'User {{userName}} was successfully reinvited',
    },
    usersBulkOperationFailure: {
      [UserBulkOperation.Delete]: 'Unable to delete {{count}} users. {{errorMessage}}',
      [UserBulkOperation.Invite]: 'Unable to invite {{count}} users. {{errorMessage}}',
      [UserBulkOperation.Reinvite]: 'Unable to reinvite {{count}} users. {{errorMessage}}',
    },
    userOperationFailure: {
      [UserBulkOperation.Delete]: 'Unable to delete user {{userName}}. {{errorMessage}}',
      [UserBulkOperation.Invite]: 'Unable to invite user {{userName}}. {{errorMessage}}',
      [UserBulkOperation.Reinvite]: 'Unable to reinvite user {{userName}}. {{errorMessage}}',
    },

    noticeAboutBindingAgreements: `By logging in you accept the <a href='https://teamalert.com/privacy-policy' target='_blank' rel='noopener noreferrer'>privacy policy</a>, <a href='https://teamalert.com/terms-of-use' target='_blank' rel='noopener noreferrer'>terms and conditions</a> and <a href='https://teamalert.com/eula' target='_blank' rel='noopener noreferrer'>EULA</a> of TeamAlert.`,

    loading: 'Loading',
  },
  signUp: {
    startYourTrial: 'Quick and Easy',
    aboutTrial: 'About Trial',
    whatIsIncluded: "What's included in the free trial?",
    unlimitedAccess: '14 days of unlimited access to features',
    noStringsAttached: 'No strings attached, no contract, no credit card required',
    industryLeadingTool: 'Industry-leading tool to allow your organization to get alerts sent out quickly and easily',
    directSupportFromTeam: 'Direct support from our team of professionals',
    alreadyHaveAnAccount: 'Already have an account?',
    signIn: 'Sign In',
  },
  signUpSuccess: {
    registrationComplete: 'Registration complete',
    paragraph1: 'Thank you for creating your account. We just sent you a welcome email, to ',
    paragraph2:
      'that shows all the details of your account. We are currently reviewing your request and once we confirmed your account you will receive the temporary password.',
    paragraph3:
      ' Someone from TeamAlert may reach out to verify your company information before confirming your trial account. We look forward to working with you.',
  },
  forms: {
    fields: {
      user: {
        [UserFormFieldName.company_id]: 'Company',
        [UserFormFieldName.contact_info]: 'Contact Info',
        [UserFormFieldName.email]: 'Email',
        [UserFormFieldName.is_email_verified]: 'Email needs to be verified',
        isEmailVerifiedTooltip:
          'For non-verified option you have to set a password for the user. Please note that the features for inviting, re-inviting as well as forgot password by the user will not be available. Only you will be able to change the password',
        [UserFormFieldName.password]: 'Password',
        [UserFormFieldName.setNewPasswordButton]: 'Set New Password',
        setNewPasswordModalTitle: 'Set new password for {{userName}}',
        [UserFormFieldName.first_name]: 'First Name',
        [UserFormFieldName.groups]: 'Groups',
        [UserFormFieldName.groups_ids]: 'Groups',
        [UserFormFieldName.id]: 'Id',
        [UserFormFieldName.last_name]: 'Last Name',
        [UserFormFieldName.permission_level]: 'Role',
        [UserFormFieldName.status]: 'Account Status',
        [LocationTagSection.header]: 'Location Tag',
        [LocationTagFieldName.location_id]: 'Location name',
        [LocationTagFieldName.tag_name]: 'Location tag',
        [UserFormFieldName.default_location_header]: 'Default Location',
        [UserFormFieldName.default_location_label]: 'Name',

        [UserFormFieldName.main_alerts_block_Header]: '',
        main_alerts_block_Title: 'Main Alerts',
        main_alerts_block_Description: `This is an optional section that allows you to set a priority for up to two alerts. These alerts will be given more visibility in the application making the alerts easier for users to raise. If you don't want any alerts to have priority, then leave this blank.`,
        [UserFormFieldName.main1_alert_type_id]: 'Priority 1 alert',
        [UserFormFieldName.main2_alert_type_id]: 'Priority 2 alert',

        [UserFormFieldName.hardware_devices_block_Header]: '',
        hardware_devices_block_Title: 'Hardware Devices',
        hardware_devices_block_Description: `This is an optional section for this user that is operating a TeamAlert hardware device. If a user does not have a TeamAlert hardware device, leave this blank.`,
        [UserFormFieldName.if_button_1_pressed_header]: 'If Red Button Pressed (Button 1)',
        [UserFormFieldName.if_button_2_pressed_header]: 'If Blue Button Pressed (Button 2)',
        [UserFormFieldName.hb1_alert_type_id]: 'Alert to Raise',
        [UserFormFieldName.hb2_alert_type_id]: 'Alert to Raise',
        [UserFormFieldName.hb1_message]: 'Message Sent when Pressed',
        [UserFormFieldName.hb2_message]: 'Message Sent when Pressed',
        [UserFormFieldName.last_active]: 'Last active',
      },
      contact_info: {
        [ContactInfoFieldName.address]: 'Address',
        [ContactInfoFieldName.sms_phone_country_code]: 'Text Message Number Country Code',
        [ContactInfoFieldName.sms_phone]: 'Text Message Number',
        [ContactInfoFieldName.city]: 'City',
        [ContactInfoFieldName.department]: 'Department / Room',
        [ContactInfoFieldName.voice_call_phone_country_code]: 'Voice Call Number Country Code',
        [ContactInfoFieldName.voice_call_phone]: 'Voice Call Number',
        [ContactInfoFieldName.state]: 'State Code',
        [ContactInfoFieldName.zip_code]: 'Zip',
      },
      company: {
        [CompanyFormFieldName.address]: 'Address',
        [CompanyFormFieldName.admins]: 'Admins',
        [CompanyFormFieldName.alerts]: 'Alerts',
        [CompanyFormFieldName.city]: 'City',
        [CompanyFormFieldName.groups]: 'Groups',
        [CompanyFormFieldName.id]: 'Id',
        [CompanyFormFieldName.isActive]: 'Is active',
        [CompanyFormFieldName.isTrial]: 'Is trial',
        [CompanyFormFieldName.created_at]: 'Registry date',
        [CompanyFormFieldName.trial_to_date]: 'Expiry date',
        [CompanyFormFieldName.trial_alerts_limit]: 'Trial alert limit',
        [CompanyFormFieldName.name]: 'Company Name',
        [CompanyFormFieldName.phone_country_code]: 'Phone Country Code',
        [CompanyFormFieldName.phone]: 'Phone',
        [CompanyFormFieldName.state]: 'State Code',
        [CompanyFormFieldName.tokens]: 'Tokens',
        [CompanyFormFieldName.users]: 'Users',
        [CompanyFormFieldName.zip_code]: 'Zip',
        [CompanyFormFieldName.country]: 'Country',
        [CompanyFormFieldName.industry]: 'Industry',
        [CompanyFormFieldName.footer_note]: 'Footer note on desktop',
        [CompanyFormFieldName.users_no]: 'Active users',
        [CompanyFormFieldName.max_users_no]: 'Users limit',
        [CompanyFormFieldName.is_e911_enabled]: 'EC enabled',
        [CompanyFormFieldName.is_e911_test_enabled]: 'E911 test enabled',
        is_e911_test_enabled_tooltip:
          'This feature allows TeamAlert to see that your E911 feature is functional without contacting your local emergency services. To test E911 flip to toggle button to enabled and call your TeamAlert representative to confirm that your E911 service is operational.',
        [CompanyFormFieldName.e911Header]: 'EC',
        [CompanyFormFieldName.e911HeaderDescription]:
          'Optional Settings - You must contact TeamAlert to get your EC phone number(s). Once your EC number is listed on your EC Add-on’s tab you are able to turn on EC Company wide. With EC turned on you will be able to select which alerts have EC functionality. EC functionality allows you to notify local emergency services that you need assistance when an alert with that feature enabled is triggered.',
        [CompanyFormFieldName.companyInfoHeader]: 'Company Info',
        [CompanyFormFieldName.trialHeader]: 'Trial',
        [CompanyFormFieldName.ssoHeader]: 'SSO',
        [CompanyFormFieldName.is_sso_enabled]: 'SSO enabled',
        is_sso_disabled_tooltip: 'For SSO feature to fully work you need to add manual configuration for a company',
        is_sso_enabled_tooltip: `
          <p>Steps for SSO manual configuration in Web Panel</p>
          <p>1. Add a new company (if it is not there already) and activate it.</p>
          <p>2. Add Company Admin.</p>
          <p>3. Add SSO Company Admin.</p>
          <p>4. Add company domain.</p>
          <p>Company Admin needs to map group permissions in order to receive proper permissions in our applications.</p>
        `,
        [CompanyFormFieldName.is_permission_mapping_enabled]: 'Permission mapping',
        is_permission_mapping_enabled_tooltip:
          'Enable SSO permission mapping. Make sure that you map permissions before enabling it.',
        [CompanyFormFieldName.is_company_group_mapping_enabled]: 'Company group mapping',
        is_company_group_mapping_enabled_tooltip:
          'Enable SSO company group mapping. Make sure that you map groups before enabling it.',
        [CompanyFormFieldName.appsHeader]: 'Applications',
        [CompanyFormFieldName.is_updates_on]: 'Desktop app updates',
        [CompanyFormFieldName.multisite_header]: 'Multi-site',
        [CompanyFormFieldName.is_multisite_enabled]: 'Multi-site enabled',
        [CompanyFormFieldName.locations_no]: 'Active locations',
        [CompanyFormFieldName.max_locations_no]: 'Locations limit',
      },
      companyDomains: {
        [CompanyDomainsFormFieldName.id]: 'Id',
        [CompanyDomainsFormFieldName.company]: 'Company Id',
        [CompanyDomainsFormFieldName.company_name]: 'Company Name',
        [CompanyDomainsFormFieldName.domain]: 'Domain Name',
      },
      ssoCompanyAdmins: {
        [SsoCompanyAdminsFormFieldName.company]: 'Company',
        [SsoCompanyAdminsFormFieldName.company_name]: 'Company Name',
        [SsoCompanyAdminsFormFieldName.email]: 'Email',
      },
      ssoGroupMapping: {
        [SsoGroupMappingFormFieldName.company]: 'Company',
        [SsoGroupMappingFormFieldName.company_name]: 'Company Name',
        [SsoGroupMappingFormFieldName.sso_group_name]: 'SSO Group ID',
        [SsoGroupMappingFormFieldName.corresponding_permission_level]: 'Corresponding Permission Level',
      },
      ssoCompanyGroupMapping: {
        [SsoCompanyGroupMappingFormFieldName.company]: 'Company',
        [SsoCompanyGroupMappingFormFieldName.company_name]: 'Company Name',
        [SsoCompanyGroupMappingFormFieldName.sso_group_name]: 'SSO Group ID',
        [SsoCompanyGroupMappingFormFieldName.company_group]: 'Company Group ID',
        [SsoCompanyGroupMappingFormFieldName.company_group_name]: 'Company Group Name',
        company_group_tooltip: 'You need to add company group in Groups page before they are available here to map.',
      },
      group: {
        [GroupFormFieldName.id]: 'Id',
        [GroupFormFieldName.name]: 'Group Name',
        [GroupFormFieldName.dept]: 'Department',
        [GroupFormFieldName.notes]: 'Notes',
        [GroupFormFieldName.company_id]: 'Company',
        [GroupFormFieldName.address]: 'Address',
        [GroupFormFieldName.city]: 'City',
        [GroupFormFieldName.phone_country_code]: 'Phone Country Code',
        [GroupFormFieldName.phone]: 'Phone',
        [GroupFormFieldName.zip_code]: 'Zip',
        [GroupFormFieldName.state]: 'State Code',
        [GroupFormFieldName.users_ids]: 'Users',
        [GroupFormFieldName.admins_ids]: 'Group Admins',
        [GroupFormFieldName.number_of_users]: 'No. of Users',
        [GroupFormFieldName.location_id]: 'EC Location',
        [GroupFormFieldName.location_name]: 'EC Location',
        adminsIdsTooltip: 'These are users that will be able to add and remove',
        optionalSectionDescription: `This is an optional section for a group related to a physical location. If this group is not related to a physical location, then leave this section blank.`,
      },
      location: {
        [LocationFormFieldName.id]: 'Id',
        [LocationFormFieldName.name]: 'Location Name',
        [LocationFormFieldName.company_id]: 'Company',
        [LocationFormFieldName.address]: 'Address',
        [LocationFormFieldName.city]: 'City',
        [LocationFormFieldName.zip_code]: 'Zip',
        [LocationFormFieldName.state]: 'State Code',
        [LocationFormFieldName.country]: 'Country',
        [LocationFormFieldName.user_ids]: 'Users',
        [LocationFormFieldName.notes]: 'Notes',
        [LocationFormFieldName.geolocation]: 'Geolocation Position',
        [LocationFormFieldName.is_geolocation_enabled]: 'EC alert area',
        gelocationTooltipMessage: 'Enable EC alert area for mobile devices',
        geoLocationRadiusLabel: 'Select area radius',
        geolocationError:
          'Incorrect geolocation. Please check if you have pinned a marker on the map and selected a radius.',
        findLocationAddress: 'Set area based on address above',
        geolocationFindFailed: 'Failed to get coordinates. Please click manually on map to set geolocation',
        geolocationFindSuccess:
          'The address of the location has been set on the map. You can change it by clicking on the map',
        geolocationFormSubmitError:
          'Incorrect geolocation. Please check if you have pinned a marker on the map and if you have selected a radius',

        [RadiusRange.SMALL]: 'Small (328ft = 100 meters)',
        [RadiusRange.MEDIUM]: 'Medium (656ft = 500 meters)',
        [RadiusRange.LARGE]: 'Large (3280ft = 1000 meters)',
        updateGeolocationBtn: 'Update geolocation alert area',

        formAlertMessage: 'Please read the description before completing the form',
        formAlertDescription:
          'The EC service allows your TeamAlert System to contact EC via phone call from your TeamAlert System.Your TeamAlert system is registered with EC Services making this possible. You can tie alerts that you specify to contact EC Services. Please verify with your EC service that they will accept these automated alerts. ',
        [LocationFormFieldName.geolocation_id]: 'Location ID',
        [LocationFormFieldName.first_emergency_contact_name]: 'Name',
        [LocationFormFieldName.first_emergency_contact_phone]: 'Phone',
        [LocationFormFieldName.first_emergency_contact_id]: 'EC ID',
        [LocationFormFieldName.first_emergency_contact_phone_country_code]: 'Country Code',
        [LocationFormFieldName.second_emergency_contact_name]: 'Name',
        [LocationFormFieldName.second_emergency_contact_phone]: 'Phone',
        [LocationFormFieldName.second_emergency_contact_id]: 'EC ID',
        [LocationFormFieldName.second_emergency_contact_phone_country_code]: 'Country Code',
        [LocationFormFieldName.third_emergency_contact_name]: 'Name',
        [LocationFormFieldName.third_emergency_contact_phone]: 'Phone',
        [LocationFormFieldName.third_emergency_contact_id]: 'EC ID',
        [LocationFormFieldName.third_emergency_contact_phone_country_code]: 'Country Code',
        [LocationFormFieldName.site]: 'Site',
        emergencyContactsHeader: 'Emergency Contacts',
        emergencyContactsDescription: `<p>When a emergency calls based alert is raised, the following emergency contacts will be contacted in this order to connect with a emergency operator. The emergency contact is contacted first, then the emergency operator is brought into the connection. One of the 3 contacts must answer and stay on the line before emergency is contacted. Hang-ups and dropped calls will result in your emergency dispatch not being contacted. These fields are required. You are allowed to repeat a contact if needed. If you have any questions please <a href='http://manage.teamalert.com/support'>contact us</a>.</p><p>The contacts below will receive the message in the paragraph below when an EC alert is raised:</p><p>Your TeamAlert System has received alert [ALERT_Name] to notify emergency provider, please see your TeamAlert notification for details. We are connecting you with emergency provider dispatch to inform them of details of the event. Please hold while emergency provider is connected. &lt;pause 3 seconds and then repeat until going to connect&gt;</p>`,
        firstEmergencyContact: 'First Contact',
        secondEmergencyContact: 'Second Contact',
        thirdEmergencyContact: 'Third Contact',
        [LocationFormFieldName.emergency_mode]: 'Emergency provider',
        [LocationFormFieldName.monitoring_company_phone_number]: 'Emergency provider phone',
        [LocationFormFieldName.monitoring_company_phone_number_country_code]: 'Emergency provider phone country code',
        [LocationFormFieldName.monitoring_company_voice_message]: 'Additional information',
      },
      alertType: {
        [AlertTypeFormFieldName.id]: 'Id',
        [AlertTypeFormFieldName.name]: 'Alert Name',
        [AlertTypeFormFieldName.cancellation_timeout]: 'Cancel timeout (sec)',
        [AlertTypeFormFieldName.is_active]: 'Is active',
        [AlertTypeFormFieldName.sender_group]: 'Sender group',
        [AlertTypeFormFieldName.recipient_groups]: 'Recipient groups',
        [AlertTypeFormFieldName.recipient_groups_ids]: 'Recipient groups',
        [AlertTypeFormFieldName.sender_group_id]: 'Sender group',
        [AlertTypeFormFieldName.can_be_lowered_by_raising_user]: 'Raising user can lower',
        [AlertTypeFormFieldName.calls_e911]: 'Calls emergency provider',
        [AlertTypeFormFieldName.button_settings]: 'Button settings',
        [AlertTypeFormFieldName.files]: 'Documents',
        [AlertTypeFormFieldName.order]: 'Order',
        [AlertTypeFormFieldName.change_order]: 'Change Order',
        [AlertTypeFormFieldName.language]: 'Language',
        [AlertTypeFormFieldName.instructions]: 'Instructions',
        [AlertTypeFormFieldName.voice_message]: 'Voice Message',
        [AlertTypeFormFieldName.e911_behavior]: 'EC alert behavior',
        canBeLoweredByRaisingUserTooltip:
          'This setting if turned on allows the person that raised the alert to also have the ability to lower this alert. By default users cannot lower active alerts. Only company admins can lower alerts.',
        calls_e911Tooltip:
          'This setting allows you to turn on and off the EC functionality for an alert. If this is turned on, when someone raises an alert then an EC call will be placed giving them the address of your location and the name of the alert. This message is repeated three times before the call is ended.',
        e911_behaviorTooltip: `
          <p>
            <strong>Raise immediately</strong> – Your EC call will send as soon as the alert is raised by any user.
          </p>
          <p>
            <strong>Ask and Wait</strong> – Your EC call will ask the raising user for confirmation that they want to raise this alert. If the person who raises the alert does not confirm by clicking “Yes”, the alert will not send.
          </p>
          <p>
            <strong>Ask with timeout</strong> – Your EC will ask the raising user confirmation that they want to raise this alert. If the raising users does not confirm, the alert will send after 30 seconds.
          </p>
          *NOTE: Only company admins can set the EC alert behaviors. The raising user of the alert has the ability to confirm the EC call when the alert is raised.
        `,
        [AlertTypeFormFieldName.locations_ids]: 'Assigned locations',
        [AlertTypeFormFieldName.locations_names]: 'Assigned locations',
        locationIdsTooltip:
          'Assign alert to certain locations. You will be able to filter alerts in the desktop application for a location. Leave empty for alert to be visible in all locations. Works only with desktop application from version 2.4.5 and up.',
      },
      e911Behavior: {
        [E911Behavior.ASK_AND_WAIT]: 'Ask and wait',
        [E911Behavior.ASK_WITH_TIMEOUT]: 'Ask with timeout',
        [E911Behavior.RAISE_IMMEDIATELY]: 'Raise immediately',
      },
      alertTypeButon: {
        [AlertTypeButtonFieldName.id]: 'Id',
        [AlertTypeButtonFieldName.text_color]: 'Text color',
        [AlertTypeButtonFieldName.background_color]: 'Background color',
        [AlertTypeButtonFieldName.icon_id]: 'Icon',
        [AlertTypeButtonFieldName.icon]: 'Icon',
      },
      alert: {
        [AlertFieldName.id]: 'Id',
        [AlertFieldName.name]: 'Alert name',
        [AlertFieldName.created_at]: 'Created time',
        [AlertFieldName.lowered_at]: 'Lowered time',
        [AlertFieldName.raising_user]: 'Raising user',
        [AlertFieldName.lowering_user]: 'Lowering user',
        [AlertFieldName.messages]: 'Messages',
        chatMessage: 'chat message',
        chatMessages: 'chat messages',
        noChatMessages: 'no chat messages',
      },
      alertLink: {
        [AlertLinkFieldName.url]: 'Link',
        [AlertLinkFieldName.description]: 'Description',
      },
      alertFile: {
        [AlertFileFieldName.file]: 'File',
        [AlertFileFieldName.description]: 'Description',
        [AlertFileFieldName.id]: 'Id',
      },
      alertCustomResponses: {
        [AlertCustomResponsesFieldName.label_text]: 'Label',
        [AlertCustomResponsesFieldName.text]: 'Text',
      },
      channel: {
        [ChannelFormFieldName.id]: 'Id',
        [ChannelFormFieldName.user_id]: 'User Id',
        [ChannelFormFieldName.alert_type_id]: 'Alert Id',
        [ChannelFormFieldName.is_email_enabled]: 'Email',
        [ChannelFormFieldName.is_call_enabled]: 'Call',
        [ChannelFormFieldName.is_sms_enabled]: 'Sms',
        [ChannelFormFieldName.is_desktop_enabled]: 'Desktop',
        [ChannelFormFieldName.user_first_name]: 'First Name',
        [ChannelFormFieldName.user_last_name]: 'Last Name',
        [ChannelFormFieldName.user_department]: 'Department',
      },
      alertDocument: {
        [AlertDocumentFieldName.id]: 'Id',
        [AlertDocumentFieldName.type]: 'Type',
        [AlertDocumentFieldName.fileNameOrUrl]: 'File / Url',
        [AlertDocumentFieldName.description]: 'Description',
        [AlertDocumentFieldName.size]: 'Size',
        [AlertDocumentFieldName.created_at]: 'Created at',
      },
      alertDocumentType: {
        [AlertDocumentTypeName.file]: 'file',
        [AlertDocumentTypeName.url]: 'url',
      },
      supportContactForm: {
        [SupportContactFormFieldName.first_name]: 'First Name',
        [SupportContactFormFieldName.last_name]: 'Last Name',
        [SupportContactFormFieldName.company]: 'Company',
        [SupportContactFormFieldName.email]: 'Email',
        [SupportContactFormFieldName.phone]: 'Phone',
        [SupportContactFormFieldName.message]: 'Message',
      },
      actions: 'Actions',
      password: 'Password',
      code: 'Code',
      newPassword: 'New Password',
      passwordToolTip:
        'Rquires a minimum password length of at least 8 characters, at least 1 uppercase, numeric, and special characters',
    },
  },
  pages: {
    messages: {
      comingSoon: 'Coming soon',
      videoComingSoon: 'Video coming soon',
    },
    dashboard: {
      header: {
        title1: 'Welcome to your ',
        title2: 'Team',
        title3: 'Alert',
        title4: ' Web Control Panel.',
        description1:
          'To setup TeamAlert, please read the instructions below. If you have any questions or need assistance ',
        description2: 'click here',
        description3: ' for support. Thank you from everyone at TeamAlert.',
      },
      setUpAutomatically: {
        title: 'Complete Setup',
        setUpForm: {
          title: 'Set up automatically',
        },
        confirmation: {
          title: 'Set up automatically was done',
          description:
            'TeamAlert Web Control Panel is set up. The system created one group, one alert and notification channels.',
        },
        content: {
          videoTitle: 'Complete Setup Video',
          step1: `
            <p>
              <strong>Step 1.</strong> Now that you are logged into the TeamAlert Web Control Panel, you will need to finish setting up your user profile. Click on the <strong>"Set up automatically"</strong> button.
            </p>
          `,
          setUpButton: {
            heading: 'Finish Setting up Account',
            title: 'Set up automatically',
            description: 'We need a little bit more information to finish setting up your account.',
          },
          step2: `
            <p>
              <strong>Step 2.</strong> Next step is to download the app on your mobile device and computer and get logged using the same credential used to log into this website. For mobile devices use the QR code to go to the app installer.
            </p>
          `,
        },
      },
      setUpManually: {
        title: 'Using Your TeamAlert Web  Control Panel',
        content: `
          <p>
            <strong>Step 1.</strong> Now that you are logged into the TeamAlert Web Control Panel, you will need to set up TeamAlert. You will start by clicking <strong>"Groups"</strong> in the <strong>"Setup Section"</strong> dropdown on the left and create the groups that will receive alerts sent out by your users. We recommend creating at least one group called <strong>"ALL"</strong> that everyone is a member of for urgent alerts. Once your groups are created, you will then click on the <strong>"Alerts"</strong> section. The <strong>"Alerts"</strong> section is where you create the alerts specifically needed for your organization. Next, you will click on <strong>"Users"</strong> and add all the users that will be part of the specified alert group that will use TeamAlert.
            </p>
          <p>
            <strong>Step 2.</strong> Once the groups, alerts, and users have been created, you have one more step so that users will receive alerts. You will want to click on <strong>"Channels"</strong> from the menu on the left and then click <strong>"Manage Channels"</strong> on the right to select how each user will receive their alert notifications for each alert you created.
            </p>
          <p>
            <strong>Step 3.</strong> Download and install the TeamAlert application for Windows or MAC workstations that you would like to send and receive instant alert notifications. You may also download and install the application on your Android or IOS Mobile Phone. Install the app from the app store or use the QR code provided on the installers section of the TeamAlert web control panel.
            </p>
          <p>
            <strong>Step 4.</strong> Once you have the users' information, groups, and alerts set up in your TeamAlert Web Control Panel and the application installed on your user's computers and/or mobile phones, you will want to do a test of the alerts you have created. We recommend raising an alert and putting the words <strong>"TEST, Please respond with received if you receive this notification"</strong> in the chat room so that users know this is a test of the system and respond that they received the alert. Once this is complete, your new system is set up and ready to use.
          </p>
          <p>
            <strong>Note: </strong> TeamAlert recommends that you test the system at least once a quarter to make sure all users are receiving alerts properly and understand what to do with the alerts that they receive.
          </p>
          `,
      },
      installers: {
        title: 'Installers',
        subTitle: 'Please choose the correct installer to match the operating system you are using.',
        regularUserTooltip: 'Recommended for all users, installer in EXE format',
        adminUsersTooltip: 'Recommended for administrators only, installer in MSI format',
        modals: {
          android: {
            title: 'Application for Android',
          },
          iOS: {
            title: 'Application for iOS',
          },
          appleWath: {
            title: 'Application for Apple Watch',
          },
          scanQrCode: 'Scan QR code by your phone',
        },
        note: 'If for some reason you encounter a problem with the version you are installing, we offer the previous version to the right. For mobile users we provide a QR code that you can use to take your mobile device to the app store. The Windows Network Installer is provided for Network Administrators Only to use when automatically deploying TeamAlert to workstations across a network.',
      },
      licenseCount: {
        title: 'License Count',
        usersLimit: 'Users Limit: {{usersLimit}}',
        activeUsers: 'Active users: {{activeUsers}}',
        locationsLimit: 'Locations Limit: {{locationsLimit}}',
        activeLocations: 'Active locations: {{activeLocations}}',
        contactUs: 'If you need a new license, contact us: ',
        email: 'sales@teamalert.com',
      },
      recommendation: {
        title: 'Recommendation',
        content:
          'TeamAlert recommends that you test the system at least once a quarter to make sure all users are receiving alerts properly and understand what to do with the alerts that they receive.',
      },
      welcomePopup: {
        title: 'Welcome To TeamAlert',
        text: 'Welcome to TeamAlert! We have created this short video to help you get started. We see the best results when new users schedule an onboarding session to meet our support team and let them guide you in this process. It’s free and only takes about 15 or 30 minutes. At any time if you need help, please click the Get Help button below to send us a support request. Thank you for choosing TeamAlert. We look forward to working with your team!',
        buttons: {
          onboarding: 'Schedule Onboarding',
          help: 'Get Help',
        },
        checkbox: 'Check this box to no longer see this pop-up.',
      },
    },
    support: {
      title: 'TeamAlert Academy',
      description:
        'Welcome to the TeamAlert Academy. In this section we will do an overview of how to use the TeamAlert Web Control Panel.',
      videoLinkDescription:
        'Please <strong>watch the video</strong> (Alert Academy) to see a tutorial on working with the TeamAlert Web Control Panel.',
      supportDocuments: {
        title: 'Support Documents',
      },
      supportVideos: {
        title: 'Support Videos',
      },
      academy: {
        title: 'TeamAlert Academy',
      },
      faq: {
        title: 'Frequently Asked Questions',
        content: [
          {
            question: 'Q. How does TeamAlert desktop app and hardware devices work?',
            answer: `
              A. See diagram below or download here
              <img src="${architectureDiagramImg}" style="max-width: 100%" />
            `,
          },
          {
            question: 'Q. How do I connect and use the TeamAlert Bluetooth button to my computer or mobile phone?',
            answer: `
              A. For instructions on how to connect your device, choose the link below for your device type.<br/><br/>
              &emsp;Android (<a href="https://teamalert.com/help/mobile-android.php">https://teamalert.com/help/mobile-android.php</a>)<br/>
              &emsp;IOS (<a href="https://teamalert.com/help/mobile-ios.php">https://teamalert.com/help/mobile-ios.php</a>)<br/>
              &emsp;Window (<a href="https://teamalert.com/help/bluetooth-windows.php">https://teamalert.com/help/bluetooth-windows.php</a>)<br/><br/>
              If you are worried about loosing TeamAlert Bluetooth buttons you can use <a href="https://tile.com">tile.com</a> products to locate device anywhere.
            `,
          },
          {
            question: 'Q. How do I learn more about TeamAlert Mobile?',
            answer:
              'A. You can visit this link to learn more about TeamAlert Mobile. <a href="https://www.teamalert.com/teamalert-mobile">https://www.teamalert.com/teamalert-mobile</a>',
          },
          {
            question: 'Q. What if my anti-virus is seeing TeamAlert as a virus or malware?',
            answer:
              'Go in to your antivirus software and do an exception for the TeamAlert program directory. This will stop your antivirus or anti-malware software from seeing TeamAlert as malicious software.',
          },
          {
            question: 'Q. How to get TeamAlert out of your anti-virus quarantine?',
            answer:
              'Locate your anti-virus software and open it. Once it is open find the tab or section called "Quarantine". If TeamAlert is located there click on it, highlight it, or right click on it then click restore. If this did not help then you will need to search for instructions on how to remove a particular program from your specific anti-virus software.',
          },
          {
            question:
              'Q. What if I have trouble receiving the email alerts or I am having an issue with delayed delivery?',
            answer:
              'Please whitelist the domain <strong>mailgun.teamalert.com</strong> on your email service. If you need assistance with this, please contact your email provider.',
          },
          {
            question: 'Q. Changes I just made are not showing up.',
            answer: 'Changes to management dashboard do not update in TeamAlert until you log out then log back in.',
          },
          {
            question: 'Q. What happens if I lose internet connectivity?',
            answer:
              'All initialized alerts and alert communications will be held for a minute or until network comes back, but not indefinitely.',
          },
          {
            question: 'Q: If you are having an issue with your anti-virus not allowing TeamAlert to run.',
            answer: `
            Whitelist the following URL’s
              <p>
                <p>
                  <strong>API: </strong> <a href='https://api.prod.teamalert.com'>https://api.prod.teamalert.com</a>
                </p>
                <p>
                  <strong>Chat: </strong> <a href='wss://ws.prod.teamalert.com'>wss://ws.prod.teamalert.com</a>
                </p>
                <p>
                  <strong>Login: </strong> <a href='https://cognito-idp.us-east-1.amazonaws.com'>https://cognito-idp.us-east-1.amazonaws.com</a>
                </p>
                <p>
                  <strong>Website: </strong> <a href='http://manage.teamalert.com'>http://manage.teamalert.com</a>
                </p>
              </p>
            `,
          },
          {
            question: 'Q. Are you having an issue adding a phone number to a user’s profile?',
            answer:
              'If a phone number does not let you put it in the users profile in the web control panel it can be tested at <a href="https://www.textmagic.com/free-tools/phone-validator">https://www.textmagic.com/free-tools/phone-validator</a>',
          },
          {
            question: 'Q. What do I need to whitelist on my router to allow traffic for TeamAlert?',
            answer: `
              Only the URLs to cognito and web socket service that we've mentioned below.
              <p>
                <p>
                  <strong>API: </strong> <a href='https://api.prod.teamalert.com'>https://api.prod.teamalert.com</a>
                </p>
                <p>
                  <strong>Chat: </strong> <a href='wss://ws.prod.teamalert.com'>wss://ws.prod.teamalert.com</a>
                </p>
                <p>
                  <strong>Login: </strong> <a href='https://cognito-idp.us-east-1.amazonaws.com'>https://cognito-idp.us-east-1.amazonaws.com</a>
                </p>
                <p>
                  <strong>Website: </strong> <a href='http://manage.teamalert.com'>http://manage.teamalert.com</a>
                </p>
              </p>
            `,
          },
          {
            question: 'Q. Does TeamAlert have anything looking back to the application for alerts or when dormant?',
            answer:
              'TeamAlert does have two communication that occurs over web sockets. Please view  the exemption list question and answer to get a list of addresses that need to be whitelisted.',
          },
          {
            question:
              'Q. We have lots of users. Is there a way to auto-install your software and the updates when they come out? I do not want to have to go to every computer.',
            answer: `Yes. Lots of our customers are in this scenario and our application is easy to deploy automatically in a domain environment. In your web control panel, there is a second choice (.msi) for domain admin users. Please download this version and put in a folder that is accessible by all users. You will then create a GPO policy that tells your workstations to install this .msi file when they login. We recommend you use a test user or two before rolling out to your entire domain. You can also uninstall our application to roll back an update the exact same way. If you have any further questions on remotely installing applications
              <a href='https://docs.microsoft.com/en-us/troubleshoot/windows-server/group-policy/use-group-policy-to-install-software'>Microsoft has a create article</a>
              and
              <a href='https://www.advancedinstaller.com/user-guide/tutorial-gpo.html'>Advanced Installer has created a create guide </a>
              that includes how to have an installer log that is helpful if you have problems.
              <br/><br/>
              Unfortunately there is not a way to install TeamAlert updates on a workstation without admin credentials without turning it off that security feature for all applications. Microsoft choose this route to protect computers/users from malicious software updates. If the GPO solution is not a good choice for you there are lots of third parts applications including some open source free ones that can help you deploy and manage this. TeamAlert customer service cannot help you with this but your local IT Support resource should be able to help. If you don't have a local IT resource the TeamAlert Customer Service team has a few options they can recommend if needed.
              <br/><br/>
              You can use additional flags when installing TeamAlert from .msi installer <br/><br/>/quiet - quiet installation <br/> /norestart - installation will not trigger automatic restart<br/><br/>
              Example: msiexec /package teamalert.msi /quiet /norestart
              `,
          },
          {
            question:
              'Q. I have lots of users. Is there a way to import users so that I do not have to set them all up manually? ',
            answer:
              'Yes. We have an import and export feature for users that allows you to import all your users at once  and mass set them up. This is by far the easiest way to setup multiple users at one time. To use this feature from the Web Control Panel, click on Users in the Setup section them click on Import Users. You can import up to 5,000 users at one time. The users first name, last name and email is required. There is a template there that shows you the fields you can enter and the order they are requested in. Once you have created your import CSV file, select the file, and choose import users. It will let you know how many users where imported and let you know the error for the ones that were not imported.',
          },
          {
            question:
              'What phone number do I need to program into my contacts for phone call and text (SMS) message notifications from the TeamAlert app?',
            answer: `Users inside the U.S. and Canada will need to program the following number into your contacts for phone call and text (SMS) notifications - <a href='tel:833-206-9288'>833-206-9288</a>. International users will get notifications from a number pool.`,
          },
        ],
      },
      contactForm: {
        title: 'Support Contact Form',
        description:
          'Our support team is available Monday-Friday 8 am- 5 pm CST. Please complete the message below and click submit. Our support team will respond within 2 business hours.',
      },
    },
    alertTypes: {
      title: 'Alert Management',
      description: `Welcome to the Alerts Management Page. From this page, you can create, edit, and delete your alerts. Alerts are notifications that you create and send out to groups letting the alert receiver(s) group know the sender needs something. There are many types of alerts that you can create specifically for your organization's needs. Alerts can be for important things such as notifying of an emergency or you can use them for everyday things such as requests for extra resources. You are also able to set custom colors and icons from our icon list for each alert. We also allow you to specify up to two alerts per user for increased visibility called “Main Alerts”. This is done from the User Management Page.`,
      videoLinkDescription: 'Click here to see a tutorial on working with Alerts.',
      noteTitle: '<strong>Notes</strong>',
      note1: 'You can have one sender group and multiple recipient groups per alert created.',
      note2:
        'When filtering by groups, you will see alerts, that have any of the selected groups in either of the recipient or sender groups',
      note3:
        'Control alerts available to users by setting sender group first and then optionally assign locations if alert is only needed in specific location(s).',
    },
    groups: {
      title: 'Group Management',
      description:
        'Welcome to the Groups Page. From this page, you can create, edit, and delete the groups for your alerts. With TeamAlert, groups are used to set who can send and receive the alerts that you have created for your organization. For example, we recommend you create at least one group called "All" and put everyone in this group. You may also create a group called "Admin" and set your administrators in this group. Then create a group called "Staff" and put non-administrative employees in this group. When you create your alerts, you can create an alert that "Staff" can raise and sends out to the "Admin" group. Groups are also the way you can separate physical locations for organizations that have multiple offices or buildings.',
      videoLinkDescription: 'Click here to see a tutorial on working with Groups.',
    },
    locationsE911: {
      title: 'Emergency Calls Location Management',
      description: `
        <p>
          The EC (Emergency Calls) service from TeamAlert allows you to contact emergency number from a local phone number with an address that you provide for one of your locations. You can tie this to the alerts you specify, allowing you to contact law enforcement at the touch of a button. Please verify with your emergency service provider if this is allowed in your area.
        </p>
        <p>
          From this screen you can do two things: see active EC accounts and request additional EC accounts. To view a current EC account you can see the information on the corresponding line, or you can click view to see it in more detail. To remove an EC account you must contact TeamAlert Customer Service. To request a new EC location, click the “Request New EC Location” button and fill in the address information for the new location. That information will be submitted to TeamAlert Customer Service who  will add a new EC phone number to that address and make it available to your account. Please be aware that each EC location has a $98 setup fee and is $37.42 per month. You will receive an email once the new EC service has been created for your account or you can go back and review this page. Once there, you will see a phone number under the EC Phone column on the line with the new address. Once this phone number is there the EC service is active.
        </p>
      `,
      numberOfEmergencyContacts: 'Emergency Contacts',
    },
    locations: {
      title: 'Locations',
      note: `TeamAlert recommends that you only use locations for changes inside one physical building. If you work in more than one location, use the locations section to change your default location. Actual GPS location will also be reported when sending an alert if location services are enabled. To modify your locations dropdown list, use the TeamAlert web control panel or contact your TeamAlert administrator. Use the locations section to change your default location if you work in more than one location.`,
      videoLinkDescription: 'Click here to see a tutorial on working with Locations.',
    },
    users: {
      title: 'User Management',
      description: `Welcome to the Users Management Page. From this page, you can create, edit, and delete users. You can also add a user to a group and set which alerts are a priority for that user. For users that will be operating a TeamAlert optional hardware device, you will be able to set them up here. You can control what type of user they are. A “Regular user” can only use the software. A “View only” user can only respond to alerts and cannot raise an alert. A “Group Admin” can edit group membership and function with all the privileges of a regular user. A “Company admin” is an administrator that has full access to this account setting and can make all changes. Users are sent an email invite to join the system. If a user does not have a valid email address, there is an alternative method for adding that type of user account.`,
      videoLinkDescription: 'Click here to see a tutorial on working with Users.',
      noteTitle: '<strong>Notes</strong>',
      note1: `Each user can have two main alerts and then an unlimited number of “other alerts”.`,
      note2: 'When filtering by groups, you will see users that are assigned to all selected groups',
      selectedUsers: '{{count}} selected',
      deleteUsers: 'Delete {{count}}',
      inviteUsers: 'Invite {{count}}',
      reinviteUsers: 'Reinvite {{count}}',
      lastActive: {
        osType: {
          [OsType.iOS]: 'iOS',
          [OsType.MacOS]: 'MacOS',
          [OsType.WIN]: 'Windows',
          [OsType.Android]: 'Android',
          [OsType.AppleWatch]: 'Apple Watch',
        },
        lastSeen: `({{dateLabel}})`,
        hasntLoggedInFor: `(hasn't logged in for {{dateLabel}})`,
        legend: {
          [LastActiveBreak.NoBreak]: 'Within 3 days of login',
          [LastActiveBreak.ShortBreak]: 'Less than 7 days without login',
          [LastActiveBreak.LongBreak]: 'More than 7 days without login',
        },
      },
    },
    importUsers: {
      title: 'Import Users',
      description: `From this page, you can import up to 5000 users into the TeamAlert Web Control Panel. Each user’s First name, Last name & Email address is required for proper importation. See CSV template file below.`,
      seeDetails: 'See details',
      usersWasSuccessfullyImported: '{{count}} user(s) was successfully imported',
      usersWasSkipped: '{{count}} user(s) was skipped due to duplication',
      usersHasValidationErrors: '{{count}} user(s) was skipped due to validation errors',
    },
    channels: {
      title: 'Channel Management',
      description: `Welcome to the Channels Management Page. From this page, you can control the channels for each alert. Channels are how each user will receive notifications for each alert. This easy-to-use responsive interface allows you to quickly set if a user receives an alert by computer, email, text, mobile device, or phone call.`,
      videoLinkDescription: 'Click here to see a tutorial on working with Channels.',
      note: `<strong>Note:</strong> Licenses are controlled by the number of users in the system. This means each user can use one, multiple, or all notification types with their user license.`,
    },
    alertChannels: {
      bullet1: `For recipients to receive Emails, Calls, and SMS, switch the  toggles to the on position.`,
      bullet2: `For Desktop recipients, if the toggle is switch to the OFF position, recipients will receive the alert but the alert room will  not make a sound or pop up on the computer.`,
      bullet3: `For Desktop recipients, if the toggle is switched to the ON position, recipients will receive the alert via the alert room pop  up with sound if the user has the sound checked on the settings  tab of the client application.`,
    },
    anlytics: {
      section: {
        [AnalyticSectionName.summary]: 'Summary',
        [AnalyticSectionName.city]: 'City',
        [AnalyticSectionName.zip_code]: 'Zip code',
        [AnalyticSectionName.state]: 'State',
        [AnalyticSectionName.country]: 'Country',
        [AnalyticSectionName.industry]: 'Industry',
      },
      column: {
        [AnalyticColumnName.Field]: 'Field',
        [AnalyticColumnName.Count]: 'Count',
      },
      totalActiveCompanies: 'Total Active Companies',
      totalTrialCompanies: 'Total Trial Companies',
      totalActiveUsers: 'Total Active Users',
      totalTrialUsers: 'Total Trial Users',
      empty: '(Empty)',
    },
    alertHistory: {
      description:
        'The Active alerts section will show you any active alerts your organization has.  You will have the ability to lower an alert and the ability to see the messages in the alert. You will only have the  ability to view messages on this screen.',
    },
    common: {
      helpVideo: 'Help Video',
    },
    sso: {
      title: 'SSO Management',
      content:
        'The addon SSO service from TeamAlert allows you to login using your company credentials. TeamAlert single sign-on (SSO) is based on SAML 2.0. TeamAlert works with Octa as well as other enterprise identity management platforms such as Microsoft Active Directory and many others. TeamAlert can map attributes to provision a user for different security controls and alert groups. TeamAlert acts as the Service Provider (SP) and offers automatic user provisioning. You do not need to register as a user in TeamAlert. Once TeamAlert receives a SAML response from the Identity Provider (Idp), TeamAlert checks if this user exists. If the user does not exist, TeamAlert creates a user account automatically with the received name ID.',
      callTeamAlert:
        'If you are interested in your organization using the TeamAlert SSO addon please contact us at info@teamalert.com or give us a call at 1-800-533-7201.',
    },
    ssoGroupMapping: {
      ssoGroupMappingInstruction: `
        <p>To configure SSO, take the following steps.</p>
        <ol>
          <li>Login to your company SSO provider and go to the group listing.</li>
          <li>Copy the SSO provider Group ID you want to map to the Team Alert permission.</li>
          <li>Add new SSO permission group mapping with copied Group ID and desired Team Alert permission.</li>
        </ol>
        <p>The change will take effect after the user will re-login in the application.</p>
      `,
      ssoPermissionMappingDisabled:
        'Permission mapping is disabled. Changes on this page are not active until you enable them on the Company page.',
    },
    ssoCompanyGroupMapping: {
      ssoCompanyGroupMappingInstruction: `
        <p>To configure SSO, take the following steps.</p>
        <ol>
          <li>Login to your company SSO provider and go to the group listing.</li>
          <li>Copy the SSO provider Group ID you want to map to the Company alert group.</li>
          <li>Add new SSO company group mapping with copied Group ID and desired Company alert group.</li>
        </ol>
        <p>The change will take effect after the user will re-login in the application.</p>
      `,
      ssoCompanyGroupMappingDisabled:
        'Company group mapping is disabled. Changes on this page are not active until you enable them on the Company page.',
    },
  },
  resources: {
    // Windows (EXE)
    [Resource.desktopAppInstallerWindowsRegularUser]: 'Windows',
    [Resource.desktopAppInstallerWindowsRegularUserPrevious]: `Windows ${INSTALLER_PREV}`,

    // Windows (MSI)
    [Resource.desktopAppInstallerWindowsDomainAdminUser]: 'Windows Network Installer',
    [Resource.desktopAppInstallerWindowsDomainAdminUserPrevious]: `Windows Network Installer ${INSTALLER_PREV}`,

    // MacOSX
    [Resource.desktopAppInstallerMac]: 'MacOSX',
    [Resource.desktopAppInstallerMacPrevious]: `MacOSX ${INSTALLER_PREV}`,

    // Mobile
    [Resource.androidApp]: 'Android',
    [Resource.seeAndroidAppQrCode]: '(see QR code)',
    [Resource.iOSApp]: 'iOS',
    [Resource.seeiOSAppQrCode]: '(see QR code)',
    [Resource.appleWatch]: 'Apple Watch',
    [Resource.seeAppleWatchQrCode]: '(see QR code)',

    [Resource.crsLanding]: 'TeamAlert',
    [Resource.crsConnect]: 'Connect (Remote Support)',
    [Resource.userGuide]: 'User Guide',
    [Resource.welcomeToTeamAlert]: 'Welcome To TeamAlert',
    [Resource.crsNotifyQuickStartGuide]: 'Quick Start Guide',
    [Resource.testingInstructions]: 'Testing Instructions',
    [Resource.usageRecommendations]: 'Usage Recommendations',
    [Resource.endUserLicenseAgreement]: 'End User License Agreement',
    [Resource.termsOfUse]: 'Terms Of Use',
    [Resource.privacyPolicy]: 'Privacy Policy',
    [Resource.quickSetupGuide]: 'Quick Setup Guide',
    [Resource.policyTemplate]: 'Policy Template',
    [Resource.importUsersTemplateCSV]: 'Template CSV file',
    [Resource.dashboardVideo]: 'Dashboard video',
    [Resource.alertManagementVideo]: 'Alert management video',
    [Resource.alertManagementVideoSupportLink]: 'Alert management support video link',
    [Resource.channelManagementVideo]: 'Channel management video',
    [Resource.channelManagementVideoSupportLink]: 'Channel management support video link',
    [Resource.groupManagementVideo]: 'Group management video',
    [Resource.groupManagementVideoSupportLink]: 'Group management support video link',
    [Resource.userManagementVideo]: 'User management video',
    [Resource.userManagementVideoSupportLink]: 'User management support video link',
    [Resource.locationsManagmentVideo]: 'Locations management video',
    [Resource.locationsManagementVideoSupportLink]: 'Locations management support video link',
    [Resource.teamAlertAcademy]: 'Alert Academy',
    [Resource.divider]: 'Divider',
    comingSoon: 'coming soon',
    previousVersion: '(previous version)',
    [Resource.newUserVideo]: 'Welcome To TeamAlert',
    [Resource.newUserCover]: 'Welcome To TeamAlert Cover',
  },
  buttons: {
    addSuperAdmin: 'Add Super admin',
    addCompanyAdmin: 'Add Company admin',
    addGroupAdmin: 'Add Group Admin',
    addSsoCompanyAdmin: 'Add SSO Company Admin',
    addSsoPermissionMapping: 'Add Permission mapping',
    updateSsoPermissionMapping: 'Update Permission mapping',
    addSsoCompanyGroupMapping: 'Add Company group mapping',
    updateSsoCompanyGroupMapping: 'Update Company group mapping',
    addUser: 'Add User',
    importUsers: 'Import Users',
    updateSuperAdmin: 'Update Super admin',
    updateCompanyAdmin: 'Update Company admin',
    updateGroupAdmin: 'Update Group admin',
    updateUser: 'Update User',
    logout: 'Logout',
    mySettings: 'My Settings',
    companySettings: 'Company settings',
    login: 'Login',
    goBackToLogin: 'Go back to login',
    forgotPassword: 'Forgot password',
    contactUs: 'Contact us',
    changePassword: 'Change password',
    resetPassword: 'Reset password',
    submitCompany: 'Submit Company',
    addCompany: 'Add Company',
    addTrialCompany: 'Add Trial Company',
    updateCompany: 'Update Company',
    addGroup: 'Add Group',
    updateGroup: 'Update Group',
    addLocationE911: 'Request New EC Location',
    updateLocation911: 'Update Location',
    addLocation: 'Add new Location',
    addLocationTag: 'Add Location Tag',
    updateLocation: 'Update Location',
    addCompanyDomain: 'Add Company Domain',
    updateCompanyDomain: 'Update Company Domain',
    addAlert: 'Add Alert',
    addFile: 'Add File',
    addLink: 'Add Link',
    updateAlert: 'Update Alert',
    switchOn: 'Switch {{toSwitchOn}} channels on',
    switchOff: 'Switch {{toSwitchOff}} channels off',
    submit: 'Submit',
    placeholders: {
      inputSearchText: 'input search text',
      groupFilterText: 'select group filter',
    },
    back: 'Back',
    selectFile: 'Select file',
    selectCsvFile: 'Select CSV file',
    tooltips: {
      moveUp: 'Move up',
      moveDown: 'Move down',
      moveToTheTop: 'Move to the top',
      moveToTheBottom: 'Move to the bottom',
    },
    addQuickResponse: 'Add Quick response',
    signUp: 'Sign up',
    exportUsers: 'Export Users',
  },
  sideMenu: {
    dashboard: 'Dashboard',
    superAdmins: 'Super admins',
    companyAdmins: 'Company admins',
    ssoCompanyAdmins: 'SSO Company admins',
    ssoGroupMappings: 'Permissions mapping',
    ssoCompanyGroupMappings: 'Company groups mapping',
    groupAdmins: 'Group admins',
    companies: 'Companies',
    trialAccounts: 'Trial accounts',
    analytics: 'Analytics',
    activeAlerts: 'Active alerts',
    alertHistory: 'Alert history',
    setup: 'Setup',
    users: 'Users',
    groups: 'Groups',
    locations: 'Locations',
    e911AddOn: 'EC Add-on',
    ssoAddOn: 'SSO Add-on',
    alerts: 'Alerts',
    gettingStarted: 'Getting started',
    support: 'Support',
    channels: 'Channels',
    mySettings: 'My settings',
  },
  validator: {
    form: {
      isRequired: 'Field is required',
      emailFormatIsNotValid: 'Invalid email format',
      passwordFormatIsNotValid: 'Password format is not valid',
      passwordLengthIsNotValid: 'Password length should be a at least 8 characters',
      passwordShouldContainLowercase: 'Password should contain lowercase letters',
      passwordShouldContainUppercase: 'Password should contain uppercase letters',
      passwordShouldContainDigits: 'Password should contain digits',
      passwordShouldContainSymbols:
        'Password should contain symbols like ^ $ * . [ ] { } ( ) ? " ! @ # % & / \\ , > < \' : ; | _ ~ ` = + -',
      phoneNumberIsNotValid: 'Phone format is not valid',
      fileSizeCannotBeMoreThan: 'A file size can not be more than {{maxFileSize}}.',
      duplicateLocation: 'Location already in use. Duplicate locations are not allowed.',
    },
  },
  accountStatus: {
    [AccountStatus.New]: 'New',
    [AccountStatus.InviteSent]: 'Invite sent',
    [AccountStatus.InviteFailed]: 'Invite failed',
    [AccountStatus.Active]: 'Active',
    [AccountStatus.ChangeFailed]: 'Change failed',
    [AccountStatus.NotActive]: 'Not active',
    [AccountStatus.SetPasswordFailed]: 'Set password failed',
  },
  role: {
    [Role.ViewOnly]: 'View only user',
    [Role.Regular]: 'Regular user',
    [Role.GroupAdmin]: 'Group admin',
    [Role.CompanyAdmin]: 'Company admin',
    [Role.SuperAdmin]: 'Super admin',
  },
  table: {
    action: {
      edit: 'Edit',
      view: 'View',
      delete: 'Delete',
      invite: 'Invite',
      reinvite: 'Reinvite',
      manageChannels: 'Manage channels',
      manageDocuments: 'Manage documents',
      manageLocations: 'Manage EC locations',
      manageDomains: 'Manage Domains',
      manageSsoGroupMapping: 'Manage SSO Group mappings',
      raise: 'Raise',
      lower: 'Lower',
      getReport: 'Get report (PDF)',
      quickResponses: 'Quick Responses',
      duplicate: 'Duplicate',
    },
  },
  modalDialog: {
    deleteCompany: 'Delete company',
    areYouSureYouWantToRemoveThisCompany: 'Are you sure you want to remove this company ?',
    deleteCompanyDomain: 'Delete company domain',
    areYouSureYouWantToRemoveThisCompanyDomain: 'Are you sure you want to remove this company domain ?',
    deleteSsoAdmin: 'Delete SSO Company admin',
    areYouSureYouWantToRemoveThisSsoCompanyAdmin: 'Are you sure you want to remove this SSO company admin ?',
    deleteSsoGroupMapping: 'Delete SSO Group mapping',
    areYouSureYouWantToRemoveThisSsoGroupMapping: 'Are you sure you want to remove this SSO Group mapping ?',
    deleteSsoCompanyGroupMapping: 'Delete SSO Company group mapping',
    areYouSureYouWantToRemoveThisSsoCompanyGroupMapping:
      'Are you sure you want to remove this SSO Company Group mapping ?',
    deleteUserAccount: 'Delete user account',
    areYouSureYouWantToRemoveThisUserAccount: 'Are you sure you want to remove this user account ?',
    deleteGroup: 'Delete group',
    areYouSureYouWantToRemoveThisGroup: 'Are you sure you want to remove this group ?',
    deleteLocation: 'Delete location',
    areYouSureYouWantToRemoveThisLocation: 'Are you sure you want to remove this location ?',
    deleteLocationE911: 'Delete location',
    areYouSureYouWantToRemoveThisLocationE911: 'Are you sure you want to remove this EC location ?',
    updateLocationE911: 'Update location',
    areYouSureYouWantToUpdateThisLocationE911:
      'Are you sure to update this EC location? This will send a request to change the data to the site administrator.',
    areYouSureYouWantToUpdateThisLocation:
      'Are you sure to update this location? This will send a request to change the data to the site administrator.',
    updateLocation: 'Update location',
    deleteAlert: 'Delete alert',
    areYouSureYouWantToRemoveThisAlert: 'Are you sure you want to remove this alert ?',
    deleteAlertDocument: 'Delete alert document',
    areYouSureYouWantToRemoveThisAlertDocument: 'Are you sure you want to remove this alert document ?',
    deleteCustomResponse: 'Delete Quick Response',
    areYouSureYouWantToRemoveThisQuickResponse: 'Are you sure you want to remove this quick response ?',
    deleteUsers: 'Delete users',
    areYouSureYouWantToDeleteUsers: 'Are you sure you want to delete {{count}} users?',
  },
  avatar: {
    changePhoto: 'Change photo',
    deletePhoto: 'Delete photo',
    cancel: 'Cancel',
    upload: 'Upload',
    areYouSureYouWantToDeleteThisPhoto: 'Are you sure you want to delete this photo?',
    savePhoto: 'Save photo',
    cropPhoto: 'Crop photo',
    changeAvailability: 'Photo can be changed after user is added',
  },
  googleMap: {
    alert: 'Failed to load the map.',
  },
};
