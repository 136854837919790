import { isString } from 'lodash';

export const getStringAfter = (value: string, deliminator: string) => {
  const splited = tryDecodeURIComponent(value).split(deliminator);
  let result = '';
  if (splited.length < 2) return undefined;
  if (splited.length === 2) result = splited[1];
  if (splited.length > 2) result = splited.slice(1, splited.length).join(deliminator);

  return result;
};

export const tryDecodeURIComponent = (value: string) => {
  try {
    return decodeURIComponent(value);
  } catch (error) {
    return value;
  }
};

// as cognito generates temporary login code that contains query string prohibited keys (?&)
// need to take this code in custom way
export const getCodeFromUrl = (url: string | null | undefined) => {
  if (!isString(url) || !url) return {};
  let queryString = getStringAfter(url, '?');
  if (!queryString) return {};
  const code = getStringAfter(url, 'code=');

  return { code };
};

export const getEmailFromUrl = (url: string | null | undefined) => {
  if (!isString(url) || !url) return {};
  let queryString = getStringAfter(url, '?');
  if (!queryString) return {};
  const email = getStringAfter(url, 'email=');

  return { email };
};
