import { takeEvery, put, call } from 'redux-saga/effects';
import { SagaManager } from '../../saga-manager/saga-manager';
import { autocompleteApi } from './autocomplete.api';
import { AutocompleteActionTypes, autocompleteActionCreators, AutocompleteActions } from './autocomplete.action';
import { ReturnActionOfType } from '../../redux/action-creator';
import { AxiosResponse } from 'axios';
import { AutocompleteResponse } from '../../../api-models/api-models';

export function* autocompleteSagaWatch() {
  yield takeEvery(AutocompleteActionTypes.FETCH_AUTOCOMPLETE, fetchAutocompleteSagaManaged);
}

function* fetchAutocompleteSagaManaged(
  action: ReturnActionOfType<AutocompleteActions, AutocompleteActionTypes.FETCH_AUTOCOMPLETE>
) {
  yield new SagaManager()
    .addTracking(autocompleteActionCreators.fetchAutocomplete.name)
    .execute(fetchAutocompleteSaga, action);
}

function* fetchAutocompleteSaga(
  action: ReturnActionOfType<AutocompleteActions, AutocompleteActionTypes.FETCH_AUTOCOMPLETE>
) {
  const { companyId, autocompleteClass } = action.payload;
  const response: AxiosResponse<AutocompleteResponse> = yield call(
    autocompleteApi.fetchAutocomplete,
    companyId,
    autocompleteClass
  );

  yield put(autocompleteActionCreators.setAutocomplete(autocompleteClass, response.data));
}
