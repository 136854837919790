import React, { useEffect, useMemo, useCallback } from 'react';
import { Field } from 'redux-form';
import { FormSelectInput } from '../form-inputs/form-select-input/form-select-input';
import { useDispatch, useSelector } from 'react-redux';
import { listsActionCreators, ListName, getFetchListTrackName } from '../../../logic/store/lists/lists.action';
import { getListData } from '../../../logic/store/lists/lists.selectors';
import { Country } from '../../../api-models/api-models';
import { getCountryCodeOptions } from '../../authentication/models/country-code/country-code.select-options';
import { FormTextInput } from '../form-inputs/form-text-input/form-text-input';
import { useNormalizePhoneAsType } from '../../../logic/phone/format-phone-as-type';
import { formatPhoneNumberAsNational } from '../../../logic/phone/phone.mapper';
import { filterPhoneOption } from './form-field-phone.logic';
import { getInProgress } from '../../../logic/store/process-tracker/process-tracker.selectors';

interface FormFieldPhoneProps {
  countryCodeFieldName: string;
  name: string;
  inProgress: boolean | undefined;
  countryCode: string | undefined;
  label?: string;
  commonAttributes: any;
  required?: boolean;
  disabled?: boolean;
  placeholder?: string;
}

export const FormFieldPhone: React.FC<FormFieldPhoneProps> = ({
  countryCodeFieldName,
  inProgress,
  name,
  countryCode,
  label,
  commonAttributes,
  required = false,
  disabled = false,
  placeholder,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(listsActionCreators.fetchList(ListName.country));
  }, [dispatch]);

  const countries = useSelector(getListData<Country>(ListName.country));
  const countryCodeOptions = useMemo(() => getCountryCodeOptions(countries), [countries]);
  const normalizePhoneAsType = useNormalizePhoneAsType(countryCode);

  const fetchCountriesInProgress = useSelector(getInProgress(getFetchListTrackName(ListName.country)));

  const renderTag = useCallback((tag: Country | undefined) => tag?.name, []);

  return (
    <Field
      addonBefore={
        <Field
          name={countryCodeFieldName}
          component={FormSelectInput}
          className={'country-code'}
          options={countryCodeOptions}
          disabled={disabled || inProgress}
          isInsideOtherField
          showSearch
          loading={fetchCountriesInProgress}
          filterOption={filterPhoneOption}
          optionLabelProp={'label'}
          dropdownMatchSelectWidth={false}
          renderTag={renderTag}
        />
      }
      name={name}
      component={FormTextInput}
      normalize={normalizePhoneAsType}
      onBlurFormater={formatPhoneNumberAsNational(countryCode)}
      label={label}
      placeholder={placeholder}
      required={required}
      {...commonAttributes}
    />
  );
};
