export enum AlertDocumentFieldName {
  id = 'id',
  type = 'type',
  fileNameOrUrl = 'fileNameOrUrl',
  description = 'description',
  created_at = 'created_at',
  size = 'size',
}

export enum AlertDocumentTypeName {
  file = 'file',
  url = 'url',
}
