import { parseNumber } from '../utils/number';
import { OrderChangeAction } from './change-order.model';

export const isChangeOrderEnabled = (
  order: number | undefined,
  minOrder: number | undefined,
  maxOrder: number | undefined,
  orderAction: OrderChangeAction
): boolean => changeOrder(order, minOrder, maxOrder, orderAction) !== undefined;

export const changeOrder = (
  order: number | undefined,
  minOrder: number | undefined,
  maxOrder: number | undefined,
  orderAction: OrderChangeAction
): number | undefined => {
  const orderParsed = parseNumber(order);
  const minOrderParsed = parseNumber(minOrder);
  const maxOrderParsed = parseNumber(maxOrder);

  if (
    orderParsed === undefined ||
    minOrderParsed === undefined ||
    maxOrderParsed === undefined ||
    orderParsed < minOrderParsed ||
    orderParsed > maxOrderParsed
  )
    return undefined;

  switch (orderAction) {
    case OrderChangeAction.Increase:
      return orderParsed < maxOrderParsed ? orderParsed + 1 : undefined;
    case OrderChangeAction.Decrease:
      return orderParsed > minOrderParsed ? orderParsed - 1 : undefined;
    case OrderChangeAction.SetMin:
      return orderParsed > minOrderParsed ? minOrderParsed : undefined;
    case OrderChangeAction.SetMax:
      return orderParsed < maxOrderParsed ? maxOrderParsed : undefined;
    default:
      return undefined;
  }
};
