import { createAction, ActionsUnion } from '../../redux/action-creator';
import { LogType } from './log.reducer';

export enum LogActionTypes {
  RESET = 'log/RESET',
  LOG = 'log/LOG',
}

export const logActionCreators = {
  reset: () => createAction(LogActionTypes.RESET),
  logError: (message: string, error?: Error, visible: boolean = true) =>
    createAction(LogActionTypes.LOG, {
      title: undefined,
      message,
      error,
      logType: LogType.Error,
      visible,
    }),
  logSuccess: (message: string) =>
    createAction(LogActionTypes.LOG, {
      title: undefined,
      message,
      error: undefined,
      logType: LogType.Success,
      visible: true,
    }),
  logSuccessModal: (title: string, message: string) =>
    createAction(LogActionTypes.LOG, {
      title,
      message,
      error: undefined,
      logType: LogType.SuccessModal,
      visible: true,
    }),
};

export type LogActions = ActionsUnion<typeof logActionCreators>;
