import { ReactNode } from 'react';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useModal } from '../../../logic/hooks/use-modal';
import {
  getInProgress,
  getProcessTrackerState,
  getProgress,
} from '../../../logic/store/process-tracker/process-tracker.selectors';
import { usePrevious } from '../../../logic/hooks/use-previous';
import { willSucceed } from '../../../logic/store/process-tracker/process-tracker.actions';
import { renderFormModal } from './render-form-modal';
import { reset, getFormValues } from 'redux-form';
import { Spin } from 'antd';
import React from 'react';
import { getProgressSpinner } from '../progress-spinner/progress-spinner';

interface FormModalProps {
  title: string;
  formName: string;
  submit: (formValues: any) => void;
  processName: string;
  getContent: () => ReactNode;
  showContentInProgress?: boolean;
  okText?: string;
  inProgress?: boolean;
}

export const useFormModal = ({
  title,
  formName,
  submit,
  processName,
  getContent,
  showContentInProgress = false,
  okText,
  inProgress = false,
}: FormModalProps) => {
  const modal = useModal<any>();
  const visible = modal.visible;
  const dispatch = useDispatch();
  const inProcessInProgress = useSelector(getInProgress(processName));
  const processProgress = useSelector(getProgress(processName));
  const progressSpinner = getProgressSpinner(inProcessInProgress || inProgress, processProgress);
  const actionState = useSelector(getProcessTrackerState(processName));
  const previousActionState = usePrevious(actionState);
  const formValues = useSelector(getFormValues(formName)) || {};
  const submitSuccess = willSucceed(previousActionState, actionState);

  useEffect(() => {
    if (visible && submitSuccess) {
      dispatch(reset(formName));
      modal.hide();
    }
  }, [modal, visible, submitSuccess, dispatch, formName]);

  const handleSubmit = () => submit(formValues);

  const handleCancel = () => {
    dispatch(reset(formName));
    modal.hide();
  };

  const renderContent = () =>
    showContentInProgress ? (
      <Spin spinning={!progressSpinner && inProcessInProgress} {...progressSpinner}>
        {getContent()}
      </Spin>
    ) : (
      getContent()
    );

  return {
    render: () =>
      visible
        ? renderFormModal(
            {
              title,
              visible,
              okText,
              onOk: handleSubmit,
              onCancel: handleCancel,
              okButtonProps: { disabled: inProcessInProgress },
              cancelButtonProps: { disabled: inProcessInProgress },
              confirmLoading: !showContentInProgress && inProcessInProgress,
            },
            renderContent()
          )
        : null,
    show: modal.show,
  };
};
