import { getSortableFilterableColumnCreator } from '../../pages/components/table/table.column';
import { LocationFormFieldName } from '../../common/location/location-form.field-name';
import { Location, Role } from '../../api-models/api-models';
import { translationKeys } from '../translations/translations.service';
import { LocationForm } from '../../common/location/location.type';
import { isDevelopment } from '../env/env';

export const getLocationColumn = getSortableFilterableColumnCreator<LocationFormFieldName, Location>(
  translationKeys.forms.fields.location
);

export const shouldShowEditAction = (authenticatedUserRole: Role | undefined) =>
  authenticatedUserRole === Role.SuperAdmin || authenticatedUserRole === Role.CompanyAdmin;
export const shouldShowViewAction = (authenticatedUserRole: Role | undefined) =>
  !shouldShowEditAction(authenticatedUserRole);
export const shouldShowBackButton = (authenticatedUserRole: Role | undefined) =>
  authenticatedUserRole === Role.SuperAdmin;
export const devFieldsLocation: Partial<LocationForm> = isDevelopment()
  ? {
      name: 'Location',
    }
  : {};
