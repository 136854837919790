import { axiosAuthenticatedClient } from '../../axios/axios-authenticated-client';
import { GroupForm, formValuesToGroupMapper } from '../../../pages/group/pages/group-form-template/group-form.form';
import { Group } from '../../../api-models/api-models';
import { env } from '../../env/env';

export const groupApi = {
  getSingle: (id: any) => axiosAuthenticatedClient().get<Group>(`${env.API_VERSION}/group/${id}`),
  addSingle: (formValues: GroupForm) =>
    axiosAuthenticatedClient().post(`${env.API_VERSION}/group/`, formValuesToGroupMapper(formValues)),
  updateSingle: (formValues: GroupForm) =>
    axiosAuthenticatedClient().put(`${env.API_VERSION}/group/${formValues.id}/`, formValuesToGroupMapper(formValues)),
  deleteSingle: (id: number) => axiosAuthenticatedClient().delete(`${env.API_VERSION}/group/${id}`),
};
