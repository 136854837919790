import { reduxForm } from 'redux-form';
import { compose } from 'redux';
import { translate, translationKeys } from '../../../logic/translations/translations.service';
import { companyActionCreators } from '../../../logic/store/company/company.action';
import { COMPANY_FORM, validateCompanyForm } from './company-form-template/company-form.form';
import { CompanyFormPage, CompanyFormPageOwnProps } from './company-form-template/company-form.page';
import { CompanyFormFieldName } from './company-form-template/company-form-field-name';
import {
  getAuthenticatedUserCompany,
  getAuthenticatedUserRole,
} from '../../../logic/store/authentication/authentication.selectors';
import { store } from '../../../app/app.store';
import { wrapComponentsWithProps } from '../../../logic/hoc/wrap-components-with-props.hoc';
import { isSuperAdmin } from '../../authentication/models/role/role.utils';

export const CompanyEditPageCreator = () => {
  const role = getAuthenticatedUserRole(store.getState());
  const company = getAuthenticatedUserCompany(store.getState());

  return compose(
    wrapComponentsWithProps<CompanyFormPageOwnProps>({
      submitButtonText: translate(translationKeys.buttons.updateCompany),
      submitFormAction: companyActionCreators.updateSingle,
      formName: COMPANY_FORM,
      fieldsToDisplay: [
        ...(isSuperAdmin(role) ? [CompanyFormFieldName.companyInfoHeader] : []),

        CompanyFormFieldName.name,

        ...(isSuperAdmin(role) ? [CompanyFormFieldName.industry] : []),
        ...(isSuperAdmin(role) ? [CompanyFormFieldName.country] : []),

        CompanyFormFieldName.address,
        CompanyFormFieldName.city,
        CompanyFormFieldName.state,
        CompanyFormFieldName.zip_code,

        CompanyFormFieldName.phone_country_code,
        CompanyFormFieldName.phone,
        CompanyFormFieldName.footer_note,

        CompanyFormFieldName.max_users_no,
        CompanyFormFieldName.users_no,

        CompanyFormFieldName.max_locations_no,
        CompanyFormFieldName.locations_no,

        ...(isSuperAdmin(role) ? [CompanyFormFieldName.isActive] : []),

        ...(isSuperAdmin(role) ? [CompanyFormFieldName.trialHeader] : []),
        ...(isSuperAdmin(role) ? [CompanyFormFieldName.isTrial] : []),
        ...(isSuperAdmin(role) ? [CompanyFormFieldName.trial_to_date] : []),
        ...(isSuperAdmin(role) ? [CompanyFormFieldName.trial_alerts_limit] : []),

        ...(isSuperAdmin(role) ? [CompanyFormFieldName.e911Header] : []),
        ...(!isSuperAdmin(role) ? [CompanyFormFieldName.e911HeaderDescription] : []),
        CompanyFormFieldName.is_e911_enabled,
        CompanyFormFieldName.is_e911_test_enabled,

        ...(isSuperAdmin(role) ? [CompanyFormFieldName.ssoHeader, CompanyFormFieldName.is_sso_enabled] : []),
        ...(!isSuperAdmin(role) && company?.is_sso_enabled
          ? [
              CompanyFormFieldName.ssoHeader,
              CompanyFormFieldName.is_permission_mapping_enabled,
              CompanyFormFieldName.is_company_group_mapping_enabled,
            ]
          : []),
        ...(!isSuperAdmin(role) ? [CompanyFormFieldName.appsHeader, CompanyFormFieldName.is_updates_on] : []),
      ],
      disabledFields: [
        CompanyFormFieldName.users_no,
        CompanyFormFieldName.locations_no,
        ...(!isSuperAdmin(role) ? [CompanyFormFieldName.max_users_no, CompanyFormFieldName.max_locations_no] : []),
      ],
    }),
    reduxForm({
      form: COMPANY_FORM,
      validate: validateCompanyForm,
    })
  )(CompanyFormPage) as React.FC;
};
