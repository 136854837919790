import { FormErrors } from 'redux-form';
import { clearEmptyFields } from '../../../../logic/validators/utils';
import { isRequiredValidator, isEmailValididator } from '../../../../logic/validators/validators';
import { isValidPhoneNumber } from '../../../../logic/phone/phone.validator';

export const SIGNUP_FORM = 'login';

export interface SignupForm {
  email?: string;
  name?: string;
  country_code?: string;
  phone_number?: string;
}

export type LoginFormValidationErrors = FormErrors<SignupForm>;

export const validateSignupForm = (formValues: SignupForm) => {
  const { email, name, phone_number, country_code } = formValues;

  const errors: FormErrors<SignupForm, any> = {
    email: isRequiredValidator(email) || isEmailValididator(email),
    name: isRequiredValidator(name),
    phone_number: isRequiredValidator(phone_number) || isValidPhoneNumber(phone_number, country_code),
  };

  return clearEmptyFields(errors);
};
