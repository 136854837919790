import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AvatarChange from './avatar-change/avatar-change';
import { Modal, Button } from 'antd';
import { translate, translationKeys } from '../../../../logic/translations/translations.service';
import { userActionCreators } from '../../../../logic/store/user/user.action';
import { getUserPhoto } from '../../../../logic/store/user/user.selector';
import DefaultAvatar from '../../../../assets/default_avatar.png';
import './user-avatar.scss';

export const UserAvatar: React.FC<{ userId: any }> = ({ userId }) => {
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [image, setImage] = useState<File>();
  const [imageURL, setImageURL] = useState(DefaultAvatar);
  const dispatch = useDispatch();
  const photoUrl = useSelector(getUserPhoto);

  useEffect(() => {
    if (userId !== null && image !== undefined) dispatch(userActionCreators.setPhoto(userId, image));
    return () => {
      setImage(undefined);
    };
  }, [dispatch, userId, image]);

  useEffect(() => {
    if (photoUrl) setImageURL(photoUrl);
    return () => {
      setImageURL(DefaultAvatar);
    };
  }, [photoUrl]);

  const showDeleteModal = (visibility: boolean) => setIsDeleteModalVisible(visibility);

  const showModal = (visibility: boolean) => setIsModalVisible(visibility);

  const onClose = () => setIsDeleteModalVisible(false);
  const onDelete = () => {
    setImage(undefined);
    setImageURL(DefaultAvatar);
    setIsDeleteModalVisible(false);
    if (userId !== null) dispatch(userActionCreators.deletePhoto(userId));
  };

  return (
    <div className={'user-avatar'}>
      <div className={'avatar'}>
        <img className={'avatar__image'} src={imageURL} alt={''} />
        <div className={'avatar__actions'}>
          {userId ? (
            <>
              <span className={'avatar__change ant-btn-link'} onClick={() => showModal(true)}>
                {translate(translationKeys.avatar.changePhoto)}
              </span>
              {imageURL !== DefaultAvatar && (
                <span className={'avatar__delete ant-btn-link'} onClick={() => showDeleteModal(true)}>
                  {translate(translationKeys.avatar.deletePhoto)}
                </span>
              )}
            </>
          ) : (
            <span>{translate(translationKeys.avatar.changeAvailability)}</span>
          )}
        </div>
      </div>

      <AvatarChange
        isModalVisible={isModalVisible}
        setImage={setImage}
        setImageURL={setImageURL}
        showModal={showModal}
      />

      <Modal
        title={translate(translationKeys.avatar.deletePhoto)}
        visible={isDeleteModalVisible}
        onOk={onDelete}
        onCancel={onClose}
        footer={[
          <Button key="back" onClick={onClose}>
            {translate(translationKeys.avatar.cancel)}
          </Button>,
          <Button key="submit" type="primary" onClick={onDelete}>
            {translate(translationKeys.avatar.deletePhoto)}
          </Button>,
        ]}
      >
        {translate(translationKeys.avatar.areYouSureYouWantToDeleteThisPhoto)}
      </Modal>
    </div>
  );
};
