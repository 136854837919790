import { FormErrors } from 'redux-form';
import { SsoCompanyGroupMapping } from '../../../../api-models/api-models';
import { clearEmptyFieldsAndEmptyObjects } from '../../../../logic/validators/utils';
import { isRequiredValidator } from '../../../../logic/validators/validators';

export interface SsoCompanyGroupMappingForm extends SsoCompanyGroupMapping {}

export const SSO_COMPANY_GROUP_MAPPING_FORM = 'ssoCompanyGroupMapping';

export const validateSsoCompanyGroupMappingForm = (
  formValues: SsoCompanyGroupMappingForm
): FormErrors<SsoCompanyGroupMappingForm, string> => {
  const { sso_group_name, company_group } = formValues;

  const validation = clearEmptyFieldsAndEmptyObjects({
    sso_group_name: isRequiredValidator(sso_group_name),
    company_group: isRequiredValidator(company_group),
  });

  return validation;
};

export const ssoCompanyGroupMappingToFormValuesMapper = (
  ssoGroupMapping: SsoCompanyGroupMapping
): SsoCompanyGroupMappingForm => {
  return {
    ...ssoGroupMapping,
  };
};

export const formValuesToSsoCompanyGroupMapper = (formValues: SsoCompanyGroupMappingForm): SsoCompanyGroupMapping => {
  return {
    ...formValues,
  };
};
