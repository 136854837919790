import { axiosAuthenticatedClient } from '../../axios/axios-authenticated-client';
import {
  CompanyDomainsForm,
  formValuesToDomainsMapper,
} from '../../../pages/company-domains/pages/domains-forms/company-domains-form.form';
import { CompanySsoDomain } from '../../../api-models/api-models';
import { env } from '../../env/env';

export const companyDomainsApi = {
  getSingle: (id: any) =>
    axiosAuthenticatedClient().get<CompanySsoDomain>(`${env.API_VERSION}/company-sso-domains/${id}`),
  addSingle: (formValues: CompanyDomainsForm) =>
    axiosAuthenticatedClient().post(`${env.API_VERSION}/company-sso-domains/`, formValuesToDomainsMapper(formValues)),
  updateSingle: (formValues: CompanyDomainsForm) =>
    axiosAuthenticatedClient().put(
      `${env.API_VERSION}/company-sso-domains/${formValues.id}/`,
      formValuesToDomainsMapper(formValues)
    ),
  deleteSingle: (id: number) => axiosAuthenticatedClient().delete(`${env.API_VERSION}/company-sso-domains/${id}`),
  getAllForCompany: (companyId: number) =>
    axiosAuthenticatedClient().get(`${env.API_VERSION}/company/${companyId}/allowed-sso-domains/`),
};
