import React, { useEffect } from 'react';
import { Button } from 'antd';
import { useDispatch } from 'react-redux';
import { ColumnProps } from 'antd/lib/table';
import { groupActionCreators } from '../../../logic/store/group/group.action';
import { navigationActionCreators } from '../../../logic/store/navigation/navigation.actions';
import { AUTHENTICATED_ROUTES } from '../../../logic/routes/routes.const';
import { translate, translationKeys } from '../../../logic/translations/translations.service';
import { MasterContent } from '../../master-page/components/master-content/master-content';
import { GroupFormFieldName } from './group-form-template/group-form.field-name';
import { getSortableFilterableColumnCreator, renderPhone } from '../../components/table/table.column';
import { useConfirmActionModal } from '../../components/confirm-modal/confirm-modal';
import { Table } from '../../components/table/table';
import { Group } from '../../../api-models/api-models';
import { useSearchInput } from '../../components/search-input/search-input';
import { TableName } from '../../../logic/store/tables/tables.action';
import { MasterPageTableHeader } from '../../components/master-page-table-header/master-page-table-header';
import { TableHeaderButton } from '../../components/master-page-table-header/master-page-table-header.components';
import parse from 'html-react-parser';
import { PageDescription } from '../../components/page-description/page-description';
import { ResourceLink } from '../../components/resources/resource-link';
import { Resource, resourceContents } from '../../components/resources/resource.const';

declare global {
  interface Window {
    dataLayer: any[];
  }
}

export const GroupsPage: React.FC = () => {
  const searchInput = useSearchInput();
  const dispatch = useDispatch();

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'setupGroupsTab_view',
    });
  }, []);

  const handleAddSingle = () => {
    dispatch(navigationActionCreators.navigateTo(AUTHENTICATED_ROUTES.GroupsAdd));
  };

  const handleEditSingle = (row: Group) => () => {
    dispatch(navigationActionCreators.navigateTo(AUTHENTICATED_ROUTES.GroupsEdit, `?id=${row.id}`));
  };

  const handleDelete = (group: Group | undefined) => {
    if (group && group.id) {
      dispatch(groupActionCreators.deleteSingle(group.id));
    }
  };

  const deleteModal = useConfirmActionModal(
    translate(translationKeys.modalDialog.deleteGroup),
    translate(translationKeys.modalDialog.areYouSureYouWantToRemoveThisGroup),
    handleDelete,
    groupActionCreators.deleteSingle.name
  );

  const actionsColumn = (): ColumnProps<Group> => ({
    title: translate(translationKeys.forms.fields.actions),
    dataIndex: 'actions',
    key: 'actions',
    render: (value, row) => (
      <span>
        <Button type={'link'} onClick={handleEditSingle(row)}>
          {translate(translationKeys.table.action.edit)}
        </Button>
        <Button type={'link'} onClick={deleteModal.tryToPerformAction(row)}>
          {translate(translationKeys.table.action.delete)}
        </Button>
      </span>
    ),
  });

  const getGroupColumn = getSortableFilterableColumnCreator<GroupFormFieldName, Group>(
    translationKeys.forms.fields.group
  );

  const getColumns = [
    actionsColumn,
    getGroupColumn(GroupFormFieldName.name),
    getGroupColumn(GroupFormFieldName.dept),
    getGroupColumn(GroupFormFieldName.notes),
    getGroupColumn(GroupFormFieldName.address),
    getGroupColumn(GroupFormFieldName.city),
    getGroupColumn(GroupFormFieldName.state),
    getGroupColumn(GroupFormFieldName.zip_code),
    getGroupColumn(GroupFormFieldName.phone, false, (value) => renderPhone(value)),
    getGroupColumn(GroupFormFieldName.location_name, false),
    getGroupColumn(GroupFormFieldName.number_of_users, false, (value, row) => row.users_ids?.length || 0),
  ];

  const handleEvent = (event: string) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event,
      page: 'GroupsPage',
      action: event,
    });
  };

  const renderAddSingleButton = () => (
    <TableHeaderButton
      type={'primary'}
      onClick={() => {
        handleAddSingle();
        handleEvent('addGroupButton');
      }}
    >
      {translate(translationKeys.buttons.addGroup)}
    </TableHeaderButton>
  );

  return (
    <>
      <MasterPageTableHeader
        searchInput={searchInput.render()}
        leftSection={renderAddSingleButton()}
        content={
          <PageDescription
            title={translate(translationKeys.pages.groups.title)}
            content={
              <>
                <p>{parse(translate(translationKeys.pages.groups.description))}</p>
                <p>
                  <ResourceLink
                    content={resourceContents[Resource.groupManagementVideoSupportLink]}
                    text={translate(translationKeys.pages.groups.videoLinkDescription)}
                    noPaddingLeft
                  />
                </p>
              </>
            }
          />
        }
      />
      <MasterContent>
        <Table tableName={TableName.group} getColumns={getColumns} searchInput={searchInput} />
      </MasterContent>
      {deleteModal.render()}
    </>
  );
};
