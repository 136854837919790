import {
  SsoCompanyAdminsForm,
  formValuesToSsoCompanyAdminsMapper,
} from '../../../pages/sso-company-admins/pages/sso-company-admins-form-template/sso-company-admins-form.form';
import { axiosAuthenticatedClient } from '../../axios/axios-authenticated-client';
import { env } from '../../env/env';

export const ssoCompanyAdminsApi = {
  addSingle: (formValues: SsoCompanyAdminsForm) =>
    axiosAuthenticatedClient().post(
      `${env.API_VERSION}/sso-company-admins/`,
      formValuesToSsoCompanyAdminsMapper(formValues)
    ),
  deleteSingle: (id: number) => axiosAuthenticatedClient().delete(`${env.API_VERSION}/sso-company-admins/${id}`),
};
