import { createAction, ActionsUnion } from '../../redux/action-creator';
import { UserAlertChannelSettings } from '../../../api-models/api-models';

export enum ChannelActionTypes {
  UPDATE_SINGLE = 'channel/UPDATE_SINGLE',
  UPDATE_MANY = 'channel/UPDATE_MANY',
}

export const channelActionCreators = {
  updateSingle: (channel: UserAlertChannelSettings) => createAction(ChannelActionTypes.UPDATE_SINGLE, { channel }),
  updateMany: (channels: UserAlertChannelSettings[]) => createAction(ChannelActionTypes.UPDATE_MANY, { channels }),
};

export type ChannelActions = ActionsUnion<typeof channelActionCreators>;
