import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getLastLog } from '../../../logic/store/log/log.selectors';
import { message } from 'antd';
import { LogType } from '../../../logic/store/log/log.reducer';
import { showSuccessModal } from '../../../components/modals/success-modal/success-modal';

export const LogHandler: React.FC = () => {
  const logState = useSelector(getLastLog);
  useEffect(() => {
    if (logState.visible) {
      switch (logState.logType) {
        case LogType.Error:
          message.error(logState.message);
          break;
        case LogType.Success:
          message.success(logState.message);
          break;
        case LogType.SuccessModal:
          showSuccessModal({
            title: logState.title,
            content: logState.message,
          });
          break;
        default:
          break;
      }
    }
  }, [logState]);
  return null;
};
