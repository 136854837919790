import { map } from 'lodash';

type SelectOptionValue = string | number | undefined;
type SelectOptionLabel = string | JSX.Element | undefined;
type SelectOptionDescription = string | JSX.Element | undefined;

export interface SelectOption<V = SelectOptionValue, L = SelectOptionLabel, T = SelectOptionDescription> {
  value: V;
  label: L;
  imageUrl?: string;
  disabled?: boolean;
  tag?: T;
}

interface SelectOptionDefaultEntity {
  id?: number;
  name?: string;
}

interface LanguageEntity {
  language: string;
  code: string;
}

export type SelectOptionsMapper<V = SelectOptionValue, L = SelectOptionLabel> = (entity: any) => SelectOption<V, L>;

export const getSelectOptions = <E, V = SelectOptionValue, L = SelectOptionLabel>(
  entities: E[],
  selectOptionGetter: SelectOptionsMapper<V, L>
): SelectOption<V, L>[] => map(entities, (entity) => selectOptionGetter(entity));

export const getDefaultSelectOptions = <E>(entities: E[]): SelectOption<number | undefined, string | undefined>[] =>
  map(entities, (entity) => mapSelectOptionDefault(entity));

export const getCustomSelectOptions = <E>(
  entities: E[],
  getCustomValuesFunction: (entity: E) => SelectOption
): SelectOption[] => {
  return map(entities, (entity) => getCustomValuesFunction(entity));
};

const mapSelectOptionDefault: SelectOptionsMapper<number | undefined, string | undefined> = (
  entity: SelectOptionDefaultEntity
) => ({
  value: entity.id,
  label: entity.name,
});

const mapSupportedLanguages: SelectOptionsMapper<string | undefined, string | undefined> = (
  entity: LanguageEntity
) => ({
  value: entity.language,
  label: entity.code,
});

export const getSupportedLanguagesSelectOptions = <E>(
  entities: E[]
): SelectOption<string | undefined, string | undefined>[] => map(entities, (entity) => mapSupportedLanguages(entity));
