import { Button } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import styled from 'styled-components';
import { colors } from '../../../../../styles/colors';

export const Heading = styled.div<{ color: string }>`
  color: ${({ color }) => color};
  font-size: 34px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 51px;
  text-align: center;
  max-width: 450px;
  width: 100%;
  margin: auto;
`;

export const SubHeading = styled.div<{ color: string }>`
  font-size: 14px;
  text-transform: uppercase;
  color: ${({ color }) => color};
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
`;

export const LogoContainer = styled.div`
  width: 123px;
  margin-top: 16px;
  margin-bottom: 0;
`;

export const Logo = styled.img`
  width: 100%;
  height: auto;
`;

export const FormContent = styled.div`
  font-family: Poppins;
  margin-top: 79px;
`;

export const FormContentFields = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .ant-form-item {
    width: 348px;
  }

  .ant-form-item-control-input {
    width: 348px;
  }

  .ant-input {
    height: 48px;
  }
`;

export const SignUpButton = styled(Button)<ButtonProps>`
  height: 48px;
  max-width: 340px;
  width: 100%;
  border-radius: 4px;
  background-color: ${colors.sushi};
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  color: ${colors.white};
  text-transform: uppercase;
  border: ${({ disabled }) => (disabled ? '1px solid transparent' : 0)};
  display: block;
  margin: 0 auto;
  &:hover {
    background-color: ${colors.sushi};
    filter: ${({ disabled }) => (disabled ? 'none' : 'brightness(110%)')};
  }
`;
