import React from 'react';
import { Form, Button } from 'antd';
import { authenticationActionCreators } from '../../../../logic/store/authentication/authentication.actions';
import { useDispatch, useSelector } from 'react-redux';
import { translate, translationKeys } from '../../../../logic/translations/translations.service';
import { reduxForm, InjectedFormProps, Field, getFormValues } from 'redux-form';
import { FormTextInput } from '../../../components/form-inputs/form-text-input/form-text-input';
import { FormContainer, formButtonLayout, formItemLayout } from '../../../components/form-container/form-container';
import { getInProgress } from '../../../../logic/store/process-tracker/process-tracker.selectors';
import { ForgotPasswordForm, FORGOT_PASSWORD_FORM_NAME, validateForgotPasswordForm } from './forgot-password.form';
import { AuthenticationMasterPage } from '../authentication-master/authentication-master.page';
import { isDevelopment, env } from '../../../../logic/env/env';
import { Typography } from 'antd';
import { Logo } from '../../../components/logo/logo';
import { trimValue } from '../../../../logic/formaters/formaters';

const { Text } = Typography;

interface ForgotPasswordPageProps extends InjectedFormProps<ForgotPasswordForm> {}

const ForgotPassword: React.FC<ForgotPasswordPageProps> = () => {
  const dispatch = useDispatch();
  const formValues: ForgotPasswordForm = useSelector(getFormValues(FORGOT_PASSWORD_FORM_NAME)) || {};
  const inProgress = useSelector(getInProgress(authenticationActionCreators.forgotPassword.name));

  const handleForgotPassword = () => {
    dispatch(authenticationActionCreators.forgotPassword(formValues));
  };

  return (
    <AuthenticationMasterPage>
      <FormContainer>
        <Form.Item {...formButtonLayout}>
          <Logo />
          <Text>{translate(translationKeys.messages.putYourEmail)}</Text>
        </Form.Item>
        <Field
          name={'email'}
          component={FormTextInput}
          label={translate(translationKeys.forms.fields.user.email)}
          required
          disabled={inProgress}
          onPressEnter={handleForgotPassword}
          onBlurFormater={trimValue}
          {...formItemLayout}
        />
        <Form.Item {...formButtonLayout}>
          <Button
            type={'primary'}
            className={'form-button'}
            onClick={handleForgotPassword}
            disabled={inProgress}
            loading={inProgress}
          >
            {translate(translationKeys.buttons.resetPassword)}
          </Button>
        </Form.Item>
      </FormContainer>
    </AuthenticationMasterPage>
  );
};

const initialValues = isDevelopment()
  ? {
      email: env.REACT_APP_DEV_EMAIL,
    }
  : {};

export const ForgotPasswordPage = reduxForm({
  form: FORGOT_PASSWORD_FORM_NAME,
  validate: validateForgotPasswordForm,
  initialValues,
})(ForgotPassword);
