import { axiosAuthenticatedClient } from '../../axios/axios-authenticated-client';
import {
  formValuesToLocationMapper,

} from '../../../pages/location-e911/pages/location-e911/location-e911-form';
import { LocationForm } from '../../../common/location/location.type';
import { env } from '../../env/env';

export const locationApi = {
  getSingle: (id: number) => axiosAuthenticatedClient().get(`${env.API_VERSION}/location/${id}`),
  addSingle: (formValues: LocationForm) =>
    axiosAuthenticatedClient().post(`${env.API_VERSION}/location/`, formValuesToLocationMapper(formValues)),
  updateSingle: (formValues: LocationForm) =>
    axiosAuthenticatedClient().put(`${env.API_VERSION}/location/${formValues.id}/`, formValuesToLocationMapper(formValues)),
  deleteSingle: (id: number) => axiosAuthenticatedClient().delete(`${env.API_VERSION}/location/${id}`),
};
