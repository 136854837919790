import { FormErrors } from 'redux-form';
import { clearEmptyFields } from '../../../../logic/validators/utils';
import { isRequiredValidator } from '../../../../logic/validators/validators';
import { isCognitoPasswordValidator } from '../../../../logic/validators/passwordValidator';

export const COMPLETE_NEW_PASSWORD_FORM_NAME = 'completeNewPassword';

export interface CompleteNewPasswordForm {
  newPassword?: string;
}

export type CompleteNewPasswordValidationErrors = FormErrors<CompleteNewPasswordForm>;

export const validateCompleteNewPasswordForm = (formValues: CompleteNewPasswordForm) => {
  const { newPassword } = formValues;

  const errors: FormErrors<CompleteNewPasswordForm, any> = {
    newPassword: isRequiredValidator(newPassword) || isCognitoPasswordValidator(newPassword),
  };

  return clearEmptyFields(errors);
};
