import { useState } from 'react';

export interface UseModal<T = any> {
  visible: boolean;
  show: (data?: T) => void;
  hide: () => void;
  data?: T;
}

export const useModal = <T = any>() => {
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState<T>();

  const show = (data?: T) => {
    setData(data);
    setVisible(true);
  };
  const hide = () => setVisible(false);

  return {
    visible,
    show,
    hide,
    data,
  };
};
