import { takeEvery, put, call, select } from 'redux-saga/effects';
import { SagaManager } from '../../saga-manager/saga-manager';
import { alertApi } from './alert.api';
import { AlertActionTypes, alertActionCreators, AlertActions } from './alert.action';
import { ReturnActionOfType } from '../../redux/action-creator';
import { fillReduxForm } from '../../redux-form/fill-redux-form';
import { ALERT_TYPE_FORM } from '../../../pages/alerts/pages/alert-type-form-template/alert-type-form';
import { logActionCreators } from '../log/log.actions';
import { translate, translationKeys } from '../../translations/translations.service';
import { navigationActionCreators } from '../navigation/navigation.actions';
import { AUTHENTICATED_ROUTES } from '../../routes/routes.const';
import { mapServerErrorsToReduxFormErrors } from '../../server-error-parser/server-error-parser';
import { getAuthenticatedUser } from '../authentication/authentication.selectors';
import { AlertType, User } from '../../../api-models/api-models';
import { tableActionCreators, TableName } from '../tables/tables.action';
import { omit } from 'lodash';
import { AlertTypeFormFieldName } from '../../../pages/alerts/pages/alert-type-form-template/alert-type.form.field-name';
import { progressHandler } from '../../axios/axios-progres-handler';
import {
  ALERT_LINK_FORM,
  validateAlertLinkForm,
} from '../../../pages/alerts/pages/alert-documents/alert-link-form/alert-link-form.form';
import {
  ALERT_FILE_FORM,
  validateAlertFileForm,
} from '../../../pages/alerts/pages/alert-documents/alert-file-form/alert-file-form.form';
import {
  ALERT_CUSTOM_RESPONSES_FORM,
  validateAlertCustomResponsesForm,
} from '../../../pages/alerts/pages/alert-custom-responses/alert-costom-responses-form/alert-custom-responses.form';
import { ApiResponse } from '../../axios/axios-api-response';
import { refreshUserCompanySaga } from '../authentication/authentication.saga';

export function* alertSagaWatch() {
  yield takeEvery(AlertActionTypes.CREATE_SINGLE, createSingleSagaManaged);
  yield takeEvery(AlertActionTypes.UPDATE_SINGLE, updateSingleSagaManaged);
  yield takeEvery(AlertActionTypes.GET_SINGLE_FOR_EDIT, getSingleForEditSagaManaged);
  yield takeEvery(AlertActionTypes.DELETE_SINGLE, deleteSingleSagaManaged);
  yield takeEvery(AlertActionTypes.RISE, riseSagaManaged);
  yield takeEvery(AlertActionTypes.LOWER, lowerSagaManaged);
  yield takeEvery(AlertActionTypes.FETCH_SINGLE, fetchSingleSagaManaged);
  yield takeEvery(AlertActionTypes.UPLOAD_FILE, uploadFileSagaManaged);
  yield takeEvery(AlertActionTypes.UPLOAD_LINK, uploadLinkSagaManaged);
  yield takeEvery(AlertActionTypes.DELETE_DOCUMENT, deleteDocumentSagaManaged);
  yield takeEvery(AlertActionTypes.CHANGE_ORDER, changeOrderSagaManaged);
  yield takeEvery(AlertActionTypes.FETCH_PDF_REPORT, fetchPdfReportSagaManaged);
  yield takeEvery(AlertActionTypes.ADD_CUSTOM_RESPONSE, addCustomResponseManaged);
  yield takeEvery(AlertActionTypes.DELETE_CUSTOM_RESPONSE, deleteCustomResponseManaged);
}

function* fetchPdfReportSagaManaged(action: ReturnActionOfType<AlertActions, AlertActionTypes.FETCH_PDF_REPORT>) {
  yield new SagaManager().addTracking(alertActionCreators.fetchPdfReport.name).execute(fetchPdfReportSaga, action);
}

function* fetchPdfReportSaga(action: ReturnActionOfType<AlertActions, AlertActionTypes.FETCH_PDF_REPORT>) {
  const pdfLocale = navigator.language;
  const pdfTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const response: ApiResponse<any> = yield call(
    alertApi.fetchPdfReport,
    action.payload.id,
    progressHandler(alertActionCreators.fetchPdfReport.name),
    pdfLocale,
    pdfTimezone
  );

  const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
  window.open(url, '_blank');
}

function* changeOrderSagaManaged(action: ReturnActionOfType<AlertActions, AlertActionTypes.CHANGE_ORDER>) {
  yield new SagaManager().addTracking(alertActionCreators.changeOrder.name).execute(changeOrderSaga, action);
}

function* changeOrderSaga(action: ReturnActionOfType<AlertActions, AlertActionTypes.CHANGE_ORDER>) {
  const { alertId, order } = action.payload;
  if (alertId !== undefined && order !== undefined) {
    yield call(alertApi.changeOrder, alertId, order);
    yield put(tableActionCreators.fetchTable(TableName.alert));
  }
}

function* fetchSingleSagaManaged(action: ReturnActionOfType<AlertActions, AlertActionTypes.FETCH_SINGLE>) {
  yield new SagaManager().addTracking(alertActionCreators.fetchSingle.name).execute(fetchSingleSaga, action);
}

function* fetchSingleSaga(action: ReturnActionOfType<AlertActions, AlertActionTypes.FETCH_SINGLE>) {
  const response: ApiResponse<AlertType> = yield call(alertApi.getSingle, action.payload.id);
  yield put(alertActionCreators.setSingle(response));
}

function* riseSagaManaged(action: ReturnActionOfType<AlertActions, AlertActionTypes.RISE>) {
  yield new SagaManager().addTracking(alertActionCreators.rise.name).execute(riseSaga, action);
}

function* riseSaga(action: ReturnActionOfType<AlertActions, AlertActionTypes.RISE>) {
  const authenticatedUser: User | undefined = yield select(getAuthenticatedUser);
  yield call(alertApi.rise, action.payload.id, authenticatedUser?.id);
  yield put(logActionCreators.logSuccess(translate(translationKeys.messages.alertWasSuccessfullyRaised)));
}

function* lowerSagaManaged(action: ReturnActionOfType<AlertActions, AlertActionTypes.LOWER>) {
  yield new SagaManager().addTracking(alertActionCreators.lower.name).execute(lowerSaga, action);
}

function* lowerSaga(action: ReturnActionOfType<AlertActions, AlertActionTypes.LOWER>) {
  yield call(alertApi.lower, action.payload.id);
  yield put(tableActionCreators.fetchTable(TableName.activeAlert));
  yield put(logActionCreators.logSuccess(translate(translationKeys.messages.alertWasSuccessfullyLowered)));
}

function* getSingleForEditSagaManaged(action: ReturnActionOfType<AlertActions, AlertActionTypes.GET_SINGLE_FOR_EDIT>) {
  yield new SagaManager().addTracking(alertActionCreators.getSingleForEdit.name).execute(getSingleForEdit, action);
}

function* getSingleForEdit(action: ReturnActionOfType<AlertActions, AlertActionTypes.GET_SINGLE_FOR_EDIT>) {
  const { data } = yield call(alertApi.getSingle, action.payload.id);
  yield fillReduxForm(action.payload.formName, action.payload.mapper, data);
}

function* updateSingleSagaManaged(action: ReturnActionOfType<AlertActions, AlertActionTypes.UPDATE_SINGLE>) {
  yield new SagaManager()
    .addTracking(alertActionCreators.updateSingle.name)
    .addReduxFormAsyncValidation(ALERT_TYPE_FORM, mapServerErrorsToReduxFormErrors)
    .execute(updateSingleSaga, action);
}

function* updateSingleSaga(action: ReturnActionOfType<AlertActions, AlertActionTypes.UPDATE_SINGLE>) {
  const alertPayload = omit(action.payload.formValues, AlertTypeFormFieldName.files);
  yield call(alertApi.updateSingle, alertPayload);
  yield put(logActionCreators.logSuccess(translate(translationKeys.messages.alertWasSuccessfullyUpdated)));
  yield put(navigationActionCreators.navigateTo(AUTHENTICATED_ROUTES.Alerts));
}

function* createSingleSagaManaged(action: ReturnActionOfType<AlertActions, AlertActionTypes.CREATE_SINGLE>) {
  yield new SagaManager()
    .addTracking(alertActionCreators.createSingle.name)
    .addReduxFormAsyncValidation(ALERT_TYPE_FORM, mapServerErrorsToReduxFormErrors)
    .execute(createSingleSaga, action);
}

function* createSingleSaga(action: ReturnActionOfType<AlertActions, AlertActionTypes.CREATE_SINGLE>) {
  yield call(alertApi.addSingle, action.payload.formValues);
  yield refreshUserCompanySaga();
  yield put(logActionCreators.logSuccess(translate(translationKeys.messages.alertWasSuccessfullyCreated)));
  yield put(navigationActionCreators.navigateTo(AUTHENTICATED_ROUTES.Alerts));
}

function* uploadFileSagaManaged(action: ReturnActionOfType<AlertActions, AlertActionTypes.UPLOAD_FILE>) {
  yield new SagaManager()
    .addTracking(alertActionCreators.uploadFile.name)
    .addReduxFormValidation(ALERT_FILE_FORM, action.payload.formValues, validateAlertFileForm)
    .execute(uploadFileSaga, action);
}

function* uploadFileSaga(action: ReturnActionOfType<AlertActions, AlertActionTypes.UPLOAD_FILE>) {
  const { alertId, formValues } = action.payload;
  if (formValues.file?.originFileObj && formValues.description) {
    yield call(
      alertApi.uploadFile,
      alertId,
      formValues.file?.originFileObj,
      formValues.description,
      progressHandler(alertActionCreators.uploadFile.name)
    );
    yield put(logActionCreators.logSuccess(translate(translationKeys.messages.fileWasSuccessfullyAddedToAlert)));
    yield put(alertActionCreators.fetchSingle(alertId));
  }
}

function* uploadLinkSagaManaged(action: ReturnActionOfType<AlertActions, AlertActionTypes.UPLOAD_LINK>) {
  yield new SagaManager()
    .addTracking(alertActionCreators.uploadLink.name)
    .addReduxFormValidation(ALERT_LINK_FORM, action.payload.formValues, validateAlertLinkForm)
    .addReduxFormAsyncValidation(ALERT_LINK_FORM, mapServerErrorsToReduxFormErrors)
    .execute(uploadLinkSaga, action);
}

function* uploadLinkSaga(action: ReturnActionOfType<AlertActions, AlertActionTypes.UPLOAD_LINK>) {
  const { alertId, formValues } = action.payload;
  yield call(alertApi.uploadLink, alertId, formValues.url, formValues.description);
  yield put(logActionCreators.logSuccess(translate(translationKeys.messages.linkWasSuccessfullyAddedToAlert)));
  yield put(alertActionCreators.fetchSingle(alertId));
}

function* addCustomResponseManaged(action: ReturnActionOfType<AlertActions, AlertActionTypes.ADD_CUSTOM_RESPONSE>) {
  yield new SagaManager()
    .addTracking(alertActionCreators.addCustomResponse.name)
    .addReduxFormValidation(ALERT_CUSTOM_RESPONSES_FORM, action.payload.formValues, validateAlertCustomResponsesForm)
    .addReduxFormAsyncValidation(ALERT_CUSTOM_RESPONSES_FORM, mapServerErrorsToReduxFormErrors)
    .execute(addCustomResponse, action);
}

function* addCustomResponse(action: ReturnActionOfType<AlertActions, AlertActionTypes.ADD_CUSTOM_RESPONSE>) {
  const { alertId, formValues } = action.payload;
  yield call(alertApi.addCustomResponse, alertId, formValues.label_text, formValues.text);
  yield put(
    logActionCreators.logSuccess(translate(translationKeys.messages.customResponseWasSuccessfullyAddedToAlert))
  );
  yield put(alertActionCreators.fetchSingle(alertId));
}

function* deleteCustomResponseManaged(
  action: ReturnActionOfType<AlertActions, AlertActionTypes.DELETE_CUSTOM_RESPONSE>
) {
  yield new SagaManager()
    .addTracking(alertActionCreators.deleteCustomResponse.name)
    .execute(deleteCustomResponseSaga, action);
}

function* deleteCustomResponseSaga(action: ReturnActionOfType<AlertActions, AlertActionTypes.DELETE_CUSTOM_RESPONSE>) {
  const { alertId, customResponseId } = action.payload;
  yield call(alertApi.deleteCustomResponse, alertId, customResponseId);
  yield put(logActionCreators.logSuccess(translate(translationKeys.messages.customResponseWasSuccessfullyDeleted)));
  yield put(alertActionCreators.fetchSingle(alertId));
}

function* deleteDocumentSagaManaged(action: ReturnActionOfType<AlertActions, AlertActionTypes.DELETE_DOCUMENT>) {
  yield new SagaManager().addTracking(alertActionCreators.deleteDocument.name).execute(deleteDocumentSaga, action);
}

function* deleteDocumentSaga(action: ReturnActionOfType<AlertActions, AlertActionTypes.DELETE_DOCUMENT>) {
  const { alertId, documentId } = action.payload;
  yield call(alertApi.deleteDocument, alertId, documentId);
  yield put(logActionCreators.logSuccess(translate(translationKeys.messages.documentWasSuccessfullyDeleted)));
  yield put(alertActionCreators.fetchSingle(alertId));
}

function* deleteSingleSagaManaged(action: ReturnActionOfType<AlertActions, AlertActionTypes.DELETE_SINGLE>) {
  yield new SagaManager().addTracking(alertActionCreators.deleteSingle.name).execute(deleteSingleSaga, action);
}

function* deleteSingleSaga(action: ReturnActionOfType<AlertActions, AlertActionTypes.DELETE_SINGLE>) {
  yield call(alertApi.deleteSingle, action.payload.id);
  yield put(logActionCreators.logSuccess(translate(translationKeys.messages.alertWasSuccessfullyDeleted)));
  yield put(tableActionCreators.fetchTable(TableName.alert));
}
