import { AlertFileFieldName } from './alert-file-form.field-name';
import { FormErrors } from 'redux-form';
import { clearEmptyFieldsAndEmptyObjects } from '../../../../../logic/validators/utils';
import { isRequiredValidator, isFileSizeLessOrEqual } from '../../../../../logic/validators/validators';
import { UploadFile } from 'antd/lib/upload/interface';
import { FILE_SIZE_POWER_BASE } from '../../../../../logic/utils/file';

export const ALERT_FILE_FORM = 'alertFile';

export interface AlertFileForm {
  [AlertFileFieldName.file]?: UploadFile;
  [AlertFileFieldName.description]?: string;
}

const MAX_ALERT_FILE_SIZE_IN_BYTES = 6 * FILE_SIZE_POWER_BASE * FILE_SIZE_POWER_BASE;

export const validateAlertFileForm = (formValues: AlertFileForm): FormErrors<AlertFileForm, string> => {
  const { file, description } = formValues;

  return clearEmptyFieldsAndEmptyObjects({
    file: isRequiredValidator(file) || isFileSizeLessOrEqual(file?.originFileObj as File, MAX_ALERT_FILE_SIZE_IN_BYTES),
    description: isRequiredValidator(description),
  });
};
