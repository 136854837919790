import { takeEvery, put, call, select } from 'redux-saga/effects';
import { SagaManager } from '../../saga-manager/saga-manager';
import { companyApi } from './company.api';
import { CompanyActionTypes, companyActionCreators, CompaniesActions } from './company.action';
import { ReturnActionOfType } from '../../redux/action-creator';
import { fillReduxForm } from '../../redux-form/fill-redux-form';
import {
  COMPANY_FORM,
  validateCompanyForm,
} from '../../../pages/company/pages/company-form-template/company-form.form';
import { logActionCreators } from '../log/log.actions';
import { translate, translationKeys } from '../../translations/translations.service';
import { navigationActionCreators } from '../navigation/navigation.actions';
import { mapServerErrorsToReduxFormErrors } from '../../server-error-parser/server-error-parser';
import { ApiResponse } from '../../axios/axios-api-response';
import { getAuthenticatedUser, getAuthenticatedUserCompanyId } from '../authentication/authentication.selectors';
import { authenticationActionCreators } from '../authentication/authentication.actions';
import { User, Company } from '../../../api-models/api-models';
import { tableActionCreators, TableName } from '../tables/tables.action';
import {
  SETUP_USER_DETAILS_FORM,
  validateSetupUserDetailsForm,
} from '../../../pages/dashboard/setup-company/setup-user-details-form/setup-user-details.form';
import { updateSetupUserDetails } from '../user/user.saga';
import { userActionCreators } from '../user/user.action';
import { refreshUserCompanySaga } from '../authentication/authentication.saga';

export function* companySagaWatch() {
  yield takeEvery(CompanyActionTypes.ADD_SINGLE, addSingleSagaManaged);
  yield takeEvery(CompanyActionTypes.UPDATE_SINGLE, updateSingleSagaManaged);
  yield takeEvery(CompanyActionTypes.FETCH_SINGLE_FOR_EDIT, fetchSingleForEditSagaManaged);
  yield takeEvery(CompanyActionTypes.DELETE_SINGLE, deleteSingleSagaManaged);
  yield takeEvery(CompanyActionTypes.SETUP_COMPANY, setupCompanySagaManaged);
  yield takeEvery(CompanyActionTypes.SETUP_COMPANY_WITCH_USER_DETAILS, setupCompanySagaWithUserDetailsManaged);
}

function* setupCompanySagaWithUserDetailsManaged(
  action: ReturnActionOfType<CompaniesActions, CompanyActionTypes.SETUP_COMPANY_WITCH_USER_DETAILS>
) {
  yield new SagaManager()
    .addTracking(companyActionCreators.setupCompanyWithUserDetails.name)
    .addReduxFormValidation(SETUP_USER_DETAILS_FORM, action.payload.formValues, validateSetupUserDetailsForm)
    .addReduxFormAsyncValidation(SETUP_USER_DETAILS_FORM, mapServerErrorsToReduxFormErrors)
    .execute(setupCompanySagaWithUserDetails, action);
}

function* setupCompanySagaWithUserDetails(
  action: ReturnActionOfType<CompaniesActions, CompanyActionTypes.SETUP_COMPANY_WITCH_USER_DETAILS>
) {
  yield updateSetupUserDetails(
    userActionCreators.updateSingle(SETUP_USER_DETAILS_FORM, action.payload.formValues, undefined, undefined)
  );
  yield setupCompanySaga();
}

function* setupCompanySagaManaged() {
  yield new SagaManager().addTracking(companyActionCreators.setupCompany.name).execute(setupCompanySaga);
}

function* setupCompanySaga() {
  const companyId: number | undefined = yield select(getAuthenticatedUserCompanyId);
  if (companyId) {
    yield call(companyApi.setupCompany, companyId);
    yield refreshUserCompanySaga();
    yield put(
      logActionCreators.logSuccessModal(
        translate(translationKeys.pages.dashboard.setUpAutomatically.confirmation.title),
        translate(translationKeys.pages.dashboard.setUpAutomatically.confirmation.description)
      )
    );
  }
}

function* fetchSingleForEditSagaManaged(
  action: ReturnActionOfType<CompaniesActions, CompanyActionTypes.FETCH_SINGLE_FOR_EDIT>
) {
  yield new SagaManager()
    .addTracking(companyActionCreators.fetchSingleForEdit.name)
    .execute(fetchSingleForEditSaga, action);
}

function* fetchSingleForEditSaga(
  action: ReturnActionOfType<CompaniesActions, CompanyActionTypes.FETCH_SINGLE_FOR_EDIT>
) {
  const { data } = yield call(companyApi.fetchSingle, action.payload.id);
  yield fillReduxForm(action.payload.formName, action.payload.mapper, data);
}

function* updateSingleSagaManaged(action: ReturnActionOfType<CompaniesActions, CompanyActionTypes.UPDATE_SINGLE>) {
  yield new SagaManager()
    .addTracking(companyActionCreators.updateSingle.name)
    .addReduxFormValidation(COMPANY_FORM, action.payload.formValues, validateCompanyForm)
    .addReduxFormAsyncValidation(COMPANY_FORM, mapServerErrorsToReduxFormErrors)
    .execute(updateSingleSaga, action);
}

const userBelongsToCompany = (user: User | undefined, companyId: number | undefined) =>
  !!user?.company_id && !!companyId && user.company_id === companyId;

function* updateSingleSaga(action: ReturnActionOfType<CompaniesActions, CompanyActionTypes.UPDATE_SINGLE>) {
  const companyResponse: ApiResponse<Company> = yield call(companyApi.updateSingle, action.payload.formValues);

  const authenticatedUser: User = yield select(getAuthenticatedUser);
  if (userBelongsToCompany(authenticatedUser, action.payload.formValues.id)) {
    yield put(authenticationActionCreators.setUserCompany(companyResponse.data));
  }

  yield put(logActionCreators.logSuccess(translate(translationKeys.messages.companyWasSuccessfullyUpdated)));
  yield put(navigationActionCreators.back());
}

function* addSingleSagaManaged(action: ReturnActionOfType<CompaniesActions, CompanyActionTypes.ADD_SINGLE>) {
  yield new SagaManager()
    .addTracking(companyActionCreators.addSingle.name)
    .addReduxFormValidation(COMPANY_FORM, action.payload.formValues, validateCompanyForm)
    .addReduxFormAsyncValidation(COMPANY_FORM, mapServerErrorsToReduxFormErrors)
    .execute(addSingleSaga, action);
}

function* addSingleSaga(action: ReturnActionOfType<CompaniesActions, CompanyActionTypes.ADD_SINGLE>) {
  yield call(companyApi.addSingle, action.payload.formValues);
  yield put(logActionCreators.logSuccess(translate(translationKeys.messages.companyWasSuccessfullyCreated)));
  yield put(navigationActionCreators.back());
}

function* deleteSingleSagaManaged(action: ReturnActionOfType<CompaniesActions, CompanyActionTypes.DELETE_SINGLE>) {
  yield new SagaManager().addTracking(companyActionCreators.deleteSingle.name).execute(deleteSingleSaga, action);
}

function* deleteSingleSaga(action: ReturnActionOfType<CompaniesActions, CompanyActionTypes.DELETE_SINGLE>) {
  yield call(companyApi.deleteSinble, action.payload.id);
  yield put(tableActionCreators.fetchTable(TableName.company));
}
