import { compose } from 'redux';
import { AlertsTemplatePage, AlertsTemplatePageProps } from './alert-template/alerts-template.page';
import { AlertFieldName } from './alert-template/alerts.field-name';
import { wrapComponentsWithProps } from '../../../logic/hoc/wrap-components-with-props.hoc';
import { QueryStringPrefilters } from '../../../logic/store/tables/tables.reducer';
import { TableName } from '../../../logic/store/tables/tables.action';

export const AlertHistoryPageCreator = () =>
  compose(
    wrapComponentsWithProps<AlertsTemplatePageProps>({
      columnsFields: [
        AlertFieldName.name,
        AlertFieldName.created_at,
        AlertFieldName.lowered_at,
        AlertFieldName.raising_user,
        AlertFieldName.lowering_user,
        AlertFieldName.messages,
      ],
      prefilters: { active: false } as QueryStringPrefilters,
      tableName: TableName.alertHistory,
      showLowerAlertAction: false,
      showFetchPdfReportAction: true,
    })
  )(AlertsTemplatePage);
