import { LocationFormProps } from '../../../../common/location/location.type';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LocationFormFieldName } from '../../../../common/location/location-form.field-name';
import { formBlockHeaderLayout, formItemLayout } from '../../../components/form-container/form-container';
import { getFetchListTrackName, ListName, listsActionCreators } from '../../../../logic/store/lists/lists.action';
import { translate, translationKeys } from '../../../../logic/translations/translations.service';
import { Field } from 'redux-form';
import { FormTextInput } from '../../../components/form-inputs/form-text-input/form-text-input';
import { STATE_CODE_MAX_LENGTH, trimValue } from '../../../../logic/formaters/formaters';
import { Form } from 'antd';
import { FormSelectInput } from '../../../components/form-inputs/form-select-input/form-select-input';
import { getListData } from '../../../../logic/store/lists/lists.selectors';
import {
  getCustomSelectOptions,
  getDefaultSelectOptions,
  SelectOption,
} from '../../../components/form-inputs/select-option/select-option';
import { getInProgress } from '../../../../logic/store/process-tracker/process-tracker.selectors';

import { AutocompleteClass, User } from '../../../../api-models/api-models';
import { getAutocomplete } from '../../../../logic/store/autocomplete/autocomplete.selectors';
import { FormAutocompleteTextInput } from '../../../components/form-inputs/form-autocomplete-text-input/form-autocomplete-text-input';

export const LocationFormFields: React.FC<LocationFormProps> = (props) => {
  const { onPressEnter, inProgress, disabledFields = [], isViewOnly } = props;

  const isDisabled = (fieldName: LocationFormFieldName) => disabledFields.indexOf(fieldName) >= 0 || isViewOnly;
  const dispatch = useDispatch();
  const commonAttributes = (fieldName: LocationFormFieldName) => ({
    ...formItemLayout,
    disabled: isDisabled(fieldName) || inProgress,
    onPressEnter,
  });
  const companies = useSelector(getListData(ListName.company)) || [];
  const companiesOptions = getDefaultSelectOptions(companies);
  const fetchCompaniesInProgress = useSelector(getInProgress(getFetchListTrackName(ListName.company)));

  const getUserOptionsValue = (item: User): SelectOption => ({
    value: item.id,
    label: `${item.first_name} ${item.last_name}`,
  });

  const users = useSelector(getListData(ListName.user)) || [];
  const usersOptions = getCustomSelectOptions(users, getUserOptionsValue);
  const fetchUsersInProgress = useSelector(getInProgress(getFetchListTrackName(ListName.user)));

  const autocomplete = useSelector(getAutocomplete(AutocompleteClass.Location)) || {};

  useEffect(() => {
    dispatch(listsActionCreators.fetchList(ListName.company));
    dispatch(listsActionCreators.fetchList(ListName.user));
  }, [dispatch]);

  const translateFieldLabel = (fieldName: LocationFormFieldName) =>
    translate(translationKeys.forms.fields.location[fieldName]);

  const getTextField = (fieldName: LocationFormFieldName, required?: boolean, maxLength?: number) => (
    <Field
      name={fieldName}
      component={FormTextInput}
      label={translateFieldLabel(fieldName)}
      required={required}
      maxLength={maxLength}
      onBlurFormater={trimValue}
      {...commonAttributes(fieldName)}
    />
  );

  return (
    <Form.Item {...formBlockHeaderLayout}>
      {getTextField(LocationFormFieldName.name, true)}
      <Field
        name={LocationFormFieldName.geolocation_id}
        component={FormTextInput}
        label={translateFieldLabel(LocationFormFieldName.geolocation_id)}
        {...commonAttributes(LocationFormFieldName.geolocation_id)}
        disabled={true}
      />
      <Field
        name={LocationFormFieldName.company_id}
        component={FormSelectInput}
        label={translateFieldLabel(LocationFormFieldName.company_id)}
        options={companiesOptions}
        loading={fetchCompaniesInProgress}
        {...commonAttributes(LocationFormFieldName.company_id)}
        disabled={true}
      />
      {getTextField(LocationFormFieldName.address, true)}
      {getTextField(LocationFormFieldName.city, true)}
      {getTextField(LocationFormFieldName.zip_code, true)}
      {getTextField(LocationFormFieldName.state, true, STATE_CODE_MAX_LENGTH)}
      {getTextField(LocationFormFieldName.country, true)}
      <Field
        name={LocationFormFieldName.user_ids}
        component={FormSelectInput}
        label={translateFieldLabel(LocationFormFieldName.user_ids)}
        options={usersOptions}
        mode={'multiple'}
        showSearch
        filterOption={true}
        optionFilterProp={'label'}
        loading={fetchUsersInProgress}
        {...commonAttributes(LocationFormFieldName.user_ids)}
        disabled={isDisabled(LocationFormFieldName.user_ids) || fetchUsersInProgress}
      />
      <Field
        name={LocationFormFieldName.site}
        component={FormAutocompleteTextInput}
        label={translateFieldLabel(LocationFormFieldName.site)}
        textOptions={autocomplete?.[LocationFormFieldName.site]}
        onBlurFormater={trimValue}
        {...commonAttributes(LocationFormFieldName.site)}
      />
      <Field
        name={LocationFormFieldName.notes}
        component={FormTextInput}
        label={translateFieldLabel(LocationFormFieldName.notes)}
        onBlurFormater={trimValue}
        rows={5}
        {...commonAttributes(LocationFormFieldName.notes)}
      />
    </Form.Item>
  );
};
