import { takeEvery, put, call } from 'redux-saga/effects';
import { SagaManager } from '../../saga-manager/saga-manager';
import { groupApi } from './group.api';
import { GroupActionTypes, groupActionCreators, GroupActions } from './group.action';
import { ReturnActionOfType } from '../../redux/action-creator';
import { fillReduxForm } from '../../redux-form/fill-redux-form';
import { GROUP_FORM, validateGroupForm } from '../../../pages/group/pages/group-form-template/group-form.form';
import { logActionCreators } from '../log/log.actions';
import { translate, translationKeys } from '../../translations/translations.service';
import { navigationActionCreators } from '../navigation/navigation.actions';
import { AUTHENTICATED_ROUTES } from '../../routes/routes.const';
import { mapServerErrorsToReduxFormErrors } from '../../server-error-parser/server-error-parser';
import { tableActionCreators, TableName } from '../tables/tables.action';

export function* groupSagaWatch() {
  yield takeEvery(GroupActionTypes.CREATE_SINGLE, createSingleSagaManaged);
  yield takeEvery(GroupActionTypes.UPDATE_SINGLE, updateSingleSagaManaged);
  yield takeEvery(GroupActionTypes.GET_SINGLE_FOR_EDIT, getSingleForEditSagaManaged);
  yield takeEvery(GroupActionTypes.DELETE_SINGLE, deleteSingleSagaManaged);
}

function* getSingleForEditSagaManaged(action: ReturnActionOfType<GroupActions, GroupActionTypes.GET_SINGLE_FOR_EDIT>) {
  yield new SagaManager().addTracking(groupActionCreators.getSingleForEdit.name).execute(getSingleForEdit, action);
}

function* getSingleForEdit(action: ReturnActionOfType<GroupActions, GroupActionTypes.GET_SINGLE_FOR_EDIT>) {
  const { data } = yield call(groupApi.getSingle, action.payload.id);
  yield fillReduxForm(action.payload.formName, action.payload.mapper, data);
}

function* updateSingleSagaManaged(action: ReturnActionOfType<GroupActions, GroupActionTypes.UPDATE_SINGLE>) {
  yield new SagaManager()
    .addTracking(groupActionCreators.updateSingle.name)
    .addReduxFormValidation(GROUP_FORM, action.payload.formValues, validateGroupForm)
    .addReduxFormAsyncValidation(GROUP_FORM, mapServerErrorsToReduxFormErrors)
    .execute(updateSingleSaga, action);
}

function* updateSingleSaga(action: ReturnActionOfType<GroupActions, GroupActionTypes.UPDATE_SINGLE>) {
  yield call(groupApi.updateSingle, action.payload.formValues);
  yield put(logActionCreators.logSuccess(translate(translationKeys.messages.groupWasSuccessfullyUpdated)));
  yield put(navigationActionCreators.navigateTo(AUTHENTICATED_ROUTES.Groups));
}

function* createSingleSagaManaged(action: ReturnActionOfType<GroupActions, GroupActionTypes.CREATE_SINGLE>) {
  yield new SagaManager()
    .addTracking(groupActionCreators.createSingle.name)
    .addReduxFormValidation(GROUP_FORM, action.payload.formValues, validateGroupForm)
    .addReduxFormAsyncValidation(GROUP_FORM, mapServerErrorsToReduxFormErrors)
    .execute(createSingleSaga, action);
}

function* createSingleSaga(action: ReturnActionOfType<GroupActions, GroupActionTypes.CREATE_SINGLE>) {
  yield call(groupApi.addSingle, action.payload.formValues);
  yield put(logActionCreators.logSuccess(translate(translationKeys.messages.groupWasSuccessfullyCreated)));
  yield put(navigationActionCreators.navigateTo(AUTHENTICATED_ROUTES.Groups));
}

function* deleteSingleSagaManaged(action: ReturnActionOfType<GroupActions, GroupActionTypes.DELETE_SINGLE>) {
  yield new SagaManager().addTracking(groupActionCreators.deleteSingle.name).execute(deleteSingleSaga, action);
}

function* deleteSingleSaga(action: ReturnActionOfType<GroupActions, GroupActionTypes.DELETE_SINGLE>) {
  yield call(groupApi.deleteSingle, action.payload.id);
  yield put(tableActionCreators.fetchTable(TableName.group));
  yield put(logActionCreators.logSuccess(translate(translationKeys.messages.groupWasSuccessfullyDeleted)));
}
