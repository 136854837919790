import React from 'react';
import './master-header.styles.scss';
import { Layout, Divider, Row, Col, Space } from 'antd';
import { UserDropdown } from '../../../components/user-dropdown/user-dropdown';
import { Logo } from '../../../components/logo/logo';
import Title from 'antd/lib/typography/Title';
import { useSelector } from 'react-redux';
import { getAuthenticatedUserCompany } from '../../../../logic/store/authentication/authentication.selectors';
import { resourceContents, Resource } from '../../../components/resources/resource.const';
const { Header } = Layout;

export const MasterHeader: React.FC = () => {
  const company = useSelector(getAuthenticatedUserCompany);
  const layout = {
    md: 24,
    lg: 12,
  };
  const renderBranding = () => (
    <div className={'brand-section'}>
      <a href={resourceContents[Resource.crsLanding].url}>
        <Logo className={'logo-menu'} />
      </a>
      <Title level={3} className={'company-name'}>
        {[company?.name].filter((value) => !!value).join(', ')}
      </Title>
    </div>
  );
  const renderUserDropdown = () => (
    <Space className="user-dropdown-container" align="end">
      <UserDropdown />
    </Space>
  );

  return (
    <>
      <Header className={'master-header'}>
        <Row justify="space-between">
          <Col {...layout}>{renderBranding()}</Col>
          <Col {...layout}>{renderUserDropdown()}</Col>
        </Row>
      </Header>
      <Divider className={'master-header-divider'} />
    </>
  );
};
