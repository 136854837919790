import { put, call, takeEvery } from 'redux-saga/effects';
import {
  SsoCompanyGroupMappingActionTypes,
  SsoCompanyGroupMappingActions,
  ssoCompanyGroupMappingActionCreators,
} from './sso-company-group-mapping.actions';
import { ReturnActionOfType } from '../../redux/action-creator';
import { SagaManager } from '../../saga-manager/saga-manager';
import { mapServerErrorsToReduxFormErrors } from '../../server-error-parser/server-error-parser';
import { ssoCompanyGroupMappingApi } from './sso-company-group-mapping.api';
import { logActionCreators } from '../log/log.actions';
import { translate, translationKeys } from '../../translations/translations.service';
import { navigationActionCreators } from '../navigation/navigation.actions';
import { AUTHENTICATED_ROUTES } from '../../routes/routes.const';
import { TableName, tableActionCreators } from '../tables/tables.action';
import {
  SSO_COMPANY_GROUP_MAPPING_FORM,
  validateSsoCompanyGroupMappingForm,
} from '../../../pages/sso-company-group-mapping/pages/sso-company-group-mapping-form-template/sso-company-group-mapping-form.form';
import { fillReduxForm } from '../../redux-form/fill-redux-form';

export function* ssoCompanyGroupMappingSagaWatch() {
  yield takeEvery(SsoCompanyGroupMappingActionTypes.CREATE_SINGLE, createSingleSagaManaged);
  yield takeEvery(SsoCompanyGroupMappingActionTypes.UPDATE_SINGLE, updateSingleSagaManaged);
  yield takeEvery(SsoCompanyGroupMappingActionTypes.DELETE_SINGLE, deleteSingleSagaManaged);
  yield takeEvery(SsoCompanyGroupMappingActionTypes.GET_SINGLE_FOR_EDIT, getSingleForEditSagaManaged);
}

function* getSingleForEditSagaManaged(
  action: ReturnActionOfType<SsoCompanyGroupMappingActions, SsoCompanyGroupMappingActionTypes.GET_SINGLE_FOR_EDIT>
) {
  yield new SagaManager()
    .addTracking(ssoCompanyGroupMappingActionCreators.getSingleForEdit.name)
    .execute(getSingleForEdit, action);
}

function* getSingleForEdit(
  action: ReturnActionOfType<SsoCompanyGroupMappingActions, SsoCompanyGroupMappingActionTypes.GET_SINGLE_FOR_EDIT>
) {
  const { data } = yield call(ssoCompanyGroupMappingApi.getSingle, action.payload.id);
  yield fillReduxForm(action.payload.formName, action.payload.mapper, data);
}

function* createSingleSagaManaged(
  action: ReturnActionOfType<SsoCompanyGroupMappingActions, SsoCompanyGroupMappingActionTypes.CREATE_SINGLE>
) {
  yield new SagaManager()
    .addTracking(ssoCompanyGroupMappingActionCreators.createSingle.name)
    .addReduxFormValidation(
      SSO_COMPANY_GROUP_MAPPING_FORM,
      action.payload.formValues,
      validateSsoCompanyGroupMappingForm
    )
    .addReduxFormAsyncValidation(SSO_COMPANY_GROUP_MAPPING_FORM, mapServerErrorsToReduxFormErrors)
    .execute(createSingleSaga, action);
}

function* createSingleSaga(
  action: ReturnActionOfType<SsoCompanyGroupMappingActions, SsoCompanyGroupMappingActionTypes.CREATE_SINGLE>
) {
  yield call(ssoCompanyGroupMappingApi.addSingle, action.payload.formValues);
  yield put(logActionCreators.logSuccess(translate(translationKeys.messages.ssoCompanyGroupMappingSuccessfullyCreate)));
  yield put(navigationActionCreators.navigateTo(AUTHENTICATED_ROUTES.SsoCompanyGroupMapping));
}

function* updateSingleSagaManaged(
  action: ReturnActionOfType<SsoCompanyGroupMappingActions, SsoCompanyGroupMappingActionTypes.UPDATE_SINGLE>
) {
  yield new SagaManager()
    .addTracking(ssoCompanyGroupMappingActionCreators.updateSingle.name)
    .addReduxFormValidation(
      SSO_COMPANY_GROUP_MAPPING_FORM,
      action.payload.formValues,
      validateSsoCompanyGroupMappingForm
    )
    .addReduxFormAsyncValidation(SSO_COMPANY_GROUP_MAPPING_FORM, mapServerErrorsToReduxFormErrors)
    .execute(updateSingleSaga, action);
}

function* updateSingleSaga(
  action: ReturnActionOfType<SsoCompanyGroupMappingActions, SsoCompanyGroupMappingActionTypes.UPDATE_SINGLE>
) {
  yield call(ssoCompanyGroupMappingApi.updateSingle, action.payload.formValues);
  yield put(
    logActionCreators.logSuccess(translate(translationKeys.messages.ssoCompanyGroupMappingSuccessfullyUpdated))
  );
  yield put(navigationActionCreators.back());
}

function* deleteSingleSagaManaged(
  action: ReturnActionOfType<SsoCompanyGroupMappingActions, SsoCompanyGroupMappingActionTypes.DELETE_SINGLE>
) {
  yield new SagaManager()
    .addTracking(ssoCompanyGroupMappingActionCreators.deleteSingle.name)
    .execute(deleteSingleSaga, action);
}

function* deleteSingleSaga(
  action: ReturnActionOfType<SsoCompanyGroupMappingActions, SsoCompanyGroupMappingActionTypes.DELETE_SINGLE>
) {
  yield call(ssoCompanyGroupMappingApi.deleteSingle, action.payload.id);
  yield put(
    logActionCreators.logSuccess(translate(translationKeys.messages.ssoCompanyGroupMappingSuccessfullyDeleted))
  );
  yield put(tableActionCreators.fetchTable(TableName.ssoCompanyGroupMapping));
}
