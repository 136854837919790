import { axiosAuthenticatedClient } from '../../axios/axios-authenticated-client';
import {
  AlertTypeForm,
  formValuesToAlertTypeMapper,
} from '../../../pages/alerts/pages/alert-type-form-template/alert-type-form';
import { getFileHeaders } from '../../axios/axios';
import { AxiosRequestConfig } from 'axios';
import { env } from '../../env/env';

export const alertApi = {
  getSingle: (id: any) => axiosAuthenticatedClient().get(`${env.API_VERSION}/alert/${id}`),
  addSingle: (formValues: AlertTypeForm) =>
    axiosAuthenticatedClient().post(`${env.API_VERSION}/alert/`, formValuesToAlertTypeMapper(formValues)),
  updateSingle: (formValues: AlertTypeForm) =>
    axiosAuthenticatedClient().put(
      `${env.API_VERSION}/alert/${formValues.id}/`,
      formValuesToAlertTypeMapper(formValues)
    ),
  deleteSingle: (id: number) => axiosAuthenticatedClient().delete(`${env.API_VERSION}/alert/${id}`),
  rise: (id: number, userId: number | undefined) =>
    axiosAuthenticatedClient().post(`${env.API_VERSION}/chat/`, {
      alert_type_id: id,
      raising_user_id: userId,
    }),
  lower: (id: number) => axiosAuthenticatedClient().patch(`${env.API_VERSION}/chat/${id}/lower_alert/`),
  uploadFile: (
    alertId: number,
    file: File | Blob,
    description: string,
    onUploadProgress?: (progressEvent: any) => void
  ) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('description', description);

    return axiosAuthenticatedClient(getFileHeaders()).post(`${env.API_VERSION}/alert/${alertId}/files/`, formData, {
      onUploadProgress,
    });
  },
  uploadLink: (alertId: number, url: string | undefined, description: string | undefined) =>
    axiosAuthenticatedClient().post(`${env.API_VERSION}/alert/${alertId}/files/`, { url, description }),
  deleteDocument: (alertId: number, documentId: number) =>
    axiosAuthenticatedClient().delete(`${env.API_VERSION}/alert/${alertId}/files/`, { data: { file_id: documentId } }),
  changeOrder: (alertId: number, order: number) =>
    axiosAuthenticatedClient().patch(`${env.API_VERSION}/alert/${alertId}/change_order/`, { order }),
  fetchPdfReport: (
    id: number,
    onDownloadProgress: AxiosRequestConfig['onDownloadProgress'],
    locale?: string,
    timezone?: string
  ) =>
    axiosAuthenticatedClient().get(`${env.API_VERSION}/chat/${id}/get_pdf/?locale=${locale}&timezone=${timezone}`, {
      onDownloadProgress,
    }),
  addCustomResponse: (alertId: number, label_text: string | undefined, text: string | undefined) =>
    axiosAuthenticatedClient().post(`${env.API_VERSION}/alert/${alertId}/custom_responses/`, { label_text, text }),
  deleteCustomResponse: (alertId: number, customResponseId: number) =>
    axiosAuthenticatedClient().delete(`${env.API_VERSION}/alert/${alertId}/custom_responses/`, {
      data: { custom_response_id: customResponseId },
    }),
};
