import React from 'react';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import { AlertLinkFieldName } from './alert-link-form.field-name';
import { translate, translationKeys } from '../../../../../logic/translations/translations.service';
import { FormTextInput } from '../../../../components/form-inputs/form-text-input/form-text-input';
import { trimValue } from '../../../../../logic/formaters/formaters';
import { ALERT_LINK_FORM, validateAlertLinkForm } from './alert-link-form.form';
import { useSelector } from 'react-redux';
import { getInProgress } from '../../../../../logic/store/process-tracker/process-tracker.selectors';
import { alertActionCreators } from '../../../../../logic/store/alert/alert.action';
import { Alert } from 'antd';

interface AlertLinkFormProps extends InjectedFormProps {}

const AlertLinkForm: React.FC<AlertLinkFormProps> = ({ error }) => {
  const inProgress = useSelector(getInProgress(alertActionCreators.uploadLink.name));
  const commonAttributes = {
    disabled: inProgress,
  };

  const translateFieldLabel = (fieldName: AlertLinkFieldName) =>
    translate(translationKeys.forms.fields.alertLink[fieldName]);

  const getTextField = (fieldName: AlertLinkFieldName) => (
    <Field
      name={fieldName}
      component={FormTextInput}
      placeholder={translateFieldLabel(fieldName)}
      onBlurFormater={trimValue}
      {...commonAttributes}
    />
  );

  return (
    <>
      {getTextField(AlertLinkFieldName.url)}
      {getTextField(AlertLinkFieldName.description)}

      {error && <Alert message={translate(translationKeys.errors.error)} description={error} type={'error'} showIcon />}
    </>
  );
};

export const AlertLinkFormFields = reduxForm({
  form: ALERT_LINK_FORM,
  validate: validateAlertLinkForm,
})(AlertLinkForm);
