export const isProduction = () => process.env.NODE_ENV === 'production';
export const isStaging = () => process.env.NODE_ENV === 'production';
export const isDevelopment = () => process.env.NODE_ENV === 'development';
export const isTest = () => process.env.NODE_ENV === 'test';

const getEnv = () => ({
  API_VERSION: '/api/v2',
  REACT_APP_API_URL: process.env.REACT_APP_API_URL,
  REACT_APP_DOWNLOADS_FOLDER_URL: process.env.REACT_APP_DOWNLOADS_FOLDER_URL,
  REACT_APP_COGNITO_USER_POOL_ID: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  REACT_APP_COGNITO_POOL_WEB_CLIENT_ID: process.env.REACT_APP_COGNITO_POOL_WEB_CLIENT_ID,
  NODE_ENV: process.env.NODE_ENV,
  REACT_APP_CB_APPLICATION_ID: process.env.REACT_APP_CB_APPLICATION_ID,
  REACT_APP_GTM_APPLICATION_ID: process.env.REACT_APP_GTM_APPLICATION_ID,
  REACT_APP_GOOGLE_API: process.env.REACT_APP_GOOGLE_API,
  REACT_APP_SSO_DOMAIN_URL: process.env.REACT_APP_SSO_DOMAIN_URL,
  REACT_APP_SSO_REDIRECT_SIGN_IN_URL: process.env.REACT_APP_SSO_REDIRECT_SIGN_IN_URL,
  REACT_APP_SSO_REDIRECT_SIGN_OUT_URL: process.env.REACT_APP_SSO_REDIRECT_SIGN_OUT_URL,
  ...getDevEnv(),
});

const getDevEnv = () =>
  (!isProduction() && {
    REACT_APP_DEV_EMAIL: process.env.REACT_APP_DEV_EMAIL,
    REACT_APP_DEV_PASSWORD: process.env.REACT_APP_DEV_PASSWORD,
    /**
     * @TODO: Remove when GTM & ClickBack dev environments are provided
     */
    REACT_APP_CB_APPLICATION_ID: undefined,
    REACT_APP_GTM_APPLICATION_ID: undefined,
  }) ||
  {};

export const env = getEnv();
