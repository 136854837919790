import React, { PropsWithChildren, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authenticationActionCreators } from '../../logic/store/authentication/authentication.actions';
import { getInProgress } from '../../logic/store/process-tracker/process-tracker.selectors';
import { AppSpin } from '../app-spin/app-spin';

export const AuthenticationGate: React.FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(authenticationActionCreators.refreshAuthenticatedUser());
  }, [dispatch]);

  const refreshAuthenticatedUserInProgress = useSelector(
    getInProgress(authenticationActionCreators.refreshAuthenticatedUser.name)
  );

  return refreshAuthenticatedUserInProgress ? <AppSpin /> : <>{children}</>;
};
