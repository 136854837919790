import { Modal } from 'antd';
import { ModalFuncProps } from 'antd/lib/modal';
import { ModalFunc } from 'antd/lib/modal/confirm';

let successModal: ReturnType<ModalFunc> | undefined = undefined;

export const isSuccessModalVisible = () => !!successModal;

export const showSuccessModal = ({ onOk, ...rest }: ModalFuncProps) => {
  successModal = Modal.success({ ...rest, onOk: onOk || closeSuccessModal });
};

export const closeSuccessModal = () => {
  if (successModal) {
    successModal.destroy();
    successModal = undefined;
  }
};
