import React from 'react';
import { Button } from 'antd';
import { useDispatch } from 'react-redux';
import { ColumnProps } from 'antd/lib/table';
import { navigationActionCreators } from '../../../logic/store/navigation/navigation.actions';
import { AUTHENTICATED_ROUTES } from '../../../logic/routes/routes.const';
import { translate, translationKeys } from '../../../logic/translations/translations.service';
import { MasterContent } from '../../master-page/components/master-content/master-content';
import { AlertTypeFormFieldName } from './alert-type-form-template/alert-type.form.field-name';
import { getSortableFilterableColumnCreator } from '../../components/table/table.column';
import { Table } from '../../components/table/table';
import { AlertType } from '../../../api-models/api-models';
import { useSearchInput } from '../../components/search-input/search-input';
import { TableName } from '../../../logic/store/tables/tables.action';
import { MasterPageTableHeader } from '../../components/master-page-table-header/master-page-table-header';
import parse from 'html-react-parser';
import { PageDescription } from '../../components/page-description/page-description';
import { ResourceLink } from '../../components/resources/resource-link';
import { Resource, resourceContents } from '../../components/resources/resource.const';

export const AlertTypesChannelsPage: React.FC = () => {
  const searchInput = useSearchInput();
  const dispatch = useDispatch();

  const handleManageChannels = (row: AlertType) => () =>
    dispatch(navigationActionCreators.navigateTo(AUTHENTICATED_ROUTES.ChannelsManage, `?alert_id=${row.id}`));

  const actionsColumn = (): ColumnProps<AlertType> => ({
    title: translate(translationKeys.forms.fields.actions),
    dataIndex: 'actions',
    key: 'actions',
    render: (value, row) => (
      <span>
        <Button type={'link'} onClick={handleManageChannels(row)}>
          {translate(translationKeys.table.action.manageChannels)}
        </Button>
      </span>
    ),
  });

  const getAlertColumn = getSortableFilterableColumnCreator<AlertTypeFormFieldName, AlertType>(
    translationKeys.forms.fields.alertType
  );

  const getColumns = [getAlertColumn(AlertTypeFormFieldName.name), actionsColumn];

  return (
    <>
      <MasterPageTableHeader
        searchInput={searchInput.render()}
        content={
          <PageDescription
            title={translate(translationKeys.pages.channels.title)}
            content={
              <>
                <p>{parse(translate(translationKeys.pages.channels.description))}</p>
                <p>{parse(translate(translationKeys.pages.channels.note))}</p>
                <p>
                  <ResourceLink
                    content={resourceContents[Resource.channelManagementVideoSupportLink]}
                    text={translate(translationKeys.pages.channels.videoLinkDescription)}
                    noPaddingLeft
                  />
                </p>
              </>
            }
          />
        }
      />
      <MasterContent>
        <Table tableName={TableName.alertChannels} getColumns={getColumns} searchInput={searchInput} />
      </MasterContent>
    </>
  );
};
