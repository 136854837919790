import React, { useEffect, ReactNode } from 'react';
import { Button } from 'antd';
import { navigationActionCreators } from '../../../../logic/store/navigation/navigation.actions';
import { useDispatch, useSelector } from 'react-redux';
import { MasterContent } from '../../../master-page/components/master-content/master-content';
import { userActionCreators } from '../../../../logic/store/user/user.action';
import { ColumnProps } from 'antd/lib/table';
import { getInProgress } from '../../../../logic/store/process-tracker/process-tracker.selectors';
import { translationKeys, translate } from '../../../../logic/translations/translations.service';
import { Table } from '../../../components/table/table';
import { TableButton } from '../../../../logic/store/tables/tables.types';
import { getAuthenticatedUser } from '../../../../logic/store/authentication/authentication.selectors';
import { ContactInfoFieldName } from '../user-form-template/user-form-field-name';
import { getUserColumns, UserFormFieldNameColumnsType } from './users-template.columns';
import { QueryStringPrefilters } from '../../../../logic/store/tables/tables.reducer';
import { User, UserBulkOperation } from '../../../../api-models/api-models';
import { useSearchInput } from '../../../components/search-input/search-input';
import { TableName } from '../../../../logic/store/tables/tables.action';
import { MasterPageTableHeader } from '../../../components/master-page-table-header/master-page-table-header';
import {
  canInvite,
  canDelete,
  canReinvite,
  getUsersToDelete,
  getUsersToInvite,
  getUsersToReinvite,
} from './users-template.logic';
import { getTableSelectedRowCount, getTableSelectedRows } from '../../../../logic/store/tables/tables.selectors';
import {
  BulkOperationButton,
  SelectedRowsText,
  TableHeaderButton,
} from '../../../components/master-page-table-header/master-page-table-header.components';
import { useConfirmActionModal } from '../../../components/confirm-modal/confirm-modal';
import { useGroupFilter } from '../../../components/groups-filter/groups-filter';

export interface UsersPageOwnProps {
  editUserRoute: (user: User) => string;
  columnsFields: Array<UserFormFieldNameColumnsType | ContactInfoFieldName>;
  buttons: TableButton[];
  shoudShowActions: (user: User | undefined, authenticatedUser: User | undefined) => boolean;
  prefilters?: QueryStringPrefilters;
  headerTitle?: string;
  headerContent?: ReactNode | string;
}

export const UsersTemplatePage: React.FC<UsersPageOwnProps> = ({
  editUserRoute,
  columnsFields,
  buttons,
  shoudShowActions,
  prefilters,
  headerTitle,
  headerContent,
}) => {
  const searchInput = useSearchInput();
  const groupFilter = useGroupFilter();
  const dispatch = useDispatch();
  const bulkOperationsInProgress = useSelector(getInProgress(userActionCreators.performUsersBulkOperation.name));
  const inProgress = bulkOperationsInProgress;
  const authenticatedUser = useSelector(getAuthenticatedUser);
  const selectedUsers = useSelector(getTableSelectedRows<User>(TableName.user));
  const selectedUsersCount = useSelector(getTableSelectedRowCount(TableName.user));
  const usersToDelete = getUsersToDelete(selectedUsers, authenticatedUser?.id);
  const usersToInvite = getUsersToInvite(selectedUsers);
  const usersToReinvite = getUsersToReinvite(selectedUsers);
  const isAnyToDelete = usersToDelete?.length;
  const isAnyToInvite = usersToInvite?.length;
  const isAnyToReinvite = usersToReinvite?.length;
  const isAnySelected = !!selectedUsersCount;

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'setupUsersTab_view',
    });
  }, []);

  const handleTableButton = (tableButton: TableButton) => () =>
    dispatch(navigationActionCreators.navigateTo(tableButton.route));

  const handleEditUser = (row: User) => () =>
    dispatch(navigationActionCreators.navigateTo(editUserRoute(row), `?id=${row.id}`));

  const handleInviteUser = (row: User) => () =>
    dispatch(userActionCreators.performUsersBulkOperation([row], UserBulkOperation.Invite));

  const handleReinviteUser = (row: User) => () =>
    dispatch(userActionCreators.performUsersBulkOperation([row], UserBulkOperation.Reinvite));

  const handleDeleteUser = (row: User | undefined) =>
    dispatch(userActionCreators.performUsersBulkOperation([row], UserBulkOperation.Delete));

  const handleExportUsers = () => dispatch(userActionCreators.downloadCsv());

  const handleDeleteUsers = () =>
    dispatch(userActionCreators.performUsersBulkOperation(usersToDelete, UserBulkOperation.Delete));
  const handleInviteUsers = () =>
    dispatch(userActionCreators.performUsersBulkOperation(usersToInvite, UserBulkOperation.Invite));
  const handleReinviteUsers = () =>
    dispatch(userActionCreators.performUsersBulkOperation(usersToReinvite, UserBulkOperation.Reinvite));

  const deleteUsersModal = useConfirmActionModal(
    translate(translationKeys.modalDialog.deleteUsers),
    translate(translationKeys.modalDialog.areYouSureYouWantToDeleteUsers, { count: usersToDelete?.length }),
    handleDeleteUsers,
    userActionCreators.performUsersBulkOperation.name
  );

  const deleteUserModal = useConfirmActionModal(
    translate(translationKeys.modalDialog.deleteUserAccount),
    translate(translationKeys.modalDialog.areYouSureYouWantToRemoveThisUserAccount),
    handleDeleteUser,
    userActionCreators.performUsersBulkOperation.name
  );

  const getUserColumnActions = (): ColumnProps<User> => ({
    title: translate(translationKeys.forms.fields.actions),
    dataIndex: 'actions',
    key: 'actions',
    render: (_, user) =>
      shoudShowActions(user, authenticatedUser) && (
        <span>
          <Button type={'link'} onClick={handleEditUser(user)}>
            {translate(translationKeys.table.action.edit)}
          </Button>
          {canDelete(user.id, authenticatedUser?.id) && (
            <Button type={'link'} onClick={deleteUserModal.tryToPerformAction(user)}>
              {translate(translationKeys.table.action.delete)}
            </Button>
          )}
          {canInvite(user.status) && (
            <Button type={'link'} onClick={handleInviteUser(user)}>
              {translate(translationKeys.table.action.invite)}
            </Button>
          )}
          {canReinvite(user.status) && (
            <Button type={'link'} onClick={handleReinviteUser(user)}>
              {translate(translationKeys.table.action.reinvite)}
            </Button>
          )}
        </span>
      ),
  });

  const getColumns = [getUserColumnActions, ...getUserColumns(columnsFields)];

  const handleEvent = (event: string) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event,
      page: 'UsersTemplatePage',
      action: event,
    });
  };

  return (
    <>
      <MasterPageTableHeader
        searchInput={searchInput.render()}
        filteringInput={groupFilter.render()}
        bulkOperations={
          isAnySelected ? (
            <>
              <SelectedRowsText>
                {translate(translationKeys.pages.users.selectedUsers, { count: selectedUsersCount })}
              </SelectedRowsText>
              {isAnyToDelete ? (
                <BulkOperationButton disabled={inProgress} onClick={deleteUsersModal.tryToPerformAction('empty')}>
                  {translate(translationKeys.pages.users.deleteUsers, { count: usersToDelete?.length })}
                </BulkOperationButton>
              ) : null}
              {isAnyToInvite ? (
                <BulkOperationButton disabled={inProgress} onClick={handleInviteUsers}>
                  {translate(translationKeys.pages.users.inviteUsers, { count: usersToInvite?.length })}
                </BulkOperationButton>
              ) : null}
              {isAnyToReinvite ? (
                <BulkOperationButton disabled={inProgress} onClick={handleReinviteUsers}>
                  {translate(translationKeys.pages.users.reinviteUsers, { count: usersToReinvite?.length })}
                </BulkOperationButton>
              ) : null}
            </>
          ) : null
        }
        leftSection={
          <>
            {buttons.map((button, index) => (
              <TableHeaderButton
                disabled={inProgress}
                type={button.type || 'primary'}
                key={index}
                onClick={() => {
                  const buttonHandler = handleTableButton(button);
                  buttonHandler();
                  if (button.event) {
                    handleEvent(button.event);
                  }
                }}
              >
                {button.text}
              </TableHeaderButton>
            ))}
            <TableHeaderButton
              disabled={inProgress}
              onClick={() => {
                handleExportUsers();
                handleEvent('exportUsersButton');
              }}
            >
              {translate(translationKeys.buttons.exportUsers)}
            </TableHeaderButton>
          </>
        }
        title={headerTitle}
        content={headerContent}
      />
      <MasterContent>
        <Table
          tableName={TableName.user}
          getColumns={getColumns}
          loading={inProgress}
          prefilters={prefilters}
          searchInput={searchInput}
          groupFilter={groupFilter}
          rowSelectable
        />
      </MasterContent>
      {deleteUserModal.render()}
      {deleteUsersModal.render()}
    </>
  );
};
