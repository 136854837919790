import { Col, Row } from 'antd';
import { authenticationActionCreators } from '../../../../logic/store/authentication/authentication.actions';
import { useDispatch, useSelector } from 'react-redux';
import { translate, translationKeys } from '../../../../logic/translations/translations.service';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { FormTextInput } from '../../../components/form-inputs/form-text-input/form-text-input';
import { formItemLayout } from '../../../components/form-container/form-container';
import { getInProgress } from '../../../../logic/store/process-tracker/process-tracker.selectors';
import { SIGNUP_FORM, SignupForm, validateSignupForm } from './signup.form';
import { LOGO } from '../../../../assets/icons';
import { MasterContent } from '../../../master-page/components/master-content/master-content';
import { SignupPageAboutTrial } from './components/about-trial/about-trial';
import { SignInButton } from './components/sign-in-button/sign-in-button';
import {
  FormContent,
  FormContentFields,
  Heading,
  Logo,
  LogoContainer,
  SignUpButton,
  SubHeading,
} from './components/common.components';
import { colors } from '../../../../styles/colors';
import { trimValue } from '../../../../logic/formaters/formaters';
import { FormFieldPhone } from '../../../components/form-fields/form-field-phone';

const Signup = () => {
  const dispatch = useDispatch();
  const formValues: SignupForm = useSelector(getFormValues(SIGNUP_FORM)) || {};
  const inProgress = useSelector(getInProgress(authenticationActionCreators.signUp.name));
  const handleSignup = () => dispatch(authenticationActionCreators.signUp(formValues));

  return (
    <MasterContent>
      <Row gutter={[16, 16]}>
        <Col {...{ md: 24, lg: 9 }}>
          <LogoContainer>
            <Logo src={LOGO} alt={''} />
          </LogoContainer>
          <FormContent>
            <h2>
              <SubHeading color={colors.silverChalice}>{translate(translationKeys.buttons.signUp)}</SubHeading>
            </h2>
            <h1>
              <Heading color={colors.mineShaft}>{translate(translationKeys.signUp.startYourTrial)}</Heading>
            </h1>
            <FormContentFields>
              <Field
                name={'email'}
                component={FormTextInput}
                placeholder={translate(translationKeys.forms.fields.user.email)}
                required
                disabled={inProgress}
                onPressEnter={handleSignup}
                autoComplete="off"
                onBlurFormater={trimValue}
                {...formItemLayout}
              />
              <FormFieldPhone
                name={'phone_number'}
                countryCodeFieldName={'country_code'}
                countryCode={formValues.country_code}
                label=""
                placeholder="Phone number"
                required
                disabled={inProgress}
                inProgress={inProgress}
                commonAttributes={formItemLayout}
              />
              <Field
                name={'name'}
                component={FormTextInput}
                placeholder={translate(translationKeys.forms.fields.user.company_id)}
                required
                disabled={inProgress}
                onPressEnter={handleSignup}
                autoComplete="off"
                {...formItemLayout}
              />
            </FormContentFields>
            <SignUpButton type={'primary'} onClick={handleSignup} disabled={inProgress} loading={inProgress}>
              {translate(translationKeys.buttons.signUp)}
            </SignUpButton>
            <br />
            <SignInButton disabled={inProgress} text={translate(translationKeys.signUp.alreadyHaveAnAccount)} />
            <br />
            <br />
            <br />
          </FormContent>
        </Col>
        <Col {...{ md: 24, lg: 15 }}>
          <SignupPageAboutTrial />
        </Col>
      </Row>
    </MasterContent>
  );
};

export const SignupPage = reduxForm({
  form: SIGNUP_FORM,
  validate: validateSignupForm,
  initialValues: {
    country_code: 'US',
  },
})(Signup as any);
