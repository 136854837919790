import PhoneNumber from 'awesome-phonenumber';
import { isString } from 'lodash';
import { DEFAULT_PHONE_COUNTRY_CODE } from '../../pages/authentication/models/country-code/country-code';
import { getNumbersOnly } from './format-phone-as-type';

export const parsePhoneNumberForPayload = (
  countryCode: string | undefined | null,
  phoneNumber: string | undefined | null
): string => {
  if (!countryCode || !phoneNumber) return '';

  const countryCallingCode = PhoneNumber.getCountryCodeForRegionCode(countryCode);
  return new PhoneNumber(`+${countryCallingCode}${getNumbersOnly(phoneNumber)}`).getNumber('e164') || '';
};

export const formatPhoneNumberAsNational = (countryCode: string | null | undefined) => (
  phone: string | null | undefined
): string | null | undefined => {
  if (!countryCode || !phone) return phone;

  const countryCallingCode = PhoneNumber.getCountryCodeForRegionCode(countryCode);
  const pn = new PhoneNumber(`+${countryCallingCode}${phone}`);

  return pn.isPossible() ? pn.getNumber('national') : phone;
};

export const formatPhoneNumberAsInternational = (phonePayload: string | null | undefined) => {
  if (!isString(phonePayload)) return '';

  const pn = new PhoneNumber(phonePayload);
  if (pn.isPossible()) return pn.getNumber('international');
  else return phonePayload;
};

export const phoneIsValid = (phone: string | null | undefined) => {
  if (phone && isString(phone)) {
    const pn = new PhoneNumber(phone);
    return pn.isValid();
  } else return false;
};

export const phoneIsPossible = (phone: string | null | undefined) => {
  if (phone && isString(phone)) {
    const pn = new PhoneNumber(phone);
    return pn.isPossible();
  } else return false;
};

export const mapPhoneToCountryCodeAndNumber = (
  phone: string | null | undefined
): { code: string; number: string } | undefined => {
  if (phone && isString(phone)) {
    const pn = new PhoneNumber(phone);

    return pn.isPossible()
      ? {
          code: pn.getRegionCode(),
          number: pn.getNumber('national'),
        }
      : {
          code: DEFAULT_PHONE_COUNTRY_CODE,
          number: phone,
        };
  }

  return undefined;
};
