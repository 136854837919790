import { AlertCustomResponsesFieldName } from './alert-custom-responses.field-name';
import { FormErrors } from 'redux-form';
import { clearEmptyFieldsAndEmptyObjects } from '../../../../../logic/validators/utils';
import { isRequiredValidator } from '../../../../../logic/validators/validators';

export const ALERT_CUSTOM_RESPONSES_FORM = 'alertCustomResponses';

export interface AlertCustomResponsesForm {
  [AlertCustomResponsesFieldName.label_text]?: string;
  [AlertCustomResponsesFieldName.text]?: string;
}

export const validateAlertCustomResponsesForm = (formValues: AlertCustomResponsesForm): FormErrors<AlertCustomResponsesForm, string> => {
  const { label_text, text } = formValues;

  return clearEmptyFieldsAndEmptyObjects({
    label_text: isRequiredValidator(label_text),
    text: isRequiredValidator(text),
  });
};
