import React, { useEffect } from 'react';
import { Field } from 'redux-form';
import { ImportUsersFormFieldName } from './import-users.field-name';
import { useDispatch } from 'react-redux';
import { FormFileInput } from '../../../components/form-inputs/form-file-input/form-file-input';
import { FileExtenssion } from '../../../components/form-inputs/form-file-input/file-accept';
import { userActionCreators } from '../../../../logic/store/user/user.action';
import { translate, translationKeys } from '../../../../logic/translations/translations.service';

interface ImportUsersFormProps {
  inProgress: boolean;
  disabledFields: ImportUsersFormFieldName[];
}

export const ImportUsersFormFields: React.FC<ImportUsersFormProps> = ({ disabledFields = [], inProgress }) => {
  const dispatch = useDispatch();
  const commonAttributes = (fieldName: ImportUsersFormFieldName) => ({
    disabled: inProgress || isDisabled(fieldName),
  });

  useEffect(() => {
    dispatch(userActionCreators.setImportUsersResult(undefined));
  }, [dispatch]);

  const isDisabled = (fieldName: ImportUsersFormFieldName) => disabledFields.indexOf(fieldName) >= 0;

  return (
    <Field
      name={ImportUsersFormFieldName.file}
      selectButtonLabel={translate(translationKeys.buttons.selectCsvFile)}
      component={FormFileInput}
      fileFormatAccept={[FileExtenssion.CSV]}
      loading={inProgress}
      {...commonAttributes(ImportUsersFormFieldName.file)}
    />
  );
};
