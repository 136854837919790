import { takeEvery, put, call, all } from 'redux-saga/effects';
import { SagaManager } from '../../saga-manager/saga-manager';
import { channelApi } from './channel.api';
import { ChannelActionTypes, channelActionCreators, ChannelActions } from './channel.action';
import { tableActionCreators, TableName } from '../tables/tables.action';
import { ReturnActionOfType } from '../../redux/action-creator';

export function* channelSagaWatch() {
  yield takeEvery(ChannelActionTypes.UPDATE_SINGLE, updateSingleSagaManaged);
  yield takeEvery(ChannelActionTypes.UPDATE_MANY, updateManySagaManaged);
}

function* updateManySagaManaged(action: ReturnActionOfType<ChannelActions, ChannelActionTypes.UPDATE_MANY>) {
  yield new SagaManager().addTracking(channelActionCreators.updateMany.name).execute(updateManySaga, action);
}

function* updateManySaga(action: ReturnActionOfType<ChannelActions, ChannelActionTypes.UPDATE_MANY>) {
  yield all(action.payload.channels.map(channel => call(channelApi.updateSingle, channel)));
  yield put(tableActionCreators.fetchTable(TableName.channel));
}

function* updateSingleSagaManaged(action: ReturnActionOfType<ChannelActions, ChannelActionTypes.UPDATE_SINGLE>) {
  yield new SagaManager().addTracking(channelActionCreators.updateSingle.name).execute(updateSingleSaga, action);
}

function* updateSingleSaga(action: ReturnActionOfType<ChannelActions, ChannelActionTypes.UPDATE_SINGLE>) {
  yield call(channelApi.updateSingle, action.payload.channel);
  yield put(tableActionCreators.fetchTable(TableName.channel));
}
