import { ReactNode } from 'react';
import { TableName } from '../../../../logic/store/tables/tables.action';
import { TableButton } from '../../../../logic/store/tables/tables.types';
import { SsoCompanyGroupMappingFormFieldName } from '../sso-company-group-mapping-form-template/sso-company-group-mapping-form-field-name';
import { useDispatch, useSelector } from 'react-redux';
import { getAuthenticatedUserCompany } from '../../../../logic/store/authentication/authentication.selectors';
import { getSsoCompanyGroupMappingColumns } from './sso-company-group-mapping.template.columns';
import { navigationActionCreators } from '../../../../logic/store/navigation/navigation.actions';
import { MasterPageTableHeader } from '../../../components/master-page-table-header/master-page-table-header';
import { TableHeaderButton } from '../../../components/master-page-table-header/master-page-table-header.components';
import { MasterContent } from '../../../master-page/components/master-content/master-content';
import { Table } from '../../../components/table/table';
import { SsoCompanyGroupMapping } from '../../../../api-models/api-models';
import { useConfirmActionModal } from '../../../components/confirm-modal/confirm-modal';
import { translate, translationKeys } from '../../../../logic/translations/translations.service';
import { ColumnProps } from 'antd/lib/table';
import { Button } from 'antd';
import { ssoCompanyGroupMappingActionCreators } from '../../../../logic/store/sso-company-group-mapping/sso-company-group-mapping.actions';
import { WarningText } from '../../../../common/components';

export interface SsoCompanyGroupMappingPageOwnProps {
  columnsFields: SsoCompanyGroupMappingFormFieldName[];
  tableName: TableName;
  buttons: TableButton[];
  headerContent?: ReactNode | string;
  ssoEnabledContent?: ReactNode | string;
  ssoDisabledContent?: ReactNode | string;
  ssoGroupMappingDisabledContent?: ReactNode | string;
  editRoute: string;
}

export const SsoCompanyGroupMappingTemplate: React.FC<SsoCompanyGroupMappingPageOwnProps> = ({
  columnsFields,
  tableName,
  buttons,
  headerContent,
  ssoEnabledContent,
  ssoDisabledContent,
  ssoGroupMappingDisabledContent,
  editRoute,
}) => {
  const dispatch = useDispatch();
  const company = useSelector(getAuthenticatedUserCompany);

  const handleEdit = (row: SsoCompanyGroupMapping) => () =>
    dispatch(navigationActionCreators.navigateTo(editRoute, `?id=${row.id}`));

  const handleDelete = (ssoCompanyGroupMapping: SsoCompanyGroupMapping | undefined) => {
    if (ssoCompanyGroupMapping && ssoCompanyGroupMapping.id) {
      dispatch(ssoCompanyGroupMappingActionCreators.deleteSingle(ssoCompanyGroupMapping.id));
    }
  };

  const deleteSsoCompanyGroupMappingModal = useConfirmActionModal(
    translate(translationKeys.modalDialog.deleteSsoCompanyGroupMapping),
    translate(translationKeys.modalDialog.areYouSureYouWantToRemoveThisSsoCompanyGroupMapping),
    handleDelete,
    ssoCompanyGroupMappingActionCreators.deleteSingle.name
  );

  const actionsColumn = (): ColumnProps<SsoCompanyGroupMapping> => ({
    title: translate(translationKeys.forms.fields.actions),
    dataIndex: 'actions',
    key: 'actions',
    render: (_, row) => (
      <span>
        <Button type={'link'} onClick={handleEdit(row)}>
          {translate(translationKeys.table.action.edit)}
        </Button>
        <Button type={'link'} onClick={deleteSsoCompanyGroupMappingModal.tryToPerformAction(row)}>
          {translate(translationKeys.table.action.delete)}
        </Button>
      </span>
    ),
  });

  const getColumns = [actionsColumn, ...getSsoCompanyGroupMappingColumns(columnsFields)];

  const handleTableButton = (tableButton: TableButton) => () =>
    dispatch(navigationActionCreators.navigateTo(tableButton.route));

  return (
    <>
      <MasterPageTableHeader
        leftSection={
          company?.is_sso_enabled && (
            <>
              {buttons.map((button, index) => (
                <TableHeaderButton type={button.type || 'primary'} key={index} onClick={handleTableButton(button)}>
                  {button.text}
                </TableHeaderButton>
              ))}
            </>
          )
        }
        content={
          <div>
            {headerContent}
            {company?.is_sso_enabled ? ssoEnabledContent : ssoDisabledContent}
            {company?.is_sso_enabled && !company?.is_company_group_mapping_enabled ? (
              <WarningText>{ssoGroupMappingDisabledContent}</WarningText>
            ) : null}
          </div>
        }
      />
      {company?.is_sso_enabled && (
        <MasterContent>
          <Table tableName={tableName} getColumns={getColumns} />
        </MasterContent>
      )}
      {deleteSsoCompanyGroupMappingModal.render()}
    </>
  );
};
