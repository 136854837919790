import React from 'react';
import { Col, Row, Typography } from 'antd';
import { MasterContent } from '../../../master-page/components/master-content/master-content';
import { SignupPageAboutTrial } from './components/about-trial/about-trial';
import { translate, translationKeys } from '../../../../logic/translations/translations.service';
import { LOGO } from '../../../../assets/icons';
import { useQuery } from '../../../../logic/hooks/use-query';
import styled from 'styled-components';
import { SignInButton } from './components/sign-in-button/sign-in-button';
import { FormContent, Heading, Logo, LogoContainer, SubHeading } from './components/common.components';
import { colors } from '../../../../styles/colors';

const { Text } = Typography;

export const SignupSuccessPage: React.FC<{}> = () => {
  const query = useQuery();
  const email = query.get('email');

  return (
    <MasterContent>
      <Row gutter={[16, 16]}>
        <Col {...{ md: 24, lg: 9 }}>
          <LogoContainer>
            <Logo src={LOGO} alt={''} />
          </LogoContainer>
          <FormContent>
            <h2>
              <SubHeading color={colors.silverChalice}>{translate(translationKeys.buttons.signUp)}</SubHeading>
            </h2>
            <h1>
              <Heading color={colors.mineShaft}>
                {translate(translationKeys.signUpSuccess.registrationComplete)}
              </Heading>
            </h1>
            <Content>
              {translate(translationKeys.signUpSuccess.paragraph1)}
              <EmailText strong>{`${email} `}</EmailText>
              {translate(translationKeys.signUpSuccess.paragraph2)}
              <br />
              <br />
              {translate(translationKeys.signUpSuccess.paragraph3)}
              <SignInButton />
            </Content>
          </FormContent>
        </Col>
        <Col {...{ md: 24, lg: 15 }}>
          <SignupPageAboutTrial />
        </Col>
      </Row>
    </MasterContent>
  );
};

const EmailText = styled(Text)`
  white-space: nowrap;
`;

const Content = styled.div`
  width: 340px;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  color: ${colors.black};
  margin: 18px auto 0;
`;
