import { isString, isNumber } from 'lodash';

const isPositive = (value: any) => value >= 0;

export const isInteger = (value: any) => Number(value) === value && value % 1 === 0;

export const isFloat = (value: any) => Number(value) === value && value % 1 !== 0;

export const exceedMax = (value: any) => Number.isSafeInteger(value);

const isExactNumber = (value: any, parsed: any) => `${value}` === `${parsed}`;

export const parseId = (value: number | string | undefined | null | any[]): number | undefined => {
  const parsed = isString(value) || isNumber(value) ? parseInt(`${value}`) : undefined;
  const result =
    isInteger(parsed) && isExactNumber(value, parsed) && isPositive(parsed) && exceedMax(parsed) ? parsed : undefined;

  return result;
};
