import React, { useEffect, useMemo, useCallback } from 'react';
import { Table as AntdTable } from 'antd';
import { TableProps as AntdTableProps, ColumnProps } from 'antd/lib/table';
import { useDispatch, useSelector } from 'react-redux';
import { getInProgress } from '../../../logic/store/process-tracker/process-tracker.selectors';
import { getTableData, getTableQuery, getTableSelectedRowKeys } from '../../../logic/store/tables/tables.selectors';
import { tableActionCreators, TableName, getFetchTableTrackName } from '../../../logic/store/tables/tables.action';
import { QueryStringPrefilters, initialTableQueryPagination } from '../../../logic/store/tables/tables.reducer';
import './table.styles.scss';
import { usePrevious } from '../../../logic/hooks/use-previous';
import { SearchInput } from '../search-input/search-input';
import { SorterResult } from 'antd/lib/table/interface';
import { SpinProps } from 'antd/lib/spin';
import { getFirstSorter } from './table.utils';
import { GroupsFilter } from '../groups-filter/groups-filter';

export type SortableFilterableColumn<E = any> = (
  filter: any | undefined,
  sorter: SorterResult<E> | undefined
) => ColumnProps<E>;

interface TableProps extends AntdTableProps<any> {
  tableName: TableName;
  loading?: boolean | SpinProps;
  getColumns: SortableFilterableColumn[];
  rowSelectable?: boolean;
  prefilters?: QueryStringPrefilters;
  initialSorter?: SorterResult<any>;
  searchInput?: SearchInput;
  groupFilter?: GroupsFilter;
  fetchUnfilteredTotal?: boolean;
}

type TableOnChange = AntdTableProps<any>['onChange'];

export const Table: React.FC<TableProps> = ({
  tableName,
  getColumns,
  loading,
  rowSelectable,
  prefilters,
  initialSorter,
  searchInput,
  groupFilter,
  fetchUnfilteredTotal = false,
  ...rest
}) => {
  const dispatch = useDispatch();
  const inProgress = useSelector(getInProgress(getFetchTableTrackName(tableName)));
  const data = useSelector(getTableData(tableName));
  const query = useSelector(getTableQuery(tableName));
  const prefiltersPrevious = usePrevious(prefilters);
  const initialSorterPrevious = usePrevious(initialSorter);
  const currentPage = query?.pagination?.current;
  const pageSize = query?.pagination?.pageSize;
  const sorter = getFirstSorter(query?.sorter);
  const sorterField = sorter?.columnKey;
  const sorterOrder = sorter?.order;
  const search = query?.search;
  const inputSearchText = searchInput?.searchText;
  const selectedGroupsFilter = groupFilter?.selectedGroups;

  // Initialize prefilters
  useEffect(() => {
    !prefiltersPrevious &&
      !!prefilters &&
      dispatch(tableActionCreators.updateQuery(tableName, { prefilters, fetchUnfilteredTotal }));

    !initialSorterPrevious &&
      !!initialSorter &&
      dispatch(tableActionCreators.updateQuery(tableName, { sorter: initialSorter }));
  }, [dispatch, tableName, prefilters, prefiltersPrevious, initialSorterPrevious, initialSorter, fetchUnfilteredTotal]);

  // Initialize unfiltered Total
  useEffect(() => {
    dispatch(tableActionCreators.updateQuery(tableName, { fetchUnfilteredTotal }));
  }, [dispatch, tableName, fetchUnfilteredTotal]);

  // Initialize external searchInput
  useEffect(() => {
    if (searchInput && inputSearchText === undefined && search) {
      searchInput.initialize(search);
    }
  }, [searchInput, search, inputSearchText]);

  // Update query search on new searchInput value
  useEffect(() => {
    if (inputSearchText !== undefined && inputSearchText !== search) {
      dispatch(
        tableActionCreators.updateQuery(tableName, { search: inputSearchText, pagination: initialTableQueryPagination })
      );
    }
  }, [dispatch, tableName, search, inputSearchText]);

  useEffect(() => {
    dispatch(
      tableActionCreators.updateQuery(tableName, {
        search: inputSearchText,
        group: selectedGroupsFilter,
        pagination: initialTableQueryPagination,
      })
    );
  }, [dispatch, inputSearchText, selectedGroupsFilter, tableName]);

  // Fetch table
  useEffect(() => {
    dispatch(tableActionCreators.fetchTable(tableName));
  }, [dispatch, currentPage, sorterField, sorterOrder, search, selectedGroupsFilter, tableName, pageSize]);

  const handleTableChange: TableOnChange = useCallback(
    (pagination: any, filters: any, sorter: any) => {
      dispatch(tableActionCreators.updateQuery(tableName, { pagination, filters, sorter }));
      dispatch(tableActionCreators.setSelectedRowKeys(tableName, []));
    },
    [dispatch, tableName]
  );

  const columns = getColumns.map((column) => column(query?.filters, sorter));

  const onSelectChange = useCallback(
    (selectedRowKeys: any[]) => {
      dispatch(tableActionCreators.setSelectedRowKeys(tableName, selectedRowKeys));
    },
    [dispatch, tableName]
  );

  const selectedRowKeys = useSelector(getTableSelectedRowKeys(tableName));

  const rowSelection = useMemo(
    () => ({
      selectedRowKeys,
      onChange: onSelectChange,
    }),
    [selectedRowKeys, onSelectChange]
  );

  return (
    <AntdTable
      className={'table'}
      columns={columns}
      rowKey={(record) => `${record.id}`}
      dataSource={data}
      pagination={{
        ...query?.pagination,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50', '100'],
      }}
      loading={inProgress || loading}
      onChange={handleTableChange}
      rowSelection={rowSelectable ? rowSelection : undefined}
      {...rest}
    />
  );
};
