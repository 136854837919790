import React, { useEffect } from 'react';
import { MasterPageHeader } from '../../../components/master-page-header/master-page-header';
import { Form, Button, Alert } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { MasterContent } from '../../../master-page/components/master-content/master-content';
import { InjectedFormProps, getFormValues } from 'redux-form';
import { AlertTypeForm, alertTypeToFormValuesMapper, duplicateAlertTypeToFormValuesMapper } from './alert-type-form';
import { FormContainer, formButtonLayout } from '../../../components/form-container/form-container';
import { getInProgress } from '../../../../logic/store/process-tracker/process-tracker.selectors';
import { AnyAction } from 'redux';
import queryString from 'query-string';
import { RouteComponentProps } from 'react-router-dom';
import { alertActionCreators } from '../../../../logic/store/alert/alert.action';
import { AlertTypeFormFields } from './alert-type-form.fields';
import { translate, translationKeys } from '../../../../logic/translations/translations.service';

export interface AlertTypeFormPageOwnProps {
  submitButtonText: string;
  submitFormAction: (formValues: AlertTypeForm) => AnyAction;
  formName: string;
}

interface AlertTypeFormTemplatePageProps
  extends RouteComponentProps,
    AlertTypeFormPageOwnProps,
    InjectedFormProps<AlertTypeForm> {}

export const AlertTypeFormTemplatePage: React.FC<AlertTypeFormTemplatePageProps> = ({
  submitButtonText,
  submitFormAction,
  formName,
  location,
  error,
}) => {
  const dispatch = useDispatch();
  const formValues: AlertTypeForm = useSelector(getFormValues(formName)) || {};

  const getSingleForEditInProgress = useSelector(getInProgress(alertActionCreators.getSingleForEdit.name));
  const submitInProgress = useSelector(getInProgress(submitFormAction.name));
  const inProgress = getSingleForEditInProgress || submitInProgress;

  useEffect(() => {
    const { id } = queryString.parse(location.search);

    if (!!id) {
      // we have an id and we are adding, we can assume that we are duplicating
      // existing alert
      const isDuplicating = location.pathname.includes('add');

      dispatch(
        alertActionCreators.getSingleForEdit(
          id,
          formName,
          isDuplicating ? duplicateAlertTypeToFormValuesMapper : alertTypeToFormValuesMapper
        )
      );
    }
  }, [dispatch, formName, location.pathname, location.search]);

  const handleSubmitAction = () => {
    dispatch(submitFormAction({ ...formValues, files: undefined }));
  };

  return (
    <>
      <MasterPageHeader showBack />
      <MasterContent>
        <FormContainer inProgress={getSingleForEditInProgress}>
          <AlertTypeFormFields inProgress={inProgress} onPressEnter={handleSubmitAction} />

          {error && (
            <Form.Item {...formButtonLayout}>
              <Alert message={translate(translationKeys.errors.error)} description={error} type={'error'} showIcon />
            </Form.Item>
          )}

          <Form.Item {...formButtonLayout}>
            <Button
              type={'primary'}
              className={'form-button'}
              onClick={handleSubmitAction}
              disabled={inProgress}
              loading={submitInProgress}
            >
              {submitButtonText}
            </Button>
          </Form.Item>
        </FormContainer>
      </MasterContent>
    </>
  );
};
