import { wrapComponentsWithProps } from '../../../../logic/hoc/wrap-components-with-props.hoc';
import { AUTHENTICATED_ROUTES } from '../../../../logic/routes/routes.const';
import { TableName } from '../../../../logic/store/tables/tables.action';
import { translate, translationKeys } from '../../../../logic/translations/translations.service';
import { PageDescription } from '../../../components/page-description/page-description';
import { SsoCompanyGroupMappingFormFieldName } from '../sso-company-group-mapping-form-template/sso-company-group-mapping-form-field-name';
import {
  SsoCompanyGroupMappingPageOwnProps,
  SsoCompanyGroupMappingTemplate,
} from '../sso-company-group-mapping-template/sso-company-group-mapping.template.page';
import parse from 'html-react-parser';

export const SsoCompanyGroupMapping = wrapComponentsWithProps<SsoCompanyGroupMappingPageOwnProps>({
  tableName: TableName.ssoCompanyGroupMapping,
  headerContent: (
    <PageDescription
      title={translate(translationKeys.sideMenu.ssoCompanyGroupMappings)}
      content={parse(translate(translationKeys.pages.sso.content))}
    />
  ),
  ssoDisabledContent: parse(translate(translationKeys.pages.sso.callTeamAlert)),
  ssoEnabledContent: parse(translate(translationKeys.pages.ssoCompanyGroupMapping.ssoCompanyGroupMappingInstruction)),
  ssoGroupMappingDisabledContent: translate(
    translationKeys.pages.ssoCompanyGroupMapping.ssoCompanyGroupMappingDisabled
  ),
  buttons: [
    {
      text: translate(translationKeys.buttons.addSsoCompanyGroupMapping),
      route: AUTHENTICATED_ROUTES.SsoCompanyGroupMappingAdd,
    },
  ],
  editRoute: AUTHENTICATED_ROUTES.SsoCompanyGroupMappingEdit,
  columnsFields: [
    SsoCompanyGroupMappingFormFieldName.sso_group_name,
    SsoCompanyGroupMappingFormFieldName.company_group_name,
  ],
})(SsoCompanyGroupMappingTemplate);
