import React from 'react';
import { Dropdown } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { AUTHENTICATION_ROUTES, AUTHENTICATED_ROUTES, getMySettingsRoute } from '../../../logic/routes/routes.const';
import { navigationActionCreators } from '../../../logic/store/navigation/navigation.actions';
import { translate, translationKeys } from '../../../logic/translations/translations.service';
import { getAuthenticatedUser } from '../../../logic/store/authentication/authentication.selectors';
import { Role } from '../../../api-models/api-models';
import { stringifyFields } from '../../../logic/utils/stringify-fields';
import './user-dropdown.styles.scss';
import { SettingOutlined, PoweroffOutlined } from '@ant-design/icons';
import { ItemType } from 'antd/lib/menu/hooks/useItems';

export const UserDropdown: React.FC = () => {
  const dispatch = useDispatch();
  const authenticatedUser = useSelector(getAuthenticatedUser);

  const handleLogout = () => {
    dispatch(navigationActionCreators.navigateTo(AUTHENTICATION_ROUTES.Logout));
  };

  const handleMySettings = () => {
    const mySettingRoute = getMySettingsRoute(authenticatedUser);
    if (mySettingRoute) {
      dispatch(navigationActionCreators.navigateTo(mySettingRoute));
    }
  };

  const handleCompanySettings = () => {
    if (authenticatedUser?.company_id) {
      dispatch(
        navigationActionCreators.navigateTo(AUTHENTICATED_ROUTES.CompaniesEdit, `?id=${authenticatedUser.company_id}`)
      );
    }
  };

  const getUserText = () =>
    stringifyFields(
      ' ',
      authenticatedUser?.email,
      authenticatedUser?.first_name,
      authenticatedUser?.last_name,
      authenticatedUser?.permission_level && translate(translationKeys.role[authenticatedUser?.permission_level])
    );

  const getMenuItems = () => {
    return [
      {
        label: translate(translationKeys.buttons.mySettings),
        key: 1,
        icon: <SettingOutlined />,
        onClick: handleMySettings,
      },
      ...[
        authenticatedUser?.permission_level === Role.CompanyAdmin
          ? {
              label: translate(translationKeys.buttons.companySettings),
              key: 2,
              icon: <SettingOutlined />,
              onClick: handleCompanySettings,
            }
          : null,
      ],
      { label: translate(translationKeys.buttons.logout), key: 3, icon: <PoweroffOutlined />, onClick: handleLogout },
    ] as Array<ItemType>;
  };

  return (
    <Dropdown.Button trigger={['click']} menu={{ items: getMenuItems() }}>
      <div className={'user-dropdown-text'}>{getUserText()}</div>
    </Dropdown.Button>
  );
};
