import { takeEvery, select, call, put } from 'redux-saga/effects';
import {
  TableActionTypes,
  TableActions,
  getFetchTableTrackName,
  tableEndpoints,
  tableActionCreators,
} from './tables.action';
import { ReturnActionOfType } from '../../redux/action-creator';
import { SagaManager } from '../../saga-manager/saga-manager';
import { invalidPageHandler } from './tables.error-handlers';
import { TableQuery } from './tables.types';
import { getTableQuery } from './tables.selectors';
import { ApiListResponse } from '../../axios/axios-api-response';
import { tablesApi } from './tables.api';
import { getTableQueryString } from '../../../pages/components/table/table.utils';
import { isEmpty } from 'lodash';

export function* tableSagaWatch() {
  yield takeEvery(TableActionTypes.FETCH_TABLE, fetchTableSagaManaged);
}

function* fetchTableSagaManaged(action: ReturnActionOfType<TableActions, TableActionTypes.FETCH_TABLE>) {
  yield new SagaManager()
    .addTracking(getFetchTableTrackName(action.payload.tableName))
    .addCustomErrorHandler(invalidPageHandler, action.payload.tableName)
    .execute(fetchTableSaga, action);
}

function* fetchTableSaga(action: ReturnActionOfType<TableActions, TableActionTypes.FETCH_TABLE>) {
  const { tableName } = action.payload;
  const query: TableQuery | undefined = yield select(getTableQuery(tableName));

  const response: ApiListResponse = yield call(
    tablesApi.fetchTable,
    tableEndpoints[tableName],
    getTableQueryString(query)
  );

  yield put(tableActionCreators.setData(tableName, response));

  if (query?.fetchUnfilteredTotal) {
    if (!isEmpty(query?.filters) || !isEmpty(query.search)) {
      const responseUnfiltered: ApiListResponse = yield call(
        tablesApi.fetchTable,
        tableEndpoints[tableName],
        getTableQueryString({
          ...query,
          filters: undefined,
          search: undefined,
        })
      );
      yield put(tableActionCreators.setUnfilteredTotal(tableName, responseUnfiltered));
    } else {
      yield put(tableActionCreators.setUnfilteredTotal(tableName, response));
    }
  }

  return response;
}
