import React, { ReactNode } from 'react';
import { PageDescription } from '../page-description/page-description';
import { BackButton } from '../back-button/back-button';
import { VerticalSpace } from '../vertical-space/vertical-space';
import {
  BulkOperationDivider,
  HeaderActionBar,
  HeaderSideContent,
  MasterPagePageHeader,
} from './master-page-table-header.components';

interface MasterPageTableHeaderProps {
  bulkOperations?: ReactNode;
  leftSection?: ReactNode;
  rightSection?: ReactNode;
  searchInput?: ReactNode;
  filteringInput?: ReactNode;
  title?: string;
  content?: ReactNode | string;
  showBack?: boolean;
}

export const MasterPageTableHeader: React.FC<MasterPageTableHeaderProps> = ({
  bulkOperations,
  leftSection,
  rightSection,
  searchInput,
  filteringInput,
  title,
  content,
  showBack,
}) => {
  return (
    <MasterPagePageHeader
      title={''}
      extra={[]}
      onBack={showBack ? () => window.history.back() : undefined}
      backIcon={<BackButton />}
    >
      <PageDescription title={title} content={content} />
      <HeaderActionBar>
        <HeaderSideContent>
          {bulkOperations}
          {bulkOperations && <BulkOperationDivider />}
          {leftSection}
          {leftSection && <VerticalSpace />}
          <div>{searchInput}</div>
          <div>{filteringInput}</div>
        </HeaderSideContent>
        <HeaderSideContent>{rightSection}</HeaderSideContent>
      </HeaderActionBar>
    </MasterPagePageHeader>
  );
};
