import { createAction, ActionsUnion } from '../../redux/action-creator';
import { AxiosProgress } from '../../axios/axios-progres';

export enum ProcessTrackerActionTypes {
  RESET = 'processTracker/RESET',
  PROCESS_STATUS_CHANGED = 'processTracker/PROCESS_STATUS_CHANGED',
  PROCESS_PROGRESS_CHANGED = 'processTracker/PROCESS_PROGRESS_CHANGED',
}

export enum ProcessStatus {
  InProgress = 'InProgress',
  Success = 'Success',
  Failure = 'Failure',
}

export const processTrackerActionCreators = {
  reset: () => createAction(ProcessTrackerActionTypes.RESET),
  changeProgress: (processName: string, progress: AxiosProgress) =>
    createAction(ProcessTrackerActionTypes.PROCESS_PROGRESS_CHANGED, {
      processName,
      progress,
    }),
  changeProcessStatus: (processName: string, status: ProcessStatus, result?: any) =>
    createAction(ProcessTrackerActionTypes.PROCESS_STATUS_CHANGED, {
      processName,
      status,
      result,
    }),
};

export type ProcessTrackerActions = ActionsUnion<typeof processTrackerActionCreators>;

export interface ProcessState<R = any> {
  status?: ProcessStatus;
  response?: R;
  error?: Error;
  progress?: AxiosProgress;
}

export const inProgress = <R>(process: ProcessState<R> = {}) => process.status === ProcessStatus.InProgress;
export const progress = <R>(process: ProcessState<R> = {}) => process.progress;
export const succeeded = <R>(process: ProcessState<R> = {}) => process.status === ProcessStatus.Success;
export const failed = <R>(process: ProcessState<R> = {}) => process.status === ProcessStatus.Failure;

const statusChanged = <R>(process: ProcessState<R> = {}, nextProcess: ProcessState<R> = {}) =>
  process.status !== nextProcess.status;
export const willFail = <R>(process: ProcessState<R> = {}, nextProcess: ProcessState<R> = {}) =>
  statusChanged(process, nextProcess) && failed(nextProcess);
export const willSucceed = <R>(process: ProcessState<R> = {}, nextProcess: ProcessState<R> = {}) =>
  statusChanged(process, nextProcess) && succeeded(nextProcess);
