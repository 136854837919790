import { FormErrors } from 'redux-form';
import { clearEmptyFields } from '../../../../logic/validators/utils';
import { isEmailValididator, isRequiredValidator } from '../../../../logic/validators/validators';

export const LOGIN_DISCOVERY_FORM_NAME = 'login_discovery';

export interface LoginDiscoveryForm {
  email?: string;
}

export type LoginDiscoveryFormValidationErrors = FormErrors<LoginDiscoveryForm>;

export const validateLoginDiscoveryForm = (formValues: LoginDiscoveryForm) => {
  const { email } = formValues;
  const errors: FormErrors<LoginDiscoveryForm, any> = {
    email: isRequiredValidator(email) || isEmailValididator(email),
  };
  return clearEmptyFields(errors);
};
