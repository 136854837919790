import React, { useEffect } from 'react';
import { MasterPageHeader } from '../../../components/master-page-header/master-page-header';
import { Form, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { MasterContent } from '../../../master-page/components/master-content/master-content';
import { InjectedFormProps, getFormValues } from 'redux-form';
import { CompanyForm, companyToFormValuesMapper } from './company-form.form';
import { FormContainer, formButtonLayout } from '../../../components/form-container/form-container';
import { getInProgress } from '../../../../logic/store/process-tracker/process-tracker.selectors';
import { AnyAction } from 'redux';
import queryString from 'query-string';
import { RouteComponentProps } from 'react-router-dom';
import { companyActionCreators } from '../../../../logic/store/company/company.action';
import { CompanyFormFields } from './company-form-fields';
import { CompanyFormFieldName } from './company-form-field-name';

export interface CompanyFormPageOwnProps {
  submitButtonText: string;
  submitFormAction: (formValues: CompanyForm) => AnyAction;
  formName: string;
  fieldsToDisplay: CompanyFormFieldName[];
  disabledFields: CompanyFormFieldName[];
}

interface CompanyFormTemplatePageProps
  extends RouteComponentProps,
    CompanyFormPageOwnProps,
    InjectedFormProps<CompanyForm> {}

export const CompanyFormPage: React.FC<CompanyFormTemplatePageProps> = ({
  submitButtonText,
  submitFormAction,
  formName,
  location,
  fieldsToDisplay,
  disabledFields,
}) => {
  const dispatch = useDispatch();
  const formValues: CompanyForm = useSelector(getFormValues(formName)) || {};

  const fetchCompanyForEditInProgress = useSelector(getInProgress(companyActionCreators.fetchSingleForEdit.name));
  const submitInProgress = useSelector(getInProgress(submitFormAction.name));
  const inProgress = fetchCompanyForEditInProgress || submitInProgress;

  useEffect(() => {
    const { id } = queryString.parse(location.search);
    if (!!id) {
      dispatch(companyActionCreators.fetchSingleForEdit(id, formName, companyToFormValuesMapper));
    }
  }, [dispatch, formName, location.search]);

  const handleSubmitAction = () => {
    dispatch(submitFormAction(formValues));
  };

  return (
    <>
      <MasterPageHeader showBack />
      <MasterContent>
        <FormContainer inProgress={fetchCompanyForEditInProgress}>
          <CompanyFormFields
            inProgress={inProgress}
            fieldsToDisplay={fieldsToDisplay}
            onPressEnter={handleSubmitAction}
            disabledFields={disabledFields}
            formName={formName}
          />
          <Form.Item {...formButtonLayout}>
            <Button
              type={'primary'}
              className={'form-button'}
              onClick={handleSubmitAction}
              disabled={inProgress}
              loading={submitInProgress}
            >
              {submitButtonText}
            </Button>
          </Form.Item>
        </FormContainer>
      </MasterContent>
    </>
  );
};
