import React, { useState, useCallback, useEffect } from 'react'
import { Modal, Button } from 'antd';
import getCroppedImg from './avatar-image-utils'
import { translate, translationKeys } from '../../../../../logic/translations/translations.service';
import AvatarChangeUpload from './avatar-change-upload'
import AvatarChangeCropper from './avatar-change-cropper'
import './avatar-change.scss'

interface AvatarChangeProps {
  isModalVisible: boolean
  setImage(image: any): void
  showModal(visibility: boolean): void
  setImageURL(url: string): void
}

const AvatarChange: React.FC<AvatarChangeProps> = ({isModalVisible, setImage, showModal, setImageURL}) => {
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [uploadedImageURL, setUploadedImageURL] = useState('');

  useEffect(() => {
    showModal(isModalVisible);
    // eslint-disable-next-line
  }, [isModalVisible]);

  const getCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        uploadedImageURL,
        croppedAreaPixels,
      );

      setImageURL(URL.createObjectURL(croppedImage));
      setImage(croppedImage);
      setUploadedImageURL('');
    } catch (e) {
      console.error(e);
    }
    showModal(false);
    // eslint-disable-next-line
  }, [croppedAreaPixels]);

  const onClose = useCallback(() => {
    showModal(false);
    setUploadedImageURL('');
    // eslint-disable-next-line
  }, []);

  return (
    <Modal
      title={translate(translationKeys.avatar.cropPhoto)}
      visible={isModalVisible}
      onOk={getCroppedImage}
      onCancel={onClose}
      footer={[
        <Button key="close" onClick={onClose}>
          {translate(translationKeys.avatar.cancel)}
        </Button>,
        <Button key="submit" type="primary" className={`${!uploadedImageURL && 'avatar-change__hidden'}`} onClick={getCroppedImage}>
          {uploadedImageURL && (translate(translationKeys.avatar.savePhoto))}
        </Button>
        ,
      ]}
    >
      <div className={'avatar-change'}>
        {uploadedImageURL ? (
          <AvatarChangeCropper
            uploadedImageURL={uploadedImageURL}
            setCroppedAreaPixels={setCroppedAreaPixels}
          />
        ) : (
          <AvatarChangeUpload setUploadedImageURL={setUploadedImageURL}/>
        )}
      </div>
    </Modal>
  )

}

export default AvatarChange
