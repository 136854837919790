import { navigateTo, back } from './navigation.service';
import { takeEvery } from 'redux-saga/effects';
import { NavigationActionTypes, NavigateToAction } from './navigation.actions';

export function* navigationSagaWatch() {
  yield takeEvery(NavigationActionTypes.NAVIGATE_TO, navigateToSaga);
  yield takeEvery(NavigationActionTypes.BACK, backSaga);
}

function* navigateToSaga(action: NavigateToAction) {
  const { routeName, queryString } = action.payload;
  yield navigateTo(routeName, queryString);
}

function* backSaga() {
  yield back();
}
