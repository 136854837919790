import React from 'react';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import { useSelector } from 'react-redux';
import { Alert } from 'antd';
import { getInProgress } from '../../../../../../logic/store/process-tracker/process-tracker.selectors';
import { userActionCreators } from '../../../../../../logic/store/user/user.action';
import { translate, translationKeys } from '../../../../../../logic/translations/translations.service';
import { FormTextInput } from '../../../../../components/form-inputs/form-text-input/form-text-input';
import { SetNewPasswordForm, SET_NEW_PASSWORD_FORM_NAME, validateSetNewPasswordForm } from './set-new-password.form';
import { trimValue } from '../../../../../../logic/formaters/formaters';

interface SetNewPasswordOwnProps {
  onPressEnter: () => void;
}

type SetNewPasswordProps = InjectedFormProps<SetNewPasswordForm, SetNewPasswordOwnProps> & SetNewPasswordOwnProps;

const SetNewPassword: React.FC<SetNewPasswordProps> = ({ onPressEnter, error }) => {
  const inProgress = useSelector(getInProgress(userActionCreators.setNewPassword.name));

  return (
    <>
      <Field
        name={'newPassword'}
        component={FormTextInput}
        isPassword
        required
        placeholder={translate(translationKeys.forms.fields.newPassword)}
        disabled={inProgress}
        onBlurFormater={trimValue}
        onPressEnter={onPressEnter}
      />

      {error && <Alert message={translate(translationKeys.errors.error)} description={error} type={'error'} showIcon />}
    </>
  );
};

export const SetNewPasswordFormFields = reduxForm<SetNewPasswordForm, SetNewPasswordOwnProps>({
  form: SET_NEW_PASSWORD_FORM_NAME,
  validate: validateSetNewPasswordForm,
})(SetNewPassword);
