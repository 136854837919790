import { LogActions, LogActionTypes } from './log.actions';

export enum LogType {
  Error = 'error',
  Success = 'success',
  SuccessModal = 'successModal',
}

export interface LogState {
  title?: string;
  message?: string;
  error?: Error;
  logType?: LogType;
  visible?: boolean;
}

const initialState: LogState = {
  title: undefined,
  message: undefined,
  error: undefined,
  logType: undefined,
  visible: false,
};

export const logReducer = (state = initialState, action: LogActions): LogState => {
  switch (action.type) {
    case LogActionTypes.RESET:
      return initialState;
    case LogActionTypes.LOG:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
