import React from 'react';
import { AuthenticationMasterPage } from '../authentication-master/authentication-master.page';
import { translationKeys, translate } from '../../../../logic/translations/translations.service';
import { Button } from 'antd';
import { useDispatch } from 'react-redux';
import { navigationActionCreators } from '../../../../logic/store/navigation/navigation.actions';
import { LANDING_ROUTES } from '../../../../logic/routes/routes.const';

export const NoAuthenticationPage: React.FC = () => {
  const dispatch = useDispatch();
  const handleNavigateToLogin = () => dispatch(navigationActionCreators.navigateTo(LANDING_ROUTES.Landing));

  return (
    <AuthenticationMasterPage>
      <h1>{translate(translationKeys.errors.noAuthentication)}</h1>
      <Button type={'primary'} onClick={handleNavigateToLogin}>
        {translate(translationKeys.buttons.login)}
      </Button>
    </AuthenticationMasterPage>
  );
};
