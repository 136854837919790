import { call, put, takeEvery } from 'redux-saga/effects';
import {
  SsoGroupMappingActionTypes,
  SsoGroupMappingActions,
  ssoGroupMappingActionCreators,
} from './sso-group-mapping.actions';
import { ReturnActionOfType } from '../../redux/action-creator';
import { SagaManager } from '../../saga-manager/saga-manager';
import {
  SSO_GROUP_MAPPING_FORM,
  validateSsoGroupMappingForm,
} from '../../../pages/sso-group-mapping/pages/sso-group-mapping-form-template/sso-group-mapping-form.form';
import { mapServerErrorsToReduxFormErrors } from '../../server-error-parser/server-error-parser';
import { ssoGroupMappingApi } from './sso-group-mapping.api';
import { logActionCreators } from '../log/log.actions';
import { translate, translationKeys } from '../../translations/translations.service';
import { navigationActionCreators } from '../navigation/navigation.actions';
import { AUTHENTICATED_ROUTES } from '../../routes/routes.const';
import { TableName, tableActionCreators } from '../tables/tables.action';
import { fillReduxForm } from '../../redux-form/fill-redux-form';

export function* ssoGroupMappingSagaWatch() {
  yield takeEvery(SsoGroupMappingActionTypes.CREATE_SINGLE, createSingleSagaManaged);
  yield takeEvery(SsoGroupMappingActionTypes.UPDATE_SINGLE, updateSingleSagaManaged);
  yield takeEvery(SsoGroupMappingActionTypes.DELETE_SINGLE, deleteSingleSagaManaged);
  yield takeEvery(SsoGroupMappingActionTypes.GET_SINGLE_FOR_EDIT, getSingleForEditSagaManaged);
}

function* getSingleForEditSagaManaged(
  action: ReturnActionOfType<SsoGroupMappingActions, SsoGroupMappingActionTypes.GET_SINGLE_FOR_EDIT>
) {
  yield new SagaManager()
    .addTracking(ssoGroupMappingActionCreators.getSingleForEdit.name)
    .execute(getSingleForEdit, action);
}

function* getSingleForEdit(
  action: ReturnActionOfType<SsoGroupMappingActions, SsoGroupMappingActionTypes.GET_SINGLE_FOR_EDIT>
) {
  const { data } = yield call(ssoGroupMappingApi.getSingle, action.payload.id);

  yield fillReduxForm(action.payload.formName, action.payload.mapper, data);
}

function* createSingleSagaManaged(
  action: ReturnActionOfType<SsoGroupMappingActions, SsoGroupMappingActionTypes.CREATE_SINGLE>
) {
  yield new SagaManager()
    .addTracking(ssoGroupMappingActionCreators.createSingle.name)
    .addReduxFormValidation(SSO_GROUP_MAPPING_FORM, action.payload.formValues, validateSsoGroupMappingForm)
    .addReduxFormAsyncValidation(SSO_GROUP_MAPPING_FORM, mapServerErrorsToReduxFormErrors)
    .execute(createSingleSaga, action);
}

function* updateSingleSagaManaged(
  action: ReturnActionOfType<SsoGroupMappingActions, SsoGroupMappingActionTypes.UPDATE_SINGLE>
) {
  yield new SagaManager()
    .addTracking(ssoGroupMappingActionCreators.updateSingle.name)
    .addReduxFormValidation(SSO_GROUP_MAPPING_FORM, action.payload.formValues, validateSsoGroupMappingForm)
    .addReduxFormAsyncValidation(SSO_GROUP_MAPPING_FORM, mapServerErrorsToReduxFormErrors)
    .execute(updateSingleSaga, action);
}

function* updateSingleSaga(
  action: ReturnActionOfType<SsoGroupMappingActions, SsoGroupMappingActionTypes.UPDATE_SINGLE>
) {
  yield call(ssoGroupMappingApi.updateSingle, action.payload.formValues);
  yield put(logActionCreators.logSuccess(translate(translationKeys.messages.ssoGroupMappingSuccessfullyUpdated)));
  yield put(navigationActionCreators.back());
}

function* createSingleSaga(
  action: ReturnActionOfType<SsoGroupMappingActions, SsoGroupMappingActionTypes.CREATE_SINGLE>
) {
  yield call(ssoGroupMappingApi.addSingle, action.payload.formValues);
  yield put(logActionCreators.logSuccess(translate(translationKeys.messages.ssoGroupMappingSuccessfullyCreate)));
  yield put(navigationActionCreators.navigateTo(AUTHENTICATED_ROUTES.SsoGroupMapping));
}

function* deleteSingleSagaManaged(
  action: ReturnActionOfType<SsoGroupMappingActions, SsoGroupMappingActionTypes.DELETE_SINGLE>
) {
  yield new SagaManager()
    .addTracking(ssoGroupMappingActionCreators.deleteSingle.name)
    .execute(deleteSingleSaga, action);
}

function* deleteSingleSaga(
  action: ReturnActionOfType<SsoGroupMappingActions, SsoGroupMappingActionTypes.DELETE_SINGLE>
) {
  yield call(ssoGroupMappingApi.deleteSingle, action.payload.id);
  yield put(logActionCreators.logSuccess(translate(translationKeys.messages.ssoGroupMappingSuccessfullyDeleted)));
  yield put(tableActionCreators.fetchTable(TableName.ssoGroupMapping));
}
