import { Auth } from 'aws-amplify';
import { CognitoAuthenticatedUser } from './authentication.models';
import { authenticationApi } from './authentication.api';
import { axiosAuthenticatedClient } from '../../axios/axios-authenticated-client';
import { env } from '../../env/env';
import { store } from '../../../app/app.store';
import { authenticationActionCreators } from './authentication.actions';

export const handleThirdPartySignIn = async () => {
  try {
    const cognitoUser: CognitoAuthenticatedUser = await authenticationApi.getCurrentAuthenticatedUser();
    if (!cognitoUser) {
      throw new Error();
    }

    const currentSession: any = await authenticationApi.getCurrentSession();
    if (!currentSession) {
      throw new Error();
    }

    const idToken = currentSession.getIdToken().getJwtToken();
    let shouldRefreshUserSession = false;

    if (!cognitoUser.attributes || !cognitoUser.attributes['custom:crs_id']) {
      try {
        await axiosAuthenticatedClient().get(`${env.API_VERSION}/user/first-sso-sign-in/`, {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        });

        shouldRefreshUserSession = true;
      } catch (axiosError: any) {
        console.error('Failed to send first SSO request', axiosError.message);
      }
    }

    let authenticatedCognitoUser: CognitoAuthenticatedUser;
    if (shouldRefreshUserSession) {
      authenticatedCognitoUser = await Auth.currentAuthenticatedUser({ bypassCache: true });
    } else {
      authenticatedCognitoUser = cognitoUser;
    }

    store.dispatch(authenticationActionCreators.setEnterpriseUser(authenticatedCognitoUser));
  } catch (error) {
    console.error('Error fetching session after federated signIn:', error);
  }
};
