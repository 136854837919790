import React from 'react';
import { env } from '../../logic/env/env';

type TrackingScriptOptions = Partial<{ text: string, src: string, async: boolean }>;

export const appendTrackingScript = ({ text, src, async }: TrackingScriptOptions = {}): void => {
  if (text) {
    const script = document.createElement('script');
    script.text = text;
    document.head.appendChild(script);
  }

  if (src) {
    const script = document.createElement('script');
    script.src = src;
    script.async = async || false;
    document.head.appendChild(script);
  }
};

export const TrackingScript = React.memo(() => {
  const clickBackAppId = env.REACT_APP_CB_APPLICATION_ID,
    gtmAppId = env.REACT_APP_GTM_APPLICATION_ID;

  /**
   * @TODO: Remove short-circuit evaluation once CB_APPLICATION_ID & GTM_APPLICATION_ID dev ID's are provided
   */
  React.useEffect(() => {
    // ClickBack
    clickBackAppId && appendTrackingScript({
      text: `
        (function(d,s){
          var DID = "${clickBackAppId}";
          var js, fjs = d.getElementsByTagName(s)[0];
          js = d.createElement(s);
          js.async = 1;
          js.src = "https://track.cbdatatracker.com/Home?v=3&id="+DID;
          fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script'));
      `,
    });

    // GTM
    if (gtmAppId) {
      appendTrackingScript({ src: `https://www.googletagmanager.com/gtag/js?id=${gtmAppId}` });
      appendTrackingScript({
        text: `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${gtmAppId}');
      `,
      });
    }
    
    //Hotjar tracking script
    appendTrackingScript({ 
      text: `
        (function(h,o,t,j,a,r){
            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
            h._hjSettings={hjid:4960269,hjsv:6};
            a=o.getElementsByTagName('head')[0];
            r=o.createElement('script');r.async=1;
            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
            a.appendChild(r);
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
    `})
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
});

TrackingScript.displayName = 'TrackingScript';
