import { DEFAULT_PHONE_COUNTRY_CODE } from '../../../authentication/models/country-code/country-code';
import { isDevelopment } from '../../../../logic/env/env';
import { Company } from '../../../../api-models/api-models';
import { FormErrors } from 'redux-form';
import { clearEmptyFieldsAndEmptyObjects } from '../../../../logic/validators/utils';
import { isValidPhoneNumber } from '../../../../logic/phone/phone.validator';
import { parsePhoneNumberForPayload, mapPhoneToCountryCodeAndNumber } from '../../../../logic/phone/phone.mapper';
import { isRequiredValidator } from '../../../../logic/validators/validators';

export const COMPANY_FORM = 'company';
const DEFAULT_COMPANY_MAX_USERS = 10;
const DEFAULT_COMPANY_MAX_LOCATIONS = 3;

export interface CompanyForm extends Company {
  phone_country_code?: string;
}

const devFields: CompanyForm = isDevelopment()
  ? {
      name: 'Company',
    }
  : {};

export const companyFormInitialValues: CompanyForm = {
  ...devFields,

  max_users_no: DEFAULT_COMPANY_MAX_USERS,
  max_locations_no: DEFAULT_COMPANY_MAX_LOCATIONS,
  tokens: 0,
  is_active: false,
  phone_country_code: DEFAULT_PHONE_COUNTRY_CODE,
};

export const validateCompanyForm = (formValues: CompanyForm): FormErrors<CompanyForm, string> => {
  const {
    name,
    phone,
    phone_country_code,
    max_users_no,
    is_trial,
    trial_to_date,
    trial_alerts_limit,
    max_locations_no,
  } = formValues;

  return clearEmptyFieldsAndEmptyObjects({
    name: isRequiredValidator(name),
    phone: !!phone ? isValidPhoneNumber(phone, phone_country_code) : undefined,
    max_users_no: isRequiredValidator(max_users_no),
    max_locations_no: isRequiredValidator(max_locations_no),
    trial_to_date: is_trial ? isRequiredValidator(trial_to_date) : undefined,
    trial_alerts_limit: is_trial ? isRequiredValidator(trial_alerts_limit) : undefined,
  });
};

export const companyToFormValuesMapper = (company: Company): CompanyForm => {
  const phone_split = mapPhoneToCountryCodeAndNumber(company.phone);
  return {
    ...company,
    phone_country_code: phone_split?.code || DEFAULT_PHONE_COUNTRY_CODE,
    phone: phone_split?.number || undefined,
  };
};

export const formValuesToCompanyMapper = (formValues: CompanyForm): Company => {
  const { phone, phone_country_code } = formValues;

  return {
    ...formValues,
    phone: parsePhoneNumberForPayload(phone_country_code, phone),
  };
};
