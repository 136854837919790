import { SsoCompanyAdminsForm } from '../../../pages/sso-company-admins/pages/sso-company-admins-form-template/sso-company-admins-form.form';
import { ActionsUnion, createAction } from '../../redux/action-creator';

export enum SsoCompanyAdminsActionTypes {
  RESET = 'sso-company-admins/RESET',
  CREATE_SINGLE = 'sso-company-admins/CREATE_SINGLE',
  DELETE_SINGLE = 'sso-company-admins/DELETE_SINGLE',
}

export const ssoCompanyAdminsActionCreators = {
  reset: () => createAction(SsoCompanyAdminsActionTypes.RESET),
  createSingle: (formValues: SsoCompanyAdminsForm) =>
    createAction(SsoCompanyAdminsActionTypes.CREATE_SINGLE, { formValues }),
  deleteSingle: (id: number) => createAction(SsoCompanyAdminsActionTypes.DELETE_SINGLE, { id }),
};

export type SsoCompanyAdminsActions = ActionsUnion<typeof ssoCompanyAdminsActionCreators>;
