import { reduxForm } from 'redux-form';
import { UserFormTemplatePage, UserFormPageOwnProps } from './user-form-template/user-form-template.page';
import { compose } from 'redux';
import { translate, translationKeys } from '../../../logic/translations/translations.service';
import { USER_FORM, validateUserForm } from './user-form-template/user-form.form';
import { UserFormFieldName } from './user-form-template/user-form-field-name';
import { userActionCreators } from '../../../logic/store/user/user.action';
import { AUTHENTICATED_ROUTES } from '../../../logic/routes/routes.const';
import { wrapComponentsWithProps } from '../../../logic/hoc/wrap-components-with-props.hoc';

export const SuperAdminEditSuperAdminPage = compose(
  wrapComponentsWithProps<UserFormPageOwnProps>({
    fieldsToDisplay: [
      UserFormFieldName.first_name,
      UserFormFieldName.last_name,
      UserFormFieldName.email,

      UserFormFieldName.permission_level,
    ],
    disabledFields: [UserFormFieldName.permission_level, UserFormFieldName.email],
    requiredFields: [
      UserFormFieldName.first_name,
      UserFormFieldName.last_name,
      UserFormFieldName.email,

      UserFormFieldName.permission_level,
    ],
    submitButtonText: translate(translationKeys.buttons.updateSuperAdmin),
    submitFormAction: userActionCreators.updateSingle,
    submitSuccessMessage: translate(translationKeys.messages.superAdminWasSuccessfullyUpdated),
    submitSuccessRedirectionRoute: AUTHENTICATED_ROUTES.SuperAdminSuperAdminUsers,
    formName: USER_FORM,
  }),
  reduxForm({
    form: USER_FORM,
    validate: validateUserForm,
  })
)(UserFormTemplatePage) as React.FC;
