import styled from 'styled-components';
import { colors } from '../../../../../../styles/colors';

export const AboutTrial = styled.div`
  font-family: Poppins;
  @media screen and (max-width: $sm) {
    height: -webkit-fill-available;
  }
  background-color: ${colors.blackPearl};
  color: ${colors.white};
  height: 100vh;
  margin-left: -16px;
  margin-right: -16px;
  margin-bottom: -16px;
  padding-top: 132px;
  display: flex;
  justify-content: center;
`;

export const AboutTrialContent = styled.div`
  max-width: 607px;
  width: 100%;
`;

export const TrialFeatures = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
`;

export const TrialFeaturesRow = styled.div`
  @media screen and (max-width: $sm) {
    flex-direction: column;
    align-items: center;
  }
  display: flex;
  justify-content: space-between;
`;

export const TrialFeaturesItem = styled.div`
  height: 150px;
  width: 251px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const IconContainer = styled.div`
  height: 56px;
  width: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.curiousBlue};
  border: 4px solid rgba(23, 82, 117, 1);
  border-radius: 50%;
  margin-bottom: 12px;
`;
