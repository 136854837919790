import { isArray, reduce, pick, omit, map, isObject, isString } from 'lodash';
import { clearEmptyFields } from '../validators/utils';
import { flatten, unflatten } from '../utils/flatten';
import { translate, translationKeys } from '../translations/translations.service';

interface ServerErrors {
  [key: string]: ServerErrors | string[];
}

const mapServerErrorArray = (
  errors: Array<string | ServerErrors> | string | undefined
): string | Array<string | ServerErrors> | undefined => {
  if ((!!errors && isArray(errors) && errors.length > 0) || isString(errors)) {
    if (isString(errors)) {
      return errors;
    }

    if (isString(errors[0])) {
      return errors.join(',');
    }

    return errors.map(error => {
      if (isString(error)) {
        return error;
      } else {
        return mapServerErrorObject(error);
      }
    });
  }
};

export const mapServerErrorObject = (serverError: ServerErrors): any => {
  if (isString(serverError) || isArray(serverError)) {
    return {};
  }

  return clearEmptyFields(
    reduce(
      serverError || {},
      (result, value, key) => ({
        ...result,
        [key]:
          isArray(value) || isString(value)
            ? mapServerErrorArray(value)
            : isObject(value)
            ? mapServerErrorObject(value)
            : undefined,
      }),
      {}
    )
  );
};

export const errorFieldAndValuesToString = (obj: object | undefined) =>
  map(obj, (value, key) => `${key}: ${value}`).join(', ');

export const errorFieldValuesToString = (obj: object) => {
  if (!obj || obj === null) return '';
  if (isHtml(obj)) return translate(translationKeys.errors.unknown);
  if (isString(obj)) return obj;

  return map(obj, value => value).join(', ');
};

export const HTML_INDICATOR = '<!DOCTYPE html>';
export const isHtml = (obj: object | string | null | undefined) =>
  obj ? JSON.stringify(obj).indexOf(HTML_INDICATOR) >= 0 : false;

export const mapServerErrorsToReduxFormErrors = (
  serverError: ServerErrors | undefined | null,
  formFields: string[] | undefined = []
) => {
  const allErrors = flatten(mapServerErrorObject(serverError || {}));
  const errorInsideForm = pick(allErrors, formFields);
  const errorOutsideForm = omit(allErrors, formFields);
  const _error = errorFieldAndValuesToString(errorOutsideForm);

  const result = {
    ...unflatten(errorInsideForm),
    _error,
  };

  return clearEmptyFields(result);
};
