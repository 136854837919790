import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import { validateCompanyDomainsForm } from './domains-forms/company-domains-form.form';
import { translate, translationKeys } from '../../../logic/translations/translations.service';
import { companyDomainsActionCreators } from '../../../logic/store/company-domains/company-domains.action';
import { wrapComponentsWithProps } from '../../../logic/hoc/wrap-components-with-props.hoc';
import { CompanyDomainsFormPage, CompanyDomainsFormPageOwnProps } from './domains-forms/company-domains-form-template.page';
import { COMPANY_DOMAINS_FORM } from './domains-forms/company-domains-form.form';
import { CompanyDomainsFormFieldName } from './domains-forms/company-domain-form.field-name';

export const CompanyDomainsEditPageCreator = () => {
  return compose(
    wrapComponentsWithProps<CompanyDomainsFormPageOwnProps>({
      submitButtonText: translate(translationKeys.buttons.updateCompanyDomain),
      submitFormAction: companyDomainsActionCreators.updateSingle,
      formName: COMPANY_DOMAINS_FORM,
      fieldsToDisplay: [CompanyDomainsFormFieldName.company, CompanyDomainsFormFieldName.company_name, CompanyDomainsFormFieldName.domain],
      disabledFields: [CompanyDomainsFormFieldName.company_name],
    }),
    reduxForm({
      form: COMPANY_DOMAINS_FORM,
      validate: validateCompanyDomainsForm,
    })
  )(CompanyDomainsFormPage) as React.FC;
};
